import React, { useEffect, useState } from 'react';
import { Form, Col, Table } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import styles from '../AlumniVerificationTabs.module.scss';
import Pagination from '../../core-components/Pagination/Pagination';
import EmptyTableBody from '../../core-components/EmptyTableBody/EmptyTableBody';
import Filters from './Filters';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { capitalize } from 'lodash';
import {
  getEventType,
  getEventTypeIcon,
  getIconBasedOnStatus,
  getMode,
  getTooltipBasedOnStatus
} from '../utils';
import { capitalizeName } from '../../utils/Utilities';

export default function DataSyncLogTab(props) {
  const { dataSyncLogs, dataSyncLogsCount, isSA } = props;
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({
    mode: null,
    status: null
  });
  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;
  const companyId = new URLSearchParams(document?.location?.search).get('id');

  useEffect(() => {
    fetchRequestList(filterValues);
  }, [offset, limit]);
  do {
    const value = pageSizes[index] > dataSyncLogsCount ? dataSyncLogsCount : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < dataSyncLogsCount);

  useEffect(() => {
    setOffset(0);
    setPageNumber(0);
    setLimit(10);
    setPageSize(10);
  }, [filterValues]);

  const fetchRequestList = (data) => {
    const body = { ...data };
    if (body?.mode === 'Manual') body.mode = 'MANUAL';
    const where = {
      limit: limit,
      offset: offset,
      companyId,
      ...body
    };
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    props.getDataSyncLogsData(where).catch((error) => {
      console.error('Error:', error);
    });
  };

  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };

  const getStatus = (desc, eventType) => {
    if (eventType === 'DISCONNECTION' || eventType === 'CONNECTION') {
      return (
        <div>
          {getEventTypeIcon(eventType)} {getEventType(eventType)}
        </div>
      );
    }
    if (!desc) {
      return '-';
    }

    const filteredData = Object.entries(desc).filter(([key, value]) => value > 0);
    const [skippedIndex, failedIndex] = ['skipped', 'failed'].map((key) =>
      filteredData.findIndex(([k, v]) => k === key)
    );
    if (skippedIndex !== -1 && failedIndex !== -1) {
      filteredData[failedIndex][1] += filteredData[skippedIndex][1];
      filteredData.splice(skippedIndex, 1);
    } else if (skippedIndex !== -1) {
      filteredData[skippedIndex][0] = 'failed';
    }

    return (
      filteredData &&
      filteredData.length > 0 &&
      filteredData.map(([key, value], index) => (
        <div className={styles.statusChips} key={key}>
          <OverlayTrigger
            key={`status_${index}`}
            placement='bottom'
            overlay={
              <Tooltip id={`status${index}`}>
                <p>{getTooltipBasedOnStatus(key, value) || '-'}</p>
              </Tooltip>
            }
          >
            {getIconBasedOnStatus(key)}
          </OverlayTrigger>{' '}
          {capitalize(key)}: {value}
        </div>
      ))
    );
  };
  const getUserName = (userObj) => {
    if (userObj) {
      return (
        <span className={userObj.deletedAt ? styles.deletedUser : ''}>
          {capitalizeName(userObj.name)}
        </span>
      );
    } else {
      return '-';
    }
  };
  return (
    <div className={styles.recentTableContainer}>
      <Filters
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        fetchRequestList={fetchRequestList}
        isSA={isSA}
      />
      <Table className={styles.recentTable}>
        <thead>
          <tr>
            <th>Event ID</th>
            <th>Timestamp</th>
            <th>Event Type</th>
            <th>Mode</th>
            <th className={styles.userRow}>User</th>
            <th>Status/ Description</th>
          </tr>
        </thead>
        {dataSyncLogsCount > 0 ? (
          <tbody>
            {loading
              ? Array.apply(null, { length: pageSize })
                  .map(Number.call, Number)
                  .map((loadData, i) => {
                    return (
                      <tr key={i}>
                        {Array(6)
                          .fill(null)
                          .map(() => {
                            return (
                              <td>
                                <ContentLoader height={20} width={60}>
                                  <rect x='0' y='0' width='60' height='14' />
                                </ContentLoader>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
              : dataSyncLogs.map((val, i) => {
                  return (
                    <tr key={'tr_' + i}>
                      <td>{val?.id || '-'}</td>
                      <td>
                        {moment
                          .utc(val?.createdAt)
                          .utcOffset('+05:30')
                          .format('DD-MM-YYYY HH:mm:ss') || '-'}
                      </td>
                      <td>{getEventType(val?.eventType) || '-'}</td>
                      <td>{getMode(val?.mode) || '-'}</td>
                      <td className={styles.userRow}>{getUserName(val?.createdByUser)}</td>
                      <td>
                        <div className={styles.descContainer}>
                          {getStatus(val?.description, val?.eventType)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        ) : (
          <EmptyTableBody isSA={isSA} name='No data sync logs found' />
        )}
      </Table>
      {dataSyncLogsCount > 0 && (
        <Form.Row className={styles.paginationWrapper}>
          <Col className={styles.showPagination}>
            Show{' '}
            <select
              className={styles.selectPagination}
              value={limit}
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                setPageSize(parseInt(event.target.value));
                paginate(0, 0);
              }}
            >
              {pageSizeOptions}
            </select>{' '}
            items of {dataSyncLogsCount}
          </Col>
          <Col>
            <Pagination
              forcePage={pageNumber}
              onPageChange={({ selected }) => {
                paginate(selected, selected);
              }}
              pageCount={Math.ceil(dataSyncLogsCount / pageSize)}
            />
          </Col>
          <Col />
        </Form.Row>
      )}
    </div>
  );
}
