// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t21uL_MsGyDksTC5Z6yf {
  min-height: 80vh;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .t21uL_MsGyDksTC5Z6yf {
    width: 1200px;
  }
}
.t21uL_MsGyDksTC5Z6yf .QWgPt9Be228ziBso90uZ {
  margin-top: -190px;
  display: flex;
  justify-content: space-between;
}
.t21uL_MsGyDksTC5Z6yf .QWgPt9Be228ziBso90uZ ._ojiNtE2uK6YKNPdIXOS {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.t21uL_MsGyDksTC5Z6yf .QWgPt9Be228ziBso90uZ .xRBfoY5hhacRjtdGA3mM {
  background: #2755fe;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 14px 24px 14px 23px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS .bRh8gaQpqe_AEHPoX81A {
  min-height: inherit;
  padding: 50px 50px 0px 50px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS .tXA64dMlzslwSZCEHpHW {
  min-height: inherit;
  padding-inline: 35px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS .FP_kQkkx4rDpPKSDjzV9 {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  padding: 24px 28px 0px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA {
  border: 0px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC {
  word-break: break-all;
  margin: auto;
  margin-top: 22px;
  margin-bottom: 24px;
  width: 97%;
  text-align: left;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC thead {
  background-color: #f2f8ff;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC thead tr th {
  white-space: normal;
  word-wrap: break-word;
  border: 0px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 15px 25px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC thead tr .GT0QaZcUYsJv6TP2LBts {
  text-align: right;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr td {
  max-width: 20vw;
  white-space: normal;
  /* Only needed when it's set differntly somewhere else */
  word-wrap: break-word;
  cursor: default;
  border: 0px;
  text-align: center;
  vertical-align: middle;
  padding: 15px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr td .VYZ7WclbClesj_7VlKeK {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  align-items: center;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr td .VYZ7WclbClesj_7VlKeK .wGuCvm3m5qiWvFMUXqRJ {
  border: 1px transparent solid;
  background: transparent;
  padding: 3px;
  border-radius: 50%;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr td .VYZ7WclbClesj_7VlKeK .wGuCvm3m5qiWvFMUXqRJ:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
  border-radius: 50%;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr td .VYZ7WclbClesj_7VlKeK .wGuCvm3m5qiWvFMUXqRJ:hover svg path {
  fill: #2755fe;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr td .VYZ7WclbClesj_7VlKeK .bP8RsK_sF4hXOeP2L7a0 {
  margin-left: 24px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr .GT0QaZcUYsJv6TP2LBts {
  text-align: right;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr:hover {
  background-color: #f5f5f5;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC tbody tr:hover .aw4SoSyGflPQmUEG0pzs {
  color: #366cf3;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC .btfMOPu65oPvlpscl6_X {
  text-align: center;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC .eHJZuMfvZCun29Czxkmv {
  text-align: center;
  padding-top: 64px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .ZyBPd3z0KeqwHxbRMyIC .eHJZuMfvZCun29Czxkmv .GWzMNJhmGnd22lP3kmKL {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .Da_qDNdGSf9KTahL_5OX {
  flex-wrap: unset;
  padding: 0 24px 15px;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .Da_qDNdGSf9KTahL_5OX .nd65P3e5Igfqo9JPd0ey {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.t21uL_MsGyDksTC5Z6yf .xClO8GKtxeLi2e3SHeJS ._My7x0lyC1NlKC_Gx5GA .Da_qDNdGSf9KTahL_5OX .nd65P3e5Igfqo9JPd0ey .bxBV96hlA1zzk8ufQved {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.xClO8GKtxeLi2e3SHeJS {
  margin: 30px 0 48px 0;
}

.NwrYdpbdXDOy3RtDmcCS {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.bdzyRoqVnK9KKZX8bAHB {
  margin-top: 5rem;
  padding: 2rem;
  padding-left: 3rem;
  display: flex;
  justify-content: flex-end;
}

.SgDEtxnrLYqTmll3Xskl {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  min-width: 100px;
  margin-right: 1rem;
  box-shadow: none;
  border: 1px solid #2755fe;
  font-family: Poppins, sans-serif;
}

.mKX8lD8skDqQsbcGJugi {
  background: #ffffff;
  color: rgb(232, 79, 79);
  cursor: pointer;
  border: 1px solid rgb(232, 79, 79);
  margin-right: 32px;
  box-shadow: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.mKX8lD8skDqQsbcGJugi:hover {
  background: #e37272;
  color: #fff;
  border: 1px solid rgb(232, 79, 79);
}
.mKX8lD8skDqQsbcGJugi:active {
  background: #e37272;
  color: #fff;
}
.mKX8lD8skDqQsbcGJugi:disabled {
  background: #e69696;
  color: #fff;
  border: 1px solid #e69696;
}
.mKX8lD8skDqQsbcGJugi:focus {
  background: #e37272;
  color: #fff;
  box-shadow: none !important;
}

.vMXaUNT05q_DM__J4OKQ {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  padding-bottom: 3rem;
  margin-right: -2rem;
}

.ZwnZLSQj28bjT8lXdjvm {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: -0.9rem;
}

.AfRaLJFBI8069FksTTUP {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: -0.9rem;
}

.gCHch4Lz6wkYYv7pq303 {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
}

.e383WVUxxoXNzryJq185 {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.ynNIdPEkzAiD51M0IEz5 {
  border: 1px solid red;
}

.E7zp1Gh8PykQg5wo2XAb {
  width: "100%";
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  padding-top: 1rem;
}

.CRqzxSFvhDuUIORniwpZ {
  padding-left: 1rem;
}

.rD_u8Jdvsn4ykYW2nhfJ {
  display: flex;
}

.axJd8MJbgwHoMhfKqtUX {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-right: 10px;
  color: #464457;
}

.P5s6JQbMuOkGdajSMg74 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #666666;
}

.eke3RoVIph6J8NBXkdYq {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #2755fe;
  cursor: pointer;
}

.nJ_NBziDnvIr3o_xo27x {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #333333;
}

.MWx4taPQmBB0I3ZMMA7p {
  margin-bottom: -2rem;
}
.MWx4taPQmBB0I3ZMMA7p ._7PGUBdEoDH_JNRcb0dz {
  display: flex;
}

.dRL3bPZrEqWkzm5Bpc3j {
  margin-top: 1rem;
}

.uIaCHuS8Jje54ZaQR6lG {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0.5rem;
  color: #464457;
}

.F9lZOaNBIM_WwXKWrRzO {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.D9_h8IrP3THb05Takd1g {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin-left: -14px;
}

.DT8CKPvZcU8QMMMyFCyr {
  color: #2755fe;
  cursor: pointer;
  display: flex;
  margin-top: 0.4rem;
  gap: 0.25rem;
}

.llOcUWRxPyuS0jL_qTKv {
  margin-top: 0.2rem;
  width: 1rem;
  height: 1rem;
}

.MU1ilnedl2q7l9gs9EyN {
  margin-top: 1.5rem;
}

.aOOOlcgTW0WlZKbOeJmt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #464457;
  margin-bottom: 0.5rem;
}

.y7FNLpXHPgQSj1ul2nAp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.qMgiKZyEK19ez8LwSouA {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin-left: -14px;
}

.KfzPzLrX4yoOptlKz6Mh {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #666666;
}

._gxXbtq9zHN54W7Qbs4Z {
  width: 378px;
}

.koqs1XBmU5CsEfjR1OZa {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.T71y8S_xMeqPueSeS0E3 {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: -14px;
}

.sgk44Rl5GIulm5vZVMD3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #464457;
}

.BILhyZ2fVWuSkNd393TR {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #333333;
}

.ZSM1J0T9EOElfxL6acAK {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.Dt5x3W8M7R81PMDaQT8N {
  display: flex;
  gap: 0.75rem;
}

.JZc39qZKQFlKje0k3znw {
  color: #2755fe;
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
}

.iSDXzR2Hbvyymn_1yNwQ {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
  margin-top: 0.1rem;
}

.wj_MtlqaMGdKq_Upz9TD {
  padding: 0 !important;
  margin: 0 0 0.5rem 0;
}
.wj_MtlqaMGdKq_Upz9TD ._hGIbb37Fgxl1PU0UaUF {
  display: flex;
  flex-direction: column;
}

.JARuxiDWMLQjgYM9wfsV {
  padding: 0 !important;
}

.lnbogwittsSMfa1oB3Ln {
  font-size: 12px;
  color: rgb(232, 79, 79);
}

.WMkOedUB8JRa7XIGkUaI {
  background-color: #c4e8d0;
  color: #4ea56c;
  align-items: center;
  margin-left: 10px;
}

.Aqj16LiiQzTV2R2QPhcN {
  border: 1px solid red;
  margin-right: 2.5rem;
}

.mVm_fngTVmdy0sxlYn6s {
  background-color: #2755fe;
}

.mVm_fngTVmdy0sxlYn6s:hover {
  background-color: #1f44ca;
}

.bi3yG37uws9yv8oPlDNE {
  color: #ff0000;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentDivWrapper": `t21uL_MsGyDksTC5Z6yf`,
	"header": `QWgPt9Be228ziBso90uZ`,
	"heading": `_ojiNtE2uK6YKNPdIXOS`,
	"generateTokenButton": `xRBfoY5hhacRjtdGA3mM`,
	"bodyCard": `xClO8GKtxeLi2e3SHeJS`,
	"parentDivTopTextArea": `bRh8gaQpqe_AEHPoX81A`,
	"parentDivInputArea": `tXA64dMlzslwSZCEHpHW`,
	"adminCardHeading": `FP_kQkkx4rDpPKSDjzV9`,
	"tableCard": `_My7x0lyC1NlKC_Gx5GA`,
	"mainTable": `ZyBPd3z0KeqwHxbRMyIC`,
	"alignRight": `GT0QaZcUYsJv6TP2LBts`,
	"icons": `VYZ7WclbClesj_7VlKeK`,
	"icon": `wGuCvm3m5qiWvFMUXqRJ`,
	"space": `bP8RsK_sF4hXOeP2L7a0`,
	"mainField": `aw4SoSyGflPQmUEG0pzs`,
	"subHeader": `btfMOPu65oPvlpscl6_X`,
	"noDataWrapper": `eHJZuMfvZCun29Czxkmv`,
	"noDataContent": `GWzMNJhmGnd22lP3kmKL`,
	"paginationWrapper": `Da_qDNdGSf9KTahL_5OX`,
	"showPagination": `nd65P3e5Igfqo9JPd0ey`,
	"selectPagination": `bxBV96hlA1zzk8ufQved`,
	"listItems": `NwrYdpbdXDOy3RtDmcCS`,
	"parentButtonContainer": `bdzyRoqVnK9KKZX8bAHB`,
	"activeBtn": `SgDEtxnrLYqTmll3Xskl`,
	"cancelBtn": `mKX8lD8skDqQsbcGJugi`,
	"footerButtonContainer": `vMXaUNT05q_DM__J4OKQ`,
	"step1FooterContainer": `ZwnZLSQj28bjT8lXdjvm`,
	"step2FooterContainer": `AfRaLJFBI8069FksTTUP`,
	"goBackBtn": `gCHch4Lz6wkYYv7pq303`,
	"goBackKNITBtn": `e383WVUxxoXNzryJq185`,
	"removeButton": `ynNIdPEkzAiD51M0IEz5`,
	"dropdownContainer": `E7zp1Gh8PykQg5wo2XAb`,
	"topAreaHeaderTextContainer": `CRqzxSFvhDuUIORniwpZ`,
	"topHeaderContainer": `rD_u8Jdvsn4ykYW2nhfJ`,
	"topHeaderText": `axJd8MJbgwHoMhfKqtUX`,
	"topHeaderSubtext": `P5s6JQbMuOkGdajSMg74`,
	"topHeaderRedirectText": `eke3RoVIph6J8NBXkdYq`,
	"listItemsHeader": `nJ_NBziDnvIr3o_xo27x`,
	"topAreaTextContainer": `MWx4taPQmBB0I3ZMMA7p`,
	"imageAndTitleContainer": `_7PGUBdEoDH_JNRcb0dz`,
	"settingPageContainer": `dRL3bPZrEqWkzm5Bpc3j`,
	"settingPageHeaderText": `uIaCHuS8Jje54ZaQR6lG`,
	"settingPageHeaderSubText": `F9lZOaNBIM_WwXKWrRzO`,
	"settingPageHeaderSubText1": `D9_h8IrP3THb05Takd1g`,
	"refreshContainer": `DT8CKPvZcU8QMMMyFCyr`,
	"refreshImageContainer": `llOcUWRxPyuS0jL_qTKv`,
	"secondPageSubSectionContainer": `MU1ilnedl2q7l9gs9EyN`,
	"settingPageSubSectionHeader": `aOOOlcgTW0WlZKbOeJmt`,
	"settingPageSubSectionList": `y7FNLpXHPgQSj1ul2nAp`,
	"settingPageSubSectionList1": `qMgiKZyEK19ez8LwSouA`,
	"dropDownNoOptionMessage": `KfzPzLrX4yoOptlKz6Mh`,
	"tagsDropDown": `_gxXbtq9zHN54W7Qbs4Z`,
	"packageSelectorHeaderContainer": `koqs1XBmU5CsEfjR1OZa`,
	"packageSelectorHeaderContainer1": `T71y8S_xMeqPueSeS0E3`,
	"packageTextStyle": `sgk44Rl5GIulm5vZVMD3`,
	"packageNameText": `BILhyZ2fVWuSkNd393TR`,
	"packageSubtypeNameText": `ZSM1J0T9EOElfxL6acAK`,
	"refreshPackageContainer": `Dt5x3W8M7R81PMDaQT8N`,
	"refreshPackageButton": `JZc39qZKQFlKje0k3znw`,
	"refreshText": `iSDXzR2Hbvyymn_1yNwQ`,
	"packageName": `wj_MtlqaMGdKq_Upz9TD`,
	"text": `_hGIbb37Fgxl1PU0UaUF`,
	"packageNameHeader": `JARuxiDWMLQjgYM9wfsV`,
	"errorInline": `lnbogwittsSMfa1oB3Ln`,
	"connectedFlag": `WMkOedUB8JRa7XIGkUaI`,
	"removeButtonRepute": `Aqj16LiiQzTV2R2QPhcN`,
	"accentColor": `mVm_fngTVmdy0sxlYn6s`,
	"mandatoryField": `bi3yG37uws9yv8oPlDNE`
};
export default ___CSS_LOADER_EXPORT___;
