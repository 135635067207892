// Import necessary libraries
import React, { useState, useEffect, useRef } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

// Import styles
import styles from './CommonStyles.module.scss';

// Import components
import ImageUpload from './ImageUpload';
import SampleModal from './SampleModal';
import CustomDropDownWithTags from './CustomDropDown/CustomDropDownWithTags';

// Import utilities
import { handleCompanyUrlValidation, processHrmsAtsOptions } from '../../utils/Utilities';
import { atsList, hrmsList } from '../../utils/CommonConstants';
import { integrationsCardData } from '../../Integrations/integrationItems';
import { getIntegrations } from '../../../../../api/company';

const BrandPage = (props) => {
  const { Button, fileUploadAPI, brandSettings, getBrandSettings, editBrandSettings, isSa } = props;

  /**
   * State Variables
   */
  const [formData, setFormData] = useState({
    name: '',
    friendlyName: '',
    logoUrlId: '',
    companyLogos: {}
  });
  const [logoFile, setLogoFile] = useState({
    dark: null,
    light: null
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [brandNameError, setBrandNameError] = useState(null);
  const [companyUrlError, setCompanyUrlError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoError, setLogoError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLogoDeleteModal, setShowLogoDeleteModal] = useState(false);
  const [integrationsData, setIntegrationsData] = useState({});
  const [atsOptions, setAtsOptions] = useState([]);
  const [hrmsOptions, setHrmsOptions] = useState([]);
  const [atsSelectedOptions, setAtsSelectedOptions] = useState([]);
  const [hrmsSelectedOptions, setHrmsSelectedOptions] = useState([]);
  const [companyUrl, setCompanyUrl] = useState('');

  // Refs to store initial values
  const initialAtsOptions = useRef([]);
  const initialHrmsOptions = useRef([]);
  const initialCompanyUrl = useRef('');

  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  /**
   * Use-Effect Hooks
   */

  useEffect(() => {
    getBrandSettingsApi();
    if (!isSa) {
      getIntegrations().then((response) => {
        const values = {
          connectedIntegrations: [],
          notConnectedButAvailableIntegrations: [],
          notConnectedAndUnavailableIntegrations: []
        };
        const integrationData = response.data.data?.integrations;

        if (integrationData) {
          const cleanIntegrationApiData = [];

          Object.keys(integrationData).map((key) => {
            const cleanedKey = key.replace(/_/g, '');
            cleanIntegrationApiData.push(cleanedKey.toUpperCase());

            if (cleanedKey === 'REPUTE') {
              const reputeData = integrationData?.[key];
              const integrationTitle = reputeData?.hrms_name;

              if (integrationTitle) {
                values.connectedIntegrations.push(integrationTitle);
              }
            } else if (integrationsCardData?.[cleanedKey.toUpperCase()]) {
              const integrationTitle = integrationsCardData?.[cleanedKey.toUpperCase()].title;
              values.connectedIntegrations.push(integrationTitle);
            }
          });

          Object.keys(integrationsCardData).map((key) => {
            if (!cleanIntegrationApiData.includes(key)) {
              const integrationTitle = integrationsCardData?.[key].title;

              if (integrationsCardData?.[key]?.comingSoon) {
                values.notConnectedAndUnavailableIntegrations.push(integrationTitle);
              } else {
                values.notConnectedButAvailableIntegrations.push(integrationTitle);
              }
            }
          });

          setIntegrationsData(values);
        }
      });
    }
  }, []);

  const updateForm = () => {
    setFormData({
      name: brandSettings?.data?.name || '',
      friendlyName: brandSettings?.data?.friendlyName || '',
      logoUrlId: brandSettings?.data?.logoUrlId || '',
      companyLogos: brandSettings?.data?.companyLogos?.reduce(
        (logoObject, logo) => ({ ...logoObject, [logo.logoType]: logo }),
        {}
      )
    });

    /**
     * ATS, HRMS and Company URL fields are available on CA side only
     */
    if (!isSa) {
      initialCompanyUrl.current = brandSettings?.data?.companyUrl || '';
      setCompanyUrl(brandSettings?.data?.companyUrl || '');

      // Process options for the drop-down
      setAtsOptions(processHrmsAtsOptions(atsList, integrationsData, true));
      setHrmsOptions(processHrmsAtsOptions(hrmsList, integrationsData, false));

      const metadata = brandSettings?.data?.companyMetadata;

      let atsData = [];
      let hrmsData = [];

      if (metadata) {
        for (const data of metadata) {
          if (data?.type === 'ats') {
            atsData = data?.value;
          } else if (data?.type === 'hrms') {
            hrmsData = data?.value;
          }
        }
      }

      const processedAtsValues = processHrmsAtsOptions(atsData, integrationsData, true);
      const processedHrmsValues = processHrmsAtsOptions(hrmsData, integrationsData, false);

      // Set Prefilled values
      setAtsSelectedOptions(processedAtsValues);
      setHrmsSelectedOptions(processedHrmsValues);

      initialAtsOptions.current = processedAtsValues;
      initialHrmsOptions.current = processedHrmsValues;
    }
  };

  const isValidImageUrl = async (url) => {
    if (!url) return null;

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img.height > 60);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const setLogoErrors = async () => {
    const logos = Object.values(formData.companyLogos).map((e) => e.logoUrl);
    const isValid = await Promise.all(logos.map(isValidImageUrl));
    if (isValid.includes(false)) {
      setLogoError('Upload an image of size more than 60px');
    } else {
      setLogoError(null);
    }
  };

  useEffect(() => {
    if (brandSettings?.data) {
      updateForm();
    }
  }, [brandSettings, integrationsData]);

  useEffect(() => {
    setLogoErrors();
  }, [formData]);

  useEffect(() => {
    /**
     * ATS, HRMS and Company URL fields are available on CA side only
     */
    if (!isSa) {
      const atsChanged =
        JSON.stringify(initialAtsOptions.current) !== JSON.stringify(atsSelectedOptions);
      const hrmsChanged =
        JSON.stringify(initialHrmsOptions.current) !== JSON.stringify(hrmsSelectedOptions);
      const companyUrlChanged = initialCompanyUrl.current !== companyUrl;

      setHasUnsavedChanges(atsChanged || hrmsChanged || companyUrlChanged);
    }
  }, [atsSelectedOptions, hrmsSelectedOptions, companyUrl]);

  /**
   * Helper Functions
   */

  const getBrandSettingsApi = () => {
    if (isSa) getBrandSettings(props?.companyId);
    else getBrandSettings();
  };

  const handleFormChange = (field, value) => {
    setHasUnsavedChanges(true);
    if (field === 'friendlyName') {
      const brandNameRegex = /^[a-zA-Z0-9 &().]*$/;
      if (!brandNameRegex.test(value)) {
        setBrandNameError('Please enter a valid Brand Name');
      } else {
        setBrandNameError(null);
      }
    } else {
      // if logo is changed
      let logoType = field;
      let logoUrl = value;

      field = 'companyLogos';
      value = {
        ...formData.companyLogos,
        [logoType]: {
          ...formData.companyLogos[logoType],
          logoUrl
        }
      };
      setIsLogoUpdated(true);
    }
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const onCancel = () => {
    getBrandSettingsApi();
    setHasUnsavedChanges(false);
    setLogoFile({
      dark: null,
      light: null
    });
    setIsLogoUpdated(false);
  };

  const uploadLogo = async (file, fileType) => {
    if (file) {
      const resp = await fileUploadAPI({
        file,
        companyId: props?.companyId || props?.profile?.company_user_mapping?.company_id || null,
        candidateId: props?.profile?.company_user_mapping?.company_id || props?.companyId || null,
        fileType: fileType
      });

      return resp.data.data;
    }
    return null;
  };

  const deleteLogo = (deletingLogo) => {
    handleFormChange(deletingLogo, '');
    setLogoError(null);
    setShowLogoDeleteModal(false);
  };

  const onSave = async () => {
    setIsSubmitting(true);
    try {
      const [newDarkLogoUrl, newLightLogoUrl] = await Promise.all([
        uploadLogo(logoFile.dark, 'COMPANY_LOGO'),
        uploadLogo(logoFile.light, 'COMPANY_LOGO')
      ]);

      const request = {
        friendlyName: formData.friendlyName,
        logoUrlId: formData.logoUrlId || null,
        companyLogos: formData?.companyLogos || { dark: {}, light: {} }
      };

      if (!isSa) {
        request.companyUrl = companyUrl;
        request.ats = atsSelectedOptions.map((option) => option.value);
        request.hrms = hrmsSelectedOptions.map((option) => option.value);
      }

      if (newDarkLogoUrl) {
        request.companyLogos.dark.logoUrl = newDarkLogoUrl;
      }
      if (newLightLogoUrl) {
        request.companyLogos.light.logoUrl = newLightLogoUrl;
      }

      request.companyLogos = Object.keys(request.companyLogos).reduce((acc, key) => {
        const brandLogo =
          brandSettings?.data?.companyLogos.find((logo) => logo.logoType === key) || {};
        if (request.companyLogos[key].logoUrl !== brandLogo?.logoUrl) {
          acc[key] = request.companyLogos[key];
        }
        return acc;
      }, {});

      if (isSa) {
        request.company_id = props?.companyId;
        await editBrandSettings({ companyId: props?.companyId, data: request });
      } else {
        await editBrandSettings(request);
      }
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
    setHasUnsavedChanges(false);
    setLogoFile({
      dark: null,
      light: null
    });
    setIsLogoUpdated(false);
  };

  const handleBlurCompanyUrl = async (e) => {
    handleCompanyUrlValidation(e, setCompanyUrl, setCompanyUrlError);
  };

  return (
    <>
      <Form>
        <Card className={styles.communicationCard}>
          <div className={styles.tabInfoText}>
            This is the designated space to Tailor your Company and brand information.{' '}
          </div>
          <Card className={styles.communicationContainer}>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Legal Name</div>
              <div className={styles.input}>
                <Form.Control
                  type='name'
                  value={formData?.name || ''}
                  placeholder='Legal Name'
                  disabled
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Brand Name</div>
              <div className={styles.sectionSubHeader}>
                Enter your brand / short name that you are commonly known as
              </div>
              <div className={styles.input}>
                <Form.Control
                  type='name'
                  placeholder='Brand Name'
                  value={formData.friendlyName}
                  onChange={(e) => handleFormChange('friendlyName', e.target.value)}
                  onBlur={(e) =>
                    handleFormChange('friendlyName', e.target.value.trim().replace(/ +/g, ' '))
                  }
                />
              </div>
              <div className={styles.inputError}>{brandNameError ? brandNameError : null}</div>
            </div>

            {/*
             * ATS, HRMS and Company URL fields are available on CA side only
             */}
            {!isSa && (
              <div className={styles.fields}>
                <div className={styles.sectionHeader}>
                  Company URL<span className={styles.required}>*</span>
                </div>

                <div className={styles.input}>
                  <Form.Control
                    type='name'
                    placeholder='Company URL'
                    value={companyUrl}
                    maxLength={120}
                    onChange={(e) => setCompanyUrl(e.target.value)}
                    onBlur={handleBlurCompanyUrl}
                  />
                </div>
                <div className={styles.inputError}>{companyUrlError ? companyUrlError : null}</div>
              </div>
            )}

            {!isSa && (
              <div className={styles.fields}>
                <div className={styles.sectionHeader}>HRMS</div>
                <div className={styles.input}>
                  <CustomDropDownWithTags
                    placeholder='Add HRMS tool'
                    isCa={true}
                    selectedOptions={hrmsSelectedOptions}
                    setSelectedOptions={setHrmsSelectedOptions}
                    options={hrmsOptions}
                  />
                </div>
              </div>
            )}
            {!isSa && (
              <div className={styles.fields}>
                <div className={styles.sectionHeader}>ATS</div>
                <div className={styles.input}>
                  <CustomDropDownWithTags
                    placeholder='Add ATS tool'
                    isCa={true}
                    selectedOptions={atsSelectedOptions}
                    setSelectedOptions={setAtsSelectedOptions}
                    options={atsOptions}
                  />
                </div>
              </div>
            )}

            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Company Logo</div>

              <div className={styles.logoContainer}>
                {/* Image 1 */}
                <ImageUpload
                  style={{
                    background: '#ebedf2',
                    border: '1px dashed #cbd5e1'
                  }}
                  title={'Upload dark logo'}
                  subtitle={'(To use on light BG)'}
                  setLogo={(e) => handleFormChange('dark', e)}
                  logo={formData.companyLogos?.dark?.logoUrl || ''}
                  setLogoFile={(e) => setLogoFile({ ...logoFile, dark: e })}
                  setLogoError={setLogoError}
                  isSa={isSa}
                  onLogoDelete={() => {
                    deleteLogo('dark');
                  }}
                />
                {/* Image 2  */}
                <ImageUpload
                  style={{ background: '#6a778b', color: 'white' }}
                  title={'Upload light logo'}
                  setLogo={(e) => handleFormChange('light', e)}
                  subtitle={'(To use on dark BG)'}
                  logo={formData.companyLogos?.light?.logoUrl || ''}
                  setLogoFile={(e) => setLogoFile({ ...logoFile, light: e })}
                  setLogoError={setLogoError}
                  isSa={isSa}
                  onLogoDelete={() => {
                    deleteLogo('light');
                  }}
                />

                <div className={styles.sample} onClick={() => setShowModal(true)}>
                  See sample images
                </div>
              </div>
              {logoError && <div className={styles.logoError}>{logoError}</div>}
            </div>
          </Card>
          <div className={styles.buttonGroup}>
            <Button
              disabled={!hasUnsavedChanges}
              className={styles.cancelButton}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={styles.submitButton}
              disabled={
                !hasUnsavedChanges || isSubmitting || brandNameError || logoError || companyUrlError
              }
              onClick={() => (isLogoUpdated ? setShowLogoDeleteModal(true) : onSave())}
            >
              Save
              <Spinner
                className={styles.spinner}
                animation='border'
                variant='light'
                size='sm'
                hidden={!isSubmitting}
                style={{ marginLeft: '5px', verticalAlign: 'middle' }}
              />
            </Button>
          </div>
        </Card>
      </Form>
      <SampleModal show={showModal} onHide={() => setShowModal(false)} />
      <Modal
        size='md'
        show={showLogoDeleteModal}
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => setShowLogoDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Updating Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Updating or deleting logos will disable the use of the logo and will have to be setup
            again through Communication/ Alumni Verification Settings Tab.
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={styles.deleteLogoBtn}
              onClick={() => {
                onCancel();
                setShowLogoDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={styles.cancelLogoBtn}
              onClick={() => {
                setShowLogoDeleteModal(false);
                onSave();
              }}
            >
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BrandPage;
