import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import styles from './Step1.module.scss';
import cn from 'classnames';
import Image from '../../core-components/Image/Image';
import moment from 'moment';
import {
  ADMIN_URL,
  UPLOAD_STATE,
  SAMPLE_FILE_ALUMNI
} from './../../core-components/Utilities/CommonConstants';
import { validateBulkUploadFile } from '../../../../../api/company';
import { bulkAccept } from './../../core-components/Utilities/utilities';
import AppConstants from '../../../../../core-components/AppConstants';
import { useDropzone } from 'react-dropzone';
import { FaInfoCircle } from 'react-icons/fa';
import useDropzoneCustomHook from '../../utils/use-dropzone-provider';
const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));

const getIconName = (uploadState) => {
  switch (uploadState) {
    case UPLOAD_STATE.UPLOADING:
      return 'loader-black.svg';
    case UPLOAD_STATE.UPLOADED:
      return 'upload-success-green.svg';
    case UPLOAD_STATE.NETWORK_ERROR:
    case UPLOAD_STATE.FILE_TOO_LARGE:
    case UPLOAD_STATE.FILE_ERROR:
    case UPLOAD_STATE.UPLOAD_FAILED:
      return 'upload-failed-red.svg';
    default:
      return 'upload-grey.svg';
  }
};

const getFileUploadHeaderText = (uploadState) => {
  switch (uploadState) {
    case UPLOAD_STATE.UPLOADING:
      return 'Uploading...';
    case UPLOAD_STATE.UPLOADED:
      return 'File Uploaded';
    case UPLOAD_STATE.NETWORK_ERROR:
      return 'Network Error!';
    case UPLOAD_STATE.FILE_TOO_LARGE:
      return 'File too large';
    case UPLOAD_STATE.FILE_ERROR:
      return 'File not supported';
    case UPLOAD_STATE.UPLOAD_FAILED:
      return 'Upload failed';
    default:
      return '';
  }
};

const FileNameCard = ({ fileName, onClose, onDelete }) => {
  return (
    <Card className={styles.fileNameCard}>
      <div className={styles.body}>
        <Image name='csv.svg' />
        <div className={styles.fileNameText}>{fileName}</div>
        <div onClick={onDelete}>
          <Image name='close-grey.svg' />
        </div>
      </div>
    </Card>
  );
};

const FileInfoComponent = ({
  uploadState,
  fileName = 'File Name not found',
  onDeleteUploadedFile,
  onTryAgainHandler,
  fileErrorMessage
}) => {
  switch (uploadState) {
    case UPLOAD_STATE.NOT_UPLOADED:
    case UPLOAD_STATE.UPLOADING:
      return (
        <div className={styles.fileInfoContainer}>
          {/* <div className={styles.fileInfoText}>File size 1 MB max</div>
          <div className={styles.fileInfoText}>File types (.csv/ .xls/ .xlsx)</div> */}
        </div>
      );
    case UPLOAD_STATE.UPLOADED:
      return <FileNameCard fileName={fileName} onDelete={onDeleteUploadedFile} />;
    case UPLOAD_STATE.NETWORK_ERROR:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.errorInfoText}>{fileErrorMessage}</div>
          <div onClick={onTryAgainHandler} className={styles.tryAgainText}>
            Try again
          </div>
        </div>
      );
    case UPLOAD_STATE.FILE_TOO_LARGE:
    case UPLOAD_STATE.FILE_ERROR:
    case UPLOAD_STATE.UPLOAD_FAILED:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.errorInfoText}>{fileErrorMessage}</div>
          <div onClick={onTryAgainHandler} className={styles.tryAgainText}>
            Try again
          </div>
        </div>
      );
  }
};

const Step1 = ({ setRows, setHeaders, flow = 'alumni', setFileId }) => {
  const isDragging = useDropzoneCustomHook();

  const [uploadState, setUploadState] = useState(UPLOAD_STATE.NOT_UPLOADED);
  const [file, setFile] = useState(null);
  const [iconName, setIconName] = useState('upload-grey.svg');
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const companyId =
    flow === 'alumni'
      ? new URLSearchParams(document.location.search).get('id')
      : new URLSearchParams(document.location.search).get('company_id');

  /**
   * UseEffects
   */

  useEffect(() => {
    setIconName(getIconName(uploadState));
  }, [uploadState]);

  /**
   * Handlers
   */
  const onDeleteUploadedFileHandler = () => {
    setFile(null);
    setHeaders([]);
    setRows([]);
    setUploadState(UPLOAD_STATE.NOT_UPLOADED);
  };

  useEffect(() => {
    if (isDragging) console.log('isDragging true');
  }, [isDragging]);

  // Helper function to format date as "YYYY-MM-DD"
  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDrop: (acceptedFiles) => {
      console.log('onDrop');
      if (acceptedFiles.length > 0) {
        const uploadedFile = acceptedFiles[0];
        setFile(uploadedFile);
        if ((uploadedFile.size / (1024 * 1024)).toFixed(2) > 10) {
          setUploadState(UPLOAD_STATE.FILE_TOO_LARGE);
          setFileErrorMessage(
            `Your file ${uploadedFile.name} was not uploaded due to large file size`
          );
          return;
        }
        let column, data;
        let formData = new FormData();
        formData.append('bulk_upload_file', uploadedFile);
        formData.append('file_type', `${flow?.toUpperCase()}_BULK_UPLOAD`);
        formData.append('flow', flow?.toUpperCase());
        if (isSA) {
          formData.append('company_id', companyId);
        }
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          try {
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', raw: true });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */

            const rowData = XLSX.utils.sheet_to_json(ws, {
              header: 1,
              raw: false,
              defval: '',
              cellDates: true
            });

            // Filter out empty rows
            const cleanedData = rowData.filter((row) =>
              row.some((cell) => cell !== null && cell !== '' && cell)
            );

            /**
             * Format dates
             * If some cell has date values then the above logic is converting it to a date object
             * which is later causing issues in the API, so we need to convert it to a string to retain
             * original value.
             */
            const formattedData = cleanedData.map((row) => row.map((cell) => cell));

            const HeaderObject = formattedData[0].map((value, index) => ({
              value: index,
              label: value
            }));

            HeaderObject.unshift({ value: -1, label: 'Ignore' });

            column = formattedData[0];
            data = formattedData.slice(1);
            setUploadState(UPLOAD_STATE.UPLOADING);
            validateBulkUploadFile(formData, flow)
              .then((response) => {
                if (response?.data?.data?.errors?.length > 0) {
                  const error = new Error();
                  error.response = response;
                  throw error;
                }
                setFile(uploadedFile);
                setFileId(response?.data?.data?.file_id || null);
                setUploadState(UPLOAD_STATE.UPLOADED);
                setHeaders(HeaderObject);
                setRows(formattedData.slice(1));
                toast.success(response.data.message);
              })
              .catch((error) => {
                setFileErrorMessage(
                  error.response?.data?.data?.errors?.length > 0
                    ? error.response?.data?.data?.errors[0]
                    : error.response?.data?.message
                );
                setHeaders([]);
                setRows([]);
                setFile(null);
                if (error?.response?.status !== 200) {
                  setUploadState(UPLOAD_STATE.NETWORK_ERROR);
                } else {
                  setUploadState(UPLOAD_STATE.UPLOAD_FAILED);
                }
              });
          } catch (error) {
            setUploadState(UPLOAD_STATE.FILE_ERROR);
            setFileErrorMessage('File type must be csv, xls or xlsx');
          }
        };
        if (rABS) reader.readAsBinaryString(uploadedFile);
        else reader.readAsArrayBuffer(uploadedFile);
      }
    }
  });
  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadState(UPLOAD_STATE.FILE_ERROR);
      setFileErrorMessage('File type must be csv, xls or xlsx');
    }
  }, [fileRejections]);
  const onTryAgainHandler = () => {
    setUploadState(UPLOAD_STATE.NOT_UPLOADED);
    setHeaders([]);
    setRows([]);
    setFile(null);
  };

  return (
    <div className={styles.fileImporterWrapper}>
      {isDragging && (
        <div
          {...getInputProps()}
          {...getRootProps()}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '2rem'
          }}
        >
          Drop anywhere
        </div>
      )}
      <div className={styles.rowOne}>
        {uploadState === UPLOAD_STATE.NOT_UPLOADED ? (
          <div {...getRootProps()} className={styles.uploadFileContainer}>
            {uploadState !== UPLOAD_STATE.UPLOADING ? (
              <Image name={iconName} />
            ) : (
              <Spinner animation='border' variant='secondary' />
            )}
            <div className={cn(styles.titleText, styles.upload)}>
              <span className={styles.containerNotUploadedInfoText}>
                {`Drag and drop files or `}
                <span className={styles.browseText}>browse</span> {`them`}
              </span>
            </div>
            <input {...getInputProps()} style={{ display: 'none' }} accept={bulkAccept} />
            <FileInfoComponent
              uploadState={uploadState}
              onDeleteUploadedFile={onDeleteUploadedFileHandler}
              fileName={file ? file?.name : 'File Name not found'}
              onTryAgainHandler={onTryAgainHandler}
              fileErrorMessage={fileErrorMessage}
            />
          </div>
        ) : (
          <div className={styles.uploadFileContainer}>
            {uploadState !== UPLOAD_STATE.UPLOADING ? (
              <Image name={iconName} />
            ) : (
              <Spinner animation='border' variant='secondary' />
            )}
            <div className={cn(styles.titleText, styles.uploaded)}>
              <span>{getFileUploadHeaderText(uploadState)}</span>
            </div>
            <FileInfoComponent
              uploadState={uploadState}
              onDeleteUploadedFile={onDeleteUploadedFileHandler}
              fileName={file ? file?.name : 'File Name not found'}
              onTryAgainHandler={onTryAgainHandler}
              fileErrorMessage={fileErrorMessage}
            />
          </div>
        )}
      </div>
      {flow === 'alumni' ? (
        <div className={styles.rowTwo}>
          <div className={styles.info}>
            <div className={styles.headerx}>
              Please note the following before proceeding to upload:
            </div>
            <li className={styles.infoList}>
              Add your data in the same format as the sample file.
            </li>
            <li className={styles.infoList}>Only CSV, XLS, and XLSX file types are accepted.</li>
            <li className={styles.infoList}>Ensure that the sheet includes a header row.</li>
            <li className={styles.infoList}>
              Currently, we accept batches of up to 1000 records at a time.
            </li>
            <li className={styles.infoList}>Existing Alumni will not be updated.</li>
          </div>
          <div className={styles.exContainer}>
            <div className={styles.left}>
              <div className={styles.headerx}>
                <Image name='ExcelLogo.svg' style={{ width: '28px' }} /> Sample File
              </div>
              <div className={styles.content}>
                You can use this attached sample file and add your data in the same format.
              </div>
            </div>
            <div
              className={styles.download}
              onClick={() => {
                const link = document.createElement('a');
                link.href = SAMPLE_FILE_ALUMNI;
                link.setAttribute('download', 'SpringVerify Alumni Verification Sample.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Download
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.rowTwo}>
          <div className={styles.info}>
            <div className={styles.headerx}>
              Please note the following before proceeding to upload:
            </div>
            <li className={styles.infoList}>Only CSV, XLS, and XLSX file types are accepted.</li>
            <li className={styles.infoList}>
              The file should contain only one sheet with no merged cells.
            </li>
            <li className={styles.infoList}>Ensure that the sheet includes a header row.</li>
            <li className={styles.infoList}>
              Full name, phone number, and email must be present for each candidate.
            </li>
            <li className={styles.infoList}>
              Currently, we accept batches of up to 1,000 records at a time.
            </li>
          </div>
          <div className={styles.exContainer}>
            <div className={styles.left}>
              <div className={styles.headerx}>
                <FaInfoCircle style={{ color: '#1890FF', marginRight: '5px' }} /> IMPORTANT
              </div>
              <div className={styles.content} style={{ paddingLeft: '20px' }}>
                <li className={styles.infoList}>
                  Selecting one package applies to all candidates in a single file upload.
                </li>
                <li className={styles.infoList}>
                  Any chosen add-on checks will be activated and billed for every candidate.
                </li>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1;
