import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import styles from './ModalCommonStyles.module.scss';

import { sendSlackNotification } from '../../../../api/company';
import velocityTravelImage from '../../../../images/velocityTravelImage.svg';

function FlightServiceModal({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    teamSize: '',
    travelSpends: '',
    comments: ''
  });
  const { profile, paymentDetails } = useSelector((state) => state);
  const { email, name, company_user_mapping } = profile?.profile;
  const { company, id } = company_user_mapping;
  const { designation, mobile } = paymentDetails;

  const teamSizeOptions = ['1-10', '10-50', '50-100', '100-250', '250-500', '500+'];
  const travelSpendsOptions = [
    'Under 2 Lakhs / month',
    'Under 5 Lakhs / month',
    'Under 15 Lakhs / month',
    'Under 30 Lakhs / month',
    'Under 60 Lakhs / month',
    '1 CR and above / month'
  ];

  const isValidForm = formData.teamSize && formData.travelSpends && !loading;

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      alert_title: 'SVIN Product Velocity Request',
      icon_emoji: ':airplane:',
      color: 'good',
      channel_name: 'sv-in-upsell-requests',
      fields: [
        { field_key: 'Company Name', field_value: `${company?.name} (${id})` },
        { field_key: 'POC Name', field_value: name },
        { field_key: 'POC Phone', field_value: mobile || 'Not Available' },
        { field_key: 'POC Email', field_value: email },
        { field_key: 'POC Designation', field_value: designation || 'Not Available' },
        { field_key: 'Number of Employees', field_value: formData.teamSize },
        { field_key: 'Travel Spend Amount', field_value: formData.travelSpends },
        { field_key: 'Comment', field_value: formData.comments || 'No comments' }
      ]
    };

    sendSlackNotification(payload)
      .then(() => {
        toast.success(`We've received your request. Our team will reach out to you.`);
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Failed to send request. Please try again.');
      });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Velocity Travel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={velocityTravelImage}
          alt='Velocity Travels Banner'
          className={styles.modalImage}
        />
        <Form className={styles.modalForm}>
          <Form.Group className='mb-3'>
            <Form.Label className={styles.inputLabel}>
              Team size <span>*</span>
            </Form.Label>
            <Form.Control
              as='select'
              value={formData.teamSize}
              className={styles.modalFormControl}
              onChange={(e) => setFormData({ ...formData, teamSize: e.target.value })}
              required
            >
              <option value='' disabled selected hidden>
                Choose team size
              </option>
              {teamSizeOptions.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className={styles.inputLabel}>
              Annual travel spends <span>*</span>
            </Form.Label>
            <Form.Control
              as='select'
              value={formData.travelSpends}
              className={styles.modalFormControl}
              onChange={(e) => setFormData({ ...formData, travelSpends: e.target.value })}
              required
            >
              <option value='' disabled selected hidden>
                Choose team's Annual travel spends
              </option>
              {travelSpendsOptions.map((spend, index) => (
                <option key={index} value={spend}>
                  {spend}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className={styles.inputLabel}>Additional comments (Optional)</Form.Label>
            <Form.Control
              as='textarea'
              className={styles.modalFormControl}
              rows={3}
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              placeholder='Write any additional comments here'
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          className={styles.requestButton}
          disabled={!isValidForm}
          onClick={onSubmit}
          variant='primary'
        >
          Request call back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FlightServiceModal;
