import React from 'react';
import Image from '../../../../core-components/Image';

const UpsellClubBanner = ({ className }) => {
  return (
    <Image
      onClick={() =>
        window.open(
          'https://payment.onepercentclub.io/masterclass/66ed66f0b665c316f28eaf76?source=organic&code=springone',
          '_blank'
        )
      }
      className={className}
      name={'upsellClubBannerTwo.png'}
    />
  );
};

export default UpsellClubBanner;
