import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import store from './store';
import Application from './components/Application';
import AppHelper from './core-components/AppHelper';
import googleEnvironmentConstant from './config/google-auth.json';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-widgets/dist/css/react-widgets.css';
import './index.scss';
import './styles/_bgvModalStyles.scss';
import { pdfjs } from 'react-pdf';
import MsAuthProvider from './utils/msAuthConfig';
import { DropzoneProvider } from './components/CompanyAdmin/frontend-common/utils/use-dropzone-provider';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// Call it once in your app. At the root of your app is the best place
toast.configure({
  autoClose: 8000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});

if (AppHelper.getEnv() !== 'local' && AppHelper.getEnv() !== 'dev') {
  Sentry.init({
    dsn: 'https://0a28d96d1e3c40788802afd1a59eddcf@o271082.ingest.sentry.io/5398158',
    ignoreErrors: [
      `Cannot read property 'containerHeight' of undefined`,
      'ResizeObserver loop completed with undelivered notifications.',
      `Cannot read property 'offsetTop' of undefined`,
      `Cannot read property 'contentWindow' of null`,
      `JWT failed to verify`,
      `Request failed with status code 401`,
      `Request failed with status code 406`,
      `Network request failed`,
      `Failed to fetch`,
      `Network Error`,
      `Non-Error promise rejection captured with value: Object Not Found Matching Id:3`,
      `Intl is not defined`,
      `Cannot read properties of null (reading 'CodeMirror')`,
      `Cannot read properties of null (reading 'document')`
    ],
    integrations: [new Sentry.BrowserTracing()],
    environment: AppHelper.getEnv()
  });
}

render(
  <GoogleOAuthProvider clientId={googleEnvironmentConstant[AppHelper?.getEnv()]?.clientId}>
    <MsAuthProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
          <DropzoneProvider>
            <Application />
          </DropzoneProvider>
        </PersistGate>
      </Provider>
    </MsAuthProvider>
  </GoogleOAuthProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
