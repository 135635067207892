import * as Yup from 'yup';
import {
  getEmailValidationSchema,
  getNameValidationSchema
} from '../../../../../utils/ValidationSchema';
import { getTrimmedValue, isValidPhoneNumber } from '../../../../../utils/utilities';
const phoneValidation = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .test({
    name: 'phone-number',
    message: 'Please enter a valid Mobile number.',
    test: function (value) {
      const isEmailValid = Yup.string().email().isValidSync(this.parent.email_id);
      if (value) {
        let intlPhoneNumber = `${this.parent.country_code + '' + value}`;
        return isValidPhoneNumber(intlPhoneNumber);
      } else if (!this.parent.email_id || (!value && this.parent.email_id && isEmailValid)) {
        return false;
      } else {
        return true;
      }
    }
  });

export default Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      name: getNameValidationSchema({ message: 'name', isRequired: true }).required(
        'Please provide name.'
      ),
      is_valid_phone: Yup.boolean(),
      source: Yup.object().required('Source is required'),
      phone_number: phoneValidation,
      email_id: getEmailValidationSchema({ isRequired: false, isPlusSignAllowed: false }).when(
        ['country_code', 'phone_number'],
        (country, phone) => {
          if (phone) {
            const combinedPhoneNumber = `${country}${phone}`;
            if (isValidPhoneNumber(combinedPhoneNumber)) {
              return getEmailValidationSchema({ isRequired: true, isPlusSignAllowed: false });
            }
          } else {
            return getEmailValidationSchema({ isRequired: true, isPlusSignAllowed: false });
          }
        }
      ),
      is_referred_anonymous: Yup.boolean()
    })
  )
});
