// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vsdxEoF1fA7e0rkffCuM {
  width: 1072px;
  top: 88px;
  left: 184px;
  border-radius: 4px;
}

.pY9DUcRG9IDhb_VHP06x {
  width: 100%;
  margin-bottom: 10px;
}
.pY9DUcRG9IDhb_VHP06x:hover {
  cursor: pointer;
}

.OoGqiRHnNHukd4YIx0_u {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.Eth3xlXdaNXmCbfAOYB6 {
  width: 1028px;
  height: 296px;
  border-radius: 10px;
  margin-top: 12px;
  background: linear-gradient(180deg, #7b5baf 0%, #25275d 100%);
}

.PCQxulm9rW7fFbuQHHHN {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 45px;
  height: 100%;
}

.ejWsqr1OG94CSwTYlp3z {
  margin-top: 2rem;
  width: 120px;
  height: 120px;
  background: rgba(255, 255, 255, 0.1254901961);
  border-radius: 100px;
  position: relative;
  overflow: hidden;
}

.qr_mndrUfjRq993NRtbK {
  width: 222.42px;
  height: 151.54px;
  top: 116px;
  left: 331.21px;
}

.E4SEZuVaAqO3mIqUheD2 {
  display: flex;
  align-items: flex-end;
  padding-bottom: 28.5px;
}

.B0c2rPa6fC8a35I1shRw {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.XL6fQE_ysBpRkiRmVewg {
  color: #ffe39a;
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
}

.LlSauAG5zGs7jneMEge7 {
  font-family: Roboto;
}

.pU47kuOW1imxW3cFZXpA {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffe39a;
}

.e3zULCKFGjX6KGJ8Vo5R {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.xn1fA3WOzQOisdsZ8exx {
  display: flex;
  align-items: center;
  gap: 12px;
}
.xn1fA3WOzQOisdsZ8exx .nBmnBkfuSe7QhOLB8E_J {
  color: #333333;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 24px;
  word-wrap: break-word;
}

.TOZhHSVlm1D0wdQQ50we {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 19px;
}
.TOZhHSVlm1D0wdQQ50we .rmi14kHUP2g0IJbDDGCM {
  width: 374px;
  height: 120px;
  padding: 10px 20px;
  border-radius: 12px;
  gap: 44px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TOZhHSVlm1D0wdQQ50we .rmi14kHUP2g0IJbDDGCM .gEW7dhnchX6xHGnpB6ds {
  height: 100px;
  width: 100px;
}
.TOZhHSVlm1D0wdQQ50we .rmi14kHUP2g0IJbDDGCM .BPc7wzsf41c_geWNZ5V2 {
  color: #ffffff;
  display: flex;
  flex-direction: column;
}
.TOZhHSVlm1D0wdQQ50we .rmi14kHUP2g0IJbDDGCM .BPc7wzsf41c_geWNZ5V2 .rHVVnRKyOlQAkd8wWwgq {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.TOZhHSVlm1D0wdQQ50we .rmi14kHUP2g0IJbDDGCM .BPc7wzsf41c_geWNZ5V2 .ein_cooPqModzusct90Q {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.GofFh35IhbQ7e8fVzbqR {
  padding: 16px 0 0 22px;
}
.GofFh35IhbQ7e8fVzbqR .mC7GC6ASauLlLI83nFeW {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
  margin-top: 1rem;
}
.GofFh35IhbQ7e8fVzbqR .jiYZiyjFMUAJGf8hiqKG {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.GofFh35IhbQ7e8fVzbqR .MOfMAjCCr_PMKOk67NQb {
  margin: 0 !important;
}
.GofFh35IhbQ7e8fVzbqR .mZPSexvTt5dmTnD9eumi {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.r1SOVh_6flbh5X9PFTdS {
  padding: 10px !important;
  border: 1px solid #dfe1e5 !important;
}

.nEVZRpd5YxuFOyMjMXug {
  color: #d3455b;
}
.nEVZRpd5YxuFOyMjMXug .dzaAUYOweFsC3awCBhMA {
  height: 20px;
  width: 20px;
}
.nEVZRpd5YxuFOyMjMXug .pHA_fWyhBcyleRLSzPuE {
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding-left: 4px;
}

.gvagJLHTuEa1E6O33fxG {
  padding: 0 !important;
  flex: 0 0 25%;
  max-width: 25%;
}

.rosuwRv7u4cRwHAzgvVg {
  width: auto;
  padding: 0;
  margin-top: 1rem;
  border: none;
  background: transparent;
}
.rosuwRv7u4cRwHAzgvVg .DFwZhbNmmKZSvNIFRMdt {
  height: 24px;
  width: 24px;
}
.rosuwRv7u4cRwHAzgvVg .pcUnXz_5g49Xn_d5ASqa {
  color: #2755fe;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
}
.rosuwRv7u4cRwHAzgvVg .Pa8SRbpzCR9sn3XbNpSk {
  color: rgba(39, 85, 254, 0.2509803922);
}

.iimf18MBhvHwD3nc1PF5 {
  margin-top: 0.5rem;
  color: #d3455b;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.byyEUkEQPBAcxq1RJCFh {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  gap: 2rem;
}
.byyEUkEQPBAcxq1RJCFh .L9u2oLU_lUDNa73DzODB {
  color: #ffffff !important;
  cursor: pointer !important;
  padding: 0.5rem 1rem !important;
  min-width: 164px !important;
  border-radius: 2px !important;
  text-transform: capitalize;
}
.byyEUkEQPBAcxq1RJCFh .lBNK_TdUbIODVa7uaimO {
  background: #2755fe !important;
}
.byyEUkEQPBAcxq1RJCFh .ZXVP98jam88kxK2njJX0 {
  background: rgba(39, 85, 254, 0.4);
}
.byyEUkEQPBAcxq1RJCFh .rhqhMG2Yk8VcJ7aPeeGl {
  cursor: pointer !important;
  padding: 0 !important;
  text-transform: capitalize;
  background-color: #ffffff;
}
.byyEUkEQPBAcxq1RJCFh .JPNlkUUWkKfJGZOS7mmQ {
  color: #2755fe;
}
.byyEUkEQPBAcxq1RJCFh .GJqSraIMo14l3NxD71jH {
  color: rgba(39, 85, 254, 0.4);
}

.LlmH7XRi6gI3PLQexybK {
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -1rem;
}

.KsM0rtbh25GHknsmqo41 {
  border: 1px solid #dfe1e5 !important;
  border-radius: 4px;
}

.O8Vet02OM7ZiMkK5NSI1 {
  border-color: #2755fe;
  border-radius: 4px;
}

.M1IJ9P9hAxTU1Ow8giSV {
  border: 1px solid #d3455b !important;
  border-radius: 4px !important;
}

.drUfDTOUnwjavCUpyOkx {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.qG57tc7eZg4SoiauDFac {
  color: #d3455b;
}

.wDYAHiYoAlZ2chmRWweq {
  max-width: 744px;
}

.wF7SxHM9t2EYFZVyaaYH {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #666666 !important;
  margin-bottom: 4px !important;
}

.jY0MxT99fskf_Dqu8anD {
  color: rgba(39, 85, 254, 0.4) !important;
  cursor: not-allowed !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referCardBody": `vsdxEoF1fA7e0rkffCuM`,
	"upsellImage": `pY9DUcRG9IDhb_VHP06x`,
	"pageHeading": `OoGqiRHnNHukd4YIx0_u`,
	"pageBanner": `Eth3xlXdaNXmCbfAOYB6`,
	"pageItemContainer": `PCQxulm9rW7fFbuQHHHN`,
	"iconContainerRound": `ejWsqr1OG94CSwTYlp3z`,
	"iconGiftbox": `qr_mndrUfjRq993NRtbK`,
	"giftboxContainer": `E4SEZuVaAqO3mIqUheD2`,
	"amountText": `B0c2rPa6fC8a35I1shRw`,
	"amount": `XL6fQE_ysBpRkiRmVewg`,
	"rupeeText": `LlSauAG5zGs7jneMEge7`,
	"rupee": `pU47kuOW1imxW3cFZXpA`,
	"bannerSubtitle": `e3zULCKFGjX6KGJ8Vo5R`,
	"contactRow": `xn1fA3WOzQOisdsZ8exx`,
	"contactText": `nBmnBkfuSe7QhOLB8E_J`,
	"stepCardContainer": `TOZhHSVlm1D0wdQQ50we`,
	"stepCard": `rmi14kHUP2g0IJbDDGCM`,
	"stepIcon": `gEW7dhnchX6xHGnpB6ds`,
	"cardText": `BPc7wzsf41c_geWNZ5V2`,
	"cardHeader": `rHVVnRKyOlQAkd8wWwgq`,
	"cardDetails": `ein_cooPqModzusct90Q`,
	"referForm": `GofFh35IhbQ7e8fVzbqR`,
	"formRow": `mC7GC6ASauLlLI83nFeW`,
	"phoneFormField": `jiYZiyjFMUAJGf8hiqKG`,
	"textField": `MOfMAjCCr_PMKOk67NQb`,
	"formField": `mZPSexvTt5dmTnD9eumi`,
	"field": `r1SOVh_6flbh5X9PFTdS`,
	"removeBtn": `nEVZRpd5YxuFOyMjMXug`,
	"removeIcon": `dzaAUYOweFsC3awCBhMA`,
	"removeText": `pHA_fWyhBcyleRLSzPuE`,
	"countryCode": `gvagJLHTuEa1E6O33fxG`,
	"addBtn": `rosuwRv7u4cRwHAzgvVg`,
	"addIcon": `DFwZhbNmmKZSvNIFRMdt`,
	"addText": `pcUnXz_5g49Xn_d5ASqa`,
	"disabled": `Pa8SRbpzCR9sn3XbNpSk`,
	"errorText": `iimf18MBhvHwD3nc1PF5`,
	"actionFooter": `byyEUkEQPBAcxq1RJCFh`,
	"btn": `L9u2oLU_lUDNa73DzODB`,
	"activeBtn": `lBNK_TdUbIODVa7uaimO`,
	"disabledBtn": `ZXVP98jam88kxK2njJX0`,
	"blankBtn": `rhqhMG2Yk8VcJ7aPeeGl`,
	"activeSkipBtn": `JPNlkUUWkKfJGZOS7mmQ`,
	"disabledSkipBtn": `GJqSraIMo14l3NxD71jH`,
	"anonymousText": `LlmH7XRi6gI3PLQexybK`,
	"inputGroup": `KsM0rtbh25GHknsmqo41`,
	"inputGroupFocus": `O8Vet02OM7ZiMkK5NSI1`,
	"inputGroupErrBorder": `M1IJ9P9hAxTU1Ow8giSV`,
	"inputGroupErrBorderFocus": `drUfDTOUnwjavCUpyOkx`,
	"required": `qG57tc7eZg4SoiauDFac`,
	"formContainer": `wDYAHiYoAlZ2chmRWweq`,
	"fieldLabel": `wF7SxHM9t2EYFZVyaaYH`,
	"disabledText": `jY0MxT99fskf_Dqu8anD`
};
export default ___CSS_LOADER_EXPORT___;
