// React and libraries imports
import React, { useEffect, useState } from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { pdfjs } from 'react-pdf';

// Core components and utils imports
import Image from '../../../../../../../core-components/Image';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import {
  FileErrorMeaage,
  getFileHeader,
  validateImage
} from '../../../../../../../utils/utilities';
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import PasswordProtectedModal from '../../../PasswordProtectedModal';
import {
  RenderErrorUI,
  RenderUploadedFile
} from '../../../Education/EducationModals/AddEditUpload/CommonUploadComponent';

// Styles
import styles from './MultiUploadForm.module.scss';

const MultiUploadForm = ({
  isMobileOnly,
  docUpload,
  setDocUpload,
  educationGradesheetUpload,
  deleteGradesheetUpload,
  isImageHavingError
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const [isCamHavingError, setIsCamHavingError] = useState(false);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [maxFileError, setMaxFileError] = useState(false);
  const [passwordProtectedFiles, setPasswordProtectedFiles] = useState([]);
  const [passwordProtectedUploadedLength, setPasswordProtectedUploadedLength] = useState(0); // Store initial password protected length

  useEffect(() => {
    if (passwordProtectedFiles.length) {
      setShowPasswordProtectedModal(true);
    }
  }, [passwordProtectedFiles]);

  const updateDocCopyAtIndex = ({
    link = '',
    error = null,
    loading = false,
    isPdf = true,
    password = null
  }) => {
    return {
      link,
      error,
      loading,
      isPdf,
      password
    };
  };

  const handleCamPhoto = () => {
    setTakePhoto(!takePhoto);
    setIsCamHavingError(false);
  };

  const handleFileUpload = (formData, isPdf, index, file, resolve, isCam = false) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      if (reader.result) {
        educationGradesheetUpload({ formData, isPdf: isPdf })
          .then((res) => {
            try {
              let docCopy = {
                link: res.link,
                error: '',
                loading: false,
                isPdf: isPdf
              };
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({
                  data: reader?.result,
                  password: ''
                });

                loadingTask.promise
                  .then(() => {
                    resolve({ docCopy, index });
                  })
                  .catch((error) => {
                    if (error.message === 'No password given') {
                      resolve({
                        docCopy: {
                          ...docCopy,
                          fileName: file.name
                        },
                        index,
                        isPasswordProtected: true
                      });
                    }
                  });
              } else {
                resolve(isCam ? false : { docCopy, index });
                if (isCam) {
                  handleCamPhoto();
                  setDocUpload((prevDocUpload) => {
                    const docCopy = [...prevDocUpload.proof_doc];
                    docCopy.push({
                      link: res?.link,
                      error: false,
                      isPdf: false
                    });
                    return {
                      ...prevDocUpload,
                      proof_doc: [...docCopy]
                    };
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            let docCopy = [];
            docCopy[index] = {
              link: '',
              error: 'Upload failed. File format not valid.',
              loading: false,
              isPdf: isPdf,
              password: ''
            };
            resolve(docCopy);
          });
      }
    };
  };

  const handleFileSelect = async (e, type, index) => {
    const numberOfFiles = docUpload.proof_doc.length + e.target.files.length;

    if (numberOfFiles > 10) {
      setMaxFileError(true);
    } else {
      setMaxFileError(false);
      const promises = Array.from(e.target.files).map((file, ind) => {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];
          docCopy[index] = updateDocCopyAtIndex({ error: '', loading: true });
          return {
            ...prevDocUpload,
            proof_doc: docCopy
          };
        });
        return new Promise((resolve, reject) => {
          let fileType = '';
          var blob = file;
          var fileReader = new FileReader();
          fileReader.onloadend = () => {
            var arr = new Uint8Array(fileReader.result).subarray(0, 4);
            var header = '';
            for (var i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            // Check the file signature against known types
            fileType = getFileHeader(header);
            const errMessage = validateImage(blob, fileType);
            const isPdf = fileType === 'application/pdf' ? true : false;
            if (!errMessage) {
              let formData = new FormData();
              formData.append(`file`, blob);
              formData.append(`file_type`, 'CHECK_DOCUMENT');
              handleFileUpload(formData, isPdf, index + ind, blob, resolve);
            } else {
              resolve({
                docCopy: updateDocCopyAtIndex({ error: FileErrorMeaage[errMessage], isPdf }),
                index: index + ind
              });
            }
          };

          if (blob instanceof Blob) {
            {
              /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
            }
            fileReader.readAsArrayBuffer(blob);
          } else {
            console.error(
              `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
            );
          }
        });
      });
      Promise.all(promises).then((files) => {
        /* Filters all files which are password protected*/
        const filesWithPassword = files.filter((file) => file?.isPasswordProtected);
        setPasswordProtectedFiles(filesWithPassword);
        setPasswordProtectedUploadedLength(filesWithPassword.length);

        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];

          files &&
            files.map((file) => {
              docCopy[file?.index] = updateDocCopyAtIndex({
                link: file?.docCopy?.link,
                error: file?.docCopy?.error,
                isPdf: file?.docCopy?.isPdf
              });
            });

          return {
            ...prevDocUpload,
            proof_doc: [...docCopy]
          };
        });
      });
    }
  };
  const renderUploadAddressDoc = () => {
    return (
      <Col lg={5} md={6} sm={11} xs={11}>
        <label className={styles.certificateInputFileContainer}>
          <div>
            <Image name='certificate_upload_enable.png' />
            <p>Add your address proof</p>
            <Form.Control
              id='formControlsFile'
              accept='image/*,application/pdf'
              type='file'
              label='File'
              multiple
              onChange={(e) => handleFileSelect(e, 'addressdoc', docUpload.proof_doc.length)}
            />
          </div>
        </label>
      </Col>
    );
  };

  const enablePhotoBtn = () => {
    let isImageHavingError = false;

    if (isMobileOnly) {
      for (let value of docUpload.proof_doc) {
        if (value.error) {
          isImageHavingError = true;
          break;
        }
      }
    }
    return isImageHavingError;
  };

  const handleImagePreview = (url) => {
    setImagePreviewUrl(url || '');
    setShowImagePreviewModal(!showImagePreviewModal);
  };

  const passwordProtectedFilesStateUpdate = () => {
    setPasswordProtectedFiles((prevPasswordFiles) => {
      const remainingProtectedFiles = [...prevPasswordFiles];
      remainingProtectedFiles.pop();
      if (remainingProtectedFiles.length == 0) {
        setShowPasswordProtectedModal(false);
      }
      return remainingProtectedFiles;
    });
  };

  const submitHandlerPasswordModal = (password = '', link, index) => {
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy[index] = updateDocCopyAtIndex({ link, password });
      return {
        ...prevDocUpload,
        proof_doc: docCopy
      };
    });
    passwordProtectedFilesStateUpdate();
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      // If any of the password protected modal is closed, then we have save unprotected files/ unlocked files
      const passwordProtectedFilesIndices = passwordProtectedFiles.map((item) => item?.index);
      const newArray = docCopy.filter((_, index) => !passwordProtectedFilesIndices.includes(index));
      return {
        ...prevDocUpload,
        proof_doc: newArray
      };
    });
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const skipFileHandler = (index) => {
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy.splice(index, 1);
      return {
        ...prevDocUpload,
        proof_doc: docCopy
      };
    });

    passwordProtectedFilesStateUpdate();
  };

  const clearError = (type, index) => {
    const addressDocCopy = [...docUpload.proof_doc];
    addressDocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...addressDocCopy]
    });
  };

  const handleDeleteImage = (type, index) => {
    const addressDocCopy = [...docUpload.proof_doc];
    addressDocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...addressDocCopy]
    });
    deleteGradesheetUpload(addressDocCopy);
  };

  const renderAddressDocHavingLength = () => {
    return docUpload.proof_doc.map((item, index) => {
      return item?.error ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderErrorUI
            handleClick={clearError}
            errorText={item.error}
            index={index}
            type='addressdoc'
          />
        </Col>
      ) : item?.loading ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <div className={styles.certificateInputFileContainer}>
            <Spinner animation='border' variant='primary' />
          </div>
        </Col>
      ) : item?.link ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={12}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderUploadedFile
            item={item}
            handleImagePreview={handleImagePreview}
            handleDeleteImage={handleDeleteImage}
            type='addressdoc'
            index={index}
            isPdf={item.isPdf}
          />
        </Col>
      ) : (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <label className={styles.certificateInputFileContainer}>
            <div>
              <Image name='certificate_upload_enable.png' />
              <p>Add your address proof</p>
              <Form.Control
                id='formControlsFile'
                accept='image/*,application/pdf'
                type='file'
                label='File'
                multiple
                onChange={(e) => handleFileSelect(e, 'addressdoc', index)}
              />
            </div>
          </label>
        </Col>
      );
    });
  };

  return (
    <>
      <Form.Row
        className={styles.Row}
        style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <Form.Label>
            Add your address proof<span style={{ color: 'red' }}>*</span>
          </Form.Label>
          {maxFileError && <p className={styles.maxFileError}>Maximum 10 files can be uploaded</p>}
        </Col>
        {isMobileOnly ? (
          <Col sm={12} xs={12}>
            <Form.Row>
              <Col sm={11} xs={11} className={styles.takePhotoBtnContainer}>
                <Button
                  className={styles.takePhotoBtn}
                  onClick={() => handleCamPhoto()}
                  disabled={enablePhotoBtn() && docUpload.proof_doc.length}
                >
                  Take Photo of proof
                </Button>
              </Col>
            </Form.Row>
          </Col>
        ) : null}
        <Col lg={10} md={12} sm={12} xs={12}>
          <Form.Row
            className={styles.Row}
            style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}
          >
            <>
              {docUpload.proof_doc.length ? renderAddressDocHavingLength() : null}
              {docUpload.proof_doc.length < 10 && !isImageHavingError()
                ? renderUploadAddressDoc()
                : null}
            </>
          </Form.Row>
        </Col>
      </Form.Row>
      {showImagePreviewModal && (
        <Form.Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <ImagePreviewModal
              showImagePreviewModal={showImagePreviewModal}
              handleImagePreview={handleImagePreview}
              imagePreviewUrl={imagePreviewUrl}
            />
          </Col>
        </Form.Row>
      )}
      {isMobileOnly && takePhoto && (
        <BGVCamera
          type='multiple'
          showBgvCameraModal={takePhoto}
          handleCameraModal={handleCamPhoto}
          //handleMultipleUpload={handleFileUpload}
          handleMultipleUpload={(formData, isPdf, index, setCamState, file) => {
            handleFileUpload(formData, isPdf, index, file, setCamState, true);
          }}
          isCameraUploadError={isCamHavingError}
          index={
            docUpload.proof_doc.length && !isImageHavingError()
              ? docUpload.proof_doc.length
              : docUpload.proof_doc.length && isImageHavingError()
              ? docUpload.proof_doc.length - 1
              : docUpload.proof_doc.length
          }
        />
      )}
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'employment'}
          SubOnHide={modalHide}
          skipFileHandler={skipFileHandler}
          passwordProtectedUploadedLength={passwordProtectedUploadedLength}
          passwordProtectedFiles={passwordProtectedFiles}
        />
      )}
    </>
  );
};

export default MultiUploadForm;
