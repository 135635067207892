// Libraries & packages
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

// Utils & other imports
import { jwtParser } from '../../utils/utilities';
import storage from '../../api/localStorage';
import MainLayout from '../../core-components/Layout/MainLayout';
import { updateCompanyPaymentType, updateJwt } from '../../actions/authentication';
import { ACCESS_LEVEL, TOKEN_TYPE, USER_TYPES } from '../../utils/commonConstant';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  component: Component,
  redirect = '/signIn',
  onlyAdmin = false,
  pageTitle = '',
  ...rest
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let lastRedirectPathname = '/dashboard';
    if (
      rest?.location &&
      rest?.location?.pathname !== '/logout' &&
      rest?.location?.pathname !== '/loading' &&
      rest?.location?.pathname !== '/company/bgv'
    ) {
      lastRedirectPathname = `${rest?.location?.pathname}${rest?.location?.search}`;
    }
    localStorage.setItem('last-redirect-pathname', lastRedirectPathname);

    /**
     * If the user is redirected to the dashboard page with a token in the query string,
     * then the token is extracted from the query string and stored in the local storage.
     */
    if (rest?.location?.pathname === '/dashboard') {
      const urlParams = new URLSearchParams(rest.location.search);
      const token = urlParams.get('token');
      if (token) {
        sessionStorage.clear();
        const parsedJwtDetail = jwtParser(token);
        const accessLevel =
          parsedJwtDetail.data.accessLevel === ACCESS_LEVEL.COMPANY_ADMIN
            ? USER_TYPES.ADMIN
            : USER_TYPES.HR;
        storage.setToken(token, TOKEN_TYPE.COMPANY);
        dispatch(updateJwt(token, TOKEN_TYPE.COMPANY));
        storage.setAccessLevel(accessLevel);

        dispatch(updateCompanyPaymentType(parsedJwtDetail.data.modelType));

        window.open('/dashboard', '_self');
      }
    }
  }, [rest.location, dispatch]);

  const token = storage.getToken();
  const parsedJwtDetail = jwtParser(token);
  if (rest.location && rest.location.pathname === '/company/bgv') {
    if (storage.getToken() !== storage.getBGVToken()) {
      storage.setBGVTokenChange('true');
    } else {
      storage.setBGVTokenChange('false');
    }
  }

  if (
    rest.location &&
    (rest.location.pathname === '/credits' ||
      rest.location.pathname === '/buy' ||
      rest.location.pathname === '/purchaseHistory' ||
      rest.location.pathname === '/transaction') &&
    parsedJwtDetail?.data?.modelType?.includes('POSTPAID')
  ) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  const getComponentLayout = (props) => {
    if (
      rest.path == '/candidate/report' ||
      rest.path == '/candidate/resume' ||
      rest.path === '/candidate/consent-letter' ||
      rest.path === '/candidate/document' ||
      rest.path === '/unauthorized' ||
      rest.path === '/company/bgv' ||
      rest.path === '/invoice' ||
      rest.path === '/verification-request' ||
      rest.path === '/proforma-invoice' ||
      rest.path === '/candidate/view/report' ||
      rest.path === '/loading'
    ) {
      return (
        <>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Component {...props} />
        </>
      );
    } else {
      return (
        <MainLayout pageTitle={pageTitle}>
          <Component
            {...props}
            isPrepaid={parsedJwtDetail?.data?.modelType?.includes('POSTPAID') ? false : true}
          />
        </MainLayout>
      );
    }
  };
  const isNonLoggedInPage = (props) => {
    if (rest.path === '/purchase/payment-request') {
      return getComponentLayout(props);
    } else {
      return <Redirect to={{ pathname: redirect, state: { from: props.location } }} />;
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.location && storage.getToken() ? (
          onlyAdmin ? (
            storage.getAccessLevel() === 'admin' ? (
              rest.render ? (
                rest.render(props)
              ) : (
                getComponentLayout(props)
              )
            ) : (
              <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
          ) : rest.render ? (
            rest.render(props)
          ) : (
            getComponentLayout(props)
          )
        ) : (
          isNonLoggedInPage(props)
        )
      }
    />
  );
};
