import { getCityValue } from '../utils/utilities';
import http from './http';

//Fetch BGV Config
export const getBgvConfig = () => {
  return http.get('candidate/candidatebgvconfiguration');
};

//Fetch Basic details of candidate
export const getBgvCandidateDetails = () => {
  return http.get('candidate/candidatebasicdetails');
};

//Fetch BGV Cache
export const getBgvCache = () => {
  return http.get('candidate/bgvcache');
};

//Set BGV Cache
export const setBgvCache = (data) => {
  return http.post('candidate/bgvcache', data);
};

export const idUpload = (data) => {
  return http({
    method: 'post',
    url: 'candidate/uploadtos3',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const validateDocument = (data) => {
  return http({
    method: 'post',
    url: 'candidate/validate-document',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const getOcr = (data) => {
  return http({
    method: 'post',
    url: 'candidate/perform-ocr',
    data: data,
    headers: { 'Content-Type': 'application/json', timeout: 1000 * 30 }
  });
};

//Save ocr
export const saveOcr = (data) => {
  return http.post('candidate/save-ocr', data);
};

export const checkBgvCandidateEmail = (email) => {
  return http.get('candidate/fetchcandidatedetail', {
    params: {
      email
    }
  });
};

export const checkBgvCandidatePhone = (phone) => {
  return http.get('candidate/fetchcandidatedetail', {
    params: {
      phone
    }
  });
};

export const checkBgvCandidateEmployeeId = (id) => {
  return http.get('candidate/fetchcandidatedetail', {
    params: {
      employeeId: id
    }
  });
};

export const setFeedback = (data) => {
  return http.post('candidate/feedback', data);
};

//generating consent pdf
export const generateBgvConsent = (data) => {
  return http.get('candidate/consent', {
    params: {
      ...data,
      place: data.place
    }
  });
};

//updating in bgvcandidatedetails
export const saveConsent = (data) => {
  return http.post('candidate/consent', data);
};

//Final Submission API
export const setBgvSubmission = (data) => {
  return http.post('candidate/submitbgvform', data);
};

/**
 * Get Package Configuration of BGV
 * @return  {promise}
 */
export const getBgvPackageConfig = () => {
  return http.get('candidate/candidatebgvconfiguration');
};

export const unlockPdf = (data) => {
  return http.post('/candidate/unlock-pdf', data);
};

export const referHr = (data) => {
  return http.post('candidate/referral', data);
};
export const referralEmailValidate = (email) => {
  return http.get(`candidate/referral/validate?email_id=${email}`);
};
