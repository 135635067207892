import React, { useEffect, useState } from 'react';
import styles from '../Integrations.module.scss';
import { Container, Row } from 'react-bootstrap';

import ConfirmationModal from '../../core-components/ConfirmationModal';
import WarningModal from '../WarningModal';

import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';
import Button from '../../core-components/Button';
import authenticate from '@truto/truto-link-sdk';
import { errToastMessage } from '../../utils/Utilities';
import { toast } from 'react-toastify';
import { kekaDetails } from '../Utils/constants';
import _, { isEmpty } from 'lodash';

import PackageMappingIdentifier from '../Utils/PackageConfiguration';

import {
  deleteIntegrations,
  postIntegrations,
  getTRUTOLinkToken,
  getViewBuyPackages,
  getIntegrationFieldSubtypeMapping
} from '../../../../../api/company';

export default function Keka(props) {
  const { setFieldValue, values, handleSubmit, isSubmitting } = props;
  const [integrationData, setIntegrationData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [linkToken, setLinkToken] = useState('');
  const [isPackageMapped, setIsPackageMapped] = useState(false);
  const [identifierDataLoading, setIdentifierDataLoading] = useState(true);
  const [identifierValues, setIdentifierValues] = useState([]);
  const [packages, setPackages] = useState([]);
  const [reputeSubtypeMapping, setReputeSubtypeMapping] = useState([]);
  const [selectedIdentifierValues, setSelectedIdentifierValues] = useState({});
  const [dropdownIdentifierValues, setDropdownIdentifierValues] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  const isFormValid = () => {
    return (
      packages.length > 0 &&
      !isEmpty(values.identifierValue) &&
      Object.values(values?.tags).filter((tag) => tag && !isEmpty(tag)).length === packages.length
    );
  };

  useEffect(() => {
    props.setIntegrationAPI();
    props.getViewBuyPackages({ limit: 50, offset: 0 });

    // Fetching TRUTO Link Token
    getTRUTOLinkToken()
      .then((response) => {
        setLinkToken(response.data.link_token);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  }, []);

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);

    // Fetching all packages
    getViewBuyPackages({ limit: 50, offset: 0 })
      .then((result) => {
        setPackages(result?.data?.data?.rows);
      })
      .catch((error) => {
        errToastMessage(error);
      });

    if (integrationData?.keka?.integrationId) {
      setIsPackageMapped(false);
      let subtypeMapping = {};

      // Fetching Integration field subtype mapping
      getIntegrationFieldSubtypeMapping({
        companyId: props.values.companyId,
        integrationName: 'KEKA'
      })
        .then((res) => {
          if (res?.data.length > 0) {
            setIsPackageMapped(true);
          }
          res?.data.map((mapping) => {
            if (subtypeMapping.hasOwnProperty(mapping.subtype_id_fk)) {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: [
                  ...subtypeMapping[mapping.subtype_id_fk].tags,
                  ...mapping.integration_field_value.split(';')
                ]
              };
            } else {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: mapping.integration_field_value.split(';')
              };
            }
          });
          setReputeSubtypeMapping(subtypeMapping);
          setSelectedIdentifierValues(subtypeMapping);
        })
        .catch((err) => console.error(err));

      // Mapping Identifier values
      mapAPICallToIdentifier();
    }
  }, [props?.integrationData]);

  useEffect(() => {
    const selectedTagsObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedTagsObj).map((obj) => !isEmpty(obj) && obj.tags);
    setDropdownIdentifierValues(_.difference(identifierValues, _.union(...allTagsArr)));
  }, [selectedIdentifierValues, identifierValues]);

  const checkIfTagsNotAvailable = () => {
    const selectedIdentifierValuesObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedIdentifierValuesObj).map(
      (obj) => !isEmpty(obj) && obj?.tags
    );
    return _.difference(_.union(...allTagsArr), identifierValues).length > 0;
  };

  const addIntegrationAPI = ({ integrationId }) => {
    const payload = {
      source: 'KEKA',
      details: { integration_id: integrationId }
    };
    postIntegrations(payload)
      .then((resp) => {
        toast.success('Keka Integration added successfully.');
        props.setIntegrationAPI();
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations('KEKA')
      .then((resp) => {
        props.setIntegrationAPI();
        toast.success('Removed connection successfully.');
        setTriggerUseEffect(!triggerUseEffect);
        // Resetting all selected values post Integration deletion
        setIsOpen(false);
        setReputeSubtypeMapping([]);
        setSelectedIdentifierValues({});
        setIdentifierValues([]);
        setDropdownIdentifierValues([]);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  const connectTruto = () => {
    const options = {
      integration: 'keka'
    };
    authenticate(linkToken, options)
      .then((response) => {
        addIntegrationAPI({
          integration: response.integration,
          integrationId: response.integrated_account_id
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setTagsValue = (packageId, subtypeId, value) => {
    let allSubtypesWithTags = { ...reputeSubtypeMapping, ...selectedIdentifierValues };

    allSubtypesWithTags[subtypeId] =
      value && value.length > 0 ? { packageId: packageId, tags: [...value] } : {};

    setSelectedIdentifierValues(allSubtypesWithTags);
    setFieldValue('tags', allSubtypesWithTags);
  };

  useEffect(() => {
    setFieldValue('tags', reputeSubtypeMapping);
  }, [reputeSubtypeMapping]);

  const preDefinedPackagesMapping = [
    'Upstart Verifier',
    'Master Verifier',
    'Elite Verifier',
    'Custom Verifier 1',
    'Custom Verifier 2',
    'Custom Verifier 3'
  ];

  const mapAPICallToIdentifier = async () => {
    setIdentifierDataLoading(true);
    const identifierFormattedData = [];
    setIdentifierDataLoading(false);
    preDefinedPackagesMapping.map((item) => identifierFormattedData.push(item));
    const uniqueIdentifierData = Array.from(new Set(identifierFormattedData));
    setIdentifierValues(uniqueIdentifierData);
  };

  const onClickIdentifierHandler = () => {
    mapAPICallToIdentifier();
  };

  const handleSetTagsValue = (packageId, subtypeId, value) => {
    setTagsValue(packageId, subtypeId, value);
  };

  const packageMappingComponent = {
    headerText: 'Setup Package Association',
    identifierValue: `${props.values?.identifierValue}`,
    integrationName: 'keka',
    onClickIdentifierHandler: onClickIdentifierHandler,
    identifierValues: identifierValues,
    dropdownIdentifierValues: dropdownIdentifierValues,
    values: values,
    handleSetTagsValue: handleSetTagsValue,
    packages: packages,
    identifierDataLoading: identifierDataLoading
  };
  {
    console.log(integrationData);
  }
  return (
    <>
      <IntegrationLayout>
        <div className={styles.parentDivTopTextArea}>
          <TopTextArea
            title={'Keka'}
            icon={'keka.png'}
            showConnected={integrationData?.keka?.integrationId}
          />
          {!integrationData?.keka?.integrationId && (
            <Container>
              <div className='mt-5'>
                {kekaDetails.map((step, index) => {
                  return (
                    <Row>
                      <h6>
                        <span className='font-weight-bold'>Step {index + 1}:</span> {step}
                      </h6>
                    </Row>
                  );
                })}
              </div>
            </Container>
          )}
          {/* <InputArea history={props.history} /> */}

          {integrationData?.keka?.integrationId && (
            <PackageMappingIdentifier packageMappingComponent={packageMappingComponent} />
          )}
        </div>

        <Container>
          <div className={`${styles.parentDivInputArea} mb-5`}>
            <div className={styles.rowDisplay}>
              <Row>
                <div
                  className={
                    integrationData?.keka?.integrationId
                      ? styles.step2FooterContainer
                      : styles.step1FooterContainer
                  }
                >
                  <Button className={styles.goBackKNITBtn} onClick={() => backStepHandler()}>
                    Go Back
                  </Button>
                  {!integrationData?.keka?.integrationId ? (
                    <>
                      <Button className={styles.connectButton} onClick={() => connectTruto()}>
                        Connect
                      </Button>
                    </>
                  ) : (
                    <div>
                      <Button
                        className={styles.removeButtonRepute}
                        onClick={() => setIsOpen(true)}
                        variant='outline-danger'
                        disabled={!isPackageMapped && identifierDataLoading}
                      >
                        Disconnect
                      </Button>

                      <Button
                        className={styles.activeBtn}
                        onClick={handleSubmit}
                        disabled={!isFormValid() || checkIfTagsNotAvailable() || isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </Row>
              {isOpen ? (
                <WarningModal
                  show={isOpen}
                  onHide={() => {
                    setIsOpen(false);
                  }}
                  title={'Keka'}
                  onConfirm={() => {
                    onRemoveConnectionHandler();
                  }}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </IntegrationLayout>

      {showModal ? (
        <ConfirmationModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          heading={'Integration Alert'}
          onSubmit={() => {
            setShowModal(false);
            props.history.push(`/integrations`);
          }}
          enableBtn={true}
          subHeading={
            'Your account includes multiple packages and requires a custom configuration. Our CS team has been alerted about your request and will get in touch with you to enable the integration.'
          }
          sBtnText={'Close'}
          hideCloseBtn={true}
        />
      ) : null}
    </>
  );
}
