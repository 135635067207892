import React from 'react';
import { Card, Form, Button, Col } from 'react-bootstrap';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import { toast } from 'react-toastify';
import { isEmpty, toLower } from 'lodash';

import CountryCodeModal from '../CountryCodeModal';
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import BGVConfirmDialog from '../../../CommonComponent/BGVConfirmDialog';
import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import CustomValidatedSelect from '../../../../../core-components/CustomValidatedSelect';
import ProgressBar from '../../../../../core-components/ProgressBar';
import CustomSwitch from '../../../../../core-components/CustomSwitch';
import Image from '../../../../../core-components/Image';
import {
  isValidPhoneNumber,
  getTrimmedValue,
  errToastMessage
} from '../../../../../utils/utilities';
import countryOptions from '../../../../../utils/countryCodesEn.json';
import countryNames from '../../../../../utils/countryNamesEn.json';
import {
  checkBgvCandidateEmail as checkCandidateEmailAPI,
  checkBgvCandidatePhone as checkCandidatePhoneAPI
} from '../../../../../api/bgv';

import styles from './ContactDetails.module.scss';
import { allowOnlyNumbersRegex } from '../../../../../utils/ValidationSchema';

class ContactDetails extends React.Component {
  state = {
    modalShow: false,
    modalTitle: null,
    modalLabel: 'India(+91)',
    modalSubTitle: null,
    defaultValue: null,
    mobCountryCodeLabel: '(' + this?.props?.values?.country_code + ')',
    country_code: null,
    mobAltCountryCodeLabel: '(' + this?.props?.values?.alt_country_code + ')',
    alt_country_code: null,
    showFinalSubmit: this?.props?.finalSubmitCheck === -2 ? true : false,
    showConfirmation: false
  };

  modalToggle = (title, titleLabel, defaultCountryCode) => {
    let result = countryNames.filter((country) => defaultCountryCode === country.value);
    let modalLabel = result && result[0] ? `${result[0].label}(${result[0].value})` : null;
    this.setState({
      modalShow: true,
      modalTitle: title,
      modalSubTitle: titleLabel,
      defaultValue: defaultCountryCode,
      modalLabel: modalLabel
    });
  };

  modalHide = () => {
    this.setState({
      modalShow: false
    });
  };

  setCountryCodeValue = ({ title, subTitle }, country_code) => {
    this.setState({
      [title]: country_code.value,
      [subTitle]: '(' + country_code.value + ')',
      modalLabel: country_code.label + '(' + country_code.value + ')'
    });
    this.props.setFieldValue(title, country_code.value);
  };

  handleFinalSubmit = () => {
    this.props.handleSubmit();
  };

  handleFinalConfirmModal = () => {
    this.setState({
      showConfirmation: false
    });
    this.props.setSubmitting(false);
  };

  handleStepSubmit = (e) => {
    e.preventDefault();
    if (this.state.showFinalSubmit) {
      this.setState({
        showConfirmation: true
      });
    } else {
      this.props.handleSubmit();
    }
  };

  componentDidMount() {
    this.props.getBgvCandidateDetails();
    window.scroll(0, 0);

    /**
     * This validation is performed on mount to ensure the phone number is valid.
     *
     * We have observed that some old candidate records contain incorrect phone numbers that were not corrected during data cleanup.
     * For example, there was one candidate in production with the phone number: IN1234567113.
     * Since phone number validations are now enforced on both the front-end and back-end, it is not possible for newly added candidates to have invalid phone numbers.
     * And validations run on OnBlur so user was able to move to next step.
     *
     * To prevent progress to the next step of the BGV, we must ensure that phone numbers are validated on mount.
     */
    if (
      this.props.values.country_code &&
      this.props.values.phone_number &&
      !isValidPhoneNumber(this.props.values.country_code + this.props.values.phone_number)
    ) {
      this.props.setFieldValue('isValidPhone', false);
    } else {
      this.props.setFieldValue('isValidPhone', true);
    }
  }

  render() {
    const { isSubmitting, values, errors, setFieldValue, initial_candidate_details } = this.props;
    const setValue = (fieldname, value, withSpace) => {
      const trimmedVal = getTrimmedValue(value, withSpace);
      setFieldValue(fieldname, trimmedVal);
      return trimmedVal;
    };

    let isValidForm =
      !isSubmitting &&
      isEmpty(errors) &&
      values.emailExists === false &&
      values.phoneNumberExists === false &&
      values.isValidPhone === true &&
      values.isAltValidPhone === true;

    const indexing =
      this.props.totalSteps
        .map((val) => {
          return val?.id;
        })
        .indexOf(-2) + 1;

    return (
      <Card className={styles.contactDetailsCard}>
        <Card.Body className={styles.contactDetailsCardBody}>
          {!isMobileOnly ? (
            <div data-testid='no-mobile-header'>
              <span className={styles.contactDetailHeading}>Contact Details</span>
              <div className={styles.contactDetailProgressContainer}>
                <span className={styles.stepText}>
                  {indexing} of {this.props.totalSteps.length}
                </span>
                <ProgressBar max={this.props.totalSteps.length} min={0} now={indexing} />
              </div>
            </div>
          ) : (
            <>
              <BgvSecureText />
              <div className={styles.contactDetailProgressContainerMobile}>
                <span className={styles.contactDetailHeading}>Contact Details</span>
                <span className={styles.stepTextMobile}>
                  {indexing} of {this.props.totalSteps.length}
                </span>
              </div>
              <ProgressBar max={this?.props?.totalSteps?.length} min={0} now={indexing} />
            </>
          )}

          <Form onSubmit={this.handleStepSubmit} data-testid='contact-details-form'>
            <Form.Row>
              <Form.Label>
                Your Email Address {!values?.formConfig?.is_email_optional ? <span>*</span> : null}
              </Form.Label>
              <Field
                type='text'
                name='email'
                className={values.emailExists ? styles.emailError : null}
                component={ValidatedFormInputField}
                data-testid='email-field'
                isTouchedNotRequired
                onBlur={() => {
                  const trimmedVal = setValue('email', values?.email, false);
                  if (trimmedVal) {
                    checkCandidateEmailAPI(values.email)
                      .then((response) => {
                        if (response?.data?.data?.resp !== null) {
                          setFieldValue('emailExists', true);
                        } else {
                          setFieldValue('emailExists', false);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        errToastMessage(error);
                        setFieldValue('emailExists', false);
                      });
                  }
                }}
                disabled={
                  initial_candidate_details &&
                  initial_candidate_details.contact_details &&
                  initial_candidate_details.contact_details.email
                }
              />
              {!errors.email && values.emailExists && (
                <div className={styles.emailErrorMsg}>
                  {values.emailExists ? 'Email already exists' : ''}
                </div>
              )}
            </Form.Row>
            <Form.Label> Alternate Email Address</Form.Label>
            <Field
              type='text'
              name='alternate_email'
              component={ValidatedFormInputField}
              onBlur={(e) => {
                this.props.handleBlur(e);
                setValue('alternate_email', toLower(values?.alternate_email), false);
              }}
            />
            <Form.Label>
              Your Mobile Number <span>*</span>
            </Form.Label>
            <Form.Row>
              {!isMobileOnly ? (
                <Field
                  type='text'
                  name='country_code'
                  defaultValue={values.country_code}
                  options={countryNames}
                  className={'countryCodeDropDown ' + styles.countryCodeDropDown}
                  filterLabel={countryOptions}
                  component={CustomValidatedSelect}
                  onChange={(e) => {
                    if (values.phone_number && e) {
                      let intlPhoneNumber = `${e + '' + values.phone_number}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isValidPhone', false);
                      } else {
                        setFieldValue('isValidPhone', true);
                        if (values.phone_number && e) {
                          let intlPhoneNumber = `${e + '' + values.phone_number}`;
                          checkCandidatePhoneAPI(intlPhoneNumber)
                            .then((response) => {
                              if (response?.data?.data?.resp !== null) {
                                setFieldValue('phoneNumberExists', true);
                              } else {
                                setFieldValue('phoneNumberExists', false);
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                              errToastMessage(error);
                              setFieldValue('phoneNumberExists', false);
                            });
                        }
                      }
                    }
                  }}
                  onBlur={(e) => {
                    this.props?.handleBlur(e);
                    setValue('phone_number', values?.phone_number, false);
                  }}
                />
              ) : (
                <Form.Group
                  as={Col}
                  className={styles.mobCountryCodeDropDown}
                  controlId='formGridPhoneNumber'
                >
                  <div
                    className={styles.mobileCountryCodes}
                    onClick={() =>
                      this.modalToggle('country_code', 'mobCountryCodeLabel', values.country_code)
                    }
                  >
                    {this.state.mobCountryCodeLabel}
                    <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                  </div>
                  {this.state.modalShow && (
                    <CountryCodeModal
                      subShow={this.state.modalShow}
                      SubOnHide={this.modalHide}
                      title={this.state.modalTitle}
                      subTitle={this.state.modalSubTitle}
                      modalLabel={this.state.modalLabel}
                      defaultValue={this.state.defaultValue}
                      handleCodeChange={this.setCountryCodeValue}
                      {...this.props}
                    />
                  )}
                </Form.Group>
              )}
              <Form.Group as={Col} controlId='formGridPhoneNumber'>
                <Field
                  type='text'
                  name='phone_number'
                  data-testid='phone-number'
                  className={
                    values.phoneNumberExists || !values.isValidPhone ? styles.emailError : null
                  }
                  component={ValidatedFormInputField}
                  onBlur={(e) => {
                    this.props?.handleBlur(e);
                    const trimmedVal = setValue('phone_number', values?.phone_number, false);
                    if (trimmedVal && values.country_code && !errors.phone_number) {
                      let intlPhoneNumber = `${values.country_code + '' + trimmedVal}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isValidPhone', false);
                      } else {
                        setFieldValue('isValidPhone', true);
                      }
                    }
                    if (trimmedVal && values.isValidPhone && values.country_code) {
                      let intlPhoneNumber = `${values.country_code + '' + trimmedVal}`;
                      checkCandidatePhoneAPI(intlPhoneNumber)
                        .then((response) => {
                          if (response?.data?.data?.resp !== null) {
                            setFieldValue('phoneNumberExists', true);
                          } else {
                            setFieldValue('phoneNumberExists', false);
                          }
                        })
                        .catch((error) => {
                          errToastMessage(error);
                          setFieldValue('phoneNumberExists', false);
                        });
                    }
                  }}
                  isHyphenNotRequired
                  regex={allowOnlyNumbersRegex}
                />
                {!values.isValidPhone && (
                  <div className={styles.emailErrorMsg}>
                    {!values.isValidPhone && values.phone_number
                      ? 'Please enter a valid Mobile Number.'
                      : ''}
                  </div>
                )}
                {values.phoneNumberExists && values.isValidPhone && values.phone_number && (
                  <div className={styles.emailErrorMsg}>Phone number already exists.</div>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Label>Alternate Mobile Number</Form.Label>
            <Form.Row>
              {!isMobileOnly ? (
                <Field
                  type='text'
                  name='alt_country_code'
                  defaultValue={values.alt_country_code}
                  className={'countryCodeDropDown ' + styles.countryCodeDropDown}
                  options={countryNames}
                  filterLabel={countryOptions}
                  component={CustomValidatedSelect}
                  onChange={(e) => {
                    setFieldValue('isAltValidPhone', true);
                    if (values.alternate_phone_number && e) {
                      let intlPhoneNumber = `${e + '' + values.alternate_phone_number}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isAltValidPhone', false);
                      } else {
                        setFieldValue('isAltValidPhone', true);
                      }
                    }
                  }}
                />
              ) : (
                <Form.Group
                  as={Col}
                  className={styles.mobCountryCodeDropDown}
                  controlId='formGridPhoneNumber'
                >
                  <div
                    className={styles.mobileCountryCodes}
                    onClick={() =>
                      this.modalToggle(
                        'alt_country_code',
                        'mobAltCountryCodeLabel',
                        values.alt_country_code
                      )
                    }
                  >
                    {this.state.mobAltCountryCodeLabel}
                    <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                  </div>
                </Form.Group>
              )}
              <Form.Group as={Col} controlId='formGridAltPhoneNumber'>
                <Field
                  type='text'
                  name='alternate_phone_number'
                  className={!values.isAltValidPhone ? styles.emailError : null}
                  component={ValidatedFormInputField}
                  onBlur={(e) => {
                    this.props.handleBlur(e);
                    setFieldValue('isAltValidPhone', true);
                    const trimmedVal = setValue(
                      'alternate_phone_number',
                      values?.alternate_phone_number,
                      false
                    );
                    if (trimmedVal && values.alt_country_code) {
                      let intlPhoneNumber = `${values.alt_country_code + '' + trimmedVal}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isAltValidPhone', false);
                      } else {
                        setFieldValue('isAltValidPhone', true);
                      }
                    }
                  }}
                  isHyphenNotRequired
                  regex={allowOnlyNumbersRegex}
                />
                {!values.isAltValidPhone && (
                  <div className={styles.emailErrorMsg}>
                    {!values.isAltValidPhone
                      ? 'Please provide a valid Alternate Mobile Number.'
                      : ''}
                  </div>
                )}
              </Form.Group>
            </Form.Row>

            <Field
              defaultValue={values.whatsapp_update}
              name='whatsapp_update'
              label='Get updates of your verification process via WhatsApp.'
              component={CustomSwitch}
            />
            {/* Confirm Dialog */}
            {this.state.showConfirmation && this.state.showFinalSubmit && (
              <BGVConfirmDialog
                showConfirmation={this.state.showConfirmation}
                handleConfirmSubmit={this.handleFinalSubmit}
                handleHide={this.handleFinalConfirmModal}
                enableBtn={isValidForm}
                {...this.props}
              />
            )}
            <Form.Row className={styles.basicDetailSubmitBtn}>
              <BgvGoBackBtn handleClick={this.props.prevStep} />
              <Button
                type='submit'
                className={
                  !isValidForm || this.props.isLoadingBgvCache || this.props.internalLoading
                    ? styles.disabledBtn
                    : styles.activeBtn
                }
                disabled={
                  !isValidForm || this.props.isLoadingBgvCache || this.props.internalLoading
                }
              >
                Next
              </Button>
            </Form.Row>
          </Form>
          <p className={styles.basicDetailInfo}>All fields marked with an asterisk are required.</p>
        </Card.Body>
      </Card>
    );
  }
}

export default ContactDetails;
