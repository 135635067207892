import React from 'react';
import PropTypes from 'prop-types';

import { ICONS } from './Icons';

const Icon = (props) => {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    path: {
      fill: props.color
    }
  };

  return (
    <svg
      data-testid='icon'
      style={styles.svg}
      width={`${props.width || props.size}px`}
      height={`${props.height || props.size}px`}
      onClick={props.onClick}
      className={props.className}
    >
      <path style={styles.path} d={ICONS[props.icon]} fillRule={props.fillRule} />
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string
};

Icon.defaultProps = {
  size: 20,
  color: '#AAAAAA'
};

export default Icon;
