import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { FaShareAlt } from 'react-icons/fa';
import { RiCloseCircleFill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';

import './CustomDropDownWithTags.module.scss';

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>⏷</components.DropdownIndicator>
    )
  );
};

const CustomDropDownWithTags = (props) => {
  const [inputValue, setInputValue] = useState('');

  const { options, selectedOptions, setSelectedOptions, placeholder } = props;

  const handleChange = (selected) => {
    if (selected?.length <= 5) {
      setSelectedOptions(selected);
    } else if (selected === null) {
      setSelectedOptions([]);
    }
  };

  const handleInputChange = (input) => {
    if (selectedOptions?.length < 5) setInputValue(input);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue && selectedOptions.length < 5) {
      const newOption = {
        value: inputValue,
        label: inputValue,
        status: 'not-connected-unavailable'
      };

      const optionExists = selectedOptions.some((option) => option.value === inputValue);

      if (!optionExists) {
        setSelectedOptions([...selectedOptions, newOption]);
      }

      setInputValue('');
    }
  };

  const CustomSelectedOption = ({ data, innerRef, innerProps }) => {
    const [hovered, setHovered] = useState(false);

    const getStyle = (status) => {
      const commonStyles = {
        margin: '5px',
        borderRadius: '25px',
        display: 'flex',
        gap: '10px',
        paddingInline: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontWeight: '600',
        backgroundColor: 'white'
      };

      const styles = {
        connected: {
          border: '3px solid rgba(29, 138, 66, 0.5)',
          color: '#1d8a42'
        },
        'not-connected-available': {
          border: '3px solid rgba(39, 85, 254, 0.5)',
          color: '#2755fe'
        },
        'not-connected-unavailable': {
          border: '3px solid rgba(123, 138, 152, 0.5)',
          color: '#7B8A98'
        }
      };

      return { ...commonStyles, ...styles[status] };
    };

    const commonStyles = {
      closeIcon: {
        position: 'relative',
        top: ' -17px',
        right: '-36px',
        color: 'grey'
      },
      fillerDiv: { width: '15px' },
      noShow: { display: 'none' }
    };

    const handleRemove = () => {
      const filteredOptions = selectedOptions.filter((option) => option.label !== data.label);
      setSelectedOptions(filteredOptions);
    };

    return (
      <OverlayTrigger
        placement='top'
        overlay={
          data.status === 'not-connected-available' && props?.isCa ? (
            <Tooltip id='tooltip-click-to-connect'>Click to Connect</Tooltip>
          ) : (
            <Tooltip style={commonStyles.noShow}></Tooltip>
          )
        }
      >
        <div
          onClick={() => {
            if (data.status === 'not-connected-available' && data?.url && props?.isCa) {
              window.open(data.url, '_blank');
            }
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={getStyle(data.status)}
          ref={innerRef}
          {...innerProps}
        >
          <span>{data.label}</span>

          <div
            style={commonStyles.closeIcon}
            onClick={(e) => {
              e.stopPropagation(); // Prevent the onClick event of the root div
              handleRemove();
            }}
          >
            {hovered ? <RiCloseCircleFill /> : <div style={commonStyles.fillerDiv}></div>}
          </div>

          {data.status === 'not-connected-available' && (
            <span>
              <FaShareAlt />
            </span>
          )}
          {data.status === 'not-connected-unavailable' && (
            <span className='not-connected-available'>
              {!hovered ? <RxCross2 /> : <div style={{ width: '15px', height: '10px' }}></div>}
            </span>
          )}
          {data.status === 'connected' && (
            <span className='connected'>
              <FaCheck />
            </span>
          )}
        </div>
      </OverlayTrigger>
    );
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#CCCCCC'
    })
  };

  return (
    <Select
      value={selectedOptions}
      onChange={handleChange}
      options={options}
      components={{ DropdownIndicator, MultiValue: CustomSelectedOption }}
      isMulti
      closeMenuOnSelect={false}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      styles={customStyles}
      isClearable={false}
    />
  );
};

export default CustomDropDownWithTags;
