import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, has } from 'lodash';

import validationSchema from './AddressStep1.validation';
import logger from '../../../../../../../core-components/Logger';
import { toCamelCase, toSnakeCase } from '../../../../../../../utils/utilities';

import { saveBgvCache as saveBgvCacheAPI } from '../../../../../../../actions/bgv';
import {
  geocodeAddress as geocodeAddressAPI,
  invalidateGeocodeAddress
} from '../../../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    cachedData: !state.bgvCachedData.data ? null : toCamelCase({ ...state.bgvCachedData.data }),
    geoCodeAddressData: state.geoCodeAddress ? state.geoCodeAddress : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    getGeocodeAddress: (data) => dispatch(geocodeAddressAPI(data)),
    invalidateGeocodeAddress: () => dispatch(invalidateGeocodeAddress())
  };
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      if (
        has(props.cachedData.cacheData, 'addressCheck') &&
        !isEmpty(props.cachedData.cacheData.addressCheck) &&
        props.cachedData.cacheData.addressCheck.length > props.index &&
        !isEmpty(props.cachedData.cacheData.addressCheck[props.index]) &&
        has(props.cachedData.cacheData.addressCheck[props.index], 'step1')
      ) {
        propsValue = props.cachedData.cacheData.addressCheck[props.index]['step1'];
      } else {
        propsValue = {};
      }
      let storedValues = {
        addressType: props.addressType !== 'ANY ADDRESS' ? props.addressType : '',
        houseNo: !isEmpty(propsValue) ? propsValue.houseNo : '',
        colony: !isEmpty(propsValue) ? propsValue.colony : '',
        rented: !isEmpty(propsValue) ? propsValue.rented : false,
        landmark: !isEmpty(propsValue) ? propsValue.landmark : '',
        pincode: !isEmpty(propsValue) ? propsValue.pincode : '',
        country: !isEmpty(propsValue) ? propsValue.country : 'India',
        city: !isEmpty(propsValue) ? propsValue.city : '',
        state: !isEmpty(propsValue) ? propsValue.state : ''
      };
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props, setFieldError }) => {
      //validation
      if (!values.country) {
        setFieldError('country', 'please select  country');
        setSubmitting(false);
      } else if (!values.state) {
        setFieldError('state', 'please select state');
        setSubmitting(false);
      } else {
        let valuesCopy = Object.assign({}, values),
          finalCacheData = {};
        const finalStepObj = {};

        // sprint-10 changes
        if (
          has(props.cachedData.cacheData, 'addressCheck') &&
          !isEmpty(props.cachedData.cacheData.addressCheck) &&
          props.cachedData.cacheData.addressCheck.length > props.index &&
          !isEmpty(props.cachedData.cacheData.addressCheck[props.index])
        ) {
          let addressCheck = [...props.cachedData.cacheData.addressCheck];
          const addressCheckStep1 = addressCheck[props.index]['step1'];
          if (_.isEqual(addressCheckStep1, valuesCopy)) {
            valuesCopy.isAddressChanged = false;
          } else {
            valuesCopy.isAddressChanged = true;
          }
          addressCheck[props.index]['step1'] = {
            ...valuesCopy
          };
          addressCheck[props.index]['currentStep'] = 2;

          const saveObject = toSnakeCase({
            ...props.cachedData,
            cacheData: {
              ...props.cachedData.cacheData,
              addressCheck: [...addressCheck]
            },
            currentState: '3#2'
          });

          finalCacheData = {
            saveObject
          };
        } else if (
          has(props.cachedData.cacheData, 'addressCheck') &&
          !isEmpty(props.cachedData.cacheData.addressCheck) &&
          props.cachedData.cacheData.addressCheck.length
        ) {
          let addressCheck = [...props.cachedData.cacheData.addressCheck];

          addressCheck[props.index] = {
            step1: { ...valuesCopy },
            currentStep: 2
          };

          const saveObject = toSnakeCase({
            ...props.cachedData,
            cacheData: {
              ...props.cachedData.cacheData,
              addressCheck: [...addressCheck]
            },
            currentState: '3#2'
          });

          finalCacheData = {
            saveObject
          };
        } else {
          finalStepObj['addressCheck'] = [];

          finalStepObj.addressCheck[props.index] = {
            step1: { ...valuesCopy },
            currentStep: 2
          };

          const saveObject = toSnakeCase({
            ...props.cachedData,
            cacheData: { ...props.cachedData.cacheData, ...finalStepObj },
            currentState: '3#2'
          });

          finalCacheData = {
            saveObject
          };
        }

        // call api
        props.setBgvCache(finalCacheData).then(() => {
          logger.push({
            message: `${props.addressType} Address step1  ${
              props.title === 'Edit' ? 'Edited' : 'Saved'
            }`,
            data: JSON.stringify(finalCacheData),
            category: `candidate-id-${props.candidateId}`,
            subCategory: `${props.addressType} Address #1`,
            type: 'info'
          });
          props.nextSubStep();
        });
      }
    },
    displayName: 'addressStep1'
  })
);
