// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xrBq0XQVwidDctGls6YA {
  width: 1200px;
  background-color: white;
  box-shadow: 0px 2px 12px 0px rgba(51, 51, 51, 0.1);
  flex-shrink: 0;
  margin: 15px auto 0 auto;
}

.XwIQDG1kpiJgdMyV5JfA {
  padding: 16px 0 16px 24px;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  color: #464457;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.GO_5mo2t2mx4ic6y5cbv {
  margin: -2px 0px 0px 8px;
}

.CnHPqs7xOlrDdvsG9Rub {
  display: flex;
  padding: 16px 32px 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `xrBq0XQVwidDctGls6YA`,
	"header": `XwIQDG1kpiJgdMyV5JfA`,
	"newTag": `GO_5mo2t2mx4ic6y5cbv`,
	"cardContainer": `CnHPqs7xOlrDdvsG9Rub`
};
export default ___CSS_LOADER_EXPORT___;
