// Import necessary libraries
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Import styles
import styles from './ModalCommonStyles.module.scss';

// Import components and other utilities
import CustomCounter from '../../../../core-components/CustomCounter';
import { sendSlackNotification } from '../../../../api/company';

/**
 * Modal component for requesting a specific laptop or model.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible or not.
 * @param {Function} props.handleClose - Callback function to handle closing the modal.
 * @returns {JSX.Element} The LaptopRequestModal component.
 */
function LaptopRequestModal({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const { profile, paymentDetails } = useSelector((state) => state);

  const { email, name, company_user_mapping } = profile?.profile;
  const { designation, mobile } = paymentDetails;
  const { company, id } = company_user_mapping;

  const [values, setValues] = useState({
    laptopName: '',
    laptopSpecification: '',
    specialInstructions: '',
    quantity: 0
  });

  const isValidForm =
    values.laptopName?.length && values.laptopSpecification?.length && values.quantity && !loading;

  /**
   * Handles the form submission for the laptop request.
   */
  const onSubmit = () => {
    setLoading(true);
    const payload = {
      alert_title: 'SVIN Product Upsell Request',
      icon_emoji: ':computer:',
      color: 'good',
      channel_name: 'sv-in-upsell-requests',
      fields: [
        { field_key: 'Company Name', field_value: `${company?.name} (${id})` },
        { field_key: 'POC Name', field_value: name },
        { field_key: 'POC Phone', field_value: mobile || 'Not Available' },
        { field_key: 'POC Email', field_value: email },
        { field_key: 'POC Designation', field_value: designation || 'Not Available' },
        { field_key: 'IT POC Phone', field_value: 'Not Available' },
        { field_key: 'Laptop Name', field_value: values.laptopName },
        { field_key: 'Laptop Specification', field_value: values.laptopSpecification },
        { field_key: 'Mode', field_value: 'Buy' },
        {
          field_key: 'Special Instruction',
          field_value: values.specialInstructions
        },
        { field_key: 'Quantity', field_value: `${values.quantity}` }
      ]
    };

    sendSlackNotification(payload)
      .then(() => {
        toast.success(`We've received your request. Our team will reach out to you.`);
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Failed to send request. Please try again.');
      });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Request specific laptop or model</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.reqModalBody}>
        <Form>
          <Form.Group className='mb-2'>
            <Form.Label className={styles.inputLabel}>
              Laptop Name<span>*</span>
            </Form.Label>
            <Form.Control
              className={styles.inputArea}
              onChange={(e) => setValues({ ...values, laptopName: e.target.value })}
              type='text'
              placeholder='Enter laptop name'
            />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label className={styles.inputLabel}>
              Laptop Specification<span>*</span>
            </Form.Label>
            <Form.Control
              className={styles.inputArea}
              type='text'
              onChange={(e) => setValues({ ...values, laptopSpecification: e.target.value })}
              placeholder='Enter laptop specification'
            />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label className={styles.inputLabel}>Special Instructions</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              className={styles.inputArea}
              onChange={(e) => setValues({ ...values, specialInstructions: e.target.value })}
              placeholder='Write any special instructions here'
            />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label className={styles.inputLabel}>
              Quantity<span>*</span>
            </Form.Label>
            <div className='d-flex align-items-center'>
              <CustomCounter
                isShowLargeSize
                value={values.quantity}
                index={null}
                valueChange={(val) => {
                  setValues({ ...values, quantity: val });
                }}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!isValidForm}
          className={styles.requestButton}
          onClick={onSubmit}
          variant='primary'
          type='submit'
        >
          Request call back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LaptopRequestModal;
