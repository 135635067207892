// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HF68Rbn6Jf8tR7zSFm5A {
  font-weight: 700 !important;
}

.oZ4dH9MKFzWpc3EfU0Pg {
  border: none;
  border-radius: none;
  width: 338px !important;
  background-color: white;
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
}
.oZ4dH9MKFzWpc3EfU0Pg:focus {
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.oZ4dH9MKFzWpc3EfU0Pg:hover {
  cursor: pointer;
}
.oZ4dH9MKFzWpc3EfU0Pg::placeholder {
  color: red;
}
.oZ4dH9MKFzWpc3EfU0Pg input:disabled {
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(170, 170, 170));
}
.oZ4dH9MKFzWpc3EfU0Pg input:disabled:hover {
  cursor: not-allowed;
}

.yYLAtN5CTVUC9otaxc7o {
  display: flex;
  border: 1px solid #ebedf2;
  width: 368px;
}
.yYLAtN5CTVUC9otaxc7o.gO17r9sYaqJjWednqA3_ {
  border-color: #dc3545;
}
.yYLAtN5CTVUC9otaxc7o img {
  margin-right: 12px;
}

.Bztnk1thfUkAnBf8iQxL {
  width: 368px;
}

.BieJZnICbV0srdO1EFnU {
  height: 60px;
}

.NhcStGIUuePmckIg0pxA {
  background: #d3455b;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
  border: none;
  cursor: pointer;
  height: 45px;
}
.NhcStGIUuePmckIg0pxA:hover {
  background: #e97070;
}
.NhcStGIUuePmckIg0pxA:disabled {
  opacity: 0.4;
  cursor: auto;
}
.NhcStGIUuePmckIg0pxA:disabled:hover {
  background: #d3455b;
}

.zzBGeczBfSfP30BvmTcr {
  border: 1px solid #2755fe;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2755fe;
  width: 136px;
  height: 45px;
}

.cfvG4voEEcFaRf4lSrLW {
  min-height: 80px;
  line-height: 24px;
  margin-bottom: 0 !important;
}

.ZVjgsIXf_XwOxm9fKpht {
  position: relative;
}

.EK5_MGHb4QtTuXI4SEuO {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #999;
  width: 100%;
  text-align: right;
  border: 1px solid #ebedf2;
  padding: 2px 5px;
  border-top: none;
  border-color: white !important;
}
.EK5_MGHb4QtTuXI4SEuO.cYMsZN0aOxWXuGHswPeE {
  bottom: 24px;
  color: #dc3545;
}

.GWxNYyQm41xnFLNBY3gB {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #dc3545;
}

.adaLSzMcyFAhRyumfIQD {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #999;
  width: 100%;
  border-top: none;
  border-color: white !important;
  position: absolute;
  bottom: 8px;
}
.adaLSzMcyFAhRyumfIQD.X8wzGpT_xjPbQFKEoGIJ {
  bottom: 30px;
}

.akZyl510wKTytgMU7_KP {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #999;
  width: 100%;
  border-top: none;
  border-color: white !important;
  position: absolute;
  bottom: -5px;
}
.akZyl510wKTytgMU7_KP.X8wzGpT_xjPbQFKEoGIJ {
  bottom: 25px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `HF68Rbn6Jf8tR7zSFm5A`,
	"datePicker": `oZ4dH9MKFzWpc3EfU0Pg`,
	"datePickerContainer": `yYLAtN5CTVUC9otaxc7o`,
	"err": `gO17r9sYaqJjWednqA3_`,
	"selectReason": `Bztnk1thfUkAnBf8iQxL`,
	"height60": `BieJZnICbV0srdO1EFnU`,
	"redButton": `NhcStGIUuePmckIg0pxA`,
	"cancelButton": `zzBGeczBfSfP30BvmTcr`,
	"otherReasonText": `cfvG4voEEcFaRf4lSrLW`,
	"OtherReasonFieldContainer": `ZVjgsIXf_XwOxm9fKpht`,
	"charCount": `EK5_MGHb4QtTuXI4SEuO`,
	"errPosition": `cYMsZN0aOxWXuGHswPeE`,
	"errDiv": `GWxNYyQm41xnFLNBY3gB`,
	"reasonNoteForOther": `adaLSzMcyFAhRyumfIQD`,
	"withErrField": `X8wzGpT_xjPbQFKEoGIJ`,
	"reasonNote": `akZyl510wKTytgMU7_KP`
};
export default ___CSS_LOADER_EXPORT___;
