// React and other libraries imports
import React, { useState } from 'react';
import { Field, useFormik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import validationSchema from './CloseCheckModal.validation';
import classNames from 'classnames';

// Common components and Utils import
import ValidatedFormTextArea from '../../../../core-components/ValidatedFormTextArea';

// Styles Import
import styles from './CloseCheckModal.module.scss';
import { CHECK_NAME_BY_ID, CLOSE_CHECK_REASONS } from '../../../../utils/commonConstant';
import { getTrimmedValue } from '../../../../utils/utilities';

import moment from 'moment';

const CloseCheckModal = ({
  open,
  onClose,
  activeItem,
  data = null,
  closeCheckAPI,
  fetchCandidate
}) => {
  const initialValues = {
    check_name: CHECK_NAME_BY_ID[activeItem?.id] || '',
    check_id: data?.id || null,
    candidate_id: data?.candidateId || null,
    current_status: data?.status,
    status: 6,
    reason: '',
    comment: '',
    closed_date: moment().format('YYYY-MM-DD')
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const res = await closeCheckAPI(values);
      fetchCandidate();
    }
  });

  const {
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
    isValid,
    dirty,
    handleSubmit,
    isSubmitting
  } = formik;

  return (
    <div>
      <Modal
        size='lg'
        centered
        show={open}
        onHide={onClose}
        backdropClassName={'customBGVModalBackdrop'}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className={styles.title}>Close check</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <p>
              This action will change the check status to CLOSED and no further action will be taken
              on this check. Any charges associated with this check will not be eligible for refund.
            </p>
            <p>
              <b> This action cannot be undone. Are you sure?</b>
            </p>
            <div className='form-group position-relative'>
              <label>
                Select reason <span>*</span>
              </label>
              <Field
                name='reason'
                component={Select}
                options={CLOSE_CHECK_REASONS}
                className={`${styles.selectReason} ${
                  values?.reason !== 'OTHER' ? styles.height60 : null
                }`}
                value={CLOSE_CHECK_REASONS?.filter((c) => c.value === values?.reason)}
                isClearable
                menuPlacement={'top'}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: errors?.reason ? 'red' : '#ccc'
                  })
                }}
                onChange={(option) => {
                  setFieldValue('reason', option?.value || '');
                }}
                onBlur={() => {
                  setFieldTouched('reason', true);
                }}
              />
              {touched?.reason && errors?.reason && (
                <div className={styles.errDiv}>{errors?.reason}</div>
              )}
              {values?.reason !== 'OTHER' && (
                <div
                  className={classNames(styles.reasonNote, {
                    [styles.withErrField]: touched.reason && errors?.reason
                  })}
                >
                  *Reason for closing will be added to the candidate report
                </div>
              )}
            </div>

            {values?.reason === 'OTHER' && (
              <div className='form-group position-relative'>
                <label>
                  Reason<span>*</span>
                </label>
                <Field
                  name='comment'
                  value={values?.comment}
                  onChange={(e) => {
                    setFieldValue('comment', e?.target?.value || '');
                  }}
                  onBlur={(e) => {
                    setFieldValue('comment', getTrimmedValue(e?.target?.value, true));
                    setFieldTouched('comment', true);
                  }}
                  containerClass={styles.OtherReasonFieldContainer}
                  charCountClass={styles.charCount}
                  errCountClass={styles.errPosition}
                  showCharCount={true}
                  rows='2'
                  maxLength={160}
                  charsCount={values?.comment?.length}
                  className={styles.otherReasonText}
                  component={ValidatedFormTextArea}
                  maxChars={160}
                  charsCountError={touched.comment && errors?.comment}
                />
                {touched?.comment && errors?.comment && (
                  <div className={styles.errDiv}>{errors?.comment}</div>
                )}
                <div
                  className={classNames(styles.reasonNoteForOther, {
                    [styles.withErrField]: touched.comment && errors?.comment
                  })}
                >
                  *Reason for closing will be added to the candidate report
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className={styles.cancelButton} onClick={onClose}>
              Cancel
            </button>
            <button
              disabled={
                !isValid ||
                !dirty ||
                isSubmitting ||
                (values?.reason === 'OTHER' && values?.comment?.length < 10)
              }
              type='submit'
              className={styles.redButton}
            >
              Close Check
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CloseCheckModal;
