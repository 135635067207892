import React from 'react';
import Image from '../Image';
import styles from './ErrorComponent.module.scss';

export default (props) => {
  const paymentRquestError = props.location?.state?.message === 'Payment_Request';
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerLogo}>
          <Image name='sv_logo_desktop.svg' />
        </div>
      </div>
      <div className={styles.errorContainer}>
        <h3>
          {paymentRquestError
            ? ' You are trying to access the payment link for a different account.'
            : 'This link is to be accessed only by the candidate and not an admin.'}
        </h3>

        <p>
          {paymentRquestError ? (
            'Please use Incognito mode to make the payment or log in to the account associated with this payment'
          ) : (
            <a
              data-testid='back-btn'
              className={styles.anchorText}
              onClick={() => props.history.push('/dashboard')}
            >
              'click here to go back to homepage'{' '}
            </a>
          )}
        </p>
      </div>
    </>
  );
};
