import React, { useEffect, useState } from 'react';
import classes from './PaymentRequest.module.scss';
import Image from '../../core-components/Image';
import Invoice from './Invoice';
import localStorage from '../../api/localStorage';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  convertToIndianNumeration,
  errToastMessage,
  getTdsOptions,
  jwtParser,
  toCamelCase
} from '../../utils/utilities';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConfirmationTDSModal from '../../core-components/ConfirmationModalTds';
import {
  buyOrder,
  buyOrderByOrderId,
  finalizeRazorpayPaymentPurchaseOrder,
  getRequestOrderDetails
} from '../../api/company';
import { toast } from 'react-toastify';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import RazorpayButton from '../../core-components/Razorpay';
import { loggly_payment_type } from '../../utils/commonConstant';
import Loader from '../../core-components/Loader/Loader';

const PaymentRequest = ({ values, setFieldValue, handleSubmit, profile, ...props }) => {
  const token = localStorage.getToken();
  const [tdsAmount, setTdsAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showTdsModal, setShowTdsModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const headerToken = new URLSearchParams(document.location.search).get('token');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [orderId, setOrderId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [addedBalance, setAddedBalance] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const paymentCompanyId = jwtParser(urlParams.get('token'))?.data.company_id;
  const loggedInCompanyId = jwtParser(token)?.data;
  useEffect(() => {
    calculateTds();
  }, [values.companyTds]);

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    setTotalAmount(orderDetails?.amount);
    setPaymentStatus(orderDetails?.status);
  }, [orderDetails]);

  const calculateTds = () => {
    const tds = orderDetails?.amount * (values.companyTds / 100);
    const tax = orderDetails?.amount * (orderDetails?.company?.tax / 100);
    setTdsAmount(isNaN(tds) ? 0 : tds);
    setTaxAmount(tax);
    setTotalAmount(orderDetails?.amount + tax - (isNaN(tds) ? 0 : tds));
  };
  const getOrderDetails = () => {
    const jwtObjData = jwtParser(headerToken);
    setTokenData(jwtObjData?.data);
    getRequestOrderDetails({
      orderId: jwtObjData?.data?.order_id,
      companyId: jwtObjData?.data?.company_id,
      token: headerToken
    })
      .then((res) => {
        setOrderDetails(res?.data?.data);
        setFieldValue(
          'companyTds',
          res?.data?.data?.tds_percentage ? String(res?.data?.data?.tds_percentage) : 'NONE'
        );
      })
      .catch((err) => {
        console.error(err);
        errToastMessage(err);
      });
  };
  const getBannerText = () => {
    const differenceDays = moment()
      .startOf('day')
      .diff(moment(orderDetails?.last_due_date).startOf('day'), 'days');
    if (differenceDays === 0) {
      return {
        type: 'DANGER',
        label: 'Due Today'
      };
    } else if (differenceDays < 0) {
      const dueDay = differenceDays * -1;
      return {
        type: differenceDays === -1 ? 'ALERT' : 'NORMAL',
        label: differenceDays === -1 ? `Due tomorrow` : `Due in ${dueDay} days`
      };
    } else {
      return {
        type: 'DANGER',
        label: `Overdue by ${differenceDays} day${differenceDays > 1 ? 's' : ''}`
      };
    }
  };

  if (loggedInCompanyId && paymentCompanyId !== loggedInCompanyId.companyId) {
    props.history.push({
      pathname: '/error',
      state: { message: 'Payment_Request' }
    });
  }

  if (
    paymentStatus == 'CANCELLED' ||
    paymentStatus == 'EXPIRED' ||
    paymentStatus == 'SUCCESS' ||
    paymentStatus == 'PAYMENT_INPROGRESS'
  ) {
    return <PaymentStatus orderDetails={toCamelCase(orderDetails)} profile={profile} />;
  }
  const onPaymentFailure = () => {
    setLoading(false);
    setShowTdsModal(false);
    getOrderDetails();
  };
  const finalizeRazorpayFailure = (payload) => {
    finalizeRazorpayPaymentPurchaseOrder(payload).catch((error) => {
      onPaymentFailure();
    });
  };
  const onPaymentSuccess = () => {
    setShowTdsModal(false);
    getOrderDetails();
  };
  const paymentObj = {
    order_id: tokenData?.order_id,
    company_id: tokenData?.company_id,
    tds_percentage: parseInt(values.companyTds) || 0
  };
  const getRazorPayObject = () => {
    return {
      setShowLoader: setLoading,
      createOrder: (paymentObj) => {
        setApiCalled(true);
        return buyOrderByOrderId(paymentObj);
      },
      createOrderPayload: paymentObj,
      buttonText: 'PAY NOW',
      onSuccess: finalizeRazorpayPaymentPurchaseOrder,
      onFailure: finalizeRazorpayFailure,
      setPaymentStatus: (value) => {
        if (value === 'failed') {
          setApiCalled(false);
        }
        return setPaymentStatus(value);
      },
      setOrderId: setOrderId,
      setTransactionId: setTransactionId,
      setAddedBalance: setAddedBalance,
      onPaymentSuccess: onPaymentSuccess,
      type: loggly_payment_type.ORDER,
      buttonIcon: 'lock_guard_white.svg',
      disable: orderDetails?.status !== 'PENDING' || apiCalled
    };
  };
  const payNow = (paymenObj) => {
    if (values?.companyTds === 'NONE') {
      buyOrderByOrderId(paymenObj);
    } else {
      setShowTdsModal(true);
    }
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {(paymentStatus == 'PENDING' || paymentStatus == 'failed') && (
        <>
          <div className={classes.paymentContainer}>
            <div className={classes.container}>
              <div className={classes.pageHeader}>Payment Request</div>
              <div className={classes.mainContainer}>
                <Invoice
                  companyId={tokenData?.company_id}
                  token={token ? token : headerToken}
                  invoiceId={orderDetails?.proforma_invoice_number}
                />
                <div className={classes.orderDetails}>
                  <div className={classes.requestDetails}>
                    <div
                      className={`${classes.statusBanner} ${
                        getBannerText().type === 'DANGER'
                          ? classes.redBanner
                          : getBannerText().type === 'ALERT'
                          ? classes.yellowBanner
                          : classes.blueBanner
                      }`}
                    >
                      <span
                        className={getBannerText().type !== 'ALERT' ? classes.bannerTextWhite : ''}
                      >
                        {getBannerText().label}
                      </span>
                    </div>
                    <div
                      className={`p-4 ${classes.flex} ${classes.contentBetween} ${classes.itemCenter}`}
                    >
                      <div className={`${classes.flex} ${classes.flexCol}`}>
                        <span
                          className={`${classes.textSmall} ${classes.fontSemibold} ${classes.textGray}`}
                        >
                          Requested By
                        </span>
                        <span className={classes.fontSemibold}>
                          {orderDetails?.requested_by_user?.name}
                        </span>
                      </div>
                      <div>
                        {!orderDetails?.gstin ? (
                          <OverlayTrigger
                            key={`password_protected`}
                            placement='top'
                            overlay={
                              <Tooltip id={`password_protected`}>
                                <p>
                                  No GSTIN found. If you have a GSTIN and want to include it in the
                                  purchase, please contact us
                                </p>
                              </Tooltip>
                            }
                          >
                            <div className={classes.tag}>
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                style={{ color: '#ffffff' }}
                              />
                              <span className='ml-1'>NO GSTIN</span>
                            </div>
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={`px-4 pt-2 pb-4 ${classes.flex} ${classes.contentBetween} ${classes.itemCenter}`}
                    >
                      <div className={`${classes.flex} ${classes.flexCol}`}>
                        <span
                          className={`${classes.textSmall} ${classes.fontSemibold} ${classes.textGray}`}
                        >
                          Requested On
                        </span>
                        <span className={classes.fontSemibold}>
                          {moment(orderDetails?.requested_on).format('DD-MMM-YYYY')}
                        </span>
                      </div>
                      <div className={`${classes.flex} ${classes.flexCol}`}>
                        <span
                          className={`${classes.textSmall} ${classes.fontSemibold} ${classes.textGray}`}
                        >
                          Due On
                        </span>
                        <span className={classes.fontSemibold}>
                          {moment(orderDetails?.last_due_date).format('DD-MMM-YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={`${classes.requestDetails} mt-4 p-4`}>
                    {orderDetails?.company?.tax > 0 ? (
                      <div className={`${classes.taxSubContainer} pb-2`}>
                        <div className={classes.summaryTitlesSmallSize}>
                          Tax({orderDetails?.company?.tax + '%'})
                        </div>
                        <div className={classes.tdsAmount}>
                          <span className={'rupee'}>&#8377;</span>
                          {convertToIndianNumeration(taxAmount, 2)}
                        </div>
                      </div>
                    ) : null}
                    {orderDetails?.company?.tds !== 'NONE' ? (
                      <div
                        className={`${classes.textLg} ${classes.fontSemibold} border-bottom pb-2`}
                      >
                        Apply TDS
                      </div>
                    ) : null}
                    {getTdsOptions(orderDetails?.company?.tds || 'NONE', false) ? (
                      <div className={`${classes.flex} ${classes.contentBetween}  mt-2`}>
                        <div className={`${classes.flex}`}>
                          {getTdsOptions(orderDetails?.company?.tds || 'NONE', false).map(
                            (option, i) => {
                              return (
                                <Form.Check
                                  name='companyTds'
                                  onChange={(e) => {
                                    setFieldValue('companyTds', e.target.value);
                                  }}
                                  className={classes.tdsRadio}
                                  value={option.value}
                                  key={i}
                                  type='radio'
                                  id={option.value}
                                  label={<p>{option.label}</p>}
                                  checked={String(option.value) === values.companyTds}
                                  disabled={option.disable}
                                />
                              );
                            }
                          )}
                        </div>
                        {values.companyTds !== 'NONE' ? (
                          <div className={classes.tdsAmount}>
                            -<span className={'rupee'}>&#8377;</span>
                            {convertToIndianNumeration(tdsAmount, 2)}
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <div className={`${classes.flex} ${classes.contentBetween}`}>
                      <span className={`${classes.textLg} ${classes.fontSemibold}`}>
                        Total Payable
                      </span>
                      <div className={`${classes.textLg} ${classes.fontSemibold}`}>
                        <span className={'rupee'}>&#8377;</span>
                        {convertToIndianNumeration(totalAmount, 2)}
                      </div>
                    </div>
                  </div>
                  <div className={`mt-2 text-center ${classes.textXs} ${classes.textGray}`}>
                    Tax invoice will be generated immediately upon payment
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes.bottomFix}`}>
            <div className={`${classes.payContainer}`}>
              <div className={`${classes.payRibbon}`}>
                {values?.companyTds === 'NONE' ? (
                  <RazorpayButton {...getRazorPayObject()} />
                ) : (
                  <Button className={classes.payButton} onClick={() => payNow()}>
                    <Image className='mr-1' name='lock_guard_white.svg' />
                    PAY NOW
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {showTdsModal ? (
        <ConfirmationTDSModal
          show={showTdsModal}
          onClose={() => {
            setShowTdsModal(false);
          }}
          heading={'Reset add-ons?'}
          subHeading={'Customizing package will reset any add-ons selected.'}
          sBtnText={'Reset add-ons'}
          enableBtn={true}
          paymentsObj={getRazorPayObject()}
        />
      ) : null}
    </>
  );
};

export default PaymentRequest;
