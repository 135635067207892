// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EukqnQJoID7oocRD3YdV {
  max-width: 250px;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

.XkVI85SKjQoojOqestKA {
  transform: scale(1.15);
}

.EukqnQJoID7oocRD3YdV p {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Candidates/VerificationIcons/VerificationIcons.modules.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".customCheckTooltip {\n  max-width: 250px;\n  overflow-wrap: break-word;\n  word-break: break-word;\n  word-wrap: break-word;\n}\n\n.iconScaling {\n  transform: scale(1.15);\n}\n\n.customCheckTooltip p {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customCheckTooltip": `EukqnQJoID7oocRD3YdV`,
	"iconScaling": `XkVI85SKjQoojOqestKA`
};
export default ___CSS_LOADER_EXPORT___;
