import { isEmpty } from 'lodash';
import { filterObjectByKeyCaseInsensitive } from '../../../utils/utilities';

export default (values, selectedPackage, addons, isAadhaarAllowed) => {
  const payload = {
    candidate: { ...values.step1 },
    package: {
      package_id: selectedPackage.packageId ? selectedPackage.packageId : '',
      package_name: selectedPackage.packageName ? selectedPackage.packageName : '',
      subtype_id: selectedPackage.subtypeId ? selectedPackage.subtypeId : '',
      subtype_name: selectedPackage.subtypeName ? selectedPackage.subtypeName : '',
      current_balance: selectedPackage.currentBalance ? selectedPackage.currentBalance : undefined,
      config: selectedPackage.config ? { ...selectedPackage.config } : {}
    },
    addons:
      addons && !isEmpty(addons) ? mapAddonsConfig(addons, values.step3, isAadhaarAllowed) : {}
  };

  if (
    selectedPackage.config &&
    selectedPackage.config.address &&
    selectedPackage.config.address.length
  ) {
    payload.package.config.address = selectedPackage.config?.address.map((ele) => ele.type);
  }

  if (
    selectedPackage.config &&
    selectedPackage.config.court &&
    selectedPackage.config.court.length
  ) {
    payload.package.config.court = selectedPackage.config?.court.map((ele) => ele.type);
  }

  if (values && values.step2 && values.step2.customise) {
    if (values.step2.customise.reference && values.step2.customise.reference.config.length) {
      payload.package.config.reference = values.step2.customise.reference.config;
    }

    if (values.step2.customise.identity && values.step2.customise.identity.config.length) {
      let arr = [];
      arr = values.step2.customise.identity.baseConfig.filter((val) => {
        if (!val.match(/ANY_/g)) return val;
      });
      arr = [...arr, ...values.step2.customise.identity.config];
      if (values.step2.customise.identity.any) {
        arr.unshift(`ANY_${values.step2.customise.identity.any}`);
      }
      payload.package.config.identity = arr;
    }

    if (values.step2.customise.address && values.step2.customise.address.value) {
      payload.package.config.address = [values.step2.customise.address.value];
    }

    if (values.step2.customise.court && values.step2.customise.court.value) {
      payload.package.config.court = [values.step2.customise.court.value];
    }
  }
  // deleting when no file is uploaded so that API can't throw error of validation
  if (!payload.candidate.resumeLink) {
    delete payload.candidate.resumeLink;
    delete payload.candidate.resume;
  }
  if (!payload.candidate.consentLink) {
    delete payload.candidate.consentLink;
    delete payload.candidate.consent;
  }

  return payload;
};

const getEduPrice = (values, config) => {
  let price = 0;
  if (values.education && values.education.method) {
    switch (values.education.method) {
      case 'OFFICIAL':
        price = config.education?.price || 0;
        break;
      case 'HYBRID':
        price = config.educationHybrid?.price || 0;
        break;
      case 'REGIONAL_PARTNER':
        price = config.educationThirdParty?.price || 0;
        break;

      default:
        break;
    }
  }
  return price;
};

const mapAddonsConfig = (originalAddons, userSelectedAddons, isAadhaarAllowed) => {
  const obj = {};
  const {
    identity,
    address,
    court,
    educationOfficial,
    educationHybrid,
    educationRegionalPartner,
    employment,
    reference,
    cibil,
    drug10Panel,
    drug5Panel,
    drug7Panel,
    world
  } = userSelectedAddons;
  if (originalAddons.identity && identity && (identity.any || identity.config.length)) {
    obj['identity'] = {
      price: originalAddons.identity.price,
      config: [...mapChecksForAadhaar(identity, isAadhaarAllowed)]
    };
  }
  if (address && (address.any || address.config.length)) {
    const filteredObj = filterObjectByKeyCaseInsensitive(
      originalAddons,
      address.config[0]?.verificationType?.replace(/_/g, '')
    );

    obj[address.config[0]?.verificationType?.toLowerCase()] = {
      price: Object.values(filteredObj)[0]?.price,
      config: address.config.map((ele) => ele.type)
    };
  }
  if (court && (court.any || court.config.length)) {
    const filteredObj = filterObjectByKeyCaseInsensitive(
      originalAddons,
      court.config[0]?.verificationType?.replace(/_/g, ''),
      true
    );
    obj[court.config[0]?.verificationType?.toLowerCase()] = {
      price: Object.values(filteredObj)[0]?.price,
      config: court.config.map((ele) => ele.type)
    };
  }
  if (originalAddons.employment && employment) {
    obj['employment'] = {
      price: originalAddons.employment.price,
      config: employment
    };
  }
  // official
  if (
    originalAddons.education &&
    educationOfficial &&
    (educationOfficial.count || educationOfficial.config.length)
  ) {
    obj['education'] = {
      price: originalAddons.education.price
    };

    if (educationOfficial.count) {
      obj['education']['config'] = educationOfficial.count;
    }

    if (educationOfficial.config.length) {
      obj['education']['config'] = [...educationOfficial.config];
    }
    obj['education']['type'] = 'OFFICIAL';
  }
  // 3rd Party
  if (
    originalAddons.educationThirdParty &&
    educationRegionalPartner &&
    (educationRegionalPartner.count || educationRegionalPartner.config.length)
  ) {
    obj['education'] = {
      price: originalAddons.educationThirdParty.price
    };

    if (educationRegionalPartner.count) {
      obj['education']['config'] = educationRegionalPartner.count;
    }

    if (educationRegionalPartner.config.length) {
      obj['education']['config'] = [...educationRegionalPartner.config];
    }
    obj['education']['type'] = 'REGIONAL_PARTNER';
  }
  //  Hybrid
  if (
    originalAddons.educationHybrid &&
    educationHybrid &&
    (educationHybrid.count || educationHybrid.config.length)
  ) {
    obj['education'] = {
      price: originalAddons.educationHybrid.price
    };

    if (educationHybrid.count) {
      obj['education']['config'] = educationHybrid.count;
    }

    if (educationHybrid.config.length) {
      obj['education']['config'] = [...educationHybrid.config];
    }
    obj['education']['type'] = 'HYBRID';
  }

  if (
    userSelectedAddons.education &&
    (userSelectedAddons.education.count || userSelectedAddons.education.config.length)
  ) {
    obj['education'] = {
      price: getEduPrice(userSelectedAddons, originalAddons)
    };

    if (userSelectedAddons.education.count) {
      obj['education']['config'] = userSelectedAddons.education.count;
    }

    if (userSelectedAddons.education.config.length) {
      obj['education']['config'] = [...userSelectedAddons.education.config];
    }
    obj['education']['type'] = userSelectedAddons.education.method || '';
    obj['education']['option'] = userSelectedAddons.education.type || '';
  }

  if (originalAddons.cibil && cibil) {
    obj['cibil'] = {
      price: originalAddons.cibil.price
    };
  }
  if (originalAddons.world && world) {
    obj['world'] = {
      price: originalAddons.world.price
    };
  }
  if (
    originalAddons.reference &&
    reference &&
    reference.config.length > 0 &&
    reference.config.reduce((a, b) => a + b) > 0
  ) {
    obj['reference'] = {
      price: originalAddons.reference.price
    };
    if (reference.count) {
      obj['reference']['config'] = reference.count;
    }
    if (reference.config.reduce((a, b) => a + b) > 0) {
      obj['reference']['config'] = [...reference.config];
    }
  }
  if (originalAddons.drug10Panel && drug10Panel) {
    obj['drug10Panel'] = {
      price: originalAddons.drug10Panel.price,
      config: originalAddons.drug10Panel.config
    };
  }
  if (originalAddons.drug7Panel && drug7Panel) {
    obj['drug7Panel'] = {
      price: originalAddons.drug7Panel.price,
      config: originalAddons.drug7Panel.config
    };
  }
  if (originalAddons.drug5Panel && drug5Panel) {
    obj['drug5Panel'] = {
      price: originalAddons.drug5Panel.price,
      config: originalAddons.drug5Panel.config
    };
  }
  return obj;
};

const mapChecks = (check) => {
  const any = check.any ? `ANY_${check.any}` : null;
  let arr = [];
  if (any) {
    arr = [any];
  }
  if (check.config.length) {
    arr = [...arr, ...check.config];
  }
  return arr;
};

const mapChecksForAadhaar = (check, isAadhaarAllowed) => {
  let configArray = [];
  if (check.any === 4 && !isAadhaarAllowed) {
    configArray = ['PAN', 'DL', 'VOTER_ID', 'PASSPORT'];
  } else if (check.any === 5 && isAadhaarAllowed) {
    configArray = ['PAN', 'DL', 'VOTER_ID', 'PASSPORT', 'AADHAAR'];
  } else if (check.any > 0) {
    configArray = [...[`ANY_${check.any}`, ...check.config]];
  } else {
    configArray = [...check.config];
  }
  return configArray;
};
