// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O_OFPCv0_awA18_wOQYy {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #333333;
}

._3GVPrpzRNQgXP8ClRse {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #333333;
}

.IsepEOGY1LuRqJb82U6i::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  opacity: 0.4 !important;
  color: #000000 !important;
}

.E1vhXkL7M1fg7t9Qbqz_ {
  width: 240px;
  height: 48px;
  background-color: #2755fe !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #ffffff;
}

.vV_QojjLHkfA_wtUCEVO {
  padding-top: 10px !important;
}

.VelVrAIZU7pSXQ0NxE1w {
  width: 100%;
  margin-bottom: 10px;
}

.siHvsmbp_8nCUQdvR1qH {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  opacity: 0.4 !important;
  color: #000000 !important;
}

.SvMdYm46rxiZ9dProrHQ {
  border-top: 1px solid rgba(51, 51, 51, 0.2) !important;
  padding-top: 15px !important;
  margin-top: 15px !important;
}

.tZdMXCCjsp3TFKOoFGI2 .modal-content {
  border-radius: 8px;
}
.tZdMXCCjsp3TFKOoFGI2 hr {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}
.tZdMXCCjsp3TFKOoFGI2 .efaDXXAfLkIenVsFbQkP {
  width: 798px !important;
  position: relative;
  left: -30px;
}

.ofMIuyGZkIwT256wsR4Q {
  width: 200px;
  height: 120px;
  background-color: #ecf2f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.a8pkHDWfg8TL_HBM9ZS0 {
  display: flex;
  gap: 5px;
}

.HG0pRILhHUV_kvR4COUa {
  position: relative;
  top: -1px;
}

.V1QnwMWco2iNfkytLMYZ {
  margin-left: 25px;
  margin-top: 25px;
}
.V1QnwMWco2iNfkytLMYZ h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #333333;
}
.V1QnwMWco2iNfkytLMYZ td {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-right: 30px !important;
  color: #333333;
}
.V1QnwMWco2iNfkytLMYZ .E5CMxaOiPpzZ9ZbzWHx3 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.Cry9jFpTGggu1T_v0YWh {
  display: flex;
  margin-bottom: 20px;
}

.ZmtqFvIqG3VuFy3yt0Su {
  width: 200px;
  margin-right: 20px;
}

.VScTmCywrt0lYM_aSmao {
  font-size: 0.9em;
}
.VScTmCywrt0lYM_aSmao td {
  padding: 2px 10px 2px 0;
}

.fb8xERDfQOnpMxY2Anob {
  width: 120px;
  margin-right: 20px;
}
.fb8xERDfQOnpMxY2Anob input {
  text-align: center;
}

.dVCVS7GMthxrt3bkKHRt {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.pxHhygV8E5AZDSXVlg6o {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.CGuum4D9DdOwB8Sq4Uah {
  border: 1px solid grey;
  border-radius: 5px;
}
.CGuum4D9DdOwB8Sq4Uah span {
  color: #2755fe !important;
}

.yEpt_8RLngHismaWmvM0 {
  color: #28a745;
}

.Vh4cggJGOIZhCi6m06Lz {
  padding: 10px 30px;
  width: 240px;
  height: 48px;
  background-color: #2755fe !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.VYV4oLPwWvAHcfth5pxX {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.IKVdf9GuQubSNH5z8m7j {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.WKfhE9nP9ZsRUjnI3ymh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.zDP7aESYZhCaFAHRYYFQ {
  display: flex;
  gap: 5px;
}

.Rnuot8Hlq1d3fkbSc4Zx {
  font-weight: bold;
  font-size: 1.2em;
  color: #36aa53;
  margin-bottom: 10px;
}

.fdwhCuSDU1JvXx2KP6Ak {
  text-decoration: line-through;
  color: #333333;
  padding-top: 2px;
}

.d9tmLIOCqYylr7l7LAoh {
  width: 612px !important;
}

.sBI8gMqKatRQCmFwy_xy {
  display: flex;
  justify-content: center;
}

.ilyTiGygqEeWDOx19_Fp {
  display: flex;
  justify-content: center;
}
.ilyTiGygqEeWDOx19_Fp hr {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  color: grey;
  border-style: dashed none none none;
  border-width: 3px;
  width: 80%;
}
.ilyTiGygqEeWDOx19_Fp .FkpN1s2d8Xpv2kS43WtV {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.ilyTiGygqEeWDOx19_Fp .FkpN1s2d8Xpv2kS43WtV .lsrheJFT4F5ZNKlXIVJC {
  width: 52px;
  height: 52px;
}
.ilyTiGygqEeWDOx19_Fp .FkpN1s2d8Xpv2kS43WtV .lOdBQrcj39_NOEu7F319 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.ilyTiGygqEeWDOx19_Fp .FkpN1s2d8Xpv2kS43WtV .t8Pq5wYvIEjx9CZ5hcQA {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.KzBlrDtUbDVJRXjjKqdZ {
  display: flex;
}
.KzBlrDtUbDVJRXjjKqdZ ._8f5lIjbA8FTvZv1IGd9 {
  width: 164px;
  height: 48px;
  background-color: #2755fe;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `O_OFPCv0_awA18_wOQYy`,
	"inputLabel": `_3GVPrpzRNQgXP8ClRse`,
	"inputArea": `IsepEOGY1LuRqJb82U6i`,
	"requestButton": `E1vhXkL7M1fg7t9Qbqz_`,
	"reqModalBody": `vV_QojjLHkfA_wtUCEVO`,
	"modalImage": `VelVrAIZU7pSXQ0NxE1w`,
	"modalFormControl": `siHvsmbp_8nCUQdvR1qH`,
	"modalFooter": `SvMdYm46rxiZ9dProrHQ`,
	"rentModal": `tZdMXCCjsp3TFKOoFGI2`,
	"fullWidthLine": `efaDXXAfLkIenVsFbQkP`,
	"laptopIcon": `ofMIuyGZkIwT256wsR4Q`,
	"infoLabel": `a8pkHDWfg8TL_HBM9ZS0`,
	"infoIcon": `HG0pRILhHUV_kvR4COUa`,
	"laptopDetails": `V1QnwMWco2iNfkytLMYZ`,
	"specificationText": `E5CMxaOiPpzZ9ZbzWHx3`,
	"laptopInfo": `Cry9jFpTGggu1T_v0YWh`,
	"laptopImage": `ZmtqFvIqG3VuFy3yt0Su`,
	"specsTable": `VScTmCywrt0lYM_aSmao`,
	"quantityInput": `fb8xERDfQOnpMxY2Anob`,
	"configInfo": `dVCVS7GMthxrt3bkKHRt`,
	"rentInfo": `pxHhygV8E5AZDSXVlg6o`,
	"counterBtns": `CGuum4D9DdOwB8Sq4Uah`,
	"rentAmount": `yEpt_8RLngHismaWmvM0`,
	"buyBtn": `Vh4cggJGOIZhCi6m06Lz`,
	"laptopSpecification": `VYV4oLPwWvAHcfth5pxX`,
	"laptopSpecificationValue": `IKVdf9GuQubSNH5z8m7j`,
	"priceSection": `WKfhE9nP9ZsRUjnI3ymh`,
	"priceContainer": `zDP7aESYZhCaFAHRYYFQ`,
	"price": `Rnuot8Hlq1d3fkbSc4Zx`,
	"mrp": `fdwhCuSDU1JvXx2KP6Ak`,
	"successModal": `d9tmLIOCqYylr7l7LAoh`,
	"body": `sBI8gMqKatRQCmFwy_xy`,
	"modalContainer": `ilyTiGygqEeWDOx19_Fp`,
	"modalBody": `FkpN1s2d8Xpv2kS43WtV`,
	"checkIcon": `lsrheJFT4F5ZNKlXIVJC`,
	"title": `lOdBQrcj39_NOEu7F319`,
	"infoText": `t8Pq5wYvIEjx9CZ5hcQA`,
	"footer": `KzBlrDtUbDVJRXjjKqdZ`,
	"closeBtn": `_8f5lIjbA8FTvZv1IGd9`
};
export default ___CSS_LOADER_EXPORT___;
