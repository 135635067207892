import { WALLET_BALLANCE_INDICATION } from '../../../utils/commonConstant';
import { getPackageUnitIndication } from '../../../utils/utilities';

export const HEADER_TAB_VALUES = {
  DASHBOARD: 0,
  CANDIDATES: 1,
  PACKAGES: 2,
  APPROVE_CHARGES: 3,
  ALUMNI: 4,
  BUY: 5
};

{
  /** Methods **/
}

export const getWalletRibbon = (walletDetail) => {
  if (
    walletDetail.currentBalance > walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
    walletDetail.currentBalance >= walletDetail.averageAdc
  ) {
    return WALLET_BALLANCE_INDICATION.SUFFICIENT;
  } else if (
    walletDetail.currentBalance < walletDetail.arrearsTotalAmount + walletDetail.pendingCosts ||
    walletDetail.currentBalance === 0
  ) {
    return WALLET_BALLANCE_INDICATION.NILL;
  } else if (
    walletDetail.currentBalance >= walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
    walletDetail.currentBalance < walletDetail.averageAdc
  ) {
    return WALLET_BALLANCE_INDICATION.LOW;
  }
  return WALLET_BALLANCE_INDICATION.SUFFICIENT;
};

export const getPackageRibbon = (packages = []) => {
  const packageTagSet = new Set();
  packages.forEach((element) => {
    const packageTag = getPackageUnitIndication(
      element.leastRechargeUnitsTier,
      element.currentBalance,
      0,
      element.packageType
    );
    if (packageTag !== 'SUFFICIENT') {
      packageTagSet.add(packageTag);
    }
  });
  return packageTagSet;
};

export const getBannerText = (walletDetail, packageDetails) => {
  const walletRibbon = getWalletRibbon(walletDetail);
  const packageRibbon = getPackageRibbon(packageDetails);
  if (walletRibbon == 'LOW' && packageRibbon.size == 0) {
    // ----------- Only Wallet balance is Low --------------
    return { text: 'Your wallet has a low balance.', color: 'Yellow' };
  } else if (walletRibbon == 'NILL' && packageRibbon.size == 0) {
    // -------------- Only Wallet balance is Critical --------------
    return { text: 'Your wallet has a critical balance.', color: 'Pink' };
  } else if (
    //-------------- Only Package balance is Low --------------
    walletRibbon == 'SUFFICIENT' &&
    packageRibbon.size > 0 &&
    packageRibbon.has('LOW') &&
    !packageRibbon.has('NILL')
  ) {
    //--------------  Only Package balance is low --------------
    return { text: 'Some of your packages have low units.', color: 'Yellow' };
  } else if (walletRibbon == 'SUFFICIENT' && packageRibbon.size > 0 && packageRibbon.has('NILL')) {
    //--------------  Only Package balance is critical--------------
    return { text: 'Some of your packages have critical units.', color: 'Pink' };
  } else if (
    walletRibbon == 'LOW' &&
    packageRibbon.size > 0 &&
    packageRibbon.has('LOW') &&
    !packageRibbon.has('NILL')
  ) {
    //-------------- Wallet (low) + Package (low) --------------
    return {
      text: 'Your wallet has a low balance and some packages have low units.',
      color: 'Yellow'
    };
  } else if (walletRibbon == 'LOW' && packageRibbon.size > 0 && packageRibbon.has('NILL')) {
    // -------------- Wallet (low) + Package (critical) --------------
    return {
      text: 'Your wallet has a low balance and some packages have critical units.',
      color: 'Pink'
    };
  } else if (
    walletRibbon == 'NILL' &&
    packageRibbon.size > 0 &&
    packageRibbon.has('LOW') &&
    !packageRibbon.has('NILL')
  ) {
    // -------------- Wallet (critical) + Package (low) --------------
    return {
      text: 'Your wallet has a critical balance and some packages have low units.',
      color: 'Pink'
    };
  } else if (walletRibbon == 'NILL' && packageRibbon.size > 0 && packageRibbon.has('NILL')) {
    // -------------- Wallet(critical) + Package(critical) --------------
    return {
      text: 'Your wallet has a critical balance and some packages have critical units.',
      color: 'Pink'
    };
  } else {
    return '';
  }
};
