// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qozg0lmru4M0PitFXtWA {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .qozg0lmru4M0PitFXtWA {
    width: 1200px;
  }
}
.qozg0lmru4M0PitFXtWA .TNRtvuJ5HJnLbD1cZBpM {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.qozg0lmru4M0PitFXtWA .TNRtvuJ5HJnLbD1cZBpM .PGZ3xtmyxgw0aTSjzwnd {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.qozg0lmru4M0PitFXtWA .TNRtvuJ5HJnLbD1cZBpM button .ZyWaUZ_KSZYyD_JWUz5k {
  background: #2755fe;
  width: 164px;
  height: 48px;
  padding: 14px 11px 14px 14px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 15px auto 48px;
  padding: 34px 24px 0 24px;
  font-family: Poppins, sans-serif;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .c28SVXNpYUcLNakXVQQx {
  position: absolute;
  cursor: pointer;
  right: 40px;
  text-decoration: underline;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH {
  display: flex;
  justify-content: space-between;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .CXcYi8TpugUfbkJOhv6x {
  padding-top: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl {
  display: flex;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .cSBhZLcy31aEf3wESqCT {
  position: absolute;
  z-index: 1;
  left: 600px;
  top: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .Dz8HOUmrhaZdTgFhTGSM {
  position: absolute;
  z-index: 1;
  left: 825px;
  top: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .BviQcAw86qVHqjNAkzTO {
  position: absolute;
  z-index: 1;
  left: 1013px;
  top: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .njYRCpn2T1hGvQ_LFrJq {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 174px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-left: 12px;
  height: 45px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .njYRCpn2T1hGvQ_LFrJq option {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .njYRCpn2T1hGvQ_LFrJq option:hover {
  background: #ffffff;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .UXm66a3qU2Cz2WCgXR3A {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 216px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-left: 12px;
  height: 45px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .UXm66a3qU2Cz2WCgXR3A option {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .UXm66a3qU2Cz2WCgXR3A option:hover {
  background: #ffffff;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .mqwYDwc95RxIOvZw2OlM {
  color: #333333;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .P76yV0je0_8C7VFqkZCL {
  border: 1px solid #c2cfff;
  width: 181px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-left: 28px;
  height: 45px;
  box-shadow: none !important;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .R6Crf56zdYahdRUlhpAa {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 181px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-left: 28px;
  height: 45px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .R6Crf56zdYahdRUlhpAa option {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .R6Crf56zdYahdRUlhpAa option:hover {
  background: #ffffff;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .i7hOgN0ftRvsFbH0bqTb {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 260px;
  display: flex;
  padding-left: 12px;
  padding-right: 15px;
  height: 44px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .i7hOgN0ftRvsFbH0bqTb .ybcxVNUtVBDDjUpnuwhn {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 14px !important;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .WLaNii4t5N4WrMyMMSyH .viEDBvEno95w1YNAAMcl .i7hOgN0ftRvsFbH0bqTb .ybcxVNUtVBDDjUpnuwhn:focus {
  box-shadow: unset;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV {
  margin-top: 24px;
  border: 0px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV {
  margin: auto;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV thead {
  background-color: #f2f8ff;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 21px 23px 16px 24px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV thead tr .uyPJKIka5fmE4uje7vDZ {
  width: 25%;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV thead tr .GSLTujwvKZr0ttPNQFES {
  text-align: center;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr {
  cursor: default;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr td {
  border: 0px;
  vertical-align: middle !important;
  padding: 18px 23px 19px 24px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: left;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .n82OxwWRw8mmpQOnfcEh {
  color: #999999 !important;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ {
  width: 25%;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .fP0SBdEtojaNSI2CyQJK {
  width: 100%;
  font-weight: 500;
  color: #366cf3;
  display: flex;
  justify-content: space-between;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .fP0SBdEtojaNSI2CyQJK .HBzxg9AT7NZaLPTFdHc4 {
  padding-left: 6px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .fP0SBdEtojaNSI2CyQJK .tpENEZt1epfGWR_nHrlS {
  display: inline-flex;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .fP0SBdEtojaNSI2CyQJK p {
  cursor: pointer;
  width: fit-content;
  margin-bottom: 0;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .QCLvgLTiKPYpOijLiIHX {
  width: 235px;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 21px;
  color: #999999;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .QCLvgLTiKPYpOijLiIHX .HiATpOfhfKHMJ78i8qM7 {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .QCLvgLTiKPYpOijLiIHX .gSJxj6PqiqUmfj5wm4D3 {
  margin-left: 3px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 2px;
  height: 28px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .uyPJKIka5fmE4uje7vDZ .QCLvgLTiKPYpOijLiIHX .gSJxj6PqiqUmfj5wm4D3:hover {
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .U2LKYMmf4ihxOj8wCh25 {
  width: 100px;
  text-align: center;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .U2LKYMmf4ihxOj8wCh25 .tKJAyA54KOnMzbZEX0XP {
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 3px;
  width: 22px;
  margin: auto;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .U2LKYMmf4ihxOj8wCh25 .tKJAyA54KOnMzbZEX0XP:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .aZQ0G2fe9qBkOo0fNAF5 {
  max-width: 164px;
  cursor: pointer !important;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .aZQ0G2fe9qBkOo0fNAF5 div {
  display: inline;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr .B2zKPHVYmrTJq_TNMkEM {
  font-size: 14px;
  font-weight: normal;
  display: block;
  background: #fafafa;
  color: #666666;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  text-align: center;
  max-width: fit-content;
  padding: 0px 8px;
  margin: 4px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV tbody tr:hover {
  background-color: #f5f5f5;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV .DeXFmB08lqalueEpxIy0 {
  text-align: center;
  padding-top: 64px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .AW8i4rNUWq8dPShITmAV .DeXFmB08lqalueEpxIy0 .NcnRd5SjnmFZJAeiazPU {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .SjdHYBD1ozJu38Y_R_a4 {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .SjdHYBD1ozJu38Y_R_a4 .wqeUFpx8UElu_2ORLfqz {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .SjdHYBD1ozJu38Y_R_a4 .wqeUFpx8UElu_2ORLfqz .OxNtCPNJkib6ZfKlubbH {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px !important;
}
.qozg0lmru4M0PitFXtWA .o0mtyMtyaB61s2YFbw8a .xvW0ZPUbQhSXejzkqORV .SjdHYBD1ozJu38Y_R_a4 .wqeUFpx8UElu_2ORLfqz .OxNtCPNJkib6ZfKlubbH:focus {
  outline-color: #2855ff !important;
}

.BiwvBNjLioPlhLGkiLhL {
  width: 1200px;
  margin: 0 auto;
}

.gDWQsUOoP2JmkxPt2al4 {
  color: #2755fe;
}

.gDWQsUOoP2JmkxPt2al4:hover {
  color: #1f44ca;
}

.y3LNWSyYpa_GQX47tO1A {
  position: relative;
}
.y3LNWSyYpa_GQX47tO1A .T1HN4gidH84FdAHnsEbX {
  position: absolute;
  top: -11px;
  z-index: 1;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 0 4px;
}
.y3LNWSyYpa_GQX47tO1A .ehVJuKKdgZU39XFE2QZY {
  left: 38px;
}
.y3LNWSyYpa_GQX47tO1A .zFG_t9vbcN6snfikZiZo {
  left: 20px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"candidatesWrapper": `qozg0lmru4M0PitFXtWA`,
	"header": `TNRtvuJ5HJnLbD1cZBpM`,
	"heading": `PGZ3xtmyxgw0aTSjzwnd`,
	"addCandidateButton": `ZyWaUZ_KSZYyD_JWUz5k`,
	"candidatesCard": `o0mtyMtyaB61s2YFbw8a`,
	"showAllCandidates": `c28SVXNpYUcLNakXVQQx`,
	"candidatesCardHeader": `WLaNii4t5N4WrMyMMSyH`,
	"headingContent": `CXcYi8TpugUfbkJOhv6x`,
	"filter": `viEDBvEno95w1YNAAMcl`,
	"allStatueses": `cSBhZLcy31aEf3wESqCT`,
	"category": `Dz8HOUmrhaZdTgFhTGSM`,
	"tag": `BviQcAw86qVHqjNAkzTO`,
	"dropdown": `njYRCpn2T1hGvQ_LFrJq`,
	"dropdownStatuses": `UXm66a3qU2Cz2WCgXR3A`,
	"dropDownPlaceholder": `mqwYDwc95RxIOvZw2OlM`,
	"widgetDropdownTime": `P76yV0je0_8C7VFqkZCL`,
	"dropdownTime": `R6Crf56zdYahdRUlhpAa`,
	"searchInput": `i7hOgN0ftRvsFbH0bqTb`,
	"inputText": `ybcxVNUtVBDDjUpnuwhn`,
	"tableCard": `xvW0ZPUbQhSXejzkqORV`,
	"candidatesTable": `AW8i4rNUWq8dPShITmAV`,
	"mainField": `uyPJKIka5fmE4uje7vDZ`,
	"alignCenter": `GSLTujwvKZr0ttPNQFES`,
	"deletedUser": `n82OxwWRw8mmpQOnfcEh`,
	"nameField": `fP0SBdEtojaNSI2CyQJK`,
	"mailIcon": `HBzxg9AT7NZaLPTFdHc4`,
	"mainNameField": `tpENEZt1epfGWR_nHrlS`,
	"email": `QCLvgLTiKPYpOijLiIHX`,
	"emailField": `HiATpOfhfKHMJ78i8qM7`,
	"editCategoryIcon": `gSJxj6PqiqUmfj5wm4D3`,
	"reportIcon": `U2LKYMmf4ihxOj8wCh25`,
	"icon": `tKJAyA54KOnMzbZEX0XP`,
	"displayInline": `aZQ0G2fe9qBkOo0fNAF5`,
	"tagText": `B2zKPHVYmrTJq_TNMkEM`,
	"noDataWrapper": `DeXFmB08lqalueEpxIy0`,
	"noDataContent": `NcnRd5SjnmFZJAeiazPU`,
	"paginationWrapper": `SjdHYBD1ozJu38Y_R_a4`,
	"showPagination": `wqeUFpx8UElu_2ORLfqz`,
	"selectPagination": `OxNtCPNJkib6ZfKlubbH`,
	"cContainer": `BiwvBNjLioPlhLGkiLhL`,
	"nameButton": `gDWQsUOoP2JmkxPt2al4`,
	"customDropdown": `y3LNWSyYpa_GQX47tO1A`,
	"customLabel": `T1HN4gidH84FdAHnsEbX`,
	"customLabelDate": `ehVJuKKdgZU39XFE2QZY`,
	"customLabelStatus": `zFG_t9vbcN6snfikZiZo`
};
export default ___CSS_LOADER_EXPORT___;
