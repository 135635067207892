import { connect } from 'react-redux';
import { closeCheck } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeCheckAPI: (data) => dispatch(closeCheck(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
