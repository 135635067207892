// React and React-Bootstrap
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import Modal from 'react-bootstrap/Modal';
// Third-Party Libraries
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { capitalizeName } from '../../../../../../CompanyAdmin/frontend-common/utils/Utilities';
import moment from 'moment';

// Core Components
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import ValidatedFormTextArea from '../../../../../../../core-components/ValidatedFormTextArea';
import Checkbox from '../../../../../../../core-components/Checkbox';

// Utilities and Constants
import countriesList from '../../../../../../../utils/countriesNames.json';
import { getTrimmedValue, getTrimmedValueWithNewLines } from '../../../../../../../utils/utilities';
import { COUNTRY_CODES, EMP_REASONS_FOR_LEAVING } from '../../../../../../../utils/commonConstant';

// Styles
import styles from './DetailsStep.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Node Modules
const stateAndCity = require('country-state-city');
const lookup = require('country-code-lookup');

const getCityValue = (data) => {
  if (!isEmpty(data) && data.endsWith('*')) {
    return data.slice(0, -1);
  }
  return data;
};

export default (props) => {
  // Props Destructuring
  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    setFieldValue,
    touched,
    setFieldTouched,
    validateForm,
    setErrors
  } = props;

  // State Hooks
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState(null);
  const [city, setCity] = useState(getCityValue(values?.city || ''));

  // Utility Functions
  const isEndDateSameOrGraterToday = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const endDate = moment(values.end_date).format('YYYY-MM-DD');
    return moment(endDate).diff(currentDate) >= 0;
  };

  const isCurrentlyWorkingInCompany = () => {
    return values?.currently_working ? isEndDateSameOrGraterToday() : true;
  };

  const isValidForm =
    isEmpty(errors) &&
    values.start_date &&
    values.end_date &&
    values.start_date < values.end_date &&
    isCurrentlyWorkingInCompany();

  const isStartEndDateSame = () => {
    return values?.end_date && moment(values?.start_date).isSame(values.end_date);
  };

  const setValue = (fieldname, value, withSpace) => {
    setFieldValue(fieldname, getTrimmedValue(value, withSpace));
  };

  const getNames = (arr) => {
    return arr.map((obj) => obj.name);
  };

  const updateMinEndDate = (startDate) => {
    if (!startDate) {
      return;
    }
    const minEndDate = new Date(startDate);
    minEndDate.setDate(minEndDate.getDate() + 1);
  };

  // Handlers
  const handleCityChange = (selectedOption) => {
    setFieldValue('city', selectedOption?.value || '');
    setSelectedCity(selectedOption);
  };

  // Effects
  useEffect(() => {
    const country = props.values.country;

    if (country) {
      const countryDetails = lookup.byCountry(country);
      let countryCode;

      if (countryDetails) {
        countryCode = countryDetails.iso2;
      } else {
        countryCode = COUNTRY_CODES[country];
      }

      const val = getNames(stateAndCity.City.getCitiesOfCountry(countryCode));
      const cityOptions = val.map((city) => ({
        label: city,
        value: city
      }));

      setCities(cityOptions);
    }

    if (selectedCity === null && !isEmpty(props.values.city)) {
      setSelectedCity({ label: props.values.city, value: props.values.city });
    }

    if (values.start_date) updateMinEndDate(values.start_date);
  }, [props.values]);

  return (
    <Modal
      size='lg'
      show={props.show}
      onHide={props.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      backdropClassName={'customBGVModalBackdrop'}
      dialogClassName='bgvModalDialog'
      className='bgvModal'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className={styles.employmentModalTitle}>
            {(!props.isEdit ? 'Add' : 'Edit') + ' Employment'}
          </div>
          <div className={styles.employmentModalSubTitle}>{'Step 1 of 3'}</div>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit} className={styles.detailsStepForm}>
        <Modal.Body className={styles.modalBody}>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <Form.Label>
                Company Legal Name (as per employment document) <span>*</span>
              </Form.Label>
              <Field type='text' name='company_name' component={ValidatedFormInputField} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={6} lg={6}>
              <Form.Label>Company Brand Name</Form.Label>
              <Field
                type='text'
                name='company_brand_name'
                component={ValidatedFormInputField}
                onBlur={(e) => {
                  props?.handleBlur(e);
                  setValue('company_brand_name', values?.company_brand_name, true);
                }}
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={6}>
              <Form.Label>
                Your Employee ID {!values.is_employee_id_not_available && <span>*</span>}
              </Form.Label>
              <Field
                type='text'
                name='employee_id'
                className={styles.noBottomMargin}
                disabled={values.is_employee_id_not_available}
                component={ValidatedFormInputField}
                onBlur={(e) => {
                  props?.handleBlur(e);
                  setValue('employee_id', values?.employee_id, true);
                }}
              />
              <div className={styles.empIdCheckBoxContainer}>
                <Form.Check
                  type='checkbox'
                  name='is_employee_id_not_available'
                  checked={values?.is_employee_id_not_available}
                  onChange={(e) => {
                    setFieldValue('is_employee_id_not_available', e.target.checked);
                    if (e.target.checked) {
                      setTimeout(() => {
                        setFieldValue('employee_id', '');
                        let newErrors = { ...errors };
                        delete newErrors.employee_id;
                        setErrors(newErrors);
                      }, 100);
                    }
                  }}
                />
                <div className={styles.empIdText}>Employee ID not available</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={6} lg={6} className={styles.countries}>
              <Form.Label>
                Country<span>*</span>
              </Form.Label>
              <Select
                menuPosition={'fixed'}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={countriesList?.countries || []}
                value={countriesList?.countries?.filter((c) => c === values?.country)}
                getOptionLabel={(l) => l}
                getOptionValue={(v) => v}
                onChange={(value) => {
                  setFieldValue('country', value);
                  setFieldValue('city', '');
                  setSelectedCity(null);
                  setCity('');
                }}
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={6} className={styles.mb1rem}>
              <Form.Label>
                City<span>*</span>
              </Form.Label>
              {isEmpty(cities) ? (
                <Field
                  type='text'
                  name='city'
                  placeholder='Please type city name'
                  value={city}
                  onChange={(e) => {
                    setFieldValue('city', e.target.value);
                    setCity(e.target.value);
                  }}
                  onBlur={() => {
                    if (!isEmpty(values.city) && !values.city.endsWith('*'))
                      setFieldValue('city', getTrimmedValue(values.city, true) + `*`);

                    setCity(getTrimmedValue(values.city, true));
                  }}
                  component={ValidatedFormInputField}
                />
              ) : (
                <Select
                  menuPosition={'fixed'}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={cities}
                  value={selectedCity}
                  onChange={handleCityChange}
                  isClearable
                  isSearchable
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <Form.Label>
                Designation <span>*</span>
              </Form.Label>
              <Field
                type='text'
                name='designation'
                component={ValidatedFormInputField}
                onBlur={(e) => {
                  props?.handleBlur(e);
                  setValue('designation', capitalizeName(values?.designation), true);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              sm={6}
              xs={12}
              md={6}
              lg={6}
              className={
                (values.start_date &&
                  values.end_date &&
                  values.start_date > values.end_date &&
                  !values.currently_working) ||
                (!values.start_date && !values.valid_start_date)
                  ? 'empDateError'
                  : 'empDateContainer'
              }
            >
              <Form.Label>
                Start Date <span>*</span>
              </Form.Label>
              <div>
                <DatePicker
                  placeholderText={'Start date'}
                  selected={values.start_date ? moment(values.start_date).toDate() : null}
                  dateFormat='dd MMM yyyy'
                  isClearable={values.start_date}
                  className={styles.datePicker}
                  popperPlacement='auto'
                  onChange={(date) => {
                    setFieldValue('start_date', date ? moment(date).format('YYYY-MM-DD') : null);
                    updateMinEndDate(date || null);
                    if (!date) {
                      setFieldValue('valid_start_date', true);
                    }
                  }}
                  onBlur={(e) => {
                    setFieldTouched('start_date', true);
                  }}
                  onChangeRaw={(e) => e.preventDefault()}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  popperContainer={(props) => (
                    <div {...props}>
                      {props.children}
                      {!values.start_date && !values.valid_start_date && (
                        <div
                          style={{
                            color: '#dc3545',
                            fontSize: '13px'
                          }}
                        >
                          Invalid date. Please select a valid date.
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>

              {!isMobileOnly &&
              values.start_date &&
              values.end_date &&
              values.start_date > values.end_date &&
              !values.currently_working ? (
                <div className={styles.errorMsg}>
                  {'Start date cannot be after Last working date'}
                </div>
              ) : null}
              {!!errors.start_date && touched.start_date && (
                <div className={styles.errorMsg}>{errors.start_date}</div>
              )}
            </Col>

            <Col
              sm={6}
              xs={12}
              md={6}
              lg={6}
              className={
                (values.start_date && values.end_date && values.start_date > values.end_date) ||
                (!values.end_date && !values.valid_end_date)
                  ? 'empDateContainer empDateError'
                  : 'empDateContainer'
              }
            >
              <Form.Label>
                {values.currently_working ? 'Tentative Last Working Date' : 'Last Working Date'}{' '}
                <span>*</span>
              </Form.Label>
              <DatePicker
                placeholderText={
                  values.currently_working ? 'Tentative Last working date' : 'Last working date'
                }
                selected={values.end_date ? moment(values.end_date).toDate() : null}
                dateFormat='dd MMM yyyy'
                isClearable={values.end_date}
                className={styles.datePicker}
                popperPlacement='auto'
                onChange={(date) => {
                  setFieldValue('end_date', date ? moment(date).format('YYYY-MM-DD') : null);
                  updateMinEndDate(date || null);
                  if (!date) {
                    setFieldValue('valid_end_date', true);
                  }
                }}
                onChangeRaw={(e) => e.preventDefault()}
                onBlur={() => {
                  setFieldTouched('end_date', true);
                }}
                // maxDate={values?.currently_working ? null : new Date()}
                minDate={
                  values?.currently_working
                    ? new Date()
                    : values.start_date
                    ? moment(values.start_date).add(1, 'days').toDate()
                    : null
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                popperContainer={(props) => (
                  <div {...props}>
                    {props.children}
                    {!values.end_date && !values.valid_end_date && (
                      <div
                        style={{
                          color: '#dc3545',
                          fontSize: '13px'
                        }}
                      >
                        Invalid date. Please select a valid date.
                      </div>
                    )}
                  </div>
                )}
              />
              {!!errors.end_date && touched.end_date && (
                <div className={styles.errorMsg}>{errors.end_date}</div>
              )}
              {(values.start_date && values.end_date && values.start_date > values.end_date) ||
              isStartEndDateSame() ? (
                <div className={styles.mobErrorMsg}>
                  {!isStartEndDateSame()
                    ? `Provide a valid ${
                        values?.currently_working ? 'Tentative' : ''
                      } Last working date`
                    : `Start and ${
                        values?.currently_working ? 'Tentative' : ''
                      } Last working date cannot be same`}
                </div>
              ) : null}
              {values?.currently_working && values?.end_date && !isEndDateSameOrGraterToday() ? (
                <div className={styles.mobErrorMsg}>
                  Tentative Last working date must be same or greater than current date
                </div>
              ) : null}
            </Col>
          </Row>
          <Row className={styles.checkBoxLabel}>
            <Col sm={12} xs={12} md={6} lg={6}>
              <Field
                custom
                className='currently_working'
                name='currently_working'
                label='I currently work here.'
                value={values?.currently_working}
                component={Checkbox}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setFieldValue('currently_working', isChecked ? true : false);
                  // setFieldValue('end_date', isChecked ? null : endDateCache);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
              />
            </Col>
          </Row>
          <Row className={styles.textArea}>
            <Col sm={12} xs={12} md={6} lg={6}>
              <Form.Label>Reason for leaving</Form.Label>
              <Select
                menuPosition={'fixed'}
                menuPlacement={'top'}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={EMP_REASONS_FOR_LEAVING || []}
                value={EMP_REASONS_FOR_LEAVING?.filter(
                  (c) => c.value === values?.reason_for_leaving
                )}
                onChange={(data) => {
                  setFieldValue('reason_for_leaving', data?.value);
                }}
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={6}>
              {values?.reason_for_leaving === 'OTHER' && (
                <>
                  <Form.Label>Add reason for leaving</Form.Label>
                  <Field
                    name='reason'
                    containerClass={styles.OtherReasonFieldContainer}
                    charCountClass={styles.charCount}
                    errCountClass={styles.errPosition}
                    showCharCount={false}
                    rows='2'
                    charsCount={values?.reason?.length}
                    className={styles.otherReasonText}
                    component={ValidatedFormTextArea}
                    maxChars={500}
                    charsCountError={touched.reason && errors?.reason}
                    onBlur={(e) => {
                      props?.handleBlur(e);
                      setFieldValue(
                        'reason',
                        capitalizeName(getTrimmedValueWithNewLines(values?.reason, true))
                      );
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className={styles.employmentSubmitBtn}>
          <Button
            className={'bgvModalSecondaryBtn'}
            onClick={(e) => {
              props.onHide();
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            className={'bgvModalPrimaryBtn'}
            disabled={isSubmitting || !isValidForm}
          >
            Next
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
