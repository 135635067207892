// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.umBQ4GS6ALYijBTO0nVD {
  font-weight: 700 !important;
}

.FbxQGQtEFnWR9XtFTptN {
  border: none;
  border-radius: none;
  width: 175px !important;
  background-color: white;
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
}
.FbxQGQtEFnWR9XtFTptN:focus {
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.FbxQGQtEFnWR9XtFTptN:hover {
  cursor: pointer;
}
.FbxQGQtEFnWR9XtFTptN::placeholder {
  color: red;
}
.FbxQGQtEFnWR9XtFTptN input:disabled {
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(170, 170, 170));
}
.FbxQGQtEFnWR9XtFTptN input:disabled:hover {
  cursor: not-allowed;
}

.DSWTkd5PWY5_NRRq6O38 {
  display: flex;
  border: 1px solid #ebedf2;
}
.DSWTkd5PWY5_NRRq6O38.eP3QWz94hXoPBwUaaxHg {
  border-color: #dc3545;
}
.DSWTkd5PWY5_NRRq6O38 img {
  margin-right: 12px;
}

.gkwU7vk6xvMD_kfR68yG {
  width: 368px;
}

.IUuv_R_PDi9a76OJNb1w {
  background: #d3455b;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
  border: none;
  cursor: pointer;
  height: 45px;
}
.IUuv_R_PDi9a76OJNb1w:hover {
  background: #e97070;
}
.IUuv_R_PDi9a76OJNb1w:disabled {
  opacity: 0.4;
  cursor: auto;
}
.IUuv_R_PDi9a76OJNb1w:disabled:hover {
  background: #d3455b;
}

.a8lIsM2lCYYrFmbWjvkz {
  border: 1px solid #2755fe;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2755fe;
  width: 136px;
  height: 45px;
}

.oZpIS5IxNInwvovV7FC4 {
  min-height: 80px;
  line-height: 24px;
  margin-bottom: 0 !important;
}

.evqkyxik9hDa6G3V7l0_ {
  position: relative;
}

.GpkFf45ggVVYSsBSOUJ0 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #999;
  width: 100%;
  text-align: right;
  border: 1px solid #ebedf2;
  padding: 2px 5px;
  border-top: none;
  border-color: white !important;
}
.GpkFf45ggVVYSsBSOUJ0.mC4_M4UrarLSKjrAvxpQ {
  bottom: 24px;
  color: #dc3545;
}

.hbXt2HLKSsC83Rqla7xV {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #dc3545;
}

.jcrT7WQ0tixWVLfvmauK {
  display: flex;
  align-items: flex-end;
}
.jcrT7WQ0tixWVLfvmauK .E7mDctGQRrnTLMJnD11W {
  margin-left: 10px;
  color: #999;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `umBQ4GS6ALYijBTO0nVD`,
	"datePicker": `FbxQGQtEFnWR9XtFTptN`,
	"datePickerContainer": `DSWTkd5PWY5_NRRq6O38`,
	"err": `eP3QWz94hXoPBwUaaxHg`,
	"selectReason": `gkwU7vk6xvMD_kfR68yG`,
	"redButton": `IUuv_R_PDi9a76OJNb1w`,
	"cancelButton": `a8lIsM2lCYYrFmbWjvkz`,
	"otherReasonText": `oZpIS5IxNInwvovV7FC4`,
	"OtherReasonFieldContainer": `evqkyxik9hDa6G3V7l0_`,
	"charCount": `GpkFf45ggVVYSsBSOUJ0`,
	"errPosition": `mC4_M4UrarLSKjrAvxpQ`,
	"errDiv": `hbXt2HLKSsC83Rqla7xV`,
	"dateFieldContainer": `jcrT7WQ0tixWVLfvmauK`,
	"daysCount": `E7mDctGQRrnTLMJnD11W`
};
export default ___CSS_LOADER_EXPORT___;
