import { connect } from 'react-redux';

import { getDavLogs, invalidateDavLogs } from './../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    davLogs: state.davLogs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateDavLogs: () => dispatch(invalidateDavLogs()),
    getDavLogs: (id, limit, offset) => dispatch(getDavLogs(id, limit, offset))
  };
};

export { mapDispatchToProps, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps);
