import React, { useState } from 'react';
import Image from '../../../core-components/Image';
import AppConstants from '../../../core-components/AppConstants';
import styles from './login.module.scss';

const CommonLayout = ({ children }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <div className={styles['loginPage-xxx']}>
      <div className={styles.loginHeader}>
        <div className={styles.loginHeaderLogo}>
          <Image
            name='logo-hrz.svg'
            onClick={() => window.open(`${AppConstants.baseURL}signIn`, '_self')}
            style={{ cursor: 'pointer' }}
          />
          <div className={styles.loginHeaderOptions}>
            <div
              className={`${styles['option-item']} ${styles.mobhide}`}
              onClick={() => window.open('https://whatsapp.springverify.com/', '_blank')}
            >
              <Image name='whatsapp.svg' />
              Verify on WhatsApp
            </div>
            {/* <div
              className={`${styles['option-item']} ${styles.mobhide}`}
              onClick={() => window.open(`${AppConstants.sv_us_URL}`, '_blank')}
            >
              <Image name='usa.svg' />
              For USA
            </div> */}
            <div
              className={styles['option-item']}
              onClick={() => window.open(`https://in.springverify.com/contact-us/`, '_blank')}
            >
              <button className={styles.getStartedBtn}>Sign Up</button>
            </div>
            <div
              className={`${styles['option-item']} ${styles.mobshow}`}
              onClick={toggleDropdown}
              style={{ display: 'none', fontSize: '1.5rem' }}
            >
              ☰
            </div>
            {showDropdown && (
              <div className={styles.dropdown}>
                <div
                  className={styles['option-item']}
                  onClick={() => window.open('https://whatsapp.springverify.com/', '_blank')}
                >
                  <Image name='whatsapp.svg' />
                  Verify on WhatsApp
                </div>
                {/* <div
                  className={styles['option-item']}
                  onClick={() => window.open(`${AppConstants.sv_us_URL}`, '_blank')}
                >
                  <Image name='usa.svg' />
                  For USA
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.loginContainer}>
        <div className={styles.loginModal}>
          <div className={styles['container-xxx']} style={{}}>
            {children}
          </div>
        </div>
        <div className={styles.footerContainer}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1728 312'
            fill='none'
            className={styles.svgOverlay}
          >
            <path
              d='M270 161.5C158 183.9 55.5 239 0 311.5V0H1728V57C1655 23.5 1585 0.211824 1456 21.5C1277 51.0394 1228.5 197 919 239C626.352 278.713 528.5 109.8 270 161.5Z'
              fill='white'
            />
          </svg>
        </div>
        <div className={styles.footerContent}>
          <div className={styles.left}>
            <div className={styles.valuttext}>
              <a href='mailto:cs@springverify.com'>cs@springverify.com</a>
            </div>
            HD-072, Prestige Atlanta-WeWork Prestige Atlanta 80 Ft, Mn Rd,
            <br />
            Koramangala Industrial Layout, Koramangala 1A Block
            <br />
            Bengaluru, Karnataka 560034
          </div>
          <div className={styles.right}>
            <div className={styles.bgvFooterItemCopyright}>
              <div className={styles.valuttext}>
                <a href={'https://in.springverify.com/privacy-policy/'} target={'_blank'}>
                  Privacy Policy
                </a>
              </div>
              <div className={styles.valuttext}>
                <a href='https://compliance.springworks.in' target='_blank'>
                  View our Trust Vault
                </a>
              </div>
              <div className={styles.logosx}>
                <div className={styles.bgvFooterImage}>
                  <Image name='iso27701.svg' />
                </div>
                <div className={styles.bgvFooterImage}>
                  <Image name='iso27001.svg' />
                </div>
                <div className={styles.bgvFooterImage}>
                  <Image name='soc2.svg' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
