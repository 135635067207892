// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.YYMvM3MolXi0liWLDlPW {
  color: #000000;
  font-size: 22px;
}

.ql8x9GF4i0U36JjEN7U9 {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: -10px;
}

.WSX40nyJfDimHg18KmTe {
  padding: 0;
  padding-bottom: 65px !important;
}
@media screen and (max-width: 601px) {
  .WSX40nyJfDimHg18KmTe {
    padding-bottom: 65px !important;
  }
}

label {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #262626 !important;
  margin-bottom: 6px !important;
}
label span {
  color: #f5222d;
}

input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
input::placeholder {
  color: #cccccc !important;
}

.Qq1OdzeJYnArEleo3i0c {
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .Qq1OdzeJYnArEleo3i0c {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .Qq1OdzeJYnArEleo3i0c button {
    height: 40px;
    margin: 0 !important;
    width: 100%;
  }
  .Qq1OdzeJYnArEleo3i0c .VLMe1UcGqypC6Xql3Mv4,
  .Qq1OdzeJYnArEleo3i0c .u3H0ZTx7IPyoWOE1I5TQ {
    padding: 5px 35px;
  }
}

.EPWXoIjstb1BCD9MdDNY {
  margin-bottom: 32px;
}

.BfCHtVhCLrTZPsxN8kGL {
  width: 100%;
}

.BfCHtVhCLrTZPsxN8kGL input {
  box-sizing: border-box;
  width: 100%;
}

.vG3uBBjdfrkNRjxg8Rrh {
  margin-bottom: 32px;
}
@media screen and (max-width: 601px) {
  .vG3uBBjdfrkNRjxg8Rrh {
    margin-bottom: 3px;
  }
}
.vG3uBBjdfrkNRjxg8Rrh .xbH7XEqAzHRkJrUuuteH {
  border: 1px solid #ebedf2;
  border-radius: 3px;
  height: 35px;
  font-weight: 100;
  padding-left: 5px;
}
.vG3uBBjdfrkNRjxg8Rrh .GT9Vhr5yFkYa_Gu2HSmy {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.zhMK2tPNrwLmN2GH9UWi {
  font-size: 14px;
  color: #dc3545;
  margin-top: 0.25rem;
}

.QmDmeQCKo7AuIGd7qe93 {
  margin-top: -10px;
}

@media screen and (max-width: 601px) {
  .QmDmeQCKo7AuIGd7qe93 {
    margin-top: 20px;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"educationModalTitle": `YYMvM3MolXi0liWLDlPW`,
	"educationModalSubTitle": `ql8x9GF4i0U36JjEN7U9`,
	"addEditEducationModalBody": `WSX40nyJfDimHg18KmTe`,
	"educationSubmitBtn": `Qq1OdzeJYnArEleo3i0c`,
	"activeBtn": `VLMe1UcGqypC6Xql3Mv4`,
	"disableBtn": `u3H0ZTx7IPyoWOE1I5TQ`,
	"formGroup": `EPWXoIjstb1BCD9MdDNY`,
	"datePickerWrapper": `BfCHtVhCLrTZPsxN8kGL`,
	"formGroupDate": `vG3uBBjdfrkNRjxg8Rrh`,
	"datePickerStyle": `xbH7XEqAzHRkJrUuuteH`,
	"datePickerHeaderStyle": `GT9Vhr5yFkYa_Gu2HSmy`,
	"errorMsg": `zhMK2tPNrwLmN2GH9UWi`,
	"checkBoxLabel": `QmDmeQCKo7AuIGd7qe93`
};
export default ___CSS_LOADER_EXPORT___;
