import React, { useState, useEffect, useReducer } from 'react';
import './style.css';
import Table from './Table';
import validateEmployeeFields from './validators.js';
import { ADMIN_URL } from '../../../core-components/Utilities/CommonConstants';
import {
  validateExEmpData,
  uploadExEmpCSV,
  validateDataForBulkUpload
} from '../../../../../../api/company';
import AppConstants from '../../../../../../core-components/AppConstants';
import { Button, Spinner } from 'react-bootstrap';
import { MdError } from 'react-icons/md';
import Loader from '../../Loader.jsx';
import ConfirmationModal from '../../../core-components/ConfirmationModal';
import { capitalizeName, errToastMessage } from '../../../utils/Utilities.js';
import { DropdownList } from 'react-widgets';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toLower } from 'lodash';
const companyId =
  new URLSearchParams(document.location.search).get('id') ||
  new URLSearchParams(document.location.search).get('company_id');
const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));

function reducer(state, action) {
  switch (action.type) {
    case 'toggle_show_error':
      return {
        ...state,
        showOnlyError: !state.showOnlyError
      };

    case 'add_row':
      return {
        ...state,
        skipReset: true,
        data: [...state.data, {}],
        errorArray: [
          ...state.errorArray,
          state.flow === 'alumni'
            ? validateEmployeeFields.validateEmployeeFields({})
            : validateEmployeeFields.validateCandidateFields({})
        ]
      };

    case 'update_cell': {
      let { rowIndex, columnId, value } = action;
      if (state.showOnlyError) {
        let ii = 0;
        while (rowIndex >= 0) {
          if (Object.keys(state.errorArray[ii++])?.length > 0) rowIndex--;
        }
        rowIndex = ii - 1;
      }
      const oldValue = state.data[rowIndex][columnId];
      if (oldValue === value) return state;
      if (columnId.includes('email')) {
        value = toLower(value);
      }
      if (columnId.includes('name')) {
        value = capitalizeName(value);
      }
      let newData = [...state.data];
      newData[rowIndex] = {
        ...newData[rowIndex],
        [columnId]: value || ''
      };
      let newErrorArray =
        state.flow === 'alumni'
          ? validateEmployeeFields.validateEmployeeFields(newData[rowIndex])
          : validateEmployeeFields.validateCandidateFields(newData[rowIndex]);

      if (state.flow !== 'alumni') {
        let oldErrorArray = state.errorArray[rowIndex];
        if (newErrorArray?.[columnId]) {
          if (oldErrorArray == true) oldErrorArray = {};

          oldErrorArray[columnId] = newErrorArray[columnId];
        } else {
          delete oldErrorArray[columnId];
        }
        if (
          oldErrorArray?.staff_id?.includes('already exists') ||
          oldErrorArray?.phone_number?.includes('already exists') ||
          oldErrorArray?.personal_email?.includes('already exists')
        ) {
          newErrorArray = oldErrorArray;
        }
      } else if (columnId === 'date_of_birth' && value !== '') {
        if (newErrorArray !== true) {
          delete newErrorArray.staff_id;
        }
      } else if (state.errorArray[rowIndex]?.staff_id && columnId !== 'staff_id') {
        if (newErrorArray === true) newErrorArray = {};
        newErrorArray.staff_id = state.errorArray[rowIndex].staff_id;
      }
      let newErrorArrayIndex = [...state.errorArray];
      newErrorArrayIndex[rowIndex] = newErrorArray;
      return {
        ...state,
        skipReset: true,
        data: newData,
        errorArray: newErrorArrayIndex
      };
    }

    case 'update_error_array':
      let newErrorArray = state.errorArray;
      for (let i = 0; i < action.errorArray.length; i++) {
        if (state.flow !== 'alumni') {
          if (typeof action.errorArray?.[i] === 'object') {
            if (typeof newErrorArray[i] !== 'object') newErrorArray[i] = {};
            if (action.errorArray?.[i]?.phone)
              newErrorArray[i].phone_number = action.errorArray?.[i].phone;
            if (action.errorArray?.[i]?.emp_id)
              newErrorArray[i].staff_id = action.errorArray?.[i].emp_id;
            if (action.errorArray?.[i]?.email)
              newErrorArray[i].personal_email = action.errorArray?.[i].email;
          }
        } else if (action.errorArray?.[i]?.staff_id) {
          if (typeof newErrorArray[i] !== 'object') newErrorArray[i] = {};
          newErrorArray[i]['staff_id'] = action.errorArray[i]['staff_id'];
        }
      }
      return {
        ...state,
        skipReset: true,
        errorArray: newErrorArray
      };

    case 'delete_rows': {
      const newDatax = [];
      const newErrorArrayx = [];
      let selectedRows = action.selectedRows;
      //better algorithm needs to be implemented
      if (state.showOnlyError) {
        let adjustedIndices = [];
        for (let i = 0; i < selectedRows.length; i++) {
          let currentIndex = selectedRows[i];
          let ii = 0;
          while (currentIndex >= 0) {
            if (Object.keys(state.errorArray[ii++])?.length > 0) currentIndex--;
          }
          currentIndex = ii - 1;
          adjustedIndices.push(currentIndex);
        }
        selectedRows = adjustedIndices;
      }
      for (let i = 0; i < state?.data?.length; i++) {
        if (!selectedRows.includes(i)) {
          newDatax.push(state.data[i]);
          newErrorArrayx.push(state.errorArray[i]);
        }
      }
      if (newDatax.length === 0) {
        return {
          ...state,
          skipReset: true,
          data: [{}],
          errorArray: [
            {
              full_name: 'Add New Record'
            }
          ]
        };
      }
      return {
        ...state,
        skipReset: true,
        data: newDatax,
        errorArray: newErrorArrayx
      };
    }
    case 'delete_dublicate_row': {
      const newData = [];
      const newErrorArray = [];
      for (let i = 0; i < state?.errorArray?.length; i++) {
        const error = state?.errorArray[i];
        if (state.flow === 'alumni') {
          if (!error?.staff_id) {
            newData.push(state.data[i]);
            newErrorArray.push(error);
          }
        } else if (
          !(
            error?.staff_id?.includes('already exists') ||
            error?.personal_email?.includes('already exists') ||
            error?.phone_number?.includes('already exists')
          )
        ) {
          newData.push(state.data[i]);
          newErrorArray.push(error);
        }
      }
      if (newData.length === 0) {
        return {
          ...state,
          skipReset: true,
          data: [{}],
          errorArray: [
            {
              full_name: 'Add New Record'
            }
          ]
        };
      }
      return {
        ...state,
        skipReset: true,
        data: newData,
        errorArray: newErrorArray
      };
    }
    case 'enable_reset':
      return {
        ...state,
        skipReset: false
      };
    case 'set_category':
      return {
        ...state,
        category: action.payload
      };
    default:
      return state;
  }
}

function TableView({
  data,
  setCurrentStep,
  setFinalData,
  flow,
  config,
  history,
  companyIdCA,
  categoryId
}) {
  const [state, dispatch] = useReducer(reducer, data);
  const [errorCount, setErrorCount] = useState(0);
  const [showDublicateModal, setShowDublicateModal] = useState(false);
  const [dublicateCount, setDublicateCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState({});
  const [showOnlyError, setShowOnlyError] = useState(false);
  const [addRecordTrigger, setAddRecordTrigger] = useState(0);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({ id: -1, category_name: 'None' });
  const [checked, setChecked] = useState(false);

  const [msg, setMsg] = useState({
    load: false,
    msg: ''
  });

  useEffect(() => {
    if (categoryId) {
      let catId = config?.categories.find((val) => val.id === categoryId);
      setSelectedCategory(catId);
    }
  }, [categoryId]);

  useEffect(() => {
    dispatch({ type: 'enable_reset' });
  }, [state.data, state.columns]);

  useEffect(() => {
    if (state?.data?.length) {
      if (flow === 'alumni') {
        alumniServerValidate(false);
      } else {
        candidateServerValidate(false);
      }
    }
  }, []);

  useEffect(() => {
    let count = 0;
    state.errorArray?.forEach((error) => {
      if (Object.keys(error).length > 0) {
        count++;
      }
    });
    setErrorCount(count);
  }, [JSON.stringify(state.errorArray)]);

  const alumniServerValidate = (upload = true) => {
    const payload = {
      alumni: state.data,
      validateOnlyStaffId: true
    };
    if (isSA) {
      payload['company_id'] = companyId;
    }
    setMsg({
      load: true,
      msg: 'Validating data...'
    });
    validateExEmpData(payload)
      .then((res) => {
        setMsg({
          load: false,
          msg: ''
        });
        let errorCountx = state.data.length;
        let dubCount = 0;
        const errorArray = res.data.data.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object' && obj[key].hasOwnProperty('error')) {
              newObj[key] = obj[key].error;
              if (key === 'staff_id' && newObj[key]) dubCount++;
            }
          });
          if (Object.keys(newObj).length === 0) {
            errorCountx--;
            return true;
          }
          return newObj;
        });
        dispatch({ type: 'update_error_array', errorArray });
        if (dubCount > 0) {
          setDublicateCount(dubCount);
          setShowDublicateModal(true);
        }
        if (upload && errorCountx === 0) {
          uploadAlumniData();
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg({
          load: false,
          msg: ''
        });
      });
  };

  const candidateServerValidate = (proceed = true) => {
    const payload = {
      candidates: state.data.map((c) => {
        return {
          firstName: c.full_name,
          lastName: c.surname,
          email: c.personal_email,
          phone: c.phone_number,
          empId: c.staff_id
        };
      }),
      validateUniqueness: true
    };
    if (isSA) {
      payload['company_id'] = companyId;
    }
    setMsg({
      load: true,
      msg: 'Validating data...'
    });
    validateDataForBulkUpload(payload)
      .then((res) => {
        setMsg({
          load: false,
          msg: ''
        });
        let dubCount = 0;
        let errorCountx = state.data.length;
        const errorArray = res.data.data.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object' && obj[key]?.hasOwnProperty('error')) {
              if (obj[key]['error']?.includes('already exists')) {
                newObj[key] = obj[key].error;
              }
            }
          });
          if (Object.keys(newObj).length === 0) {
            errorCountx--;
            return true;
          } else {
            dubCount++;
          }
          return newObj;
        });
        dispatch({ type: 'update_error_array', errorArray });
        if (dubCount > 0) {
          setDublicateCount(dubCount);
          setShowDublicateModal(true);
        }
        if (proceed && errorCountx === 0) {
          setShowCategoryModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg({
          load: false,
          msg: ''
        });
      });
  };

  const uploadAlumniData = async () => {
    try {
      setMsg({
        load: true,
        msg: 'Uploading data...'
      });
      const payload = {
        alumni: state.data.map((obj) => {
          const newObj = {};
          for (const key in obj) {
            if (obj[key]) {
              newObj[key] = obj[key];
            }
          }
          return newObj;
        })
      };
      if (isSA) {
        payload['company_id'] = companyId;
      }
      const response = await uploadExEmpCSV(payload);
      setMsg({
        load: false,
        msg: ''
      });
      if (response) {
        setFinalData(state.data);
        setCurrentStep(3);
      }
    } catch (err) {
      setMsg({
        load: false,
        msg: ''
      });
      console.log(err);
      errToastMessage(err);
    }
  };

  const candidateContinueHandler = () => {
    const categoryTypeId = selectedCategory.id;
    const tableData = state.data;
    const keyMappings = [
      ['full_name', 'firstName'],
      ['surname', 'lastName'],
      ['phone_number', 'phone'],
      ['staff_id', 'empId'],
      ['personal_email', 'email']
    ];

    tableData.forEach((obj) => {
      keyMappings.forEach(([oldKey, newKey]) => {
        if (obj.hasOwnProperty(oldKey)) {
          obj[newKey] = obj[oldKey];
          delete obj[oldKey];
        }
      });
    });
    setFinalData({
      isBulkUpload: true,
      step: 2,
      categoryTypeId: categoryTypeId === -1 ? null : categoryTypeId,
      tableData,
      companyId: companyIdCA,
      flow,
      setCurrentStep
    });

    setCurrentStep(3);
  };

  return (
    <>
      <div className='blk-foot' style={{ zIndex: '999' }}>
        <div>
          {msg.msg && (
            <div className='msg'>
              <Spinner animation='border' size='sm' style={{ margin: '0px 8px 3px 0px' }} />
              {msg.msg}
            </div>
          )}
          {!msg.msg && errorCount > 0 && (
            <div className='msg' style={{ color: '#D3455B' }}>
              <MdError style={{ margin: '0px 10px 3px 0px' }} />
              {errorCount} rows have error. Fix all errors to continue.
            </div>
          )}
        </div>
        <div className='btnContainer'>
          <Button
            onClick={() => {
              const tableData = state.data;
              const keyMappings = [
                ['full_name', 'firstName'],
                ['surname', 'lastName'],
                ['phone_number', 'phone'],
                ['staff_id', 'empId'],
                ['personal_email', 'email']
              ];

              tableData.forEach((obj) => {
                keyMappings.forEach(([oldKey, newKey]) => {
                  if (obj.hasOwnProperty(oldKey)) {
                    obj[newKey] = obj[oldKey];
                    delete obj[oldKey];
                  }
                });
              });
              setFinalData((prevValue) => ({
                ...prevValue,
                isBulkUpload: true,
                tableData
              }));

              setMsg({
                load: false,
                msg: ''
              });
              setCurrentStep(1);
            }}
            variant='outline-primary'
            className='cancelButton'
          >
            Back
          </Button>

          <Button
            onClick={() => {
              window.location.reload();
            }}
            variant='outline-primary'
            className='cancelButton'
          >
            Re-Upload
          </Button>

          <Button
            onClick={() => {
              if (flow === 'alumni') alumniServerValidate(true);
              else candidateServerValidate(true);
            }}
            disabled={errorCount > 0 || msg.load || state.data.length === 0}
            variant='primary'
            className='saveButton'
          >
            Upload
          </Button>
        </div>
      </div>
      {msg.load && <Loader height={'100%'} />}
      <div className='step3header'>
        Please review the data uploaded and fix errors if any. You can edit the data in the table.
      </div>
      <div className='step3InfoHeader'>
        <div className='recordsInfo'>Total Records: {state.data.length}</div>
        <div className='showErrorbtn' style={{ display: 'flex', gap: '10px' }}>
          <button
            className='tableDelBtn btn'
            onClick={() => {
              setShowDeleteModal(true);
            }}
            disabled={selectedRows.length === 0}
          >
            Delete Selected Rows
          </button>
          <button
            className='tableBtn btn btn-primary'
            onClick={() => {
              setShowOnlyError(!showOnlyError);
              dispatch({ type: 'toggle_show_error' });
            }}
          >
            {showOnlyError ? 'Show All' : 'Show Only Errors'}
          </button>
          <button
            className='tableBtn btn btn-primary'
            onClick={() => {
              dispatch({ type: 'add_row' });
              setAddRecordTrigger(addRecordTrigger + 1);
            }}
          >
            Add New Record
          </button>
        </div>
      </div>
      <div
        className='tableView-x'
        style={{
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          maxHeight: 'calc(90vh - 10rem)'
        }}
      >
        <div style={{ overflow: 'auto', display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Table
              columns={state.columns}
              data={state.data}
              dispatch={dispatch}
              skipReset={state.skipReset}
              errorData={state.errorArray}
              showOnlyError={showOnlyError}
              setSelectedRows={setSelectedRows}
              errorCount={errorCount}
              addRecordTrigger={addRecordTrigger}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={showDublicateModal}
        onClose={() => setShowDublicateModal(false)}
        heading={`${dublicateCount} Duplicate Records Found`}
        subHeading='Some candidates are duplicates or already exist in our database. You can delete those records or Continue.'
        onSubmit={() => {
          setShowDublicateModal(false);
          dispatch({ type: 'delete_dublicate_row' });
        }}
        sBtnText='Delete Duplicate Records'
        cBtnText='Continue'
        enableBtn={true}
      />
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        heading={`Delete Selected Records`}
        subHeading='Are you sure you want to delete the selected records?'
        onSubmit={() => {
          dispatch({ type: 'delete_rows', selectedRows, showOnlyError });
          setShowDeleteModal(false);
        }}
        sBtnText='Delete Selected Records'
        cBtnText='Cancel'
        enableBtn={true}
      />
      <ConfirmationModal
        show={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
        heading={`Select Category(Optional)`}
        subHeading={
          <div>
            <DropdownList
              data={[{ id: -1, category_name: 'None' }, ...(config?.categories || [])]}
              dataKey='id'
              textField='category_name'
              value={selectedCategory}
              onSelect={(e) => {
                setSelectedCategory(e);
                dispatch({ type: 'set_category', payload: e });
              }}
              selectIcon={<FontAwesomeIcon icon={faCaretDown} />}
            />
            <div
              className={`custom-control custom-checkbox custom-checkbox-style-1`}
              style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}
            >
              <input
                type='checkbox'
                className='custom-control-input'
                id='customCheck1'
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
              <label className='custom-control-label' htmlFor={'customCheck1'}>
                I understand that any option selected in the steps ahead will apply to all
                candidates being added and cannot be changed later.
              </label>
            </div>
          </div>
        }
        onSubmit={candidateContinueHandler}
        sBtnText='Next'
        cBtnText='Cancel'
        enableBtn={true}
        disableSubmit={!checked}
      />
    </>
  );
}

export default TableView;
