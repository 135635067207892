import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import { ADDRESS_FOR_PACKAGE_CONFIG, PLAIN_CHECK_ICONS } from '../../../utils/commonConstant';
import { capitalizeCase } from '../../../components/CompanyAdmin/frontend-common/utils/Utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import styles from './Address.module.scss';

export default (props) => {
  const { values, pConfig, handleChange, isCourt } = props;

  const valueChange = (event, name) => {
    handleChange('value', name);
  };

  return (
    <Row className={styles.idPackageComp}>
      <Col xl={12} md={12} lg={12} sm={12} xs={12}>
        <h6 className={styles.checkHeading}>
          <span
            style={{ marginRight: '16px', fontSize: '16px' }}
            title={isCourt ? 'Court' : 'Address'}
          >
            <FontAwesomeIcon
              data-testid='icon'
              icon={
                PLAIN_CHECK_ICONS[isCourt ? 'court' : 'address']
                  ? PLAIN_CHECK_ICONS[isCourt ? 'court' : 'address'].icon
                  : faBan
              }
              color='#2755FE'
            />
          </span>
          {isCourt ? 'Court' : 'Address'}&nbsp;
          {!isCourt &&
            `(${pConfig.map((addr) => capitalizeCase(addr.verificationType)).join(', ')})`}
        </h6>
      </Col>
      <Col xl={11} md={11} lg={11} sm={11} xs={11} className={styles.checkContainer}>
        {ADDRESS_FOR_PACKAGE_CONFIG &&
          ADDRESS_FOR_PACKAGE_CONFIG.map((id, index) => {
            return (
              <Form.Check
                type={'radio'}
                className={styles.formCheck}
                key={`${isCourt ? 'court' : 'address'}_package_${index}`}
              >
                <Form.Check.Input
                  data-testid='checkbox'
                  type={'radio'}
                  name={`${isCourt ? 'court' : 'address'}Customize`}
                  id={`${isCourt ? 'court' : 'address'}Package-${index}`}
                  onChange={(e) => {
                    valueChange(e, id.name);
                  }}
                  checked={values.value === id.name}
                />
                <Form.Check.Label htmlFor={`${isCourt ? 'court' : 'address'}Package-${index}`}>
                  {id.label}
                </Form.Check.Label>
              </Form.Check>
            );
          })}
      </Col>
    </Row>
  );
};
