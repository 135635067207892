// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eupJBxWgs1eXxsJnv7Nv {
  max-width: 1200px;
  margin: auto;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
}
@media only screen and (max-width: 1200px) {
  .eupJBxWgs1eXxsJnv7Nv {
    width: 1200px;
  }
}
.eupJBxWgs1eXxsJnv7Nv .dAAbzfgG74DYaWd5Jpag {
  padding-left: 0px !important;
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
  padding: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  padding-right: 0;
}
.eupJBxWgs1eXxsJnv7Nv .dAAbzfgG74DYaWd5Jpag .AUu3tII5vPqpUM1F9WHN {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.eupJBxWgs1eXxsJnv7Nv .dAAbzfgG74DYaWd5Jpag button .BNhBJaCLJYTrJ6ef5tF6 {
  background: #009b53;
  width: 164px;
  height: 48px;
  padding: 14px 11px 14px 14px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.eupJBxWgs1eXxsJnv7Nv .dAAbzfgG74DYaWd5Jpag button .ip6xdcV_nnYKjZFnxjiA {
  background: #009b53;
  width: 164px;
  height: 48px;
  padding: 14px 11px 14px 14px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f {
  margin-top: 24px;
  border: 0px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg {
  margin: auto;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg thead {
  background-color: #f2f8ff;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 21px 10px 16px 10px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg thead tr .aMfCEzZnRZF9j_ufM_TJ {
  width: 25%;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg thead tr .FuO0VYkCBVTvQskIUJcd {
  text-align: center;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody {
  padding-left: 10px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr {
  cursor: default;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr td {
  border: 0px;
  vertical-align: middle !important;
  padding: 18px 10px 19px 10px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: left;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .k8kZEMaFEuUypbelgZ0J {
  color: #999999 !important;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ {
  width: 25%;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .R37uiLupSmE3d5n4c7xE {
  width: 100%;
  font-weight: 500;
  color: #366cf3;
  display: flex;
  justify-content: space-between;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .R37uiLupSmE3d5n4c7xE .vdMtnQZOFGvYbdN8TqJO {
  padding-left: 6px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .R37uiLupSmE3d5n4c7xE .NhTqyBLOvdmIqPeT1RYQ {
  display: inline-flex;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .R37uiLupSmE3d5n4c7xE p {
  cursor: pointer;
  width: fit-content;
  margin-bottom: 0;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .REmzvIKyIXZXtCTWIn4c {
  width: 235px;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 21px;
  color: #999999;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .REmzvIKyIXZXtCTWIn4c .BMbvr6wH50odeoflMEuT {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .REmzvIKyIXZXtCTWIn4c .guH8kz4oskH7J6D0nTbZ {
  margin-left: 3px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 2px;
  height: 28px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .aMfCEzZnRZF9j_ufM_TJ .REmzvIKyIXZXtCTWIn4c .guH8kz4oskH7J6D0nTbZ:hover {
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .DeAtKiJLVOoUpKnzahoV {
  font-family: Roboto;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .Ru0o1NgnJMwuS9la62tL {
  width: 100px;
  text-align: center;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .Ru0o1NgnJMwuS9la62tL .Qa0gyAanqlyf4XWWsmnL {
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 3px;
  width: 22px;
  margin: auto;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .Ru0o1NgnJMwuS9la62tL .Qa0gyAanqlyf4XWWsmnL:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .ndqVvs5ejlq2pcdRwd5R {
  max-width: 164px;
  cursor: pointer !important;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .ndqVvs5ejlq2pcdRwd5R div {
  display: inline;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr .y5Y5keqvQfN6vigdh53I {
  font-size: 14px;
  font-weight: normal;
  display: block;
  background: #fafafa;
  color: #666666;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  text-align: center;
  max-width: fit-content;
  padding: 0px 8px;
  margin: 4px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg tbody tr:hover {
  background-color: #f5f5f5;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg .ZNMth84Wo3gDc9gnBfle {
  text-align: center;
  padding-top: 64px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .VD6v_gyjSSifV9pkC1Jg .ZNMth84Wo3gDc9gnBfle .fWogiXUoAKZgGJvzsmtZ {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .n9rwG86vT7jBHlhSv2yO {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .n9rwG86vT7jBHlhSv2yO .rif795fh55tme6yqRk6y {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .n9rwG86vT7jBHlhSv2yO .rif795fh55tme6yqRk6y .JwvmQBw9ayEvQuGiaUTa {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px !important;
}
.eupJBxWgs1eXxsJnv7Nv .kLTXvp3DHrRs4tlZLs2f .n9rwG86vT7jBHlhSv2yO .rif795fh55tme6yqRk6y .JwvmQBw9ayEvQuGiaUTa:focus {
  outline-color: #2855ff !important;
}
.eupJBxWgs1eXxsJnv7Nv .LDNly8td4cNOGkM18S7i {
  max-width: 1200px;
  height: 500px;
  background: #ffffff;
  border-radius: 4px;
  margin: 30px auto 48px;
  font-family: Poppins, sans-serif;
  color: #333333;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .eupJBxWgs1eXxsJnv7Nv .LDNly8td4cNOGkM18S7i {
    width: 1200px;
  }
}
.eupJBxWgs1eXxsJnv7Nv .LDNly8td4cNOGkM18S7i .me3PmmeAdsriNVCmSxNW {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ycAGPZG5iPxe16s1pldW {
  border: 1px solid gray;
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: black;
  padding: 0 12px;
}
.ycAGPZG5iPxe16s1pldW::placeholder {
  color: red;
}
.ycAGPZG5iPxe16s1pldW input:disabled {
  box-shadow: inset 0 2px 2px #3f3f3f;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(107, 107, 107));
}
.ycAGPZG5iPxe16s1pldW input:disabled:hover {
  cursor: not-allowed;
}

.tVQZRqs8aXkE7I0qtGz0 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.tVQZRqs8aXkE7I0qtGz0 .dlPYNdIA1elcALq3jVFJ {
  width: 75%;
  margin-left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.tVQZRqs8aXkE7I0qtGz0 .pGLzfMeGCQVMukdnfHp5 {
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.tVQZRqs8aXkE7I0qtGz0 .Z3WbX2B5vLKJgwSlMkiy {
  color: #333333 !important;
  width: 181px;
  margin-left: 17px;
}
.tVQZRqs8aXkE7I0qtGz0 .Dh2EagNrr9w1um9dJi9f {
  color: #333333;
}
.tVQZRqs8aXkE7I0qtGz0 .VmLOO2V48bV6oPSVE_Tb {
  color: #333333 !important;
  font-size: 14px;
  height: 45px;
  border-color: #c2cfff;
}
.tVQZRqs8aXkE7I0qtGz0 .S75m31e6FU2d6_nmD8Sk {
  margin-left: 5px;
}

._nUmoKesjiAYh2pFoN4G {
  background: #2755fe;
}

.UIEkCOpj3AXjrOONq4b8 {
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 34px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}

.jNvbcWSFIJ5PipV2ra_c {
  position: absolute;
  z-index: 1;
  left: 231px;
  top: 34px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}

.tEH_tHHtbAsNpzGUMDBe {
  position: absolute;
  z-index: 1;
  left: 430px;
  top: 34px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}

.CNv1ENuk8rz4BTyKEZvO {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 267px;
  display: flex;
  padding-left: 8px;
  padding-right: 15px;
  height: 44px;
}
.CNv1ENuk8rz4BTyKEZvO .eDZz_ItS9caS4BqQH5iI {
  width: 14px;
  height: 14px;
  margin-top: 14px;
}
.CNv1ENuk8rz4BTyKEZvO .PZbeQsPdRTG5JmvWxoEY {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0 0 2px !important;
}
.CNv1ENuk8rz4BTyKEZvO .PZbeQsPdRTG5JmvWxoEY:focus {
  box-shadow: unset;
}

.zKOVyCrtqfufxrtPAVvt {
  color: #2855ff;
  cursor: pointer;
}

.DT17oKAL14syxSuub7x1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vfNdeE0U0mV4RdeEJU_p {
  padding-left: 2px;
  height: 1rem;
}
.vfNdeE0U0mV4RdeEJU_p:hover {
  cursor: pointer;
}

.m2gMBbBhrtnBA0OmlH9w {
  padding-left: 2px;
  width: 21px;
  height: 18px;
}
.m2gMBbBhrtnBA0OmlH9w:hover {
  cursor: pointer;
}

.Ui688qURafXDqGGZ5wU0 {
  background-color: #d3455b;
}
.Ui688qURafXDqGGZ5wU0:hover {
  background-color: #e97070;
}

.jgxQmaU52ogEUd5lAXrA {
  font-size: medium;
}

.udyCdkns88G4jrU79PBK {
  color: #d42d2d;
}

.NmlXS9GLZLkU0vacrvMY {
  color: black;
  font-size: 300;
}

.fgChUsTLXHI7AWTMf6l7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DeAtKiJLVOoUpKnzahoV {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}

.R81z1KGUHQtJ2RpPPp9j > .W4PlnjJnK9wsG_USBTxK {
  text-align: left;
}

.VbWVlZLupVeBZYg15m0L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.q9Amlud22xyXvm0iJdcN {
  font-weight: 1200;
}

.tl84k5Uc9L7xMrIa10lR {
  font-weight: 300;
}

.PaZbiwmuqejfQ_CTW1Lg {
  font-weight: 600;
}

.Fs9BZNBW4Ar5n9g9CTpf {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
}

.MRlZZ6IlRVV9ACQa8c9c {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.a5IqezqY3sqy2RZLefar {
  width: 6rem;
}

.pRh5jJRWo3MS1glDcMJw {
  display: flex;
}

.IanFzddYJibRsX7XcA5c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #333333;
}

._CPcllQidKUaILGC12px {
  color: #999999;
}

.tz0mjLeMwJ2MPZkX4uuc {
  margin-right: 3px;
}

.qNIXp1_rPJACxq1kWiIQ {
  padding: 6px;
}
.qNIXp1_rPJACxq1kWiIQ .Rjy6qYwy1uOyseJTvJx0 {
  padding: 4px !important;
}

.U7OF0zSXhDQzH7E2Pozw {
  width: 181px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.TiO0i_gfZxrF0K1SsPCH {
  width: 181px;
  height: 45px;
  border: 1px solid #c2cfff;
  border-radius: 4px;
}

.S2Unxh4256EH1G0pIXav {
  font-size: 14px;
  line-height: 20px;
}

.S7Bmupbnyi29hA922f5n {
  font-size: 14px;
  color: #333333;
  display: flex;
  padding-top: 12px;
  place-content: space-between;
}

.xYnNxteu0zCYYBEQSQU4 {
  font-size: 14px;
  margin-top: 1px;
}

.xD6BU_trYntGGXC_o5gg {
  font-size: 13px;
  margin-top: 2px;
  padding-left: 1px;
}

.uWH1BSOifAVmpDks0zVQ {
  width: 150px;
}

.KIx3Zy5JRkISodmTNSbS {
  padding-left: 2px;
}

.OxN5FCSAC9wnDLe04Wpq {
  padding-right: 2px;
}

.pnXOEWuiGAhIwR5976Rh {
  padding-inline: 8px;
}

.WRXNtND73pzvkIw8XvNd {
  z-index: 9999;
  position: absolute;
  margin: 7px;
}

.TJ7OIuSLukRIgRfC3dMo {
  width: 136px;
  height: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 5px;
  background-color: #18a899 !important;
  border: none;
}

.TJ7OIuSLukRIgRfC3dMo:active,
.TJ7OIuSLukRIgRfC3dMo:focus {
  outline: none;
  box-shadow: none;
}

.TJ7OIuSLukRIgRfC3dMo:hover {
  background-color: #18a899;
}

.UB_MDcgEYYrvysnrRFX6 {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.26);
  margin-right: 4px;
  border-color: rgba(0, 0, 0, 0.12);
}

.ajWZ_PJrMe7afqswwX32 {
  width: 136px;
  height: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 5px;
  background-color: #d3455b;
  border: none;
}

.ajWZ_PJrMe7afqswwX32:hover {
  background-color: #e97070;
}

.OfeKot0lz1l33pDnfmtV {
  display: flex;
  flex-direction: row;
}

.LyFNsBZmQMcOuzcsKyYV,
.LyFNsBZmQMcOuzcsKyYV:hover {
  cursor: pointer;
  color: #6558f5;
}

.C_amP8a1W9hEFG6d3KZ0,
.C_amP8a1W9hEFG6d3KZ0:hover {
  margin-left: 20px;
  cursor: pointer;
  color: #d65065;
}

._sKUfC5cPmKfyhBgskJm {
  color: #2755fe !important;
}

.LvRdLS0hV6uhoWHhW_Fn {
  border-bottom: 1px dotted #315dfe;
  text-decoration: none;
  cursor: pointer;
}
.LvRdLS0hV6uhoWHhW_Fn :hover {
  text-decoration: none;
}

.fwVO_qv7ydf5UiREH2MA {
  color: grey;
}

.QNVK1fjYng6aHjZdYXJ9 {
  display: flex;
}

.uau6lQfpbKMk1HHju9Pd {
  margin-right: 20px;
  cursor: pointer;
}

.dZX0BGJvc9xs6HQjQdt4 {
  margin-left: 2px;
  font-size: 20px;
  color: #315dfe;
}

.ZNMth84Wo3gDc9gnBfle {
  text-align: center;
  padding-top: 64px;
}
.ZNMth84Wo3gDc9gnBfle .fWogiXUoAKZgGJvzsmtZ {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.zVDDTFUei3pLdeJd1Wfk {
  color: red;
  font-family: roboto !important;
  font-size: 12px;
}

.zTmQ7G6tUeZ2IXQFrKIw {
  color: blue;
}
.zTmQ7G6tUeZ2IXQFrKIw:hover {
  cursor: pointer;
  color: #4169e1;
}

.wD2r7B9uD1fObteTvVFg {
  margin: unset;
  height: 80px;
}
.wD2r7B9uD1fObteTvVFg .AvkFQvzF_5rjMOPbjeu_ {
  border-radius: unset;
  background-color: #fff;
  border-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: black;
  width: 100px;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 40px;
}
.wD2r7B9uD1fObteTvVFg .K0M5KWkTMrJqaBCksIIZ,
.wD2r7B9uD1fObteTvVFg .AvkFQvzF_5rjMOPbjeu_:active,
.wD2r7B9uD1fObteTvVFg :focus {
  border: 1px solid #2755fe;
  color: #2755fe !important;
  box-shadow: none !important;
}
.wD2r7B9uD1fObteTvVFg .AvkFQvzF_5rjMOPbjeu_:hover {
  z-index: unset;
}
.wD2r7B9uD1fObteTvVFg .EK2dkCfgkQLQcx4vZsYh {
  margin-bottom: 6px;
  border-radius: unset;
  background-color: #fff;
  border-color: #d9d9d9;
  cursor: pointer;
  min-width: 75px;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 40px;
}
.wD2r7B9uD1fObteTvVFg .EK2dkCfgkQLQcx4vZsYh:hover {
  color: #333333;
}
.wD2r7B9uD1fObteTvVFg .dlRTEkaGZ9sPdxiKqhdQ {
  margin-bottom: 6px;
  height: 40px;
  animation: hobPBQB3AFJpxKNAZFmS 0.75s none 0s 1;
}
.wD2r7B9uD1fObteTvVFg .dlRTEkaGZ9sPdxiKqhdQ input {
  height: unset;
  border-left: none;
}
.wD2r7B9uD1fObteTvVFg input:focus {
  outline: 1px solid #2755fe;
}
.wD2r7B9uD1fObteTvVFg input:focus .dxXrfesk4JwVMUjvh18w {
  outline: 1px solid #2755fe;
}
.wD2r7B9uD1fObteTvVFg .di7ULd7apHZheWWQ9arj span {
  background-color: #fff;
  border-radius: 0;
  font-family: roboto;
  border-right: none;
}

.b9gxFx_eRLSgm6Zh4bdi {
  font-size: 80%;
  font-weight: 400;
}

.PuKKtevNOabN4RWgNi7I {
  color: #2755fe;
}

@keyframes hobPBQB3AFJpxKNAZFmS {
  0% {
    opacity: 0;
    width: 50px;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}
.iESldnAfUiAZ5UlIpxU0 {
  top: 166px;
}

.X5dfLgDX6TjUs_7dTIEm {
  top: 120px;
}

.g9Lncs94nmIavXth4IBZ {
  top: 79px;
}

.FZoS3U96mwruNjHvBBO3 {
  width: 100%;
  padding-top: 24px;
}
.FZoS3U96mwruNjHvBBO3 .hmV8oUDjb8cBtLZuSoXw {
  border-radius: 2px;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  background: #ffe3e3;
  display: flex;
  padding-inline: 20px;
  padding-top: 5px;
}
.FZoS3U96mwruNjHvBBO3 .hmV8oUDjb8cBtLZuSoXw .RDFtLooBD4nogPYkJZv6 {
  width: 14px;
  height: 14px;
}
.FZoS3U96mwruNjHvBBO3 .hmV8oUDjb8cBtLZuSoXw .R9N8v7AunC4qR1QICctx {
  padding-top: 1px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 100;
}
.FZoS3U96mwruNjHvBBO3 .hmV8oUDjb8cBtLZuSoXw .R9N8v7AunC4qR1QICctx .GyTeHZ8nc817oOM5UfBN {
  color: #2755fe !important;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv {
  background: #fffbf4;
  height: 86px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 20px;
  width: 100%;
  border: 1px solid #fbe2b7;
  border-radius: 8px;
  display: flex;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .uoTS52j4vwqhQ3S6fgyN {
  padding-left: 36px;
  margin-right: 16px;
  width: 80%;
  display: flex;
  border-right: 1px solid #999999;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .TYrdIh3RtqVMm8fsuGNu {
  margin-top: 7px;
  color: #333333;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .F8CM1LvYuS_mVePuRtOd {
  color: #333333;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .rG3a6WWTmwtv63txqEpa {
  width: 30%;
  padding: 0px;
  margin-top: 5px;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .vq12EoQJYk4sY30D4m84 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .gpf8DRlK8R9YL21AkMmW {
  display: flex;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .gpf8DRlK8R9YL21AkMmW .hKOuzbnx3UhOhvxGKV2U {
  color: #333333;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 600;
}
.FZoS3U96mwruNjHvBBO3 .B_w47wgqz5F7gfGCE_Tv .gpf8DRlK8R9YL21AkMmW .XPtdNXUK2K7lPl_t9FNJ {
  padding: 4px 8px 4px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #666666;
  height: 25px;
  border-radius: 20px;
  background: #ffe3e3;
}

.fbZjjwnqbn_Lw5wTOQh_ {
  color: #2755fe;
  border-color: #2755fe;
}

.dwxhmx2v9JCGdbcGwgEP {
  width: 130px;
  height: 36px;
}

.bVfip34Jytl0zjV06GwB {
  all: unset;
}

.sEQ_f0f3vb505hrwkkKn {
  font-family: roboto;
}

.kCVQoGpSL5MX4rW8QWUP {
  width: 30%;
}

.NVMabBvEvntBXXea3pYD {
  width: 20%;
}

.O86HPEo4ogLExpBIrTjK {
  width: 70%;
}

.IarGy0qgPpHJoBhhfwCh {
  width: 80%;
}

.BxG2L6C5n7hlXP_6ZH1X {
  background: #ffc62c;
  width: 82px;
  height: 29px;
  padding: 4px 12px 4px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #595959;
}

.afLmLvuBG6lsMdcPpsmk {
  background: #d3455b;
  width: 155px;
  height: 28px;
  padding: 6px 12px 4px 12px !important;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.TV1iXRccZmiQnUba4SuA {
  padding-inline: 24px;
}

.OCjx4DIoMtnaaHhLYQcv {
  background: white;
  padding: 0px !important;
}

.ii4BENPGg0DEifa9DnFn {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin-bottom: 0;
  padding: 12px 24px;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.EBb0d5HW8RGwVrEAaqmp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.EBb0d5HW8RGwVrEAaqmp .BwMqRdxlctv7iZK8SADO {
  max-width: 70px;
  margin-right: 20px;
}
.EBb0d5HW8RGwVrEAaqmp .jks9d8yRmYJnSrmrDvFA {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin: 0;
}
.EBb0d5HW8RGwVrEAaqmp .jks9d8yRmYJnSrmrDvFA strong {
  font-weight: 500;
}
.EBb0d5HW8RGwVrEAaqmp .ZE8o4Lmtx2pBziAH_cjY {
  width: 100px;
  background: transparent;
  height: 42px;
  border: 1px solid #2755fe;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #2755fe;
  line-height: 42px;
  padding: 0;
}
.EBb0d5HW8RGwVrEAaqmp .ZE8o4Lmtx2pBziAH_cjY:hover, .EBb0d5HW8RGwVrEAaqmp .ZE8o4Lmtx2pBziAH_cjY:focus {
  background-color: #2755fe;
  border-color: #2755fe;
  color: #fff;
}

.CqUBfUxAODpRCNIH5SG1 {
  background-color: #d3455b;
}
.CqUBfUxAODpRCNIH5SG1:hover, .CqUBfUxAODpRCNIH5SG1:active {
  background-color: #e97070;
}

.kBlv7tmVGJccBTBfc2Nu {
  color: #2755fe;
}
.kBlv7tmVGJccBTBfc2Nu:hover {
  color: #1f44ca;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chargesWrapper": `eupJBxWgs1eXxsJnv7Nv`,
	"header": `dAAbzfgG74DYaWd5Jpag`,
	"heading": `AUu3tII5vPqpUM1F9WHN`,
	"approveAllBtn": `BNhBJaCLJYTrJ6ef5tF6`,
	"rejectAllButton": `ip6xdcV_nnYKjZFnxjiA`,
	"candidatesCard": `kLTXvp3DHrRs4tlZLs2f`,
	"approvalChargesTable": `VD6v_gyjSSifV9pkC1Jg`,
	"mainField": `aMfCEzZnRZF9j_ufM_TJ`,
	"alignCenter": `FuO0VYkCBVTvQskIUJcd`,
	"deletedUser": `k8kZEMaFEuUypbelgZ0J`,
	"nameField": `R37uiLupSmE3d5n4c7xE`,
	"mailIcon": `vdMtnQZOFGvYbdN8TqJO`,
	"mainNameField": `NhTqyBLOvdmIqPeT1RYQ`,
	"email": `REmzvIKyIXZXtCTWIn4c`,
	"emailField": `BMbvr6wH50odeoflMEuT`,
	"editCategoryIcon": `guH8kz4oskH7J6D0nTbZ`,
	"amount": `DeAtKiJLVOoUpKnzahoV`,
	"reportIcon": `Ru0o1NgnJMwuS9la62tL`,
	"icon": `Qa0gyAanqlyf4XWWsmnL`,
	"displayInline": `ndqVvs5ejlq2pcdRwd5R`,
	"tagText": `y5Y5keqvQfN6vigdh53I`,
	"noDataWrapper": `ZNMth84Wo3gDc9gnBfle`,
	"noDataContent": `fWogiXUoAKZgGJvzsmtZ`,
	"paginationWrapper": `n9rwG86vT7jBHlhSv2yO`,
	"showPagination": `rif795fh55tme6yqRk6y`,
	"selectPagination": `JwvmQBw9ayEvQuGiaUTa`,
	"loadingChargesCard": `LDNly8td4cNOGkM18S7i`,
	"viewChargesLoader": `me3PmmeAdsriNVCmSxNW`,
	"datePicker": `ycAGPZG5iPxe16s1pldW`,
	"secondheader": `tVQZRqs8aXkE7I0qtGz0`,
	"filters": `dlPYNdIA1elcALq3jVFJ`,
	"actionsbtns": `pGLzfMeGCQVMukdnfHp5`,
	"filterItems": `Z3WbX2B5vLKJgwSlMkiy`,
	"dropDownPlaceholder": `Dh2EagNrr9w1um9dJi9f`,
	"dropDownStyle": `VmLOO2V48bV6oPSVE_Tb`,
	"filterItemsSearch": `S75m31e6FU2d6_nmD8Sk`,
	"accentColor": `_nUmoKesjiAYh2pFoN4G`,
	"filterByDateLabel": `UIEkCOpj3AXjrOONq4b8`,
	"approvalStatusLabel": `jNvbcWSFIJ5PipV2ra_c`,
	"costTypeLabel": `tEH_tHHtbAsNpzGUMDBe`,
	"searchInput": `CNv1ENuk8rz4BTyKEZvO`,
	"searchIcon": `eDZz_ItS9caS4BqQH5iI`,
	"inputText": `PZbeQsPdRTG5JmvWxoEY`,
	"textBlue": `zKOVyCrtqfufxrtPAVvt`,
	"flexItemsCenter": `DT17oKAL14syxSuub7x1`,
	"image": `vfNdeE0U0mV4RdeEJU_p`,
	"imageAutoApp": `m2gMBbBhrtnBA0OmlH9w`,
	"imageAutoBackground": `Ui688qURafXDqGGZ5wU0`,
	"creditLimit": `jgxQmaU52ogEUd5lAXrA`,
	"creditLimitRedText": `udyCdkns88G4jrU79PBK`,
	"creditLimitBlackText": `NmlXS9GLZLkU0vacrvMY`,
	"info": `fgChUsTLXHI7AWTMf6l7`,
	"tooltip": `R81z1KGUHQtJ2RpPPp9j`,
	"tooltip-inner": `W4PlnjJnK9wsG_USBTxK`,
	"modalFooter": `VbWVlZLupVeBZYg15m0L`,
	"modalbodyheader": `q9Amlud22xyXvm0iJdcN`,
	"modalBodyText": `tl84k5Uc9L7xMrIa10lR`,
	"modalFooterText": `PaZbiwmuqejfQ_CTW1Lg`,
	"modalFooterEnd": `Fs9BZNBW4Ar5n9g9CTpf`,
	"paymentDiv": `MRlZZ6IlRVV9ACQa8c9c`,
	"paymentBtn": `a5IqezqY3sqy2RZLefar`,
	"actionsbtnsrow": `pRh5jJRWo3MS1glDcMJw`,
	"actiontext": `IanFzddYJibRsX7XcA5c`,
	"deletedCaActiontext": `_CPcllQidKUaILGC12px`,
	"deleteInfo": `tz0mjLeMwJ2MPZkX4uuc`,
	"customDatePicker": `qNIXp1_rPJACxq1kWiIQ`,
	"react-daterange-picker__wrapper": `Rjy6qYwy1uOyseJTvJx0`,
	"dateFilterField": `U7OF0zSXhDQzH7E2Pozw`,
	"dateInputField": `TiO0i_gfZxrF0K1SsPCH`,
	"filterLabelText": `S2Unxh4256EH1G0pIXav`,
	"dateTextField": `S7Bmupbnyi29hA922f5n`,
	"textDateRange": `xYnNxteu0zCYYBEQSQU4`,
	"dateText": `xD6BU_trYntGGXC_o5gg`,
	"dateTextWidth": `uWH1BSOifAVmpDks0zVQ`,
	"paddingLeft": `KIx3Zy5JRkISodmTNSbS`,
	"paddingRight": `OxN5FCSAC9wnDLe04Wpq`,
	"topTextPadding": `pnXOEWuiGAhIwR5976Rh`,
	"dateRangePickerDiv": `WRXNtND73pzvkIw8XvNd`,
	"approveBtn": `TJ7OIuSLukRIgRfC3dMo`,
	"disabledButton": `UB_MDcgEYYrvysnrRFX6`,
	"rejectBtn": `ajWZ_PJrMe7afqswwX32`,
	"modalFooterButtons": `OfeKot0lz1l33pDnfmtV`,
	"cancelBtn": `LyFNsBZmQMcOuzcsKyYV`,
	"closeBtn": `C_amP8a1W9hEFG6d3KZ0`,
	"anchorTextColor": `_sKUfC5cPmKfyhBgskJm`,
	"actionbtn": `LvRdLS0hV6uhoWHhW_Fn`,
	"fontLightGrey": `fwVO_qv7ydf5UiREH2MA`,
	"displayFlex": `QNVK1fjYng6aHjZdYXJ9`,
	"btnAction": `uau6lQfpbKMk1HHju9Pd`,
	"arrowMargin": `dZX0BGJvc9xs6HQjQdt4`,
	"warningText": `zVDDTFUei3pLdeJd1Wfk`,
	"addCredits": `zTmQ7G6tUeZ2IXQFrKIw`,
	"radioButtonContainer": `wD2r7B9uD1fObteTvVFg`,
	"btn": `AvkFQvzF_5rjMOPbjeu_`,
	"btnSelected": `K0M5KWkTMrJqaBCksIIZ`,
	"other": `EK2dkCfgkQLQcx4vZsYh`,
	"input": `dlRTEkaGZ9sPdxiKqhdQ`,
	"animateSearchTabView": `hobPBQB3AFJpxKNAZFmS`,
	"input-group-prepend": `dxXrfesk4JwVMUjvh18w`,
	"rupee": `di7ULd7apHZheWWQ9arj`,
	"rechargeBtnLabel": `b9gxFx_eRLSgm6Zh4bdi`,
	"rechargeBtnLabelColor": `PuKKtevNOabN4RWgNi7I`,
	"topHeightMax": `iESldnAfUiAZ5UlIpxU0`,
	"topHeightMid": `X5dfLgDX6TjUs_7dTIEm`,
	"topHeightForRibbonCase": `g9Lncs94nmIavXth4IBZ`,
	"topItemsDiv": `FZoS3U96mwruNjHvBBO3`,
	"ribbonRow": `hmV8oUDjb8cBtLZuSoXw`,
	"warningIcon": `RDFtLooBD4nogPYkJZv6`,
	"ribbonTextDiv": `R9N8v7AunC4qR1QICctx`,
	"link": `GyTeHZ8nc817oOM5UfBN`,
	"actionBarRow": `B_w47wgqz5F7gfGCE_Tv`,
	"parentOne": `uoTS52j4vwqhQ3S6fgyN`,
	"pendingCostsTextDiv": `TYrdIh3RtqVMm8fsuGNu`,
	"pendingCostsAmountDiv": `F8CM1LvYuS_mVePuRtOd`,
	"actionButtonsCol": `rG3a6WWTmwtv63txqEpa`,
	"walletBalText": `vq12EoQJYk4sY30D4m84`,
	"walletBalDiv": `gpf8DRlK8R9YL21AkMmW`,
	"walletAmount": `hKOuzbnx3UhOhvxGKV2U`,
	"criticalBalPill": `XPtdNXUK2K7lPl_t9FNJ`,
	"cancleButton": `fbZjjwnqbn_Lw5wTOQh_`,
	"modalButton": `dwxhmx2v9JCGdbcGwgEP`,
	"unsetDefaults": `bVfip34Jytl0zjV06GwB`,
	"rupeeFont": `sEQ_f0f3vb505hrwkkKn`,
	"width30": `kCVQoGpSL5MX4rW8QWUP`,
	"width20": `NVMabBvEvntBXXea3pYD`,
	"width70": `O86HPEo4ogLExpBIrTjK`,
	"width80": `IarGy0qgPpHJoBhhfwCh`,
	"pendingPill": `BxG2L6C5n7hlXP_6ZH1X`,
	"autoAppPill": `afLmLvuBG6lsMdcPpsmk`,
	"padding24": `TV1iXRccZmiQnUba4SuA`,
	"contentDivStyle": `OCjx4DIoMtnaaHhLYQcv`,
	"adcAlert": `ii4BENPGg0DEifa9DnFn`,
	"adcAlertContent": `EBb0d5HW8RGwVrEAaqmp`,
	"clockImage": `BwMqRdxlctv7iZK8SADO`,
	"adcContent": `jks9d8yRmYJnSrmrDvFA`,
	"adcSetNowBtn": `ZE8o4Lmtx2pBziAH_cjY`,
	"deleteButton": `CqUBfUxAODpRCNIH5SG1`,
	"linkStyle": `kBlv7tmVGJccBTBfc2Nu`
};
export default ___CSS_LOADER_EXPORT___;
