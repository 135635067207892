// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F9RgD1oqXfWkZmBk5h6L {
  font-family: Poppins;
  padding-top: 20px;
  margin-bottom: 40px;
}
.F9RgD1oqXfWkZmBk5h6L .jbTC8OifPedkvt0UaOUa {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.F9RgD1oqXfWkZmBk5h6L .k8CqogLqA66si8wgoyEV {
  max-height: 800px;
  overflow-y: auto;
  padding: 20px 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.F9RgD1oqXfWkZmBk5h6L .k8CqogLqA66si8wgoyEV .TiVmWF_9aXRBx9BIjBRe {
  color: #2755fe;
  margin-bottom: 20px;
  width: 100px;
}
.F9RgD1oqXfWkZmBk5h6L .PhEfOO9uKgF_NQMHIsZ0 {
  overflow-y: visible !important;
  margin-top: 50px;
}

.q7mwF1zA4Dj9UjBIIQvY {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: inherit;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v {
  text-align: center;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v h2 {
  font-weight: normal;
  font-size: 24px;
  color: #333333;
  margin-top: 24px;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v p {
  font-weight: normal;
  font-size: 14px;
  color: #666666;
  margin-top: 8px;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v p a {
  font-weight: bold;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v button {
  margin-top: 24px;
  background: #2755fe;
  border-radius: 2px;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v button a {
  color: #ffffff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseGrid": `F9RgD1oqXfWkZmBk5h6L`,
	"heading": `jbTC8OifPedkvt0UaOUa`,
	"packageListContainer": `k8CqogLqA66si8wgoyEV`,
	"goBackBtn": `TiVmWF_9aXRBx9BIjBRe`,
	"bulkUploadPackageListing": `PhEfOO9uKgF_NQMHIsZ0`,
	"noPackageFound": `q7mwF1zA4Dj9UjBIIQvY`,
	"textContainer": `a3Z1kZzM8vrJISOtp52v`
};
export default ___CSS_LOADER_EXPORT___;
