import { connect } from 'react-redux';
import {
  getAddCandidateConfig as getAddCandidateConfigAPI,
  invalidateAddCandidateConfig
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    createCandConfig: state.createCandConfig || null,
    profile: state.profile?.profile || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateAddCandidateConfig: () => dispatch(invalidateAddCandidateConfig()),
    getAddCandidateConfig: (companyId) => dispatch(getAddCandidateConfigAPI(companyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
