import { mergeWith, isEqual, isEmpty, has } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';

import validationSchema from './AddEditModalPackage.validation';
import logger from '../../../../../../../core-components/Logger';
import {
  toCamelCase,
  toSnakeCase,
  getErrorMessage,
  errToastMessage
} from '../../../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  setTemporaryEducationData,
  invalidateTemporaryEducationData
} from '../../../../../../../actions/bgv';

const mapDateFormat = (startDate, endDate, isSaving) => {
  if (isSaving) {
    startDate = moment(startDate, 'ddd MMM DD YYYY').format('MM/YYYY');
    endDate = moment(endDate, 'ddd MMM DD YYYY').format('MM/YYYY');
  } else {
    startDate = moment(moment(startDate, 'MM/YYYY', true)).isValid()
      ? moment(startDate, 'MM/YYYY').format('ddd MMM DD YYYY')
      : null;
    endDate = moment(moment(endDate, 'MM/YYYY', true)).isValid()
      ? moment(endDate, 'MM/YYYY').format('ddd MMM DD YYYY')
      : null;
  }
  return { startDate, endDate };
};

const getFinalSavingObject = (props, currentState, finalStepObj, applySnakeCase = false) => {
  if (applySnakeCase) {
    return {
      ...props.educationCachedData,
      cacheData: { ...props.educationCachedData.cacheData, ...finalStepObj },
      currentSavingIndex: JSON.stringify(props.currentSavingIndex),
      currentState
    };
  } else {
    return toSnakeCase({
      ...props.educationCachedData,
      cacheData: { ...props.educationCachedData.cacheData, ...finalStepObj },
      currentSavingIndex: JSON.stringify(props.currentSavingIndex),
      currentState
    });
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setTemporaryEducationData: (data) => dispatch(setTemporaryEducationData(data)),
    invalidateTemporaryEducationData: () => dispatch(invalidateTemporaryEducationData())
  };
};

const mapStateToProps = (state) => {
  const educationCachedData = !state.bgvCachedData.data
    ? null
    : toCamelCase(state.bgvCachedData.data);

  return {
    educationCachedData,
    temporaryEducation: !isEmpty(state.temporaryEducation) ? { ...state.temporaryEducation } : {}
  };
};

let propsValue = {};

const setPropsValueToBlank = () => {
  propsValue = {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {};
      storedValues = {
        educationLevel: props.educationLevel || null,
        universityName: '',
        collegeName: '',
        registrationNumber: '',
        degree: '',
        degreeType: '',
        areasOfStudy: '',
        startDate: null,
        endDate: null,
        validStartDate: true,
        validEndDate: true
      };
      if (
        props.educationCachedData &&
        props.educationCachedData.cacheData &&
        props.educationCachedData.cacheData.educationCheck &&
        props.educationCachedData.cacheData.educationCheck.length > 0 &&
        props.educationCachedData.cacheData.educationCheck[props.currentSavingIndex]
      ) {
        if (!isEmpty(props.temporaryEducation)) {
          //Because upload doc is dependent on education-level of step1 so in case of edit until user will not click on save of step2 we will not save the data
          propsValue['startDate'] =
            props.temporaryEducation.saveObject.cacheData.educationCheck[
              props.currentSavingIndex
            ].step1.datesAttended.startDate;
          propsValue['endDate'] =
            props.temporaryEducation.saveObject.cacheData.educationCheck[
              props.currentSavingIndex
            ].step1.datesAttended.endDate;
          propsValue = {
            ...props.temporaryEducation.saveObject.cacheData.educationCheck[
              props.currentSavingIndex
            ].step1,
            ...mapDateFormat(propsValue['startDate'], propsValue['endDate'], false)
          };

          delete propsValue['datesAttended'];
        } else if (
          isEmpty(props.temporaryEducation) &&
          props.educationCachedData.cacheData.educationCheck[props.currentSavingIndex] &&
          !isEmpty(props.educationCachedData.cacheData.educationCheck[props.currentSavingIndex])
        ) {
          const eduData =
            props.educationCachedData.cacheData.educationCheck[props.currentSavingIndex];
          propsValue = {
            ...eduData.step1
          };
          propsValue.validStartDate = eduData?.step1?.datesAttended?.startDate
            ? moment(moment(eduData?.step1?.datesAttended?.startDate, 'MM/YYYY', true)).isValid()
            : true;
          propsValue.validEndDate = eduData.step1.datesAttended.endDate
            ? moment(moment(eduData.step1.datesAttended.endDate, 'MM/YYYY', true)).isValid()
            : true;
          // console
          let dates = mapDateFormat(
            eduData.step1.datesAttended.startDate,
            eduData.step1.datesAttended.endDate,
            false
          );
          propsValue.startDate = dates.startDate;
          propsValue.endDate = dates.endDate;

          delete propsValue['datesAttended'];
        } else {
          setPropsValueToBlank();
        }
      } else {
        setPropsValueToBlank();
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      let valuesCopy = Object.assign({}, values),
        finalCacheData = {};

      const finalStepObj = {};

      let callAPIOrNot = true;
      valuesCopy = {
        ...valuesCopy,
        datesAttended: { ...mapDateFormat(values.startDate, values.endDate, true) }
      };

      delete valuesCopy['startDate'];
      delete valuesCopy['endDate'];

      if (
        props.educationCachedData &&
        props.educationCachedData.cacheData &&
        props.educationCachedData.cacheData.educationCheck &&
        props.educationCachedData.cacheData.educationCheck.length
      ) {
        // If current saving index exists in cache API
        if (props.educationCachedData.cacheData.educationCheck[props.currentSavingIndex]) {
          finalStepObj['educationCheck'] = [...props.educationCachedData.cacheData.educationCheck];
          finalStepObj['educationCheck'][props.currentSavingIndex]['step1'] = { ...valuesCopy };
          if (
            props.isEdit &&
            (props.educationCheck[props.currentSavingIndex].step1.educationLevel !==
              valuesCopy.educationLevel ||
              !isEqual(
                valuesCopy.datesAttended,
                props.educationCheck[props.currentSavingIndex].step1.datesAttended
              ))
          ) {
            callAPIOrNot = false;
            const saveObject = getFinalSavingObject(
              props,
              props.isEdit ? '5' : '5#2',
              finalStepObj,
              true
            );
            finalCacheData = {
              saveObject
            };

            if (
              isEmpty(props.temporaryEducation) ||
              (!isEmpty(props.temporaryEducation) &&
                !isEqual(props.temporaryEducation, finalCacheData))
            ) {
              props.invalidateCertificateUpload();
              props.invalidateGradesheetUpload();
              props.setTemporaryEducationData(finalCacheData);
              resetForm();
            }
          } else {
            props.invalidateTemporaryEducationData();
            finalStepObj['educationCheck'][props.currentSavingIndex].packageCurrentSubstep = 2;
            const saveObject = getFinalSavingObject(
              props,
              props.isEdit ? '5' : '5#2',
              finalStepObj
            );
            finalCacheData = {
              saveObject
            };
            resetForm();
          }
        } else if (
          // If current saving index is not existing in cache API
          !props.educationCachedData.cacheData.educationCheck[props.currentSavingIndex]
        ) {
          finalStepObj['educationCheck'] = [...props.educationCachedData.cacheData.educationCheck];
          finalStepObj['educationCheck'][props.currentSavingIndex] = {
            step1: { ...valuesCopy },
            packageCurrentSubstep: 2
          };

          const saveObject = getFinalSavingObject(props, '5#2', finalStepObj);

          finalCacheData = {
            saveObject
          };
        }
      } else {
        // If educationCheck key is not existing in cache
        finalStepObj['educationCheck'] = [];
        finalStepObj['educationCheck'][props.currentSavingIndex] = {
          step1: { ...valuesCopy },
          packageCurrentSubstep: 2
        };

        const saveObject = getFinalSavingObject(props, '5#2', finalStepObj);

        finalCacheData = {
          saveObject
        };
      }

      if (callAPIOrNot) {
        props
          .setBgvCache(finalCacheData)
          .then(() => {
            setSubmitting(false);

            // logger code
            const loggerObj = {
              message: `Education step1 is ${props.isEdit ? 'Edited' : 'Saved'}`,
              data: JSON.stringify(finalCacheData),
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'Education details#1',
              type: 'info'
            };

            logger.push(loggerObj);
            props.nextSubStep();
            resetForm();
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);

            // logger code
            const loggerObj = {
              message: getErrorMessage(error),
              data: JSON.stringify(finalCacheData),
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'Education details#1',
              type: 'error'
            };

            logger.push(loggerObj);
            errToastMessage(error);
          });
      } else {
        props.nextSubStep();
      }
    },
    displayName: 'Education Detail'
  })
);
