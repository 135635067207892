// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ymil6sy15UxbuiSXnjdY {
  margin: auto;
}
.ymil6sy15UxbuiSXnjdY thead {
  background-color: #f2f8ff;
}
.ymil6sy15UxbuiSXnjdY thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 21px 23px 16px 24px;
}
.ymil6sy15UxbuiSXnjdY .S5m45gOUuRQ1zHpaZW2O {
  color: #007bff;
  cursor: pointer;
}
.ymil6sy15UxbuiSXnjdY tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.ymil6sy15UxbuiSXnjdY tbody tr {
  cursor: default;
}
.ymil6sy15UxbuiSXnjdY tbody tr td {
  border: 0px;
  vertical-align: middle !important;
  padding: 18px 23px 19px 24px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: left;
}
.ymil6sy15UxbuiSXnjdY tbody tr .o0y2Axr_W2KxMosIAQyf {
  max-width: 400px;
}
.ymil6sy15UxbuiSXnjdY tbody tr:hover {
  background-color: #f5f5f5;
}

.SvnA4qPDNxPLkN8jHb05 {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.SvnA4qPDNxPLkN8jHb05 .FF1g_lAZa52y28mCXPI0 {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.SvnA4qPDNxPLkN8jHb05 .FF1g_lAZa52y28mCXPI0 .OBXIwXE5MDjnzSlbPB6J {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px !important;
}
.SvnA4qPDNxPLkN8jHb05 .FF1g_lAZa52y28mCXPI0 .OBXIwXE5MDjnzSlbPB6J:focus {
  outline-color: #2855ff !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logsTable": `ymil6sy15UxbuiSXnjdY`,
	"browserInformation": `S5m45gOUuRQ1zHpaZW2O`,
	"mainField": `o0y2Axr_W2KxMosIAQyf`,
	"paginationWrapper": `SvnA4qPDNxPLkN8jHb05`,
	"showPagination": `FF1g_lAZa52y28mCXPI0`,
	"selectPagination": `OBXIwXE5MDjnzSlbPB6J`
};
export default ___CSS_LOADER_EXPORT___;
