// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zob3bygpJnRylERwIB46 {
  margin-top: 8px;
}
.zob3bygpJnRylERwIB46 img {
  margin-left: 175px;
}
.zob3bygpJnRylERwIB46 .wxOGt4wtUSdOtaq3A9r1 {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #333333;
  margin: 12px 0 0px;
  text-align: center;
}
.zob3bygpJnRylERwIB46 .twnLrTsj5KfLp8ZzZ1l2 {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #999999;
  margin-top: 8px;
}

.UVLOdLbWC2QmQrmlOqIC {
  margin: auto;
  margin-top: 32px;
  height: 40px;
  width: 130px;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.o16ABNdK5UDlMeToWr8x {
  display: flex;
  margin-top: 30px;
  justify-content: center !important;
}

.lxfozkFUb2JieC9AnE4u {
  display: flex;
  margin-top: 32px;
  margin-right: 66px;
}
.lxfozkFUb2JieC9AnE4u .LEARxhK49tr2s7PQV_vq {
  height: 48px;
  width: 136px;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 16px;
}
.lxfozkFUb2JieC9AnE4u .mmYFCgi6px0DN9PXIIYL {
  height: 48px;
  width: 136px;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 80px;
}
.lxfozkFUb2JieC9AnE4u .p_XfOWvslu4_L7ix0GVv {
  height: 48px;
  width: 136px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": `zob3bygpJnRylERwIB46`,
	"content": `wxOGt4wtUSdOtaq3A9r1`,
	"subContent": `twnLrTsj5KfLp8ZzZ1l2`,
	"button": `UVLOdLbWC2QmQrmlOqIC`,
	"footer": `o16ABNdK5UDlMeToWr8x`,
	"buttonFooter": `lxfozkFUb2JieC9AnE4u`,
	"submitButton": `LEARxhK49tr2s7PQV_vq`,
	"okayButton": `mmYFCgi6px0DN9PXIIYL`,
	"cancelButton": `p_XfOWvslu4_L7ix0GVv`
};
export default ___CSS_LOADER_EXPORT___;
