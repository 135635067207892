import React from 'react';
import classes from './WalletPackageSummary.module.scss';
import { getPackageUnitIndication } from '../../../utils/utilities';
import { Badge } from 'react-bootstrap';
const Package = (props) => {
  const { packageDetails, onViewCheckClick } = props;
  const renderPackageUnitIndication = (leastTier, currentBalance, packageType) => {
    const unitIndication = getPackageUnitIndication(leastTier, currentBalance, 0, packageType);
    switch (unitIndication) {
      case 'NILL':
        return (
          <Badge pill className={classes.criticalPill}>
            Critical balance
          </Badge>
        );
      case 'LOW':
        return (
          <Badge pill className={classes.lowPill}>
            Low balance
          </Badge>
        );
      default:
        return '';
    }
  };
  return (
    <div className={classes.packageCard}>
      <div className={classes.packageHeader}>
        <div className={classes.packageHeaderTitle}>
          <div>
            <span className={classes.sectionTitle}>{packageDetails[0].packageName}</span>
          </div>
          <div>
            <span className='ml-2'>
              {renderPackageUnitIndication(
                packageDetails[0]?.leastRechargeUnitsTier,
                packageDetails[0]?.currentBalance,
                packageDetails[0]?.packageType
              )}
            </span>
          </div>
        </div>
        <div className={classes.packageHeaderUnit}>
          {packageDetails[0].currentBalance} Unit{packageDetails[0].currentBalance > 1 ? 's' : ''}
        </div>
      </div>
      <div>
        <span
          onClick={(e) => {
            e.stopPropagation();
            onViewCheckClick();
          }}
          className={classes.viewCheck}
        >
          View checks
        </span>
      </div>
    </div>
  );
};

export default Package;
