// React and other libraries imports
import React, { useState, useEffect } from 'react';
import { isEmpty, has, isEqual } from 'lodash';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';

// JS libraries imports
const stateAndCity = require('country-state-city');
const lookup = require('country-code-lookup');

// Core components and utils imports
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import BGVConfirmDialog from '../../../CommonComponent/BGVConfirmDialog';
import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import ProgressBar from '../../../../../core-components/ProgressBar';
import Image from '../../../../../core-components/Image';
import { getTrimmedValue } from '../../../../../utils/utilities';
import { COUNTRY_CODES } from '../../../../../utils/commonConstant';
import { capitalizeName } from '../../../../CompanyAdmin/frontend-common/utils/Utilities';

// Styles import
import styles from './ConsentDetails.module.scss';

const getCityValue = (data) => {
  if (!isEmpty(data) && data.endsWith('*')) {
    return data.slice(0, -1);
  }
  return data;
};

export default (props) => {
  const [isConsentOnly, setIsConsentOnly] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [country, setCountry] = useState({
    label: 'India',
    value: 'India'
  });
  const [countries, setCountries] = useState(null);

  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    props.invalidateConsentData();
    setIsConsentOnly(props.isConsentOnly);

    const res = stateAndCity.Country.getAllCountries().map((obj) => obj.name);
    const countryOptions = res.map((data) => ({
      label: data,
      value: data
    }));

    setCountries(countryOptions);
  }, []);

  /*
   *This useEffect retrieves a list of cities for a selected country and generates city options for a dropdown menu.
   */
  useEffect(() => {
    if (country) {
      const countryDetails = lookup.byCountry(country.value);
      let countryCode;

      if (countryDetails) {
        countryCode = countryDetails.iso2;
      } else {
        countryCode = COUNTRY_CODES[country];
      }

      const val = getNames(stateAndCity.City.getCitiesOfCountry(countryCode));

      let cityOptions = val.map((city) => ({
        label: city,
        value: city
      }));

      setIsOtherSelected(false);
      cityOptions.push({ label: 'Other', value: 'OTHER' });

      setCities(cityOptions);
    }
  }, [country]);

  useEffect(() => {
    if (selectedCity?.value === 'OTHER') {
      setIsOtherSelected(true);
      setSelectedCity(null);
      props.setFieldValue('place', '');
    }
  }, [selectedCity]);

  useEffect(() => {
    const city = getCityValue(values?.place || '');

    if (!isEmpty(city)) {
      if (!isEmpty(cities)) {
        // Check for drop-down values
        if (checkCityList(city)) {
          if (!isEqual(city, selectedCity?.value)) setSelectedCity({ label: city, value: city });
        } else {
          setSelectedCity(null);
        }
      }
    }
  }, [props.values, cities]);

  const selectCountry = (val) => {
    setCountry(val);
    props.setFieldValue('place', '');
    setSelectedCity(null);
    setCities(null);
  };

  const getNames = (arr) => {
    return arr.map((obj) => obj.name);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    props.setFieldValue('place', selectedOption?.value);
  };

  const handleCountryChange = (selectedOption) => {
    selectCountry(selectedOption);
    props.setFieldValue('place', '');
  };

  const renderHeading = () => {
    const indexing =
      props.totalSteps
        .map((val) => {
          return val?.id;
        })
        .indexOf(-3) + 1;

    return isConsentOnly ? (
      <span className={styles.consentDetailHeading}>Consent Form</span>
    ) : !isMobileOnly && !isConsentOnly ? (
      <>
        <span className={styles.consentDetailHeading}>Consent Form</span>
        <div className={styles.consentDetailProgressContainer}>
          <span className={styles.stepText}>
            {indexing} of {props.totalSteps.length}
          </span>
          <ProgressBar max={props.totalSteps.length} min={0} now={indexing} />
        </div>
      </>
    ) : (
      <>
        <BgvSecureText />
        <div className={styles.consentDetailProgressContainerMobile}>
          <span className={styles.consentDetailHeading}>Consent Form</span>
          <span className={styles.stepTextMobile}>
            {indexing} of {props.totalSteps.length}
          </span>
        </div>
        <ProgressBar max={props.totalSteps.length} min={0} now={indexing} />
      </>
    );
  };

  const renderAlmostDoneText = () => {
    return (
      <div className={styles.almostDoneText}>
        {isConsentOnly ? (
          <>
            <span>Please sign the SpringVerify consent form</span>
            <p>
              Enter your name and current city to sign the SpringVerify background verifcation
              consent form below
            </p>
          </>
        ) : (
          <>
            <span>You’re almost done!</span>
            <p>
              Please enter your name and current city to the sign & submit the consent form below.
            </p>
          </>
        )}
      </div>
    );
  };

  const renderConsentFormView = () => {
    return (
      <>
        <Row>
          <Col lg={10} md={12} sm={12} xs={12}>
            <Row className={styles.consentFormView}>
              <Col lg={8} md={8} sm={6} xs={6} className={styles.textContainer}>
                <p>Consent Form</p>
                <a
                  href={
                    'https://springverify-assets-id.s3.amazonaws.com/SpringRole-Terms-of-Consent-Form.pdf'
                  }
                  target='_blank'
                >
                  View
                </a>
              </Col>
              <Col lg={4} md={4} sm={6} xs={6} className={styles.imageContainer}>
                <Image name='consent_form.svg' />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const renderAgreeText = () => {
    return (
      <p>
        By selecting <strong>'Yes, I agree'</strong> below, you agree to sign SpringVerify India’s{' '}
        <a
          href='https://springverify-assets-id.s3.amazonaws.com/SpringRole-Terms-of-Consent-Form.pdf'
          target='_blank'
        >
          consent form
        </a>
        . You also confirm that the information you have provided for your BGV check is accurate to
        the best of your knowledge.
      </p>
    );
  };

  const handleConfirmSubmit = (childProps) => {
    childProps.handleSubmit();
  };
  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    props.setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };
  const handleConfirmModal = () => {
    setShowConfirmation(!showConfirmation);
    props.setSubmitting(false);
  };

  const handleBlur = (e) => {
    props.handleBlur(e);
    if (props.bgvCachedData && !props.isConsentOnly) {
      if (
        props.bgvCachedData.cacheData?.basicDetails['fullName']?.toLowerCase() !==
        getTrimmedValue(props.values.name.toLowerCase(), true)
      ) {
        props.setFieldValue('isNameValid', false);
        setErrorMessage(
          'Your name should match with the Basic Details Section (' +
            props?.bgvCachedData?.cacheData?.basicDetails['fullName'] +
            ')'
        );
      } else {
        props.setFieldValue('isNameValid', true);
        setErrorMessage(null);
      }
    } else {
      if (
        props?.initialCandidateDetails &&
        props?.initialCandidateDetails?.['basicDetails']?.['fullName']?.toLowerCase() !==
          getTrimmedValue(props.values.name.toLowerCase(), true)
      ) {
        props.setFieldValue('isNameValid', false);
        setErrorMessage(
          'Your name should match with the Basic Details Section (' +
            props?.initialCandidateDetails?.['basicDetails']?.['fullName'] +
            ')'
        );
      } else {
        props.setFieldValue('isNameValid', true);
        setErrorMessage(null);
      }
    }
  };
  const getCompanyMessage = () => {
    if (props.isCompany && !props.initialCandidateDetails.basicDetails.consentLetterUrl) {
      return (
        <>
          <p className={styles.consentSubText}>
            Consent has been sent to candidate to fill in the consent form page. You can go ahead
            and submit your details now.
          </p>
        </>
      );
    } else if (props.isCompany && props.initialCandidateDetails.basicDetails.consentLetterUrl) {
      return (
        <>
          <p className={styles.consentSubText}>
            You have entered the consent. You can go ahead and submit your details now.
          </p>
        </>
      );
    } else if (!values.isConsentForm) {
      return (
        <>
          <p className={styles.consentSubText}>
            {props?.config?.company?.name} has provided us with your signed{' '}
            <a
              href={props.initialCandidateDetails['basicDetails']['consentLetterUrl']}
              target='_blank'
            >
              consent form
            </a>{' '}
            for running the background verification. You can go ahead and submit your details now.
          </p>
        </>
      );
    }
  };

  const { handleSubmit, isSubmitting, values, errors } = props;
  const enableBtn =
    !isSubmitting &&
    values.name &&
    values.place &&
    isEmpty(errors) &&
    (!has(values, 'isNameValid') || (has(values, 'isNameValid') && values.isNameValid))
      ? true
      : false;

  // Check if the entered city exists in the list of options.
  const checkCityList = (val) => {
    if (isEmpty(cities)) return false;

    return cities.some((object) => object.value === val);
  };

  return (
    <Card className={styles.consentDetailsCard}>
      <Card.Body className={styles.consentDetailsCardBody}>
        {values.isConsentForm ? (
          <>
            {renderHeading()}
            {renderAlmostDoneText()}
            {renderConsentFormView()}
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} xs={12} md={8} lg={8}>
                  <Form.Label>
                    {' '}
                    Full Name <span>*</span>
                  </Form.Label>
                  <Field
                    type='text'
                    name='name'
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      handleBlur(e);
                      setValue('name', capitalizeName(values?.name), true);
                    }}
                    className={
                      !errors.name && has(values, 'isNameValid') && !values.isNameValid
                        ? styles.nameError
                        : ''
                    }
                  />
                  {!errors.name && has(values, 'isNameValid') && !values.isNameValid && (
                    <div className={styles.emailErrorMsg}>{errorMessage}</div>
                  )}
                </Col>
              </Row>
              <Form.Row>
                <Col lg={4} md={4}>
                  <Form.Label>
                    Select Country<span className={styles.redColor}>*</span>
                  </Form.Label>
                  <div className={styles.dropdown}>
                    <Select
                      menuPosition={'fixed'}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={countries}
                      value={country}
                      onChange={handleCountryChange}
                      isSearchable
                    />
                  </div>
                </Col>
                <Col lg={8} md={8}>
                  <Form.Label>
                    Select City <span>*</span>
                  </Form.Label>

                  <div className={styles.citySelectorParentDiv}>
                    {isOtherSelected || isEmpty(cities) ? (
                      <Field
                        type='text'
                        name='place'
                        placeholder='Type city name'
                        value={getCityValue(values?.place || '')}
                        onChange={(e) => {
                          let value = e.target?.value?.replace(/[^0-9a-zA-Z\s\-.]/g, '');

                          value = value.slice(0, 120);

                          props.setFieldValue('place', value);
                        }}
                        onBlur={() => {
                          if (isEmpty(props.values.place)) {
                            setIsOtherSelected(false);
                          }
                          if (!isEmpty(props.values.place) && !props.values.place.endsWith('*'))
                            props.setFieldValue(
                              'place',
                              capitalizeName(getTrimmedValue(props.values.place, true)) + `*`
                            );
                        }}
                        component={ValidatedFormInputField}
                      />
                    ) : (
                      <Select
                        menuPosition={'fixed'}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        options={cities}
                        value={selectedCity}
                        onChange={handleCityChange}
                        isClearable
                        noOptionsMessage={() => 'No city found, please select Other'}
                        placeholder={'Select city name'}
                        isSearchable
                      />
                    )}
                  </div>
                </Col>
              </Form.Row>

              <Row className={styles.agreeText}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {renderAgreeText()}
                </Col>
              </Row>

              {/* footer part */}
              <Row>
                <Col sm={12} xs={12} md={12} lg={12} className={styles.consentDetailSubmitBtn}>
                  {!isConsentOnly && <BgvGoBackBtn handleClick={props.prevStep} />}
                  <Button
                    type='button'
                    className={enableBtn ? styles.activeBtn : styles.disabledBtn}
                    disabled={!enableBtn}
                    onClick={() => {
                      if (isConsentOnly) {
                        props.handleSubmit();
                      } else {
                        setShowConfirmation(true);
                      }
                    }}
                  >
                    Yes, I agree
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            {renderHeading()}
            <Form onSubmit={handleSubmit}>
              <Row className={styles.consentDoneContainer}>
                <Col sm={12} xs={12} md={12} lg={12}>
                  <Image name='consentDone.svg' />
                  <h6>You're all done!</h6>
                  {getCompanyMessage()}
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} md={12} lg={12} className={styles.consentDetailSubmitBtn}>
                  <BgvGoBackBtn handleClick={props.prevStep} />
                  <Button
                    type='button'
                    className={styles.activeBtn}
                    onClick={() => {
                      setShowConfirmation(true);
                    }}
                    disabled={!props.bgvCachedData.cacheData}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
        {/* Confirm Dialog */}
        {showConfirmation && (
          <BGVConfirmDialog
            showConfirmation={showConfirmation}
            handleConfirmSubmit={handleConfirmSubmit}
            handleHide={handleConfirmModal}
            enableBtn={values.isConsentForm ? enableBtn : !isSubmitting}
            {...props}
          />
        )}
      </Card.Body>
    </Card>
  );
};
