// Import necessary libraries
import React, { useState } from 'react';
import { Modal, Button, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsInfoCircleFill } from 'react-icons/bs';

// Import styles
import styles from './ModalCommonStyles.module.scss';

// Import other components and images
import LaptopIcon from '../../../../images/laptop-icon.png';
import CustomCounter from '../../../../core-components/CustomCounter';
import { sendSlackNotification } from '../../../../api/company';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { convertToIndianNumeration } from '../../../../utils/utilities';

/**
 * Modal component for buying a laptop.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible or not.
 * @param {Function} props.onHide - Callback function to handle modal close event.
 * @param {Object} props.laptop - The laptop object containing model, display, processor, ram, storage, price, and mrp.
 * @returns {JSX.Element} The rendered BuyLaptopModal component.
 */
const BuyLaptopModal = ({ show, onHide, laptop, setIsSuccessModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    itPocNumber: '',
    specialInstructions: '',
    quantity: 0
  });
  const isValidForm = values.quantity && !loading;
  const { profile, paymentDetails } = useSelector((state) => state);

  const { email, name, company_user_mapping } = profile?.profile;
  const { designation, mobile } = paymentDetails;

  const { company, id } = company_user_mapping;

  const onClickBuyHandler = () => {
    setLoading(true);
    const payload = {
      alert_title: 'SVIN Product Upsell Request',
      icon_emoji: ':computer:',
      color: 'good',
      channel_name: 'sv-in-upsell-requests',
      fields: [
        { field_key: 'Company Name', field_value: `${company?.name} (${id})` },
        { field_key: 'POC Name', field_value: name },
        { field_key: 'POC Phone', field_value: mobile || 'Not Available' },
        { field_key: 'POC Email', field_value: email },
        { field_key: 'POC Designation', field_value: designation || 'Not Available' },
        { field_key: 'IT POC Phone', field_value: values.itPocNumber || 'Not Available' },
        { field_key: 'Laptop Name', field_value: laptop.model },
        {
          field_key: 'Laptop Specification',
          field_value: `Display: ${laptop.display}, Processor: ${laptop.processor}, RAM: ${laptop.ram}, Hard Drive: ${laptop.storage}`
        },
        { field_key: 'Mode', field_value: 'Buy' },
        {
          field_key: 'Price',
          field_value: `₹${
            laptop.mrp
              ? convertToIndianNumeration(parseInt(laptop.mrp))
              : convertToIndianNumeration(parseInt(laptop.price))
          }`
        },
        {
          field_key: 'Special Instruction',
          field_value: values.specialInstructions
        },
        { field_key: 'Quantity', field_value: `${values.quantity}` }
      ]
    };

    sendSlackNotification(payload)
      .then(() => {
        toast.success(`We've received your request. Our team will reach out to you.`);
        setLoading(false);
        setIsSuccessModalOpen(true);
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Failed to send request. Please try again.');
      });

    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size='lg' centered className={styles.rentModal}>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Buy laptop</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.laptopInfo}>
          <div className={styles.laptopIcon}>
            <img src={LaptopIcon} alt='laptop' />
          </div>

          <div className={styles.laptopDetails}>
            <h4>{laptop.model}</h4>
            <Table borderless className={styles.specsTable}>
              <tbody>
                <tr>
                  <td>Display</td>
                  <td>Processor</td>
                  <td>RAM</td>
                  <td>Hard Drive</td>
                </tr>
                <tr>
                  <td className={styles.specificationText}>{laptop.display}</td>
                  <td className={styles.specificationText}>{laptop.processor}</td>
                  <td className={styles.specificationText}>{laptop.ram}</td>
                  <td className={styles.specificationText}>{laptop.storage}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        <hr />
        <Form.Group className='mb-3'>
          <Form.Label className={styles.inputlabel}>Special Instructions</Form.Label>
          <Form.Control
            onChange={(e) => setValues({ ...values, specialInstructions: e.target.value })}
            as='textarea'
            rows={3}
            placeholder='Write any special instructions here'
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label className={styles.inputLabel}>
            Quantity<span>*</span>
          </Form.Label>
          <div className='d-flex align-items-center'>
            <CustomCounter
              isShowLargeSize
              value={values.quantity}
              index={null}
              valueChange={(val) => {
                setValues({ ...values, quantity: val });
              }}
            />
          </div>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label className={`${styles.inputLabel} ${styles.infoLabel}`}>
            IT team contact number (optional)
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  We'll coordinate with your IT team directly for configuration requirements.
                </Tooltip>
              }
            >
              <div className={styles.infoIcon}>
                <BsInfoCircleFill />
              </div>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            onChange={(e) => setValues({ ...values, itPocNumber: e.target.value })}
            type='number'
            placeholder='Enter your IT team contact'
          />
        </Form.Group>

        <hr className={styles.fullWidthLine} />

        <div className={styles.rentInfo}>
          <div>
            <div className={styles.laptopSpecification}>Total Price</div>
            <div className={styles.priceSection}>
              <div className={styles.priceContainer}>
                <span className={styles.price}>
                  <span className='rupee'>₹</span>
                  {convertToIndianNumeration(parseInt(laptop.price))}
                </span>
                {laptop.mrp && (
                  <span className={styles.mrp}>
                    <span className='rupee'>
                      ₹{convertToIndianNumeration(parseInt(laptop.mrp))}
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>

          <Button
            onClick={onClickBuyHandler}
            disabled={!isValidForm}
            variant='primary'
            className={styles.buyBtn}
          >
            Request call back
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BuyLaptopModal;
