// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.SNS0VypYuz0KfN3PVUY_ {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1200px;
  height: 850px;
  border-radius: 4px;
  margin: -145px auto 5px;
  font-family: Poppins, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .SNS0VypYuz0KfN3PVUY_ {
    margin-left: 150px;
  }
}

.SHJkrseYjUgn9qBaLGtc {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.QHexXJ3vJfGfqSVl3_Al {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px;
  padding-bottom: 0px !important;
  border: none !important;
  background-color: white;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
}

.zXg1sU3_8sgmlBMls6PA {
  max-height: 690px;
  overflow-y: auto;
  padding-top: 10px !important;
  padding: 15px;
  padding-bottom: 0 !important;
}

.hyW5wJ4qfODnMxB4csW6 {
  margin-bottom: 20px;
  width: 362px !important;
  height: 320px !important;
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.hyW5wJ4qfODnMxB4csW6 .ByahrLO_6g5zSjJPOYdU {
  height: 143px;
  padding-left: 15px !important;
}
.hyW5wJ4qfODnMxB4csW6 .ByahrLO_6g5zSjJPOYdU .PUiUZQGjDIvQTJbAVYVR {
  display: flex;
  gap: 5px;
}
.hyW5wJ4qfODnMxB4csW6 .ePnyvGMP1L5bWIe6e9DK {
  padding-top: 15px;
  padding-left: 15px;
}
.hyW5wJ4qfODnMxB4csW6 .__UneB2ChHBXg2HiNLNE {
  display: flex;
  height: 176px !important;
  border-bottom: 1px solid #e9ecef;
}
.hyW5wJ4qfODnMxB4csW6 .hxu74WhIXDvcfX4FGxdU {
  width: 174px;
  height: 174px;
  background-color: #ecf2f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hyW5wJ4qfODnMxB4csW6 .gOBFgfOTDRkIXhY0ydQg {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.hyW5wJ4qfODnMxB4csW6 ._cRMYbztwmjpuOVjZcFE {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.hyW5wJ4qfODnMxB4csW6 .t3ZpHI4UvwI2Uu710y2w {
  line-height: 28px;
}
.hyW5wJ4qfODnMxB4csW6 .A4a_MWxbXSYuCckykqH0 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.hyW5wJ4qfODnMxB4csW6 .v321XPZfx8INrRRwF0aw {
  background-color: #2755fe;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 328px;
  height: 44px;
}

.lRDJFiVgrXI66RItH9hs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.INtQdZoCr3uj3qO1qPAG {
  font-weight: bold;
  font-size: 1.2em;
  color: #36aa53;
  margin-bottom: 10px;
}

.Wa2iZGn6AI9IKSiFd02Q {
  text-decoration: line-through;
  color: #333333;
  padding-top: 2px;
}

.IbmuhRsvVXSIqizPMvVA {
  width: 1134px;
  height: 56px;
  background-color: rgba(235, 199, 105, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-left: 15px;
}

.L0WNbxP17XcM5yrCpFSt {
  position: relative;
  top: -2px;
}

.i2oRDQDFdMWLAX2AYuKe {
  color: #333333;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  opacity: 1 !important;
}

._8msjN0sqIx50vmiX4VRg {
  color: #2755fe;
  text-decoration: underline;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
._8msjN0sqIx50vmiX4VRg:hover {
  text-decoration: none;
}

.HAlISg6iOhG6_fBpEX08 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1200px;
  border-radius: 4px;
  height: 295px;
  margin: 10px auto 5px;
  font-family: Poppins, sans-serif;
  padding-top: 15px !important;
  padding-left: 15px !important;
}
@media only screen and (max-width: 1024px) {
  .HAlISg6iOhG6_fBpEX08 {
    margin-left: 150px;
  }
}
.HAlISg6iOhG6_fBpEX08 .Co6mFZ3M_lT11s0erMHj {
  background-color: #ffffff;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: none !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}
.HAlISg6iOhG6_fBpEX08 .wMrJ0Wsy3Jy0Goc5yUUr {
  border: none !important;
}
.HAlISg6iOhG6_fBpEX08 .ofLLj4UvBYPz0C3wvVpw {
  margin-top: 15px;
  padding-top: 0 !important;
}

.HAlISg6iOhG6_fBpEX08 td {
  padding: 13px 0;
  border-bottom: 1px solid #e9ecef;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.HAlISg6iOhG6_fBpEX08 td:first-child {
  font-weight: bold;
  width: 150px;
}
.HAlISg6iOhG6_fBpEX08 tr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.HAlISg6iOhG6_fBpEX08 strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.zVsAR5vsVYNmt2VnNgZG {
  margin-bottom: 20px;
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt {
  background: #ffffff !important;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1200px;
  border-radius: 4px;
  margin: 10px auto 68px;
  font-family: Poppins, sans-serif;
  min-height: 84px;
  padding-left: 15px;
}
@media only screen and (max-width: 1024px) {
  .zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt {
    margin-left: 150px;
  }
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt .Xl9DkVZmNJxIBDT2vaBw {
  background-color: #ffffff !important;
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 20px;
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt .TEgtyzyx1N9JRE6xT1kW {
  padding-top: 0 !important;
  margin-top: 10px;
  padding-left: 25px;
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt .pmKDGoDkMiLo9XVbUDV9 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt .oC_yFU_lqTpq6ERP8qGo {
  background-color: #fff;
  border-bottom: none;
  cursor: pointer;
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt .oC_yFU_lqTpq6ERP8qGo:hover {
  background-color: #f8f9fa;
}
.zVsAR5vsVYNmt2VnNgZG .ExJdu0oXmizMZskcr5yt .oC_yFU_lqTpq6ERP8qGo h3 {
  margin-bottom: 0;
}
.zVsAR5vsVYNmt2VnNgZG .Jwk_3YAPyGOSUlDjzdod {
  padding: 0;
  color: #000;
}
.zVsAR5vsVYNmt2VnNgZG .Jwk_3YAPyGOSUlDjzdod:focus {
  box-shadow: none;
}
.zVsAR5vsVYNmt2VnNgZG .Jwk_3YAPyGOSUlDjzdod .H1EAfYrX490tlRIFv8wQ {
  display: inline-block;
  transition: transform 0.3s;
}
.zVsAR5vsVYNmt2VnNgZG .Jwk_3YAPyGOSUlDjzdod[aria-expanded=true] .H1EAfYrX490tlRIFv8wQ {
  transform: rotate(180deg);
}

.w8xhgwKpwtSowvvpZSkg {
  list-style-type: none;
  padding-left: 0;
}
.w8xhgwKpwtSowvvpZSkg li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.w8xhgwKpwtSowvvpZSkg li:before {
  content: "•";
  position: absolute;
  left: 0;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerCard": `SNS0VypYuz0KfN3PVUY_`,
	"mainCard": `SHJkrseYjUgn9qBaLGtc`,
	"cardHeader": `QHexXJ3vJfGfqSVl3_Al`,
	"laptopList": `zXg1sU3_8sgmlBMls6PA`,
	"laptopCard": `hyW5wJ4qfODnMxB4csW6`,
	"laptopCardBody": `ByahrLO_6g5zSjJPOYdU`,
	"priceContainer": `PUiUZQGjDIvQTJbAVYVR`,
	"laptopDetailsSection": `ePnyvGMP1L5bWIe6e9DK`,
	"laptopCardHeader": `__UneB2ChHBXg2HiNLNE`,
	"laptopIcon": `hxu74WhIXDvcfX4FGxdU`,
	"laptopName": `gOBFgfOTDRkIXhY0ydQg`,
	"laptopSpecification": `_cRMYbztwmjpuOVjZcFE`,
	"lineHeight28": `t3ZpHI4UvwI2Uu710y2w`,
	"laptopSpecificationValue": `A4a_MWxbXSYuCckykqH0`,
	"buyButton": `v321XPZfx8INrRRwF0aw`,
	"priceSection": `lRDJFiVgrXI66RItH9hs`,
	"price": `INtQdZoCr3uj3qO1qPAG`,
	"mrp": `Wa2iZGn6AI9IKSiFd02Q`,
	"requestSection": `IbmuhRsvVXSIqizPMvVA`,
	"searchIconBox": `L0WNbxP17XcM5yrCpFSt`,
	"requestText": `i2oRDQDFdMWLAX2AYuKe`,
	"raiseRequestBtn": `_8msjN0sqIx50vmiX4VRg`,
	"faqCard": `HAlISg6iOhG6_fBpEX08`,
	"faqHeader": `Co6mFZ3M_lT11s0erMHj`,
	"noBorder": `wMrJ0Wsy3Jy0Goc5yUUr`,
	"faqCardBody": `ofLLj4UvBYPz0C3wvVpw`,
	"termsAccordion": `zVsAR5vsVYNmt2VnNgZG`,
	"termsCard": `ExJdu0oXmizMZskcr5yt`,
	"termsHeader": `Xl9DkVZmNJxIBDT2vaBw`,
	"termsCardBody": `TEgtyzyx1N9JRE6xT1kW`,
	"termsTitle": `pmKDGoDkMiLo9XVbUDV9`,
	"card-header": `oC_yFU_lqTpq6ERP8qGo`,
	"accordionToggle": `Jwk_3YAPyGOSUlDjzdod`,
	"caret": `H1EAfYrX490tlRIFv8wQ`,
	"termsList": `w8xhgwKpwtSowvvpZSkg`
};
export default ___CSS_LOADER_EXPORT___;
