// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.rdBQ982nMHFuD9WjUtSp {
  margin-top: 20px;
}

.xFyANb3K9RPmb6ueKx8B {
  color: #f5222d;
}

.E6uE12dsbAZPv7nXRbhu {
  background: #f5f7ff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
  cursor: pointer;
}
.E6uE12dsbAZPv7nXRbhu p {
  font-weight: normal !important;
  margin: 0;
}

._dvcZxRC2bm3fddTqEHU {
  padding: 0;
}
._dvcZxRC2bm3fddTqEHU .d076fQQsxJiM3qyFl66K {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #2755fe;
  padding: 8px 18px;
  margin-bottom: 16px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}
._dvcZxRC2bm3fddTqEHU .d076fQQsxJiM3qyFl66K:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Row": `rdBQ982nMHFuD9WjUtSp`,
	"maxFileError": `xFyANb3K9RPmb6ueKx8B`,
	"certificateInputFileContainer": `E6uE12dsbAZPv7nXRbhu`,
	"takePhotoBtnContainer": `_dvcZxRC2bm3fddTqEHU`,
	"takePhotoBtn": `d076fQQsxJiM3qyFl66K`
};
export default ___CSS_LOADER_EXPORT___;
