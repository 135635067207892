import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Form, Col, Row, Accordion, Card } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { isEmpty, capitalize, toLower } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import CustomValidatedSelect from '../../../../core-components/CustomValidatedSelect';
import countryNames from '../../../../utils/countryNamesEn.json';
import countryOptions from '../../../../utils/countryCodesEn.json';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField';
import ValidatedRadioFields from '../../../../core-components/ValidatedRadioFields';
import Button from '../../../../core-components/Button';
import ValidatedFileSelect from '../../../../core-components/ValidatedFileSelect';
import Checkbox from '../../../../core-components/Checkbox';
import {
  jwtParser,
  getTrimmedValue,
  isTrimmedPhoneValid,
  getTrimmedValueWithNewLines,
  validateCategoriesAndTags,
  getErrorMessage
} from '../../../../utils/utilities';
import {
  checkCandidateEmail as checkCandidateEmailAPI,
  checkCandidatePhone as checkCandidatePhoneAPI,
  checkCandidateEmployeeId as checkCandidateEmployeeIdAPI
} from '../../../../api/company';
import localStorage from '../../../../api/localStorage';
import CategoryModals from '../../CategoryModals';
import AdminModals from '../../Administrators/AdminModals';
import Image from '../../../../core-components/Image';
import cn from 'classnames';
import styles from './BasicDetails.module.scss';
import { allowOnlyNumbersRegex } from '../../../../utils/ValidationSchema';
import {
  getIconName,
  getFileUploadHeaderText,
  FileInfoComponent,
  UPLOAD_STATE
} from './FileImporterUtilities';
import fileImporterStyles from './FileImporter.module.scss';
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import { fileUpload as fileUploadAPI } from '../../../../api/company';
import { capitalizeName } from '../../frontend-common/utils/Utilities';

const tagDropdownStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    borderRadius: '4px',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    color: state.isSelected ? '#fff' : '#666666'
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid hsl(0, 0%, 87%)',
    borderColor: state.selectProps.error ? '#dc3545 !important' : 'hsl(0, 0%, 87%)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#333333',
    display: 'flex',
    width: '100%'
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#333333'
    };
  },
  valueContainer: (base) => ({
    ...base
  }),
  container: (base) => ({
    ...base,
    width: '100%'
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '10'
  })
};

const tagsSeparator = ({ innerProps }) => {
  return <span style={{ display: 'none' }} {...innerProps} />;
};

export default (props) => {
  const ctErrorRef = useRef(null);

  const [isAdmin, setIsAdmin] = useState(localStorage.getAccessLevel() === 'admin');
  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('id') || null
  );
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCatModal, setShowCatModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const [isUploading, setIsUploading] = useState(null);
  const [isConsentUploading, setIsConsentUploading] = useState(null);
  const [resumeError, setResumeError] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [bgvFiller, setBgvFiller] = useState([
    { value: '1', label: 'Ask Candidate', disable: true },
    { value: '2', label: "I'll fill it" }
  ]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [deletedTags, setDeletedTags] = useState([]);
  const [deletedCategoryId, setDeletedCategoryId] = useState('');
  const [deletedTagsError, setDeletedTagsError] = useState(null);
  const [deletedCategoryError, setDeletedCategoryError] = useState(null);
  const [didRender, setDidRender] = useState(false);

  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    setFieldValue,
    ctError,
    setCtError,
    resetForm,
    uploadState,
    setUploadState,
    file,
    setFile
  } = props;
  const charCount = values?.hrInviteNote?.trim()?.length;
  const token = localStorage.getToken();
  const parsedJwtDetail = jwtParser(token);

  const [iconName, setIconName] = useState('upload-grey.svg');
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [draggedOver, setDraggedOver] = useState(false);
  const acceptedTypes = {
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
  };

  useEffect(() => {
    setDidRender(true);
  }, []);

  useEffect(() => {
    setIconName(getIconName(uploadState));
  }, [uploadState]);

  const handleFileUpload = (uploadedFile) => {
    if (uploadedFile) {
      setFile(uploadedFile);
      setIsUploading(true);
      let request = {
        candidateId: parsedJwtDetail?.data?.companyId,
        file: uploadedFile,
        fileType: 'CANDIDATE_RESUME',
        fetchResumeDetails: true
      };
      setUploadState(UPLOAD_STATE.UPLOADING);
      fileUploadAPI(request)
        .then((response) => {
          setFieldValue('resume', file);
          setFieldValue('resumeFile', file);
          setFieldValue('resumeLink', response.data.data);
          if (
            response?.data?.resumeDetails &&
            Object.keys(response?.data?.resumeDetails)?.length > 0
          ) {
            const resumeDetails = response?.data?.resumeDetails;

            if (resumeDetails && Array.isArray(resumeDetails?.phone)) {
              resumeDetails.phone = resumeDetails?.phone?.[0];
            }
            if (Array.isArray(resumeDetails?.email)) {
              resumeDetails.email = resumeDetails?.email?.[0];
            }

            setFieldValue('resumeDetails', resumeDetails);
          }
          setIsUploading(false);
          setUploadState(UPLOAD_STATE.UPLOADED);
        })
        .catch((error) => {
          console.error('error: ', error);
          setUploadState(UPLOAD_STATE.NETWORK_ERROR);
          setFileErrorMessage(getErrorMessage(error));
        });
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    accept: acceptedTypes,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      }
    }
  });

  const handleDrop = (e) => {
    e.preventDefault();
    setDraggedOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (Object.keys(acceptedTypes).includes(file.type)) {
        handleFileUpload(file);
      } else {
        setUploadState(UPLOAD_STATE.FILE_ERROR);
        setFileErrorMessage('Please provide a valid resume format(.pdf, .doc, .docx)');
      }
    }
  };

  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadState(UPLOAD_STATE.FILE_ERROR);
      setFileErrorMessage('Please provide a valid resume format(.pdf, .doc, .docx)');
    }
  }, [fileRejections]);

  const onDeleteUploadedFileHandler = () => {
    setFile(null);
    setUploadState(UPLOAD_STATE.NOT_UPLOADED);
    setFileErrorMessage('');
    setFieldValue('resume', '');
    setFieldValue('resumeFile', null);
    setFieldValue('resumeLink', '');
    resetForm();
  };

  const onTryAgainHandler = () => {
    setUploadState(UPLOAD_STATE.NOT_UPLOADED);
    setFile(null);
  };

  useEffect(() => {
    if (
      (!values.formConfig.isEmailOptional && values.email) ||
      (values.formConfig.isEmailOptional && values.email)
    ) {
      setFieldValue('invite', '1');
      setBgvFiller([
        { value: '1', label: 'Ask Candidate' },
        { value: '2', label: "I'll fill it" }
      ]);
    } else {
      setFieldValue('invite', '2');
      setBgvFiller([
        { value: '1', label: 'Ask Candidate', disable: true },
        { value: '2', label: "I'll fill it" }
      ]);
    }
  }, [values.email]);

  useEffect(() => {
    if (!isEmpty(ctError)) {
      if (ctError?.tagsError) {
        setDeletedTags(ctError?.deletedTags || []);
        setDeletedTagsError(ctError.tagsError);
      }
      if (ctError?.categoryError) {
        setDeletedCategoryId(ctError.deletedCategoryId);
        setDeletedCategoryError(ctError.categoryError);
      }
      if (ctErrorRef.current) {
        ctErrorRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setTimeout(() => {
        setCtError({});
      }, 500);
    }
  }, [ctError]);

  // if we coming back to this page via go back button then set the value based on saved values which user has seected while filling basic details
  useEffect(() => {
    if (props?.isGoingBackToStep1) {
      setFieldValue('invite', props?.savedValues?.invite ? '1' : '2');
    }
  }, [props?.isStep2]);
  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };
  let isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    !values.phoneNumberExists &&
    !isUploading &&
    !isConsentUploading &&
    !values.emailExists &&
    !values.employeeIdExists &&
    values?.isValidPhone &&
    values?.isAltValidPhone &&
    !deletedCategoryError &&
    !deletedTagsError &&
    ((values.email && values.consentDoc) ||
      values.consentLink ||
      values?.isConsentUndertakingLetter === '1') &&
    !resumeError &&
    (values?.hrInviteNote?.trim() ? (charCount >= 10 && charCount <= 160 ? true : false) : true);

  if (values?.consentFile?.name && values?.consentFile?.name !== '') {
    isValidForm &&= !consentError;
  }

  const propsCategory = props?.createCandConfig?.categories
    ? props?.createCandConfig?.categories
    : [];
  let categories = propsCategory;
  const refreshTags = () => {
    props.getAddCandidateConfig(companyId);
  };

  const onChangeInvite = (e) => {
    setFieldValue('invite', e.target.value);
  };

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  const [isCategoriesLoading, setCategoriesLoading] = useState(false);
  const [isTagsLoading, setTagsLoading] = useState(false);

  const getConfigData = async () => {
    try {
      const config = await props.getAddCandidateConfig(companyId);
      setCategoriesLoading(false);
      setTagsLoading(false);
      return config;
    } catch (err) {
      setCategoriesLoading(false);
      setTagsLoading(false);
      console.error('err: ', err);
    }
  };

  const handleCategoriesToggle = () => {
    setCategoriesLoading(true);
    getConfigData();
  };

  const handleTagsMenuOpen = () => {
    setTagsLoading(true);
    getConfigData();
  };

  const handleContinue = async () => {
    const selectedCategoryId = values?.category_id;
    const selectedTags = values?.tags;
    if (!selectedCategoryId && (!selectedTags || selectedTags.length === 0)) {
      handleSubmit();
      return;
    }
    try {
      props.setSubmitting(true);
      const config = await getConfigData();
      const categoriesList = config?.categories;
      const tags = config?.tags;
      const res = validateCategoriesAndTags(values, categoriesList, tags);
      if (isEmpty(res)) {
        handleSubmit();
        return;
      }
      if (res?.tagsError) {
        setDeletedTags(res?.deletedTags || []);
        setDeletedTagsError(res.tagsError);
      }
      if (res?.categoryError) {
        setDeletedCategoryId(res.deletedCategoryId);
        setDeletedCategoryError(res.categoryError);
      }
      props.setSubmitting(false);
    } catch (err) {
      props.setSubmitting(false);
      console.error('handleContinue Error: ', err);
    }
  };

  const validateCategoryOnChange = (value) => {
    if (!value || (value && value !== deletedCategoryId)) {
      setDeletedCategoryError(null);
    }
  };

  const validateTagsOnChange = (value) => {
    if (!value || deletedTags.length <= 0) {
      setDeletedTagsError(null);
      return;
    }
    if (value && deletedTags.length > 0) {
      const selectedIds = value.map((t) => t.id);
      const res = deletedTags.filter((dt) => selectedIds.some((st) => dt.id === st));
      if (res.length <= 0) setDeletedTagsError(null);
    }
  };

  const checkCandidateEmailValid = (e) => {
    checkCandidateEmailAPI(e)
      .then((response) => {
        if (response.data.data.data) {
          setFieldValue('emailExists', true);
        } else {
          setFieldValue('emailExists', false);
        }
      })
      .catch((error) => {
        setFieldValue('emailExists', false);
      });
  };

  const checkCandidateEmployeeIdValid = (e) => {
    checkCandidateEmployeeIdAPI(e).then((response) => {
      if (response.data.data.data) {
        setFieldValue('employeeIdExists', true);
      } else {
        setFieldValue('employeeIdExists', false);
      }
    });
  };

  const checkPhoneNumberValid = (e) => {
    checkCandidatePhoneAPI(e)
      .then((response) => {
        if (response.data.data.data !== null) {
          setFieldValue('phoneNumberExists', true);
        } else {
          setFieldValue('phoneNumberExists', false);
        }
      })
      .catch((error) => {
        setFieldValue('phoneNumberExists', false);
      });
  };

  const cleanPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';

    let cleanedNumber = phoneNumber.replace(/^0+/, '');
    cleanedNumber = cleanedNumber.replace(/^91/, '+91');

    cleanedNumber = cleanedNumber.replace(/[\s-]/g, '');

    if (!cleanedNumber.startsWith('+')) {
      cleanedNumber = `+${cleanedNumber}`;
    }
    return cleanedNumber;
  };

  useEffect(() => {
    // didRender -> dont run on initial render as when coming back from step 2,
    // resume details will throw error if duplicate records are found (by api)
    if (
      values.resumeDetails &&
      !isEmpty(values.resumeDetails) &&
      uploadState === UPLOAD_STATE.UPLOADED &&
      didRender
    ) {
      setFieldValue('name', capitalizeName(values?.resumeDetails?.name) || '');

      const trimmedEmailValue = values.resumeDetails.email
        ? setValue('email', toLower(values.resumeDetails.email), false)
        : '';
      if (trimmedEmailValue) {
        checkCandidateEmailValid(trimmedEmailValue);
      }

      if (values.resumeDetails.phone) {
        const phoneValue = cleanPhoneNumber(values.resumeDetails?.phone);
        if (phoneValue) {
          const tenDigitPhone = phoneValue.length > 10 ? phoneValue.slice(-10) : phoneValue;
          setFieldValue('phone', tenDigitPhone);
          let countryCode = phoneValue?.replace(tenDigitPhone, '') || '+91';
          if (countryCode && countryNames.some((item) => item.value === countryCode)) {
            setFieldValue('countryCode', countryCode);
          } else {
            countryCode = '+91';
          }

          if (tenDigitPhone && countryCode) {
            isTrimmedPhoneValid(countryCode, tenDigitPhone, setFieldValue, 'isValidPhone');
            const intlPhoneNumber = `${countryCode}${tenDigitPhone}`;
            checkPhoneNumberValid(intlPhoneNumber);
          }
        }
      } else {
        setFieldValue('phone', '');
      }

      if (values.resumeDetails.employee_id) {
        const trimmedEmployeeIdVal = setValue('employeeId', values.resumeDetails.employee_id, true);
        if (trimmedEmployeeIdVal && !errors.employeeId) {
          checkCandidateEmployeeIdValid(trimmedEmployeeIdVal);
        }
      } else {
        if (isEmpty(values.employeeId)) setFieldValue('employeeId', '');
      }
      if (isEmpty(values.uanNumber))
        setFieldValue('uanNumber', values?.resumeDetails?.uanNumber || '');
    }
  }, [uploadState]);

  useEffect(() => {
    if (props.savedValues) {
      const fieldsToUpdate = ['name', 'email', 'phone', 'employeeId', 'uanNumber'];

      fieldsToUpdate.forEach((field) => {
        if (props.savedValues[field]) {
          const value =
            field === 'phone' ? props.savedValues[field].slice(-10) : props.savedValues[field];
          setFieldValue(field, value);
          if (field === 'phone') {
            isTrimmedPhoneValid(
              values.countryCode,
              props.savedValues[field].slice(-10),
              setFieldValue,
              'isValidPhone'
            );
          }
        }
      });
    }
  }, [props.savedValues]);

  return (
    <>
      <div
        className={styles.addCandidate}
        onDragOver={(e) => {
          e.preventDefault();
          setDraggedOver(true);
        }}
      >
        {draggedOver && (
          <div
            className={styles.overlay}
            onDragLeave={(e) => {
              e.preventDefault();
              setDraggedOver(false);
            }}
            onDrop={handleDrop}
          >
            <Image name='upload-grey.svg' />
          </div>
        )}
        <div className={styles.form}>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <div className={fileImporterStyles.rowOne} style={{ width: '100%', height: '140px' }}>
                {uploadState === UPLOAD_STATE.NOT_UPLOADED ? (
                  <div {...getRootProps()} className={fileImporterStyles.uploadFileContainer}>
                    {uploadState !== UPLOAD_STATE.UPLOADING ? (
                      <Image name={iconName} />
                    ) : (
                      <Spinner animation='border' variant='secondary' size='lg' />
                    )}
                    <div className={cn(fileImporterStyles.titleText, fileImporterStyles.upload)}>
                      <span className={fileImporterStyles.containerNotUploadedInfoText}>
                        {`Drag and drop resume or `}
                        <span className={fileImporterStyles.browseText}>browse</span>{' '}
                        {!values.formConfig.isResumeOptional ? (
                          <span
                            style={{
                              color: 'red',
                              fontSize: '14px'
                            }}
                          >
                            *
                          </span>
                        ) : null}
                      </span>
                      <div className={fileImporterStyles.subtext}>(Upload .pdf, .doc, .docx)</div>
                    </div>
                    <input
                      {...getInputProps()}
                      style={{ display: 'none' }}
                      accept={'.pdf, .doc, .docx'}
                    />
                    <FileInfoComponent
                      uploadState={uploadState}
                      onDeleteUploadedFile={onDeleteUploadedFileHandler}
                      fileName={file ? file?.name : 'File Name not found'}
                      onTryAgainHandler={onTryAgainHandler}
                      fileErrorMessage={fileErrorMessage}
                    />
                  </div>
                ) : (
                  <div className={fileImporterStyles.uploadFileContainer}>
                    {uploadState !== UPLOAD_STATE.UPLOADING ? (
                      <Image name={iconName} />
                    ) : (
                      <Spinner animation='border' variant='secondary' />
                    )}
                    <div className={cn(fileImporterStyles.titleText, fileImporterStyles.uploaded)}>
                      <span>{getFileUploadHeaderText(uploadState)}</span>
                    </div>
                    <FileInfoComponent
                      uploadState={uploadState}
                      onDeleteUploadedFile={onDeleteUploadedFileHandler}
                      fileName={file ? file?.name : 'File Name not found'}
                      onTryAgainHandler={onTryAgainHandler}
                      fileErrorMessage={fileErrorMessage}
                    />
                  </div>
                )}
              </div>
            </Form.Group>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>
                    Candidate Name (as per ID document) <span>*</span>
                  </Form.Label>
                  <Field
                    type='text'
                    name='name'
                    {...(values?.name ? { isTouchedNotRequired: true } : {})}
                    fieldWithoutSpace={false}
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      props?.handleBlur(e);
                      setValue('name', capitalizeName(values?.name), true);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Employee ID</Form.Label>
                  <Field
                    type='text'
                    name='employeeId'
                    className={values.employeeIdExists ? styles.emailError : null}
                    component={ValidatedFormInputField}
                    {...(values?.employeeId ? { isTouchedNotRequired: true } : {})}
                    onBlur={(e) => {
                      props?.handleBlur(e);
                      const trimmedEmployeeIdVal = setValue('employeeId', values?.employeeId, true);
                      if (trimmedEmployeeIdVal && !errors.employeeId) {
                        checkCandidateEmployeeIdValid(trimmedEmployeeIdVal);
                      }
                      if (trimmedEmployeeIdVal === '') {
                        setFieldValue('employeeIdExists', false);
                      }
                    }}
                  />
                  {!errors.employeeId && values.employeeIdExists && (
                    <div className={styles.emailErrorMsg}>
                      {values.employeeIdExists ? 'Employee Id already exists.' : ''}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Email
                    {!values.formConfig.isEmailOptional ? <span>*</span> : null}
                  </Form.Label>
                  <Field
                    type='text'
                    name='email'
                    className={values.emailExists || values.emailInvalid ? styles.emailError : null}
                    component={ValidatedFormInputField}
                    {...(values?.email ? { isTouchedNotRequired: true } : {})}
                    onBlur={(e) => {
                      props?.handleBlur(e);
                      const trimedEmailValue = setValue('email', toLower(values?.email), false);
                      if (trimedEmailValue && !errors.email) {
                        checkCandidateEmailValid(trimedEmailValue);
                      }
                      if (trimedEmailValue === '') {
                        setFieldValue('emailExists', false);
                      }
                    }}
                  />
                  {!errors.email && values.emailExists && (
                    <div className={styles.emailErrorMsg}>
                      {values.emailExists ? 'Email already exists.' : ''}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Alt. Email</Form.Label>
                  <Field
                    type='text'
                    name='alternateEmail'
                    {...(values?.alternateEmail ? { isTouchedNotRequired: true } : {})}
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      props?.handleBlur(e);
                      setValue('alternateEmail', toLower(values?.alternateEmail), false);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Mobile Number <span>*</span>
                  </Form.Label>
                  <Form.Row>
                    {!isMobileOnly ? (
                      <Field
                        type='text'
                        name='countryCode'
                        defaultValue={values.countryCode}
                        options={countryNames}
                        className='countryCodeDropDown'
                        filterLabel={countryOptions}
                        component={CustomValidatedSelect}
                        onChange={(e) => {
                          if (values.phone && e) {
                            isTrimmedPhoneValid(e, values.phone, setFieldValue, 'isValidPhone');
                            let intlPhoneNumber = `${e + '' + values.phone}`;
                            checkPhoneNumberValid(intlPhoneNumber);
                          }
                        }}
                        onBlur={(e) => {
                          this.props?.handleBlur(e);
                          setValue('phone', values?.phone, false);
                        }}
                      />
                    ) : (
                      <Form.Group
                        as={Col}
                        className={styles.mobCountryCodeDropDown}
                        controlId='formGridPhoneNumber'
                      >
                        <div
                          className={styles.mobileCountryCodes}
                          onClick={() =>
                            this.modalToggle(
                              'countryCode',
                              'mobCountryCodeLabel',
                              values.countryCode
                            )
                          }
                        >
                          {this.state.mobCountryCodeLabel}
                          <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                        </div>
                        {this.state.modalShow && (
                          <CountryCodeModal
                            subShow={this.state.modalShow}
                            SubOnHide={this.modalHide}
                            title={this.state.modalTitle}
                            subTitle={this.state.modalSubTitle}
                            modalLabel={this.state.modalLabel}
                            defaultValue={this.state.defaultValue}
                            handleCodeChange={this.setCountryCodeValue}
                            {...this.props}
                          />
                        )}
                      </Form.Group>
                    )}
                    <Form.Group as={Col} controlId='formGridPhoneNumber'>
                      <Field
                        type='text'
                        name='phone'
                        inputmode='numeric'
                        className={
                          values.phoneNumberExists || !values.isValidPhone
                            ? styles.emailError
                            : null
                        }
                        component={ValidatedFormInputField}
                        fieldWithoutSpace={false}
                        {...(values?.phone ? { isTouchedNotRequired: true } : {})}
                        onBlur={(e) => {
                          props?.handleBlur(e);
                          const trimmedVal = setValue('phone', values?.phone, false);
                          isTrimmedPhoneValid(
                            values.countryCode,
                            values.phone,
                            setFieldValue,
                            'isValidPhone'
                          );
                          if (trimmedVal && values.isValidPhone && values.countryCode) {
                            let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                            checkPhoneNumberValid(intlPhoneNumber);
                          }
                        }}
                        regex={allowOnlyNumbersRegex}
                        isHyphenNotRequired
                      />
                      {!errors.phone && !values.isValidPhone && (
                        <div className={styles.emailErrorMsg}>
                          {!values.isValidPhone ? 'Please provide a valid Phone number.' : ''}
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Alternate Mobile Number</Form.Label>
                  <Form.Row>
                    {!isMobileOnly ? (
                      <Field
                        type='text'
                        name='altCountryCode'
                        defaultValue={values.altCountryCode}
                        options={countryNames}
                        className='countryCodeDropDown'
                        filterLabel={countryOptions}
                        component={CustomValidatedSelect}
                        onChange={(e) => {
                          if (values.altPhone && e) {
                            isTrimmedPhoneValid(
                              e,
                              values.altPhone,
                              setFieldValue,
                              'isAltValidPhone'
                            );
                          }
                        }}
                      />
                    ) : (
                      <Form.Group
                        as={Col}
                        className={styles.mobCountryCodeDropDown}
                        controlId='formGridPhoneNumber'
                      >
                        <div
                          className={styles.mobileCountryCodes}
                          onClick={() =>
                            this.modalToggle(
                              'altCountryCode',
                              'mobAltCountryCodeLabel',
                              values.altCountryCode
                            )
                          }
                        >
                          {this.state.mobAltCountryCodeLabel}
                          <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                        </div>
                      </Form.Group>
                    )}
                    <Form.Group as={Col} controlId='formGridAltPhoneNumber'>
                      <Field
                        type='text'
                        name='altPhone'
                        inputmode='numeric'
                        className={!values.isAltValidPhone ? styles.emailError : null}
                        component={ValidatedFormInputField}
                        {...(values?.altPhone ? { isTouchedNotRequired: true } : {})}
                        onBlur={(e) => {
                          props.handleBlur(e);
                          setValue('altPhone', values?.altPhone, false);
                          isTrimmedPhoneValid(
                            values.altCountryCode,
                            values?.altPhone,
                            setFieldValue,
                            'isAltValidPhone'
                          );
                        }}
                        isHyphenNotRequired
                        regex={allowOnlyNumbersRegex}
                      />
                      {!values.isAltValidPhone && (
                        <div className={styles.emailErrorMsg}>
                          {!values.isAltValidPhone ? 'Please provide a valid Phone Number.' : ''}
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} style={{ maxWidth: '50%' }}>
                <Form.Group>
                  <Form.Label>Universal Account Number</Form.Label>
                  <Field
                    type='text'
                    name='uanNumber'
                    component={ValidatedFormInputField}
                    {...(values?.uanNumber ? { isTouchedNotRequired: true } : {})}
                    onBlur={(e) => {
                      setValue('uanNumber', values?.uanNumber, false);
                      props?.handleBlur(e);
                    }}
                    isHyphenNotRequired
                    regex={allowOnlyNumbersRegex}
                    max={12}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className={styles.formGroups} ref={ctErrorRef}>
                  <Form.Label>Category</Form.Label>
                  <Form.Row>
                    <Select
                      isLoading={isCategoriesLoading}
                      onMenuOpen={handleCategoriesToggle}
                      isClearable={true}
                      styles={tagDropdownStyles}
                      placeholder='No Category'
                      options={categories || []}
                      getOptionLabel={(option) => capitalize(option.categoryName)}
                      getOptionValue={(option) => option.id}
                      noOptionsMessage={() => 'No Categories found'}
                      value={
                        values?.category_id && {
                          id: values.category_id,
                          categoryName: values.category_name
                        }
                      }
                      error={deletedCategoryError}
                      onChange={(value) => {
                        validateCategoryOnChange(value?.id || '');
                        setFieldValue('category_name', value?.categoryName || '');
                        setFieldValue('category_id', value?.id || '');
                      }}
                    />
                    {deletedCategoryError && (
                      <div
                        className={styles.serviceErrorMsg}
                        dangerouslySetInnerHTML={{ __html: deletedCategoryError }}
                      ></div>
                    )}
                  </Form.Row>
                  <Form.Row>
                    {isAdmin ? (
                      <div
                        onClick={() => {
                          setModalDetails({
                            modalTitle: 'Create Category',
                            modalSubTitle: 'Name',
                            modalButton: 'Create',
                            modalPlaceholder: 'Category Name'
                          });
                          setShowCatModal(true);
                        }}
                        className={styles.linkColor}
                      >
                        Add new category
                      </div>
                    ) : null}
                  </Form.Row>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className={styles.formGroups}>
                  <Form.Label>Tag</Form.Label>
                  <Form.Row>
                    <Select
                      isMulti
                      isLoading={isTagsLoading}
                      onMenuOpen={handleTagsMenuOpen}
                      placeholder='No tags'
                      name='tags'
                      components={{ tagsSeparator }}
                      options={
                        props.createCandConfig && props.createCandConfig.tags
                          ? props.createCandConfig.tags
                          : []
                      }
                      getOptionLabel={(option) => capitalize(option.category)}
                      getOptionValue={(option) => option.id}
                      styles={tagDropdownStyles}
                      isClearable={false}
                      error={deletedTagsError}
                      noOptionsMessage={() => 'No Tags found'}
                      value={values.tags}
                      closeMenuOnSelect={false}
                      onChange={(value) => {
                        validateTagsOnChange(value || []);
                        setFieldValue('tags', value);
                      }}
                    />
                    {deletedTagsError && (
                      <div
                        className={styles.serviceErrorMsg}
                        dangerouslySetInnerHTML={{ __html: deletedTagsError }}
                      ></div>
                    )}
                  </Form.Row>
                  <Form.Row>
                    {isAdmin ? (
                      <div
                        onClick={() => {
                          setModalDetails({
                            modalTitle: 'Create Tag',
                            modalSubTitle: 'Name',
                            modalButton: 'Create',
                            modalPlaceholder: 'Tag Name'
                          });
                          setShowCatModal(true);
                        }}
                        className={styles.linkColor}
                      >
                        Add new tag
                      </div>
                    ) : null}
                  </Form.Row>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label className={styles.formConsent}>
                Consent <span>*</span>
              </Form.Label>
              {props?.createCandConfig?.consentType === 'UNDERTAKING_LETTER' && (
                <div className={styles.radioBtn}>
                  <Field
                    name='isConsentUndertakingLetter'
                    component={ValidatedRadioFields}
                    disabled={!props?.createCandConfig?.consentUndertakingLetterUrl}
                    options={[
                      {
                        value: '1',
                        label: `Consent Undertaking Letter by ${values?.companyName}`
                      }
                    ]}
                  />
                  <div className={styles.consentSentence}>
                    Through this undertaking, the client (you) hereby acknowledges and agrees that
                    it is solely responsible for collecting consent and has collected the same from
                    the candidate before proceeding/initiating the background check. By proceeding
                    further you also acknowledge that
                    <br />
                    <br />
                    a) SpringVerify will not collect consent from the candidate or the client (as
                    outlined in the consent undertaking letter)
                    <br />
                    b) The candidate is aware of the verification activity to be performed on them
                    <br />
                    c) The candidate has provided their written consent to the client for the same
                  </div>
                  <Field
                    name='isConsentUndertakingLetter'
                    component={ValidatedRadioFields}
                    options={[
                      {
                        value: '2',
                        label: 'Ignore the Consent Undertaking Letter'
                      }
                    ]}
                  />
                </div>
              )}
              <Row>
                <Col md={6}>
                  {values?.isConsentUndertakingLetter === '2' ? (
                    <div style={{ marginTop: '4px' }}>
                      {!values.email ? (
                        <Field
                          className={styles.consentLabel}
                          custom
                          name='consentDoc'
                          component={Checkbox}
                          label={
                            <div className={styles.noEmailConsent}>
                              <div>Ask candidate for consent</div>
                              <div>
                                (You cannot ask candidate for consent as you have not added an email
                                address)
                              </div>
                            </div>
                          }
                          disabled={!values.email}
                        />
                      ) : (
                        <Field
                          className={styles.consentLabel}
                          custom
                          name='consentDoc'
                          component={Checkbox}
                          label='Ask Candidate for Consent'
                          onChange={() => {
                            setFieldValue('consentDoc', !values.consentDoc);
                            setFieldValue('consentLink', '');
                            setFieldValue('consent', '');
                            setFieldValue('consentFile', null);
                          }}
                        />
                      )}
                      {values.email && !values.consentDoc && (
                        <div className={styles.consentUncheckMsg}>
                          Unchecking this option acknowledges that the consent of the candidate has
                          been taken by you for this verification.
                        </div>
                      )}
                    </div>
                  ) : null}
                </Col>
                <Col md={6}>
                  {values?.isConsentUndertakingLetter === '2' ? (
                    <div>
                      {!values.consentDoc || !values.email ? (
                        <Form.Group>
                          <Form.Label>Upload Manual Consent Document</Form.Label>
                          <Field
                            required={true}
                            name='consent'
                            candidateId={parsedJwtDetail?.data?.companyId}
                            className='consentFile'
                            component={ValidatedFileSelect}
                            setIsUploading={setIsConsentUploading}
                            fileValue={values.consentFile}
                            uploadToServer={'s3Upload'}
                            linkFieldName={'consentLink'}
                            files='consentFile'
                            defaultField={false}
                            handleErrorCallback={(value) => {
                              setConsentError(value);
                            }}
                          />
                          <div className={styles.consentDocErrorMsg}>
                            <ErrorMessage name='consent' />
                          </div>
                        </Form.Group>
                      ) : null}
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label className={styles.required}>
                Who will fill the BGV form? <span>*</span>
              </Form.Label>
              <div className={styles.radioBtn}>
                <Field
                  name='invite'
                  component={ValidatedRadioFields}
                  options={bgvFiller}
                  onChange={(e) => {
                    onChangeInvite(e);
                  }}
                />
                <div className={styles.genderRadioError}>
                  <ErrorMessage name='invite' />
                </div>
              </div>
            </Form.Group>
            {values.invite === '1' && (
              <div className={styles.accordionContainer}>
                <Accordion>
                  <Card className={styles.accordionContainerCard}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='0'
                      className={styles.accordionContainerHeader}
                      onClick={() => decoratedOnClick(0)}
                    >
                      <span className={styles.inviteMailHeading}>
                        Add Note to Candidate (in Invite Email)
                      </span>
                      {activeAccordion === 0 ? (
                        <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                      ) : (
                        <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                      )}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body className={styles.cardBody}>
                        <Form.Group controlId='inviteEmail'>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            name='hrInviteNote'
                            value={values?.hrInviteNote}
                            placeholder='Type a note...'
                            className={styles.accordionTextarea}
                            onInput={(event) => {
                              if (event.target.value.length > 160) {
                                event.target.value = event.target.value.slice(0, 160);
                              }
                            }}
                            onChange={(e) => {
                              setFieldValue('hrInviteNote', e.target.value);
                            }}
                            onBlur={() => {
                              setFieldValue(
                                'hrInviteNote',
                                getTrimmedValueWithNewLines(values.hrInviteNote, true)
                              );
                            }}
                          />
                        </Form.Group>
                        <div
                          className={styles.textareaCharacterLimit}
                          style={
                            charCount == 160 || (charCount < 10 && charCount > 0)
                              ? { color: '#DC3545' }
                              : null
                          }
                        >
                          Characters {charCount} out of 160
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            )}
            <div className={styles.btnGrid}>
              <Button
                className={true ? styles.inviteBtn : styles.disabledButton}
                type='button'
                onClick={handleContinue}
                disabled={!isValidForm}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {showSuccessModal ? (
        <AdminModals
          show={showSuccessModal}
          onHide={() => {
            setShowSuccessModal(false);
          }}
          content={
            modalDetails.modalTitle === 'Create Tag'
              ? 'Tag created successfully'
              : 'Category created successfully'
          }
        />
      ) : null}
      {showCatModal ? (
        <CategoryModals
          show={showCatModal}
          onHide={(value) => {
            if (value === 'reload tag' || value === 'reload category') {
              refreshTags();
              setShowSuccessModal(true);
            }
            setShowCatModal(false);
          }}
          title={modalDetails.modalTitle}
          subTitle={modalDetails.modalSubTitle}
          button={modalDetails.modalButton}
          placeholder={modalDetails.modalPlaceholder}
          toDelete={values}
        />
      ) : null}
    </>
  );
};
