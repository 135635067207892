import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { Field } from 'formik';

import Stars from './Stars';
import Image from '../../../../../core-components/Image';
import Icon from '../../../../../core-components/Icon';
import { toast } from 'react-toastify';

import styles from './Feedback.module.scss';
import { errToastMessage } from '../../../../../utils/utilities';
import UpsellClubBanner from '../../../../CompanyAdmin/Banners/UpsellClubBanner/UpsellClubBanner';
import {
  UPSELL_BANNER_DISPLAY_END_DATE,
  UPSELL_BANNER_DISPLAY_START_DATE
} from '../../../../../utils/commonConstant';

const RED_COLOR = 'red';
const GREEN_COLOR = 'green';
const YELLOW_COLOR = 'yellow';
const GREY_COLOR = 'grey';

const getColorForStars = (number) => {
  switch (true) {
    case number === 5: {
      return GREEN_COLOR;
    }
    case number >= 3 && number < 5: {
      return YELLOW_COLOR;
    }
    case number >= 1 && number < 3: {
      return RED_COLOR;
    }
    default:
      return GREY_COLOR;
  }
};

const getRatingBasedMessage = (color) => {
  let message;
  let emoji;
  const testId = `feedback-message-${color}`;

  switch (color) {
    case RED_COLOR:
      message = 'Oh-no! sorry for the bad experience. What did we get wrong?';
      emoji = (
        <div data-testid='emoji' className={styles.jiggle}>
          😓
        </div>
      );
      break;
    case YELLOW_COLOR:
      message = 'Help us get 5 stars. What could have been better?';
      emoji = (
        <div data-testid='emoji' className={styles.jiggle}>
          😟
        </div>
      );
      break;
    case GREEN_COLOR:
      message = 'Thank you :) What did you like?';
      emoji = (
        <div data-testid='emoji' className={styles.jiggle}>
          🤩
        </div>
      );
      break;
  }

  return (
    <div style={{ display: 'flex' }}>
      <h4 data-testid={testId}>{message}</h4>
      {emoji}
    </div>
  );
};

const openGoogleRating = () => {
  window.location.href = 'https://g.page/r/CYDzpQ3-mneREB0/review';
};
const setClipboard = (text) => {
  const type = 'text/plain';
  const blob = new Blob([text], { type });

  if (!navigator?.clipboard?.write) {
    // Clipboard API not supported in this browser
    errToastMessage('Clipboard API is not supported in this browser!');
    return;
  }

  const data = [new ClipboardItem({ [type]: blob })];
  navigator.clipboard
    .write(data)
    .then(() => {
      toast.info('Copied to Clipboard!');
      openGoogleRating();
    })
    .catch((error) => {
      errToastMessage('Was unable to copy to clipboard!');
      console.log(error);
    });
};

export default (props) => {
  useEffect(() => {
    window.scroll(0, 0);
    props.getBgvCandidateDetails();
    props.invalidateFeedback();
  }, []);

  const [starColor, setStarColor] = useState(GREY_COLOR);

  const currentDate = new Date();

  const handleRateOnGoogle = () => {
    if (props?.values?.message === '') {
      openGoogleRating();
    } else {
      setClipboard(props.values.message.trim());
    }
  };

  const onCLickStarHandler = (number) => {
    props.setFieldValue('stars', number);

    const starColor = getColorForStars(number);

    setStarColor(starColor);
  };

  const MyStars = () => {
    return (
      <Stars
        getColorForStars={getColorForStars}
        color={starColor}
        count={props.values.stars}
        handleClick={onCLickStarHandler}
      />
    );
  };

  const {
    isSubmitting,
    values,
    handleSubmit,
    initialCandidateDetailsFeedback,
    isCompany,
    referralData,
    initialCandidateDetails,
    config
  } = props;
  const enableBtn = !isSubmitting && (values.stars > 0 || values.message) ? true : false;
  const basicDetails =
    initialCandidateDetailsFeedback && !isEmpty(initialCandidateDetailsFeedback)
      ? initialCandidateDetailsFeedback.basicDetails
      : {};
  const isCurrentUserCompanyAdmin = isCompany;
  const getFeedbackText = () => {
    return (
      <span>
        We would be incredibly grateful if you could leave us a 5-Star review on Google as well.
      </span>
    );
  };
  const getExtraText = () => {
    return (
      <span>
        Your review has been copied to your clipboard - just paste in the Google Review and done!
      </span>
    );
  };
  return (
    <>
      {!config?.candidate_bgv_form_config?.is_partnership_banner_disabled &&
        !isCurrentUserCompanyAdmin &&
        currentDate >= UPSELL_BANNER_DISPLAY_START_DATE &&
        currentDate <= UPSELL_BANNER_DISPLAY_END_DATE && (
          <UpsellClubBanner className={styles.upsellImage} />
        )}

      {!isEmpty(referralData) && !referralData?.isSkipped ? (
        <Card className={styles.successReferralCard}>
          <div className={styles.titleContainer}>
            <span className={styles.titleText}>
              You have referred {referralData?.contacts?.length} HR
              {referralData?.contacts?.length > 1 ? 's' : ''}
            </span>
            <Image name='tickCircleGreen.svg' className={styles.titleIcon} />
          </div>
          <div className={styles.infoText}>
            Thanks for connecting HR with us. Your referral is under processing. On successful
            referral, you will receive a <span>gift card worth ₹1,000 </span> on your registered
            Email: <span>{initialCandidateDetails?.contact_details?.email}</span>
          </div>
        </Card>
      ) : null}
      <Card className={styles.feedbackCard}>
        <Card.Body className={styles.feebackCardBody}>
          <Image name='feedback_desktop.svg' />
          <h2 className={styles.allDoneText}>You’re all done here!</h2>
          <section className={styles.verifiedSoonSection}>
            <h4>We’ll get you SpringVerified soon!</h4>
            <p>
              Hooray! Your verification is underway.&nbsp;
              {/* Will add dynamic link later */}
              {!props.isCompany && !isEmpty(basicDetails) && basicDetails.consentLetterUrl ? (
                <>
                  You can view your signed consent form
                  <a
                    href={!isEmpty(basicDetails) ? basicDetails.consentLetterUrl : '#'}
                    target='_blank'
                  >
                    {' '}
                    here
                  </a>
                  .
                </>
              ) : null}
            </p>
          </section>
          <section className={styles.expectSection}>
            <h4>Before we wrap things up...</h4>
            <p>
              A hearty congratulations to you for successfully completing the background check form.
              Now, take a moment for a calming cup of coffee or tea, and unwind, as SpringVerify
              handles the rest of the verification process. ☕
            </p>
          </section>

          {(basicDetails && (basicDetails.isFeedbackSubmitted || props.isFeedbackSubmitted)) ||
          !props?.isShowFeedBackPage ? (
            <Row>
              <Col lg={12} md={12} xs={12} sm={12}>
                <section
                  className={styles.expectSection}
                  style={{ marginTop: '0', display: 'inline-block' }}
                >
                  <h4>Thanks for your feedback!</h4>
                </section>
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: '5px'
                  }}
                >
                  <Icon icon={'TickBGV'} color={'#52C41A'} width={'16'} height={'16'} />
                </div>
              </Col>
              {!isCurrentUserCompanyAdmin && props?.values?.stars === 5 && (
                <div>
                  <Col lg={12} md={12} xs={12} sm={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '14px',
                        color: '#999999'
                      }}
                    >
                      {getFeedbackText()}
                      {props.values.message !== '' && getExtraText()}
                    </div>
                  </Col>
                  <Button className={styles.googleRatingBtn} onClick={handleRateOnGoogle}>
                    Rate us on Google
                  </Button>
                </div>
              )}
            </Row>
          ) : (
            <>
              <section className={styles.howDoingSection}>
                <h4>How did we do? Rate us.</h4>
              </section>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Form className={styles.feedbackForm} onSubmit={handleSubmit}>
                    <Form.Group>
                      <Field name='stars' id='stars' type='number' component={MyStars} />
                    </Form.Group>
                    {props?.values?.stars >= 1 ? (
                      <section className={styles.howDoingSection}>
                        <h4>{getRatingBasedMessage(starColor)}</h4>
                      </section>
                    ) : null}
                    {props?.values?.stars >= 1 ? (
                      <Form.Group controlId='feeback_value_container'>
                        <Field
                          className={styles.feedbackTextarea}
                          name='message'
                          as='textarea'
                          placeholder='Please share your feedback with us'
                          rows={4}
                        />
                      </Form.Group>
                    ) : null}
                    <Button
                      type='submit'
                      className={enableBtn ? styles.activeBtn : styles.disabledBtn}
                      block={isMobileOnly ? true : false}
                      disabled={!enableBtn}
                    >
                      {isSubmitting ? 'Sending...' : 'Send Feedback'}
                    </Button>
                  </Form>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
