import React, { useState } from 'react';
import TableView from '../Components/Table/TableView.jsx';
import abc from './sample.js';
import makeData from '../Components/Table/makeData.js';
import '../bulkuploader.css';
const Step3 = ({
  columns,
  setCurrentStep,
  setFinalData,
  flow,
  config,
  history,
  companyId,
  finalData
}) => {
  let data = makeData(columns, -1, flow);
  // If the user is coming back to this step from select package step show the saved data
  if (finalData?.isBulkUpload) {
    data.data = finalData.tableData;
    const errorArray = [];
    const keyMappings = [
      ['firstName', 'full_name'],
      ['lastName', 'surname'],
      ['phone', 'phone_number'],
      ['empId', 'staff_id'],
      ['email', 'personal_email']
    ];
    data.data.forEach((obj) => {
      errorArray.push(true);
      keyMappings.forEach(([oldKey, newKey]) => {
        if (obj.hasOwnProperty(oldKey)) {
          obj[newKey] = obj[oldKey];
          delete obj[oldKey];
        }
      });
    });
    data.errorArray = errorArray;
  }
  return (
    <div
      className='step3'
      style={{
        width: '100%',
        padding: '0px 30px'
      }}
    >
      {columns && (
        <TableView
          data={data}
          setCurrentStep={setCurrentStep}
          setFinalData={setFinalData}
          flow={flow}
          config={config}
          history={history}
          categoryId={finalData?.categoryTypeId}
          companyIdCA={companyId}
        />
      )}
    </div>
  );
};

export default Step3;
