import React from 'react';
// UI component Import
import { Modal } from 'react-bootstrap';

// Style import
import styles from './AddressActivityLog.module.scss';
import LogsTable from './LogsTable';

const AddressActivityLog = ({ show, onClose, addressId }) => {
  return (
    <Modal size='xl' show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          DIGITAL VERIFICATION ACTIVITY LOGS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <LogsTable addressId={addressId} />
      </Modal.Body>
    </Modal>
  );
};

export default AddressActivityLog;
