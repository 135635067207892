// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L97W8JGqcwImx9t6I_DI {
  width: 100%;
  padding: 0px 25px;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}
.L97W8JGqcwImx9t6I_DI .Fxmz4Ztc97LuJ2I38moT {
  color: #333333 !important;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px !important;
  font-weight: 500 !important;
}
.L97W8JGqcwImx9t6I_DI .Ou5jpg8FL5fuz76Z7MHq {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .fIpDrlMVNG30qhyEatRX {
  padding-left: 10px !important;
  margin-bottom: 30px !important;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .fIpDrlMVNG30qhyEatRX .xpQ83NGfpVSCzg_ZIk2D {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .fIpDrlMVNG30qhyEatRX .XoegHZaKaXWkuxrMvlmg {
  color: #333333;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  margin-bottom: 6px;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .bhi84UkvBjGO6nCVPeEr {
  display: flex;
  align-items: center;
  color: #333333;
  padding: 15px;
  background-color: #f3f5f7;
  border-radius: 10px;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .bhi84UkvBjGO6nCVPeEr .Y8FaU_x9zKmiygZGcijI {
  margin-right: 8px;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .bhi84UkvBjGO6nCVPeEr .Y8FaU_x9zKmiygZGcijI .xpQ83NGfpVSCzg_ZIk2D {
  font-size: 15px;
  font-weight: 500;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .bhi84UkvBjGO6nCVPeEr .Y8FaU_x9zKmiygZGcijI .vblywsHENyLolHzwXsvE {
  font-size: 13px;
  margin-top: 5px;
  color: #5a5a5a;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .bhi84UkvBjGO6nCVPeEr .WnarWnOCoDCp7Rs7cVfs {
  margin-left: 40px;
  background-color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #dadada;
  padding: 7px 12px;
  cursor: pointer;
  color: #5a5a5a;
  font-size: 15px;
  line-height: normal;
}
.L97W8JGqcwImx9t6I_DI .SCjEcGTfaGWViQif_fPn .bhi84UkvBjGO6nCVPeEr .WnarWnOCoDCp7Rs7cVfs:hover {
  border: 1px solid rgb(141, 141, 141);
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn {
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #f8fafc;
  border: 2px dashed;
  border-color: #cbd5e1;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .fsUoel1uGqcdHnqrAwo9 {
  color: #1a1a1a;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .XjCrsZ98jCMFvYmjp4TC {
  display: flex;
  flex-direction: column;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .q2exsyP8TTiS3ojoJQn7 {
  margin-bottom: 2px !important;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .s6juhePyF8YGBfuALEht {
  height: 25px;
  display: flex;
  gap: 3px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .dbP7IbUw_oXwzy_RgI5p {
  max-height: 25px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .L2n22HXuAlyidCYSNrKM {
  display: flex;
  align-items: center;
  padding: 9px 13px;
  width: 270px;
  min-height: 52px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .L2n22HXuAlyidCYSNrKM .pHPUIFTedWT05IoOCuFN {
  display: flex;
  align-items: center;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .L2n22HXuAlyidCYSNrKM .pHPUIFTedWT05IoOCuFN .jqK9egMYp_QqnJyrzoXT {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 173px;
  margin-left: 12px;
  margin-right: 8px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .jLRNsOZCKtQYOmxRC4jO {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .mMBKGsXgV9q3MYpm2Yqn {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 173px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .Z1_z8r4nxzTSJ5uG7pxN {
  color: #666666;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  letter-spacing: 0.1px;
  line-height: 20px;
  text-align: center;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .ed9hS0y6kcLEyKBloHOP {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}
.L97W8JGqcwImx9t6I_DI .sl25T_wwwg6jB9s8HNsn .VBZZs5MFeQUvGqXfl8Zb {
  color: #535961;
  font-family: "Poppins-Italic", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.L97W8JGqcwImx9t6I_DI .KODevkYj6wfHUu_mucFj {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.L97W8JGqcwImx9t6I_DI .BAMMKlU493Pzb7nMoNdP {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}
.L97W8JGqcwImx9t6I_DI .BAMMKlU493Pzb7nMoNdP .xDwuPKqrSGJKiLhHN5Jk {
  color: #2755fe;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 16px;
  width: 142px;
  border-radius: 2px;
}
.L97W8JGqcwImx9t6I_DI .BAMMKlU493Pzb7nMoNdP .xDwuPKqrSGJKiLhHN5Jk:hover {
  color: #ffffff;
}
.L97W8JGqcwImx9t6I_DI .BAMMKlU493Pzb7nMoNdP .IHJceVGLoetDakuBdkIM {
  background-color: #2755fe;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 16px;
  width: 142px;
  border-radius: 2px;
}
.L97W8JGqcwImx9t6I_DI .DrGoOkWehMOuvuHeZkc_ {
  max-width: 1200px;
  margin: auto;
  height: auto;
}
@media only screen and (max-width: 1200px) {
  .L97W8JGqcwImx9t6I_DI .DrGoOkWehMOuvuHeZkc_ {
    width: 1200px;
  }
}
.L97W8JGqcwImx9t6I_DI .DrGoOkWehMOuvuHeZkc_ .W5jKXovRraKzDwpURVgE {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.L97W8JGqcwImx9t6I_DI .DrGoOkWehMOuvuHeZkc_ .W5jKXovRraKzDwpURVgE .Ywne238_A2E5gogg1Y1s {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.L97W8JGqcwImx9t6I_DI .pkTdNiemNDj_LuK2zSc4 {
  margin-top: 12rem;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileImporterWrapper": `L97W8JGqcwImx9t6I_DI`,
	"headerTitle": `Fxmz4Ztc97LuJ2I38moT`,
	"rowOne": `Ou5jpg8FL5fuz76Z7MHq`,
	"rowTwo": `SCjEcGTfaGWViQif_fPn`,
	"info": `fIpDrlMVNG30qhyEatRX`,
	"headerx": `xpQ83NGfpVSCzg_ZIk2D`,
	"infoList": `XoegHZaKaXWkuxrMvlmg`,
	"exContainer": `bhi84UkvBjGO6nCVPeEr`,
	"left": `Y8FaU_x9zKmiygZGcijI`,
	"content": `vblywsHENyLolHzwXsvE`,
	"download": `WnarWnOCoDCp7Rs7cVfs`,
	"uploadFileContainer": `sl25T_wwwg6jB9s8HNsn`,
	"titleText": `fsUoel1uGqcdHnqrAwo9`,
	"flexDiv": `XjCrsZ98jCMFvYmjp4TC`,
	"iconGroup": `q2exsyP8TTiS3ojoJQn7`,
	"containerNotUploadedInfoText": `s6juhePyF8YGBfuALEht`,
	"browseFileUploadForm": `dbP7IbUw_oXwzy_RgI5p`,
	"fileNameCard": `L2n22HXuAlyidCYSNrKM`,
	"body": `pHPUIFTedWT05IoOCuFN`,
	"fileNameText": `jqK9egMYp_QqnJyrzoXT`,
	"browseText": `jLRNsOZCKtQYOmxRC4jO`,
	"uploadedFileName": `mMBKGsXgV9q3MYpm2Yqn`,
	"errorInfoText": `Z1_z8r4nxzTSJ5uG7pxN`,
	"tryAgainText": `ed9hS0y6kcLEyKBloHOP`,
	"fileInfoText": `VBZZs5MFeQUvGqXfl8Zb`,
	"activeLinkText": `KODevkYj6wfHUu_mucFj`,
	"footer": `BAMMKlU493Pzb7nMoNdP`,
	"cancelButton": `xDwuPKqrSGJKiLhHN5Jk`,
	"saveButton": `IHJceVGLoetDakuBdkIM`,
	"alumniWrapperWrapper": `DrGoOkWehMOuvuHeZkc_`,
	"subHeading": `W5jKXovRraKzDwpURVgE`,
	"header": `Ywne238_A2E5gogg1Y1s`,
	"saContainer": `pkTdNiemNDj_LuK2zSc4`
};
export default ___CSS_LOADER_EXPORT___;
