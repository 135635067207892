import * as Yup from 'yup';

export default Yup.object().shape({
  end_date: Yup.string().required('Hold date required.').nullable(),
  reason: Yup.string().required('Hold reason required.').nullable(),
  comment: Yup.mixed().when('reason', {
    is: 'OTHER',
    then: Yup.string()
      .min(10, 'Reason must be at least 10 characters')
      .max(160, 'Reason cannot exceed 160 characters')
      .required('Reason is required')
  })
});
