import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './ContactDetails.validation';
import logger from '../../../../../core-components/Logger';
import {
  getErrorMessage,
  getIntlPhoneNumberObject,
  getIntlPhoneNumber,
  errToastMessage
} from '../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  setBgvSubmission as setBgvSubmissionAPI,
  getBgvCandidateDetails as getBgvCandidateDetailsAPI
} from '../../../../../actions/bgv';
import { getBgvCandidateDetails } from '../../../../../api/bgv';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data)),
    getBgvCandidateDetails: (data) => dispatch(getBgvCandidateDetailsAPI(data))
  };
};

const mapStateToProps = (state) => {
  return {
    cached_data: !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data },
    initial_candidate_details: !isEmpty(state.initialCandidateDetails.data)
      ? { ...state.initialCandidateDetails.data, loading: state.initialCandidateDetails.loading }
      : null,
    bgv_config: !isEmpty(state.bgvPackageConfig.data)
      ? { ...state.bgvPackageConfig.data, loading: state.bgvPackageConfig.loading }
      : null,
    isLoadingBgvCache: state?.bgvCachedData?.loading
  };
};

const formattedSaveObject = (props, values) => {
  let finalCacheObj = {};
  let saveObject = {};

  if (!props?.cached_data) {
    saveObject['cache_data'] = {
      contact_details: {
        ...values
      }
    };
  } else {
    saveObject = {
      ...props?.cached_data,
      cache_data: {
        ...props?.cached_data?.cache_data,
        contact_details: {
          ...values
        }
      }
    };
  }
  finalCacheObj['saveObject'] = {
    ...saveObject
  };
  return finalCacheObj;
};

const getPhoneNumberString = (countryCode, phoneNumber) => {
  let intlNumber = null;
  if (countryCode !== '' && phoneNumber !== '') {
    let phoneNumberwithCode = `${countryCode + phoneNumber}`;
    intlNumber = getIntlPhoneNumber(phoneNumberwithCode);
  }
  return intlNumber;
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const initialContactDetails = props?.initial_candidate_details?.contact_details;
      let storedValues = {
        email: '',
        emailExists: false,
        alternate_email: '',
        country_code: '+91',
        phone_number: '',
        phoneNumberExists: false,
        alt_country_code: '+91',
        alternate_phone_number: '',
        isAltValidPhone: true,
        isValidPhone: true,
        whatsapp_update: false,
        alt_phone_ref: {},
        formConfig:
          props?.bgv_config?.candidate_bgv_form_config || props?.bgv_config?.candidateBgvFormConfig
      };
      if (
        props.cached_data &&
        !isEmpty(props.cached_data) &&
        props.cached_data.cache_data &&
        props.cached_data.cache_data['contact_details']
      ) {
        propsValue = props.cached_data.cache_data['contact_details'];
      } else {
        propsValue =
          (!isEmpty(props.initial_candidate_details) &&
            props.initial_candidate_details['contact_details']) ||
          {};
      }
      if (
        (propsValue?.phone_number && !isEmpty(propsValue?.phone_number)) ||
        (propsValue?.alternate_phone_number && !isEmpty(propsValue?.alternate_phone_number))
      ) {
        if (propsValue.phone_number) {
          let phoneNumberObj = getIntlPhoneNumberObject(
            propsValue.phone_number,
            propsValue.country_code
          );
          if (phoneNumberObj) {
            propsValue.phone_number = phoneNumberObj.nationalNumber;
            propsValue.country_code = `+${phoneNumberObj.countryCallingCode}`;
          }
        }
        if (propsValue.alternate_email) {
          propsValue.alternate_email = propsValue.alternate_email?.toLowerCase();
        }
        if (propsValue.alternate_phone_number && !propsValue.alt_country_code) {
          let altPhoneNumberObj = getIntlPhoneNumberObject(
            propsValue?.alt_phone_ref?.data ?? propsValue.alternate_phone_number
          );
          if (altPhoneNumberObj) {
            propsValue.alternate_phone_number = altPhoneNumberObj.nationalNumber;
            propsValue.alt_country_code = `+${altPhoneNumberObj.countryCallingCode}`;
          }
        } else if (
          initialContactDetails?.candidate_metadata &&
          isEmpty(propsValue?.alt_phone_ref)
        ) {
          const altPhoneNumberMeta = initialContactDetails?.candidate_metadata?.find(
            (p) => p.belongs_to === 'CANDIDATE' && p.type === 'PHONENUMBER'
          );
          if (altPhoneNumberMeta) {
            let altPhoneNumberObj = getIntlPhoneNumberObject(altPhoneNumberMeta?.data);
            if (altPhoneNumberObj) {
              propsValue['alt_phone_ref'] = altPhoneNumberMeta;
              propsValue['alternate_phone_number'] = altPhoneNumberObj.nationalNumber;
              propsValue['alt_country_code'] = `+${altPhoneNumberObj.countryCallingCode}`;
            }
          }
        }
        if (
          props.initial_candidate_details &&
          !isEmpty(props.initial_candidate_details) &&
          props.initial_candidate_details['contact_details']
        ) {
          if (propsValue.email)
            propsValue.email =
              props.initial_candidate_details['contact_details'].email ||
              props.cached_data.cache_data['contact_details'].email ||
              '';
        }
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: async (values, { setStatus, setSubmitting, resetForm, props }) => {
      const candidateDetails = await getBgvCandidateDetails();
      let valuesCopy = {
        email: candidateDetails?.data?.data?.contact_details?.email || values.email || null,
        alternate_email: (values.alternate_email && values.alternate_email?.toLowerCase()) || null,
        phone_number: getPhoneNumberString(values.country_code, values.phone_number),
        alternate_phone_number: values.alternate_phone_number
          ? getPhoneNumberString(values.alt_country_code, values.alternate_phone_number)
          : null,
        whatsapp_update: values.whatsapp_update
      };

      if (values?.alt_phone_ref && !isEmpty(values?.alt_phone_ref)) {
        valuesCopy['alt_phone_ref'] = {
          ...values?.alt_phone_ref,
          is_deleted: valuesCopy?.alternate_phone_number ? false : true
        };
      }
      //calling cache API if previous value and new value is diff otherwise we will move to the next step
      const finalCacheObj = formattedSaveObject(props, valuesCopy);

      props
        .setBgvCache(finalCacheObj)
        .then(() => {
          setSubmitting(false);
          const loggerObj = {
            message: 'Contact details Step',
            data: JSON.stringify(finalCacheObj),
            category: `candidate-id-${props.candidateId}`,
            subCategory: 'Contact details',
            type: 'info'
          };
          logger.push(loggerObj);
          props.nextStep(true);
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
          const loggerObj = {
            message: getErrorMessage(error),
            data: JSON.stringify(finalCacheObj),
            category: `candidate-id-${props.candidateId}`,
            subCategory: 'Contact details',
            type: 'error'
          };
          logger.push(loggerObj);
          errToastMessage(error);
        });
    },
    displayName: 'Contact Details'
  })
);
