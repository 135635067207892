import React from 'react';
import moment from 'moment';
import { Field, Formik, Form as FormikForm } from 'formik';
import { Form, Modal } from 'react-bootstrap';

import Button from '../../../../core-components/Button';
import SingleDatePicker from '../../../../core-components/SingleDatePicker';

import styles from './FilterModal.module.scss';

export default (props) => {
  const handleCustomFilter = (values) => {
    props?.onHide(true);
    props?.parentGetCandidate(
      10,
      0,
      props?.filterData?.status,
      props?.filterData?.search,
      props?.filterData?.tag,
      props?.filterData?.userCategory,
      props?.filterData?.days,
      {
        from: moment(values.fromDate).format('YYYY-MM-DD'),
        to: moment(values.toDate).format('YYYY-MM-DD')
      },
      props?.filterData?.showAllCandidate
    );
  };

  const validateFromDate = (value) => {
    let errorMessage;
    if (!value) {
      errorMessage = 'Please select start date';
    } else if (value.fromDate && value.toDate && value.fromDate > value.toDate) {
      errorMessage = `<div className='${styles.errorMsg}'>Please enter correct date range</div>`;
    }
    return errorMessage;
  };

  const validateToDate = (value) => {
    let errorMessage;
    if (!value) {
      errorMessage = 'Please select end date';
    } else if (value.fromDate && value.toDate && value.fromDate > value.toDate) {
      errorMessage = `<div className='${styles.errorMsg}'>Please enter correct date range</div>`;
    }
    return errorMessage;
  };

  const FilterForm = () => (
    <Formik
      initialValues={{ fromDate: null, toDate: null }}
      onSubmit={(values) => {
        if (!validateFromDate(values)) {
          handleCustomFilter(values);
        } else {
          props?.setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, values, isSubmitting }) => {
        return (
          <FormikForm>
            <Modal.Body>
              <Form.Row>
                <Form.Label>{'From'}</Form.Label>
                <Field
                  key={1}
                  validate={validateFromDate}
                  name='fromDate'
                  component={SingleDatePicker}
                  id='fromDate'
                />
                {values.fromDate &&
                  values.toDate &&
                  values.fromDate > values.toDate &&
                  (validateFromDate(values) ? (
                    <div className={styles.errorMsg}>{'Please enter the correct date range'}</div>
                  ) : null)}
                {!!errors.fromDate && touched.fromDate && (
                  <div className={styles.errorMsg}>{errors.fromDate}</div>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Label>{'To'}</Form.Label>
                <Field
                  key={2}
                  validate={validateToDate}
                  name='toDate'
                  component={SingleDatePicker}
                  id='toDate'
                />
                {values.fromDate &&
                  values.toDate &&
                  values.fromDate > values.toDate &&
                  (validateToDate(values) ? (
                    <div className={styles.errorMsg}>{'Please enter correct date range'}</div>
                  ) : null)}
                {!!errors.toDate && touched.toDate && (
                  <div className={styles.errorMsg}>{errors.toDate}</div>
                )}
              </Form.Row>
            </Modal.Body>
            <Modal.Footer className={styles.customFooter}>
              {
                <Button type='submit' disabled={isSubmitting} className={styles.submitButton}>
                  {props?.button}
                </Button>
              }
            </Modal.Footer>
          </FormikForm>
        );
      }}
    </Formik>
  );

  return (
    <Modal
      size='md'
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      dialogClassName='completedFilterModal'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{'Set custom range'}</Modal.Title>
      </Modal.Header>
      <FilterForm />
    </Modal>
  );
};
