// React and Other libraries imports
import React, { useState, useEffect } from 'react';
import { has, isEmpty } from 'lodash';
import { Form, Badge, Spinner, Modal, Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import { pdfjs } from 'react-pdf';

// Core components and utils imports
import ImagePreviewModal from '../../../../CommonComponent/ImagePreviewModal';
import BGVCamera from '../../../../CommonComponent/BGVCamera';
import Button from '../../../../../../core-components/Button';
import Checkbox from '../../../../../../core-components/Checkbox';
import ValidatedFormInputField from '../../../../../../core-components/ValidatedFormInputField';
import Image from '../../../../../../core-components/Image';
import logger from '../../../../../../core-components/Logger';
import AppConstants from '../../../../../../core-components/AppConstants';
import {
  getFileHeader,
  toCamelCase,
  validateImage,
  getTrimmedValue,
  getPassportIdValidity
} from '../../../../../../utils/utilities';
import { ID_DROPDOWN, ID_DOC_TYPE } from '../../../../../../utils/commonConstant';
import { getOcr as getOcrAPI, saveOcr as saveOcrAPI } from '../../../../../../api/bgv';
import PasswordProtectedModal from '../../PasswordProtectedModal';
import { allowOnlyNumbersRegex } from '../../../../../../utils/ValidationSchema';

// Styles imports
import styles from './AddIdModal.module.scss';

export default (props) => {
  //if any then alternate way should be found
  const { setFieldValue, values, config } = props;
  const [name, setName] = useState(null);
  const [ids, setIds] = useState(null);
  let idChecks = [];
  const token = new URLSearchParams(document.location.search).get('token');

  //if only one send one or else if any then send all

  //we have on_link_present on_loading on_Error

  //use props.getBackId to send back id or can do using cache
  //image modal
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imagePreviewURL, setImagePreviewURL] = useState(false);
  //bgv mobile camera
  const [takeIdPhoto, setTakeIdPhoto] = useState(false);
  const [cameraUploadError, setCameraUploadError] = useState(false);

  //ifEndsWithPdf

  const [idTypeChanges, setIdTypeChanges] = useState(false);

  //remove
  const [showRemoveFront, setShowRemoveFront] = useState(false);
  const [showRemoveBack, setRemoveBack] = useState(false);

  //onLoading
  const [onLoadingFront, setOnloadingFront] = useState(false);
  const [onLoadingBack, setOnLoadingBack] = useState(false);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [ocrIdNumberError, setOcrIdNumberError] = useState(false);

  //setFront
  const [Front, setFront] = useState(false);
  const [Back, setBack] = useState(false);
  //onError back
  const [onFrontError, setFrontError] = useState(false);
  const [onBackError, setBackError] = useState(false);
  const [frontMaxError, setFrontMaxError] = useState(false);
  const [backMaxError, setBackMaxError] = useState(false);
  const [frontFileError, setFrontFileError] = useState(false);
  const [backFileError, setBackFileError] = useState(false);
  const [idNumberError, setIdNumberError] = useState(false);
  const [useEffectDone, setUseEffectDone] = useState(true);
  const [update, setUpdate] = useState(false);
  const [previousDDValue, setPreviousDDValue] = useState(props.values.idType);

  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [currentFileFrontOrBack, setCurrentFileFrontOrBack] = useState('');

  const setDropdown = () => {
    if (props.dropdownValue) {
      if (Array.isArray(props.dropdownValue)) {
        props.dropdownValue.map((idValue, index) => {
          idChecks.push(ID_DROPDOWN[idValue]);
        });
      } else {
        setFieldValue('idType', ID_DROPDOWN[props.dropdownValue]);
        setFieldValue('docType', ID_DOC_TYPE[props.dropdownValue]);
        idChecks.push(ID_DROPDOWN[props.dropdownValue]);
      }
      if (!config.candidate_bgv_form_config.include_aadhaar_in_id_check) {
        idChecks = idChecks.filter((ids) => ids?.name !== 'AADHAAR');
      }
      setIds(idChecks);
    }
  };

  useEffect(() => {
    //set the name
    if (
      props?.cachedData &&
      has(props.cachedData, 'cacheData') &&
      has(props.cachedData.cacheData, 'basicDetails')
    ) {
      setName(props.cachedData.cacheData.basicDetails?.fullName);
      setUseEffectDone(false);
    }
    setDropdown();
  }, []);

  //image preiew
  const handleImagePreview = () => {
    setShowImagePreview(!showImagePreview);
  };

  const handleIdPhoto = () => {
    setTakeIdPhoto(!takeIdPhoto);
    setRemoveBack(false);
    setShowRemoveFront(false);
    setCameraUploadError(false);
  };

  //handleUpload for mobile cam
  const handleIdUpload = (formData, isPdf, setCameraSubmitting = null) => {
    //which photo to go where has to be asked
    //we have cam front and cam back
    if (!props.values.frontDocUrl) {
      setOnloadingFront(!onLoadingFront);

      //call s3 link here and get itsresponse and save it in file
      props
        .bgvIdUpload(formData)
        .then(() => {
          setFront(true);
          setCameraSubmitting(false);
          handleIdPhoto();
        })
        .catch((error) => {
          setFrontError(true);
        });
    } else {
      // handleFileSelectBack(formData);
      setOnLoadingBack(!onLoadingBack);
      props
        .bgvIdUpload(formData)
        .then(() => {
          setBack(true);
          setCameraSubmitting(false);
          handleIdPhoto();
        })
        .catch((error) => {
          setBackError(true);
        });
    }
  };

  const handleRefreshFront = () => {
    setOnloadingFront(false);
    setFrontMaxError(false);
    setFrontError(false);
    setFieldValue('frontDocUrl', '');
  };

  const handleRefreshBack = () => {
    setOnLoadingBack(false);
    setBackMaxError(false);
    setBackError(false);
    setFieldValue('backDocUrl', '');
  };

  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };

  // handle ocr response ... if success then save ID Number, frontId and backId
  const handleOcrResponse = (ocrResponse) => {
    if (ocrResponse.data.data.ocrResult) {
      // Identity number
      ocrResponse.data.data.ocrResult.idNumber
        ? setFieldValue('identityNumber', ocrResponse.data.data.ocrResult.idNumber)
        : setFieldValue('identityNumber', props?.values?.identityNumber);

      //  canidate name
      ocrResponse.data.data.ocrResult.name
        ? setFieldValue('identityCandidateName', ocrResponse.data.data.ocrResult.name)
        : setFieldValue('identityCandidateName', props.values.identityCandidateName);

      //  father name
      ocrResponse.data.data.ocrResult.relativeName1
        ? setFieldValue('fatherName', ocrResponse.data.data.ocrResult.relativeName1)
        : setFieldValue('fatherName', props.values.fatherName);

      //  candidate dob
      ocrResponse.data.data.ocrResult.dob
        ? setFieldValue('dob', ocrResponse.data.data.ocrResult.dob)
        : setFieldValue('dob', props.values.dob);

      //  cocr request id
      ocrResponse.data.data.ocrResult.requestId
        ? setFieldValue('requestId', ocrResponse.data.data.ocrResult.requestId)
        : setFieldValue('requestId', props.values.requestId);

      //logger code for ocr successfull
      const loggerObj = {
        message: ` Identity ocr check successfull`,
        data: JSON.stringify(ocrResponse.data.data.ocrResult),
        category: `candidate-id-${props.candidateId}`,
        subCategory: 'Identity modal',
        type: 'info'
      };
      logger.push(loggerObj);
      setIdNumberError(false);
      setOcrIdNumberError(false);
      setOcrLoading(false);
    } else {
      //logger code if fails
      const loggerObj = {
        message: ` Identity ocr check failes`,
        data: JSON.stringify(ocrResponse),
        category: `candidate-id-${props.candidateId}`,
        subCategory: 'Identity modal',
        type: 'error'
      };
      logger.push(loggerObj);
      setOcrIdNumberError(true);
    }
  };

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    if (currentFileFrontOrBack == 'front') {
      setOnloadingFront(false);
    } else if (currentFileFrontOrBack == 'back') {
      setOnLoadingBack(false);
    }
  };

  const submitHandlerPasswordModal = (password = '') => {
    //Function for handling submit password when unable to unlock the file and when successfully saved the password

    setShowPasswordProtectedModal(false);
    if (currentFileFrontOrBack == 'front') {
      setFront(true);
      setFieldValue('frontDocPassword', password);
      setUpdate(true);
    } else if (currentFileFrontOrBack == 'back') {
      setFieldValue('backDocPassword', password);
      setBack(true);
      setUpdate(true);
    }
  };
  //calling ocr api
  useEffect(() => {
    if (
      (props?.values?.singleDoc || (props?.values?.idType?.label === 'PAN' && !useEffectDone)) &&
      update
    ) {
      if (props.values.frontDocUrl) {
        //call ocr
        setOcrLoading(true);
        let data = {
          full_name: name,
          front: props.values.frontDocUrl,
          back: null,
          type: props.values.idType.value,
          candidate_id: props.candidateId
        };
        //after get ocr is called always call save ocr

        getOcrAPI(data)
          .then((response) => {
            let repData = toCamelCase(response);
            if (response?.data?.data?.ocr_result?.status_ocr === 'success') {
              handleOcrResponse(repData);
            }
            //save frontId and backId
            setFieldValue('frontId', repData.data.data.frontId || null);
            setFieldValue('backId', repData.data.data.backId);
            setOcrLoading(false);
          })
          .catch((error) => {
            setFieldValue('frontId', null);
            setIdNumberError(false);
            setOcrLoading(false);
            console.error(error);
          });
      }
    } else {
      if (props?.values?.frontDocUrl && !useEffectDone) {
        //call ocr
        setOcrLoading(true);
        let data = {
          full_name: name,
          front: props.values.frontDocUrl,
          back: props.values.backDocUrl,
          type: props.values.idType.value,
          candidate_id: props.candidateId
        };

        getOcrAPI(data)
          .then((response) => {
            let repData = toCamelCase(response);
            handleOcrResponse(repData);
            //save frontId and backId
            setFieldValue('frontId', repData.data.data.frontId || null);
            setFieldValue('backId', repData.data.data.backId);
            setOcrLoading(false);
          })
          .catch((error) => {
            setFieldValue('frontId', null);
            setIdNumberError(false);
            setOcrLoading(false);
            console.error(error);
          });
      }
    }
  }, [props.values.frontDocUrl, props.values.singleDoc]);

  //setFrontDLink
  useEffect(() => {
    if (Front) {
      let Id = props.uploadedID;
      setFieldValue('frontDocUrl', Id);
      setOnloadingFront(false);

      setFront(false);
    }
  }, [Front]);

  //setBackIdLink
  useEffect(() => {
    if (Back) {
      let Id = props.uploadedID;
      setFieldValue('backDocUrl', Id);
      setOnLoadingBack(false);

      setBack(false);
    }
  }, [Back]);

  //Id Front upload
  const handleFileSelectFront = (e, type, index) => {
    setFieldValue('frontDocPassword', '');

    let fileType = '';
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      setFrontError(false);
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileHeader(header);
      const errMessage = validateImage(blob, fileType);
      const isPdf = fileType === 'application/pdf' ? true : false;
      if (isPdf) {
        setFieldValue('frontPdf', true);
      } else {
        setFieldValue('frontPdf', false);
      }
      if (!errMessage) {
        let formData = new FormData();
        formData.append(`file`, blob);
        formData.append(`file_type`, 'CHECK_DOCUMENT');
        setOnloadingFront(!onLoadingFront);
        props
          .bgvIdUpload(formData)
          .then((res) => {
            try {
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({ data: fileReader.result, password: '' });
                loadingTask.promise
                  .then((pdfDocument) => {
                    setCurrentFileFrontOrBack('');
                    setFront(true);
                    setUpdate(true);
                  })
                  .catch((error) => {
                    if (error.message == 'No password given') {
                      setCurrentFileFrontOrBack('front');
                      setShowPasswordProtectedModal(true);
                    }
                  });
              } else {
                setCurrentFileFrontOrBack('');
                setFront(true);
                setUpdate(true);
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            setFrontError(true);
            setFrontFileError(true);
            setOnloadingFront(false);
          });
      } else {
        setFrontError(true);
        errMessage === 'FILE_SIZE_EXCEEDS' ? setFrontMaxError(true) : setFrontFileError(true);
      }
    };

    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };

  //Id back upload
  const handleFileSelectBack = (e, type, index) => {
    setFieldValue('backDocPassword', '');

    let fileType = '';
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileHeader(header);
      const errMessage = validateImage(blob, fileType);
      const isPdf = fileType === 'application/pdf' ? true : false;
      if (isPdf) {
        setFieldValue('backPdf', true);
      } else {
        setFieldValue('backPdf', false);
      }

      if (!errMessage) {
        let formData = new FormData();
        formData.append(`file`, blob);
        formData.append(`file_type`, 'CHECK_DOCUMENT');
        setOnLoadingBack(!onLoadingBack);
        props
          .bgvIdUpload(formData)
          .then(() => {
            try {
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({ data: fileReader.result, password: '' });
                loadingTask.promise
                  .then((pdfDocument) => {
                    setCurrentFileFrontOrBack('');
                    setBack(true);
                    setUpdate(true);
                  })
                  .catch((error) => {
                    if (error.message == 'No password given') {
                      setCurrentFileFrontOrBack('front');
                      setShowPasswordProtectedModal(true);
                    }
                  });
              } else {
                setCurrentFileFrontOrBack('');
                setBack(true);
                setUpdate(true);
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            setBackError(true);
            setBackFileError(true);
            setOnLoadingBack(false);
          });
      } else {
        setBackError(true);
        errMessage === 'FILE_SIZE_EXCEEDS' ? setBackMaxError(true) : setBackFileError(true);
      }
    };

    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };
  //delete uploaded ID
  const DeleteLink = (IdSide) => {
    if (IdSide === 'FRONT') {
      setShowRemoveFront(false);
      setFieldValue('frontDocUrl', '');
    } else {
      setRemoveBack(false);
      setFieldValue('backDocUrl', '');
    }

    setFieldValue('identityNumber', '');
    setFieldValue('frontId', null);
  };
  const handleIdNumberBlur = (e) => {
    let regexObj = {
      PAN: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
      Aadhaar: /^[2-9]{1}[0-9]{11}$/,
      'Voter ID': /^[a-zA-Z]{3}[0-9]{7}$/
    };
    if (props?.values?.identityNumber) {
      const trimmedIdentityNumVal = setValue(
        'identityNumber',
        props?.values?.identityNumber,
        false
      );

      if (
        props?.values?.idType?.label === 'PAN' ||
        props?.values?.idType?.label === 'Aadhaar' ||
        props?.values?.idType?.label === 'Voter ID'
      ) {
        setFieldValue('identityNumber', trimmedIdentityNumVal?.toUpperCase());
        !trimmedIdentityNumVal.match(regexObj[props.values.idType.label])
          ? setIdNumberError(true)
          : setIdNumberError(false);
      }
      if (props?.values?.idType?.label === 'Passport') {
        setFieldValue('identityNumber', trimmedIdentityNumVal?.toUpperCase());
        const isPassportValid = getPassportIdValidity(trimmedIdentityNumVal);
        isPassportValid?.error ? setIdNumberError(true) : setIdNumberError(false);
      }
      if (props?.values?.idType?.label === 'Driving License') {
        setFieldValue('identityNumber', trimmedIdentityNumVal?.toUpperCase());
      }
    } else {
      !props.values.identityNumber ? setIdNumberError(true) : setIdNumberError(false);
    }
  };

  const getLabelTextForUploadDocument = () => {
    const idType = values?.idType?.label || '';

    switch (idType) {
      case 'PAN':
        return `Add a front photo of your ID`;
      default:
        return `Add a front and back photo of your ID`;
    }
  };
  return (
    <>
      {ids && ids.length ? (
        <Modal
          size={showPasswordProtectedModal ? 'sm' : 'lg'}
          show={props.show}
          onHide={props.onHide}
          dialogClassName='bgvModalDialog'
          className='bgvModal'
          backdropClassName={'customBGVModalBackdrop'}
          centered
        >
          <div style={showPasswordProtectedModal ? { display: 'none' } : { position: 'relative' }}>
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className={styles.identityTitle}>{props.title} ID Proof</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
              <Form>
                <Form.Row>
                  <Col md={12} lg={12}>
                    <p className={styles.warningSubHeading}>
                      Please make sure you upload a clear picture of both sides of the ID. Upload
                      .jpg, .jpeg, .png or .pdf files only.
                    </p>
                  </Col>
                </Form.Row>
                <Form.Row className={styles.row}>
                  <Form.Label>
                    Select your document type<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                </Form.Row>
                <Form.Row>
                  <Col md={8} lg={5}>
                    <Select
                      menuPosition={'fixed'}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={ids}
                      value={props?.values?.idType}
                      onChange={(value) => {
                        setFieldValue('idType', value);
                        setFieldValue(
                          'docType',
                          ID_DOC_TYPE[
                            Object.keys(ID_DROPDOWN).find((key) => ID_DROPDOWN[key].value === value)
                          ]
                        );
                        setIdTypeChanges(!idTypeChanges);
                      }}
                      isDisabled={!Array.isArray(props?.dropdownValue)}
                    />
                  </Col>
                </Form.Row>
                {props.values.idType && (
                  <>
                    <Form.Row className={styles.row}>
                      <Form.Label>
                        {getLabelTextForUploadDocument()} <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                    </Form.Row>
                    {isMobileOnly ? (
                      <Col sm={12} xs={12}>
                        <Row>
                          <Col sm={11} xs={11} className={styles.takePhotoBtnContainer}>
                            <Button
                              className={styles.addBtn}
                              disabled={
                                props.values.frontDocUrl &&
                                (props.values.singleDoc ||
                                  props.values.backDocUrl ||
                                  props.values.idType.label === 'PAN') &&
                                true
                              }
                              onClick={() => {
                                handleIdPhoto();
                                setUpdate(true);
                              }}
                            >
                              Take Photo of ID
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    ) : null}
                    {isMobileOnly && takeIdPhoto && (
                      <BGVCamera
                        type='single'
                        showBgvCameraModal={takeIdPhoto}
                        handleCameraModal={handleIdPhoto}
                        handleSingleUpload={handleIdUpload}
                        isCameraUploadError={cameraUploadError}
                      />
                    )}
                    <Form.Row>
                      <Col md={6} lg={4} sm={6}>
                        {/* Id Front here conditions will come   */}
                        <div
                          className={styles.idPlaceHolder}
                          style={{ background: onFrontError && '#000000' }}
                        >
                          {!props.values.frontDocUrl ? (
                            <>
                              {/* onloading  */}
                              {onLoadingFront && (
                                <Spinner
                                  animation='border'
                                  variant='primary'
                                  style={{ margin: '35px 85px' }}
                                />
                              )}
                              {/* onErrorhandling is left line 266 */}

                              {/* not on loading not on error  */}
                              {!onLoadingFront && onFrontError ? (
                                <>
                                  <div
                                    className={
                                      frontMaxError || frontFileError
                                        ? styles.idMaxError
                                        : styles.idUploadText
                                    }
                                  >
                                    <Image name='BgvErrorUploadDoc.png' />
                                    {frontMaxError ? (
                                      <div className={styles.errorTag}>
                                        {'Upload Failed, please upload a file that exceeds ' +
                                          AppConstants.fileSize +
                                          ' KB'}
                                      </div>
                                    ) : (
                                      <div className={styles.errorTag}>
                                        Upload Failed.{' '}
                                        {frontFileError ? 'File-type not supported' : ''}
                                      </div>
                                    )}

                                    <div
                                      className={styles.errorRefresh}
                                      onClick={handleRefreshFront}
                                    >
                                      <Image
                                        name='BgvErrorRefresh.png'
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                !onLoadingFront && (
                                  <>
                                    <label
                                      style={{ cursor: 'pointer', width: '210px', height: '110px' }}
                                    >
                                      <div className={styles.idUploadText}>
                                        <Image name='BgvIdFront.png' />

                                        {props.values.singleDoc ? (
                                          <div className={styles.tag}>Add document </div>
                                        ) : (
                                          <div className={styles.tag}>Add Front of your ID </div>
                                        )}
                                      </div>
                                      <Form.Control
                                        id='formControlsFile'
                                        className={styles.attachDocBtn}
                                        accept='image/*,application/pdf'
                                        type='file'
                                        label='File'
                                        onChange={handleFileSelectFront}
                                      />
                                    </label>
                                  </>
                                )
                              )}
                            </>
                          ) : (
                            //when image is present
                            // pdf handling is left
                            <>
                              <div className={styles.uploadFunctionality}>
                                <div className={styles.functionBtn}>
                                  {props.values.frontPdf ? (
                                    <>
                                      {' '}
                                      <a
                                        href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.frontDocUrl}`}
                                        target='_blank'
                                      >
                                        <Image
                                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                                          name='BgvIdView.png'
                                        />
                                      </a>{' '}
                                    </>
                                  ) : (
                                    <Image
                                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                                      onClick={() => {
                                        handleImagePreview();
                                        setImagePreviewURL(props.values.frontDocUrl);
                                      }}
                                      name='BgvIdView.png'
                                    />
                                  )}
                                </div>
                                <div
                                  className={styles.functionBtn}
                                  onClick={() => {
                                    setShowRemoveFront(true);
                                  }}
                                  style={{ background: showRemoveFront && 'transparent' }}
                                >
                                  <Image
                                    style={{
                                      display: showRemoveFront && 'none',
                                      marginLeft: '5px',
                                      cursor: 'pointer'
                                    }}
                                    name='BgvIdDelete.png'
                                  />
                                  {showRemoveFront && (
                                    // here call delete api
                                    <Badge
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        DeleteLink('FRONT');
                                      }}
                                      pill
                                      variant='danger'
                                    >
                                      Remove ?
                                    </Badge>
                                  )}
                                </div>
                              </div>
                              {props.values.frontDocPassword && (
                                <OverlayTrigger
                                  key={`password_protected`}
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`password_protected`}>
                                      <p>Password is saved</p>
                                    </Tooltip>
                                  }
                                >
                                  <div className={styles.passwordProtectIcon}>
                                    <Image
                                      style={{
                                        cursor: 'pointer',
                                        height: '24px',
                                        width: '24px'
                                      }}
                                      name='passwordSaved.png'
                                    />
                                  </div>
                                </OverlayTrigger>
                              )}
                              {props.values.frontPdf ? (
                                <Image
                                  name='BgvIdPdf.png'
                                  style={{ margin: '35px 0px 0px 90px', cursor: 'pointer' }}
                                />
                              ) : (
                                <Image
                                  src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.frontDocUrl}`}
                                  style={{ width: '210px', height: '115px', cursor: 'pointer' }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </Col>

                      {props.values.idType.label !== 'PAN' && !props.values.singleDoc && (
                        <Col md={6} lg={4} sm={6}>
                          {/* Id Back here condition will come  */}
                          <div
                            className={styles.idPlaceHolder}
                            style={{ background: onBackError && '#000000' }}
                          >
                            {!props.values.backDocUrl ? (
                              <>
                                {/* onloading  */}
                                {onLoadingBack && (
                                  <Spinner
                                    animation='border'
                                    variant='primary'
                                    style={{ margin: '35px 85px' }}
                                  />
                                )}

                                {/* on error handling pending  */}

                                {/* not on loding or not on error  */}
                                {!onLoadingBack && onBackError ? (
                                  <>
                                    <div
                                      className={
                                        backMaxError || backFileError
                                          ? styles.idMaxError
                                          : styles.idUploadText
                                      }
                                    >
                                      <Image name='BgvErrorUploadDoc.png' />
                                      {backMaxError ? (
                                        <div className={styles.errorTag}>
                                          {'Upload Failed,please upload a file that exceeds ' +
                                            AppConstants.fileSize +
                                            ' KB'}
                                        </div>
                                      ) : (
                                        <div className={styles.errorTag}>
                                          Upload Failed.{' '}
                                          {backFileError ? 'File-type not supported' : ''}
                                        </div>
                                      )}
                                      <div
                                        className={styles.errorRefresh}
                                        onClick={handleRefreshBack}
                                      >
                                        <Image
                                          style={{ cursor: 'pointer' }}
                                          name='BgvErrorRefresh.png'
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  !onLoadingBack && (
                                    <label
                                      style={{ cursor: 'pointer', width: '210px', height: '110px' }}
                                    >
                                      <div className={styles.idUploadText}>
                                        <Image name='BgvIdBack.png' />

                                        <div className={styles.tag}>Add Back of your ID </div>
                                      </div>
                                      <Form.Control
                                        id='formControlsFile'
                                        className={styles.attachDocBtn}
                                        accept='image/*,application/pdf'
                                        type='file'
                                        label='File'
                                        onChange={handleFileSelectBack}
                                      />
                                    </label>
                                  )
                                )}
                              </>
                            ) : (
                              // when the doc is added
                              // pdf handling is pending
                              <>
                                <div className={styles.uploadFunctionality}>
                                  <div className={styles.functionBtn}>
                                    {props.values.backPdf ? (
                                      <>
                                        {' '}
                                        <a
                                          href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.backDocUrl}`}
                                          target='_blank'
                                        >
                                          <Image
                                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                                            name='BgvIdView.png'
                                          />
                                        </a>{' '}
                                      </>
                                    ) : (
                                      <Image
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        onClick={() => {
                                          handleImagePreview();
                                          setImagePreviewURL(props.values.backDocUrl);
                                        }}
                                        name='BgvIdView.png'
                                      />
                                    )}
                                  </div>
                                  <div
                                    className={styles.functionBtn}
                                    onClick={() => {
                                      setRemoveBack(true);
                                    }}
                                    style={{ background: showRemoveBack && 'transparent' }}
                                  >
                                    <Image
                                      style={{
                                        display: showRemoveBack && 'none',
                                        marginLeft: '5px',
                                        cursor: 'pointer'
                                      }}
                                      name='BgvIdDelete.png'
                                    />
                                    {showRemoveBack && (
                                      // here call delete api
                                      <Badge
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          DeleteLink('BACK');
                                        }}
                                        pill
                                        variant='danger'
                                      >
                                        Remove ?
                                      </Badge>
                                    )}
                                  </div>
                                </div>
                                {props.values.backDocPassword && (
                                  <OverlayTrigger
                                    key={`password_protected`}
                                    placement='top'
                                    overlay={
                                      <Tooltip id={`password_protected`}>
                                        <p>Password is saved</p>
                                      </Tooltip>
                                    }
                                  >
                                    <div className={styles.passwordProtectIcon}>
                                      <Image
                                        style={{
                                          marginRight: '5px',
                                          cursor: 'pointer',
                                          height: '24px',
                                          width: '24px'
                                        }}
                                        name='passwordSaved.png'
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {props.values.backPdf ? (
                                  <Image
                                    name='BgvIdPdf.png'
                                    style={{ margin: '35px 0px 0px 90px', cursor: 'pointer' }}
                                  />
                                ) : (
                                  <Image
                                    src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.backDocUrl}`}
                                    style={{ width: '210px', height: '115px', cursor: 'pointer' }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </Col>
                      )}
                      <Col md={4} lg={4}></Col>
                    </Form.Row>
                    <Form.Row style={{ marginTop: '15px' }}>
                      {values?.idType?.label !== 'PAN' && (
                        <Col style={{ color: '#BFBFBF' }}>
                          <Field
                            className={styles.consentLabel}
                            custom
                            name='singleDoc'
                            component={Checkbox}
                            onClick={() => setUpdate(true)}
                            disabled={props.values.idType.label === 'PAN' && true}
                            label='I have images of both sides of my ID in a single file.'
                          />
                        </Col>
                      )}
                    </Form.Row>
                    <Form.Row className={styles.row}>
                      <Form.Label>
                        ID Number <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                    </Form.Row>
                    <Form.Row>
                      <Col lg={5} md={8} sm={12} xs={12}>
                        {/* Once ocr is called add setFieldvalue in ocr api call to get the value */}
                        <Field
                          type={'text'}
                          name='identityNumber'
                          onBlur={(e) => {
                            handleIdNumberBlur(e);
                          }}
                          regex={
                            props.values.idType.label === 'Aadhaar' ? allowOnlyNumbersRegex : ''
                          }
                          component={ValidatedFormInputField}
                        />
                        {
                          // !props.values.identityNumber && ocrCalled &&
                          idNumberError && !props.errors.identityNumber && (
                            <div className={styles.error}>Please enter valid ID number.</div>
                          )
                        }
                        {props.errors.identityNumber && (
                          <div className={styles.error}>Please enter valid ID number.</div>
                        )}
                      </Col>

                      {ocrLoading && (
                        <span>
                          <Spinner
                            className={styles.ocrSpinner}
                            style={{ position: 'absolute', left: '310px', marginTop: '10px' }}
                            animation='border'
                            variant='primary'
                            size='sm'
                          />
                        </span>
                      )}
                    </Form.Row>
                    {!ocrLoading && ocrIdNumberError && (
                      <Form.Row>
                        <p className={styles.warningSubHeading}>
                          We could not read your ID number, Please enter your ID number manually.
                        </p>
                      </Form.Row>
                    )}
                  </>
                )}
              </Form>
              <ImagePreviewModal
                showImagePreviewModal={showImagePreview}
                handleImagePreview={handleImagePreview}
                imagePreviewUrl={imagePreviewURL}
              />
            </Modal.Body>
            <Modal.Footer className={styles.identitySubmitBtn}>
              <Button className={'bgvModalSecondaryBtn'} onClick={() => props.onHide()}>
                Cancel
              </Button>

              <Button
                className={'bgvModalPrimaryBtn'}
                onClick={() => {
                  props.onHide();
                  props.handleSubmit();
                }}
                disabled={
                  props.values.identityNumber
                    ? !isEmpty(props.errors)
                      ? true
                      : ((props?.values?.singleDoc && props?.values?.frontDocUrl) ||
                          (props?.values?.backDocUrl && props?.values?.frontDocUrl) ||
                          (props?.values?.idType?.label === 'PAN' && props?.values?.frontDocUrl)) &&
                        !ocrLoading &&
                        !idNumberError
                      ? false
                      : true
                    : true
                }
              >
                Save
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      ) : null}
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'id'}
          SubOnHide={modalHide}
        />
      )}
    </>
  );
};
