import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './columnmatcher.css';
import { BiSolidError, BiSolidErrorCircle } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import { getTrimmedValue, dateFormats, getAbsoluteDateFormat } from '../Table/utils';
import moment from 'moment';

const ColumnMatcher = ({
  id,
  key_index,
  column,
  options,
  setColumn,
  rows,
  usedOptions,
  setUsedOptions,
  isStaffIdOrDobAvailable,
  flow,
  isInvalid,
  setFinalData
}) => {
  const [dateField, setDateField] = useState('YYYY-MM-DD');

  useEffect(() => {
    setDateField(column?.date_format);
  }, []);

  const fetchFieldsFromArray = (array, index, dateFormat) => {
    const fields = [];

    let invalidCount = 0,
      nullCount = 0,
      totalCount = array.length;

    array.forEach((element) => {
      const value = element[index];
      if (
        value === null ||
        value === undefined ||
        (typeof value === String && value?.trim() === '')
      ) {
        nullCount++;
      } else if (column?.validations?.regex) {
        const regexPattern = new RegExp(column.validations.regex);
        const valtocheck = getTrimmedValue(value, id);
        if (!regexPattern.test(String(valtocheck))) {
          invalidCount++;
        }
      } else if (
        column?.type === 'date' &&
        !(
          moment(value.replace(/\b\d\b/g, '0$&'), dateFormat, true).isValid() ||
          moment(value, 'YYYY-MM-DD', true).isValid()
        )
      ) {
        invalidCount++;
      }

      fields.push(typeof value === 'number' ? String(value) : value);
    });
    const takenull = column.required ? nullCount : 0;
    return {
      fields,
      value_percentage: ((totalCount - nullCount) / totalCount) * 100,
      validation_percentage: ((totalCount - takenull - invalidCount) / totalCount) * 100
    };
  };

  const handleChange = (e) => {
    let dateFormat = dateField;
    if (e === null) {
      const removedColumn = column.selectedHeader;
      setUsedOptions(usedOptions.filter((option) => option !== removedColumn.value));
      const columnDatax = { ...column };
      columnDatax.selectedHeader = null;
      columnDatax.rowData = [];
      setColumn(columnDatax);
      return;
    }

    if (column?.selectedHeader) {
      const selectedValue = column.selectedHeader.value;
      if (e.value === -1) {
        setUsedOptions((prevUsedOptions) =>
          prevUsedOptions.filter((option) => option !== selectedValue)
        );
      } else {
        setUsedOptions((prevUsedOptions) => {
          const filteredOptions = prevUsedOptions.filter((option) => option !== selectedValue);
          return [...filteredOptions, e.value];
        });
      }
    } else {
      if (e.value !== -1) {
        setUsedOptions((prevUsedOptions) => [...prevUsedOptions, e.value]);
      }
    }

    if (column?.type === 'date' && !column?.rowData) {
      let firstValue = rows.find(
        (row) => row[e.value] !== null && row[e.value] !== undefined && row[e.value].trim() !== ''
      );
      const absoluteDateFormat = getAbsoluteDateFormat(firstValue?.[e?.value]);
      if (absoluteDateFormat) {
        dateFormat = absoluteDateFormat;
      }
    }

    const index = e?.value;
    const rowData = index === -1 ? { fields: null } : fetchFieldsFromArray(rows, index, dateFormat);

    const updatedColumn = {
      ...column,
      selectedHeader: e,
      rowData: rowData.fields,
      value_percentage: rowData.value_percentage,
      validation_percentage: rowData.validation_percentage,
      date_format: dateFormat
    };
    setColumn(updatedColumn);
  };

  useEffect(() => {
    if (column?.selectedHeader) {
      handleChange(column?.selectedHeader);
    }
  }, [dateField]);

  const staffIdOrDobColumn = ['Staff ID', 'Date of Birth'].includes(column?.name);

  const invalidFieldStatus =
    column?.name === 'Staff ID' ? isInvalid?.staff_id : isInvalid?.date_of_birth;
  return (
    <div className='columnMatcher_container' key={key_index}>
      <div className='left'>
        <div className='column_header'>
          <div className='column_name'>
            <span>
              {`${key_index + 1}. ${column.name}`}
              {(flow === 'alumni' && staffIdOrDobColumn) || column?.required ? (
                <span
                  style={{
                    color: flow === 'alumni' && staffIdOrDobColumn ? 'orange' : '#D3455B',
                    padding: '0'
                  }}
                >
                  *
                </span>
              ) : (
                ''
              )}
            </span>
            {column?.selectedHeader &&
              !(column?.type === 'date' && column?.validation_percentage < 90) &&
              (flow === 'alumni' && staffIdOrDobColumn
                ? !isInvalid?.staff_id && !isInvalid?.date_of_birth
                : true) && (
                <FaCheckCircle style={{ color: 'green', marginLeft: '10px', fontSize: '20px' }} />
              )}
            {((column?.selectedHeader &&
              column?.type === 'date' &&
              column?.validation_percentage < 90) ||
              (flow === 'alumni' &&
                !staffIdOrDobColumn &&
                !column?.selectedHeader &&
                column?.required) ||
              (flow === 'alumni' &&
                staffIdOrDobColumn &&
                (!isStaffIdOrDobAvailable || invalidFieldStatus)) ||
              (flow === 'candidate' && !column?.selectedHeader && column?.required)) && (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip
                    style={{
                      color: '#ffffff',
                      fontSize: '8px'
                    }}
                    id={'error'}
                  >
                    {flow === 'alumni' &&
                    staffIdOrDobColumn &&
                    (invalidFieldStatus || (!column?.selectedHeader && !isStaffIdOrDobAvailable))
                      ? 'Provide DOB or Staff ID'
                      : ((flow === 'alumni' && !staffIdOrDobColumn) || flow === 'candidate') &&
                        !column?.selectedHeader &&
                        column?.required
                      ? 'This is a mandatory field'
                      : column?.type === 'date' && column?.validation_percentage < 90
                      ? 'Invalid Date! Select correct Format.'
                      : 'Multiple Invalid Data'}
                  </Tooltip>
                }
              >
                <MdError style={{ color: '#D3455B', marginLeft: '10px', fontSize: '24px' }} />
              </OverlayTrigger>
            )}
          </div>
          <div className='column_selector'>
            <Select
              className='select'
              placeholder='Select Column'
              options={
                options.filter(
                  (option) =>
                    !usedOptions?.includes(option.value) &&
                    !(column.required && option.value === -1)
                ) || []
              }
              onChange={(e) => {
                setFinalData(null);
                handleChange(e);
              }}
              menuPortalTarget={document.body}
              isClearable={true}
              value={column.selectedHeader}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '14px',
                  height: 35,
                  minHeight: 35
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '14px',
                  padding: '5px',
                  zIndex: 9999
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '5px'
                })
              }}
            />
          </div>
        </div>
        <div className='column-description'>
          {column.type === 'date' && (
            <div className='item'>
              <BiSolidErrorCircle style={{ color: 'grey', marginRight: '10px' }} /> Select Date
              Format:
              <select
                style={{
                  marginLeft: '10px',
                  border: '1px solid #ccc',
                  outline: 'none',
                  borderRadius: '5px',
                  padding: '4px',
                  fontSize: '12px',
                  backgroundColor: 'transparent'
                }}
                value={dateField}
                onChange={(e) => {
                  setDateField(e.target.value);
                }}
              >
                {dateFormats.map((format, index) => (
                  <option key={index} value={format} style={{ padding: '5px' }}>
                    {format}
                  </option>
                ))}
              </select>
            </div>
          )}
          {column?.selectedHeader && column?.selectedHeader.value !== -1 && (
            <>
              <div className='item'>
                <BiSolidErrorCircle style={{ color: 'grey', marginRight: '8px' }} />
                {column?.value_percentage % 1 == 0
                  ? column?.value_percentage.toFixed(0)
                  : column?.value_percentage.toFixed(2)}
                % of your rows has data for this column.
              </div>
              {column?.validation_percentage < 100 && (
                <div className='item'>
                  <BiSolidError style={{ color: 'orange', marginRight: '8px' }} />
                  {(100 - column?.validation_percentage) % 1 !== 0
                    ? (100 - column?.validation_percentage).toFixed(2)
                    : (100 - column?.validation_percentage).toFixed(0)}
                  % of rows has invalid data.
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='right'>
        <div className='prev_cont'>
          <div className='preview_table'>
            <div className='row'>
              <div className='row_index'>1</div>
              <span>{column?.rowData?.[0] || ''}</span>
            </div>
            <div className='row'>
              <div className='row_index'>2</div>
              <span>{column?.rowData?.[1] || ''}</span>
            </div>
            <div className='row'>
              <div className='row_index'>3</div>
              <span>{column?.rowData?.[2] || ''}</span>
            </div>
            <div className='row'>
              <div className='row_index'>4</div>
              <span>{column?.rowData?.[3] || ''}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnMatcher;
