import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import EmptyTableBody from '../../core-components/EmptyTableBody/EmptyTableBody';
import Pagination from '../../core-components/Pagination/Pagination';
import styles from '../AlumniVerificationTabs.module.scss';
import Filters from './Filters';
import { Link } from 'react-router-dom';
import Image from '../../core-components/Image';
import ConfirmationModal from '../../core-components/ConfirmationModal';
import { deleteAlumniRecord } from '../../../../../api/company';
import { toast } from 'react-toastify';
import { getErrorMessage, capitalizeName } from '../../utils/Utilities';
export default function AlumniRecordTab(props) {
  const { alumniRecords, alumniRecordsCount, isSA = false } = props;
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [empId, setEmpId] = useState(null);
  const [filterValues, setFilterValues] = useState({
    name: null,
    staff_id: null,
    email: null,
    phone: null,
    designation: null,
    exit_type: null
  });
  const companyId = new URLSearchParams(document?.location?.search).get('id');

  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;

  useEffect(() => {
    fetchRequestList(filterValues);
  }, [offset, limit]);

  useEffect(() => {
    setOffset(0);
    setPageNumber(0);
    setLimit(10);
    setPageSize(10);
  }, [filterValues]);

  do {
    const value = pageSizes[index] > alumniRecordsCount ? alumniRecordsCount : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < alumniRecordsCount);

  const onDeleteHandler = () => {
    if (isSA) {
      deleteAlumniRecord(empId, companyId)
        .then((res) => {
          toast.success('Alumni Record deleted Successfully');
          fetchRequestList(filterValues);
        })
        .catch((err) => {
          toast.error(getErrorMessage(err));
        })
        .finally(setEmpId(null));
    } else {
      deleteAlumniRecord(empId)
        .then((res) => {
          toast.success('Alumni Record deleted Successfully');
          fetchRequestList(filterValues);
        })
        .catch((err) => {
          toast.error(getErrorMessage(err));
        })
        .finally(setEmpId(null));
    }
  };
  const fetchRequestList = (data) => {
    const where = {
      limit: limit,
      offset: offset,
      companyId,
      ...data
    };
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    props.getAlumniRecords(where).catch((error) => {
      console.error('Error:', error);
    });
  };

  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };

  return (
    <div className={styles.recentTableContainer}>
      <Filters
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        fetchRequestList={fetchRequestList}
        isSA={isSA}
      />
      <Table className={styles.recentTable}>
        <thead>
          <tr>
            <th>Record ID</th>
            <th>Last Updated at</th>
            <th>Staff ID</th>
            <th>Employee Name</th>
            <th>Designation</th>
            <th>Requested</th>
            <th className={styles.textCenter}>Action</th>
          </tr>
        </thead>
        {alumniRecordsCount > 0 ? (
          <tbody>
            {loading
              ? Array.apply(null, { length: pageSize })
                  .map(Number.call, Number)
                  .map((loadData, i) => {
                    return (
                      <tr key={i}>
                        {Array(6)
                          .fill(null)
                          .map(() => {
                            return (
                              <td>
                                <ContentLoader height={20} width={60}>
                                  <rect x='0' y='0' width='60' height='14' />
                                </ContentLoader>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
              : alumniRecords.map((val) => {
                  return (
                    <tr>
                      <td>{val?.employeeRecordId || ''}</td>
                      <td>{moment(val?.lastUpdatedAt).utc().format('DD-MM-YYYY')}</td>
                      <td>{val?.staffId || ''}</td>
                      <td>
                        <Link
                          to={
                            isSA
                              ? {
                                  pathname: '/company/alumniVerification',
                                  search: `?id=${companyId}&flow=view&empId=${val?.employeeRecordId}`
                                }
                              : {
                                  pathname: 'alumni-verification/record',
                                  search: `flow=view&id=${val?.employeeRecordId}`
                                }
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          className={styles.linkStyle}
                        >
                          {capitalizeName(val?.employeeName || '')}
                        </Link>
                      </td>
                      <td>{val?.designation || ''}</td>
                      <td>{val?.numberOfRequests}</td>
                      <td className={styles.textCenter}>
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip id={'DeleteTag'}>
                              <p>{'Delete'}</p>
                            </Tooltip>
                          }
                        >
                          <Image
                            className={styles.deleteIcon}
                            height={'14'}
                            onClick={() => {
                              setEmpId(val?.employeeRecordId);
                              setIsModalOpen(true);
                            }}
                            name='Delete.svg'
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        ) : (
          <EmptyTableBody isSA={isSA} name='No recent requests found' />
        )}
      </Table>
      {alumniRecordsCount > 0 && (
        <Form.Row className={styles.paginationWrapper}>
          <Col className={styles.showPagination}>
            Show{' '}
            <select
              className={styles.selectPagination}
              value={limit}
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                setPageSize(parseInt(event.target.value));
                paginate(0, 0);
              }}
            >
              {pageSizeOptions}
            </select>{' '}
            items of {alumniRecordsCount}
          </Col>
          <Col>
            <Pagination
              forcePage={pageNumber}
              onPageChange={({ selected }) => {
                paginate(selected, selected);
              }}
              pageCount={Math.ceil(alumniRecordsCount / pageSize)}
            />
          </Col>
          <Col />
        </Form.Row>
      )}
      {isModalOpen && (
        <ConfirmationModal
          show={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEmpId(null);
          }}
          heading={'Delete record?'}
          subHeading={'Are you sure you want to delete the record?'}
          onSubmit={() => {
            setIsModalOpen(false);
            onDeleteHandler();
          }}
          isDelete={true}
          enableBtn={true}
          sBtnText='Delete'
        />
      )}
    </div>
  );
}
