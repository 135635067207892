import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import TabComponent from '../../../core-components/TabComponent/TabComponent';
import Brand from './Brand';
import Communication from './Communication';
import AdditionalCharges from './AdditionalCharges';
import AlumniVerification from '../frontend-common/Settings/AlumniVerification';
import styles from './CompanySettings.module.scss';
import ManageApiToken from './ManageApiToken/ManageApiToken';
import SecuritySettings from '../../../components/CompanyAdmin/frontend-common/SecuritySettings';
import {
  DEFAULT_TAB_MAPPING,
  TAB_ADDITIONAL_CHARGES,
  TAB_API,
  TAB_BRAND,
  TAB_COMMUNICATION,
  TAB_SECURITY,
  TAB_ALUMNI_VERIFICATION
} from '../../../utils/commonConstant';
import cn from 'classnames';
import { getCompanyPaymentDetails } from '../../../actions/company';
import { useDispatch } from 'react-redux';
export default function CompanySettings({ history, location }) {
  const tabItems = [
    TAB_BRAND,
    TAB_COMMUNICATION,
    TAB_ADDITIONAL_CHARGES,
    TAB_SECURITY,
    TAB_API,
    TAB_ALUMNI_VERIFICATION
  ];
  const dispatch = useDispatch();

  dispatch(getCompanyPaymentDetails());
  const searchParams = new URLSearchParams(location.search);
  const defaultTab = searchParams?.get('tab') || 'bnd';

  const [activeTab, setActiveTab] = useState(DEFAULT_TAB_MAPPING[defaultTab] || TAB_BRAND);

  const handleOnChangeTab = (tab) => {
    setActiveTab(tab);
    const queryParam =
      Object.keys(DEFAULT_TAB_MAPPING).find((key) => DEFAULT_TAB_MAPPING[key] === tab) || 'com';
    searchParams.set('tab', queryParam);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return (
    <div className={cn(styles.companySettingsContainer)}>
      <div className={styles.heading}>Settings</div>
      <Card className={styles.cardContainer}>
        <TabComponent
          tabItems={tabItems}
          activeTab={activeTab}
          handleTabChange={handleOnChangeTab}
        />
        {activeTab === 'Company Profile' && <Brand />}
        {activeTab === 'Additional Charges' && <AdditionalCharges />}
        {activeTab === 'Communication' && <Communication />}
        {activeTab === 'API' && <ManageApiToken />}
        {activeTab === 'Security' && <SecuritySettings isCA={true} />}
        {activeTab === 'Alumni Verification' && <AlumniVerification />}
      </Card>
    </div>
  );
}
