import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, Table } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import AppConstants from '../../../../../core-components/AppConstants';
import EmptyTableBody from '../../core-components/EmptyTableBody/EmptyTableBody';
import Image from '../../core-components/Image';
import Pagination from '../../core-components/Pagination/Pagination';
import styles from '../AlumniVerificationTabs.module.scss';
import Filters from './Filters';
import { Link } from 'react-router-dom';
import { capitalizeName } from '../../utils/Utilities';

export default function RecentRequestTable(props) {
  const { recentRequest, recentRequestCount, isSA = false } = props;
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({
    designation: null,
    name: null,
    staff_id: null,
    requesting_entity: null
  });

  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;
  const companyId = new URLSearchParams(document?.location?.search).get('id');

  useEffect(() => {
    fetchRequestList(filterValues);
  }, [offset, limit]);

  do {
    const value = pageSizes[index] > recentRequestCount ? recentRequestCount : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < recentRequestCount);

  const fetchRequestList = (data) => {
    const where = {
      limit: limit,
      offset: offset,
      companyId,
      ...data
    };
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    props.getRecentRequest(where).catch((error) => {
      console.error('Error:', error);
    });
  };

  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };

  useEffect(() => {
    setOffset(0);
    setPageNumber(0);
    setLimit(10);
    setPageSize(10);
  }, [filterValues]);

  return (
    <div className={styles.recentTableContainer}>
      <Filters
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        fetchRequestList={fetchRequestList}
        isSA={isSA}
      />
      <Table className={styles.recentTable}>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Timestamp</th>
            <th>Staff ID</th>
            <th>Employee Name</th>
            <th>Designation</th>
            <th>Requesting entity</th>
            <th>Consent</th>
            <th>Amount</th>
          </tr>
        </thead>
        {recentRequestCount > 0 ? (
          <tbody>
            {loading
              ? Array.apply(null, { length: pageSize })
                  .map(Number.call, Number)
                  .map((loadData, i) => {
                    return (
                      <tr key={i}>
                        {Array(8)
                          .fill(0)
                          .map((val, index) => {
                            return (
                              <td key={index}>
                                <ContentLoader height={20} width={60}>
                                  <rect x='0' y='0' width='60' height='14' />
                                </ContentLoader>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
              : recentRequest.map((val) => {
                  return (
                    <tr>
                      <td>{val?.id}</td>
                      <td>{moment(val?.createdAt).utc().format('DD-MM-YYYY')}</td>
                      <td>{val?.staffId || ''}</td>
                      <td>
                        <Link
                          to={
                            isSA
                              ? {
                                  pathname: '/company/alumniVerification',
                                  search: `?id=${companyId}&flow=view&empId=${val?.employeeRecordId}`
                                }
                              : {
                                  pathname: 'alumni-verification/record',
                                  search: `flow=view&id=${val?.employeeRecordId}`
                                }
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          className={styles.linkStyle}
                        >
                          {capitalizeName(val?.employeeName || '')}
                        </Link>
                      </td>
                      <td>{val?.designation || ''}</td>
                      <td>{val?.requestingEntity}</td>
                      <td>
                        <a
                          href={`${
                            AppConstants.baseURL
                          }verification-request?partner_request_log_id=${val.id}${
                            companyId ? '&company_id=' + companyId : ''
                          }`}
                          target='_blank'
                          className='styles.linkStyle'
                        >
                          <Image isSA={isSA} name='reportIcon.svg' />
                        </a>
                      </td>
                      <td>
                        <div>
                          <span className={styles.rupeeFont}>₹</span>
                          {val?.clientShareAmount}
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        ) : (
          <EmptyTableBody isSA={isSA} name='No recent requests found' />
        )}
      </Table>
      {recentRequestCount > 0 && (
        <Form.Row className={styles.paginationWrapper}>
          <Col className={styles.showPagination}>
            Show{' '}
            <select
              className={styles.selectPagination}
              value={limit}
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                setPageSize(parseInt(event.target.value));
                paginate(0, 0);
              }}
            >
              {pageSizeOptions}
            </select>{' '}
            items of {recentRequestCount}
          </Col>
          <Col>
            <Pagination
              forcePage={pageNumber}
              onPageChange={({ selected }) => {
                paginate(selected, selected);
              }}
              pageCount={Math.ceil(recentRequestCount / pageSize)}
            />
          </Col>
          <Col />
        </Form.Row>
      )}
    </div>
  );
}
