// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M7QuZgj90v_yMheJCtaY {
  font-family: Poppins;
  position: relative;
}
.M7QuZgj90v_yMheJCtaY .m11vCs5K8i7ejpBnI8Tj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(216, 225, 230, 0.5);
  backdrop-filter: blur(2px);
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.M7QuZgj90v_yMheJCtaY .m11vCs5K8i7ejpBnI8Tj img {
  width: 50px;
  height: 50px;
}
.M7QuZgj90v_yMheJCtaY input {
  border: 1px solid hsl(0, 0%, 87%);
}
.M7QuZgj90v_yMheJCtaY .Jrr6xynfOxH5MFruvf19 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.M7QuZgj90v_yMheJCtaY .L6JYue9JKI0wOuYHhiEr {
  width: 100%;
  padding: 25px 40px;
}
.M7QuZgj90v_yMheJCtaY .L6JYue9JKI0wOuYHhiEr form label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  margin-bottom: 6px;
}
.M7QuZgj90v_yMheJCtaY .L6JYue9JKI0wOuYHhiEr form label span {
  color: red;
}
.M7QuZgj90v_yMheJCtaY .L6JYue9JKI0wOuYHhiEr form .HgJxqX_i6kthsoFOqt2y {
  justify-content: space-between !important;
}

.NjtP7Do9VIA6wSwFA82J {
  margin-bottom: 20px;
}

.oaTtLenwZGpQvyAFbS0l {
  margin-left: -20px;
  color: #788896;
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: 12px;
}

.rDEZtNz1mBo4BzeKcnl6 {
  background: #2755fe;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-right: 4px;
}
.rDEZtNz1mBo4BzeKcnl6:hover {
  background-color: #1f44ca;
}

.J8LCF9a5EYo7J3eI4ovR {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.26);
  margin-right: 4px;
}

.Yj_b8wO46k2Ncpk_Ynbs {
  height: 38px;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
  padding: 0 !important;
}
.Yj_b8wO46k2Ncpk_Ynbs:active, .Yj_b8wO46k2Ncpk_Ynbs:hover {
  color: #2755fe !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.BH0LkP8nDXy8X2a604AW {
  height: 38px;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  background: white;
  color: #2755fe;
  cursor: pointer;
  margin-top: 5px;
  padding: 0 !important;
}

.TcF5rKxdNKSP2x_QuGUI {
  padding: 0 20px;
  margin-bottom: 40px;
}

.i_TUPiTyCFYsIWH7MTCJ {
  margin-top: 60px;
}
.i_TUPiTyCFYsIWH7MTCJ button {
  width: 150px;
}

.WOMObwKkEu7yyY8nCrgx {
  position: relative;
}

.TMgbf6TJrAXDw0sZHjw4 {
  position: absolute;
  left: 190px;
  z-index: 1;
}

.nPrVWaPTXCUy57bsDPRb {
  font-size: 80%;
  color: #dc3545;
  margin-top: -20px;
  margin-left: -28px;
  margin-bottom: -20px;
}

.HCoAAABt45BRLyyFwtue {
  display: block;
  margin-left: unset !important;
}

.diksUCQNK6YBuH4jat1E {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #c5c5c5;
}

.huD3W_HQNN81bDI_CTpR {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #af6eeb;
  margin-top: -20px;
  margin-bottom: 24px;
}

.t_xzeP3kqz6nIf2QQpnp {
  color: #c5c5c5;
}

.eG0LjqQ6z4GRI6PhAN_C input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}

.lJWqeIcsIcvXVkjF5Kru {
  font-size: 13px;
  color: #dc3545;
  margin-top: -12px !important;
}

.x4Oem_10wo8kybK30YJM {
  font-size: 13px;
  color: #dc3545;
}

.DEGkPAwP4_H61PJiycl3 {
  font-size: 13px;
  color: #dc3545;
}

.W1gX78C_DGLxt3slcc5y {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
  margin-bottom: 28px;
}

.c8NBeB120Nqcy6uGFRkj {
  display: flex;
  align-items: center;
}

.mI37Dx85D9FhioeJrr4y {
  margin: 0 !important;
}

.FLz21AJ9wOzuMGFzgHqo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FQh5AFvZiEPoRHdKKOf3 {
  margin-bottom: 32px;
}

.Godnru97nAJ2OSsNgRkv {
  margin-bottom: 32px;
  margin-top: 32px;
}
.Godnru97nAJ2OSsNgRkv .Ca5H0ckaaDDcYAN6rTBo {
  border: none;
}
.Godnru97nAJ2OSsNgRkv .Ca5H0ckaaDDcYAN6rTBo .mn5iTJmdOuGc7UkO9LQq {
  display: flex;
  justify-content: space-between;
  background: #f8f9fa;
  border: none;
  border: 1px solid #ebedf2;
  cursor: pointer;
}
.Godnru97nAJ2OSsNgRkv .Ca5H0ckaaDDcYAN6rTBo .f4Pbj6manJ6dbnZKBhrQ {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646c9a;
}
.Godnru97nAJ2OSsNgRkv .Ca5H0ckaaDDcYAN6rTBo .zk38O23pcgn4XEE6BWMQ {
  padding: 0;
}
.Godnru97nAJ2OSsNgRkv .Ca5H0ckaaDDcYAN6rTBo .zk38O23pcgn4XEE6BWMQ .zbaDwSe5LvErOpSJlt9s {
  resize: none;
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
  border: 1px solid #ebedf2;
  outline: none;
  box-shadow: none;
}
.Godnru97nAJ2OSsNgRkv .Ca5H0ckaaDDcYAN6rTBo .zk38O23pcgn4XEE6BWMQ .NpCaZFGhyLeBHw8oCKl1 {
  margin-top: -30px;
  margin-right: 22px;
  position: absolute;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  right: 0;
  color: #c5c5c5;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addCandidate": `M7QuZgj90v_yMheJCtaY`,
	"overlay": `m11vCs5K8i7ejpBnI8Tj`,
	"heading": `Jrr6xynfOxH5MFruvf19`,
	"form": `L6JYue9JKI0wOuYHhiEr`,
	"form-row": `HgJxqX_i6kthsoFOqt2y`,
	"consentDiv": `NjtP7Do9VIA6wSwFA82J`,
	"consentSentence": `oaTtLenwZGpQvyAFbS0l`,
	"inviteBtn": `rDEZtNz1mBo4BzeKcnl6`,
	"disabledButton": `J8LCF9a5EYo7J3eI4ovR`,
	"addBtn": `Yj_b8wO46k2Ncpk_Ynbs`,
	"linkColor": `BH0LkP8nDXy8X2a604AW`,
	"radioBtn": `TcF5rKxdNKSP2x_QuGUI`,
	"btnGrid": `i_TUPiTyCFYsIWH7MTCJ`,
	"formConsent": `WOMObwKkEu7yyY8nCrgx`,
	"identityDropdown": `TMgbf6TJrAXDw0sZHjw4`,
	"genderRadioError": `nPrVWaPTXCUy57bsDPRb`,
	"checkboxWrapper": `HCoAAABt45BRLyyFwtue`,
	"consentLabelMsg": `diksUCQNK6YBuH4jat1E`,
	"consentUncheckMsg": `huD3W_HQNN81bDI_CTpR`,
	"noEmailConsent": `t_xzeP3kqz6nIf2QQpnp`,
	"emailError": `eG0LjqQ6z4GRI6PhAN_C`,
	"emailErrorMsg": `lJWqeIcsIcvXVkjF5Kru`,
	"serviceErrorMsg": `x4Oem_10wo8kybK30YJM`,
	"consentDocErrorMsg": `DEGkPAwP4_H61PJiycl3`,
	"resumeErrorMsg": `W1gX78C_DGLxt3slcc5y`,
	"radioGroup": `c8NBeB120Nqcy6uGFRkj`,
	"radioLabel": `mI37Dx85D9FhioeJrr4y`,
	"formRow": `FLz21AJ9wOzuMGFzgHqo`,
	"formGroups": `FQh5AFvZiEPoRHdKKOf3`,
	"accordionContainer": `Godnru97nAJ2OSsNgRkv`,
	"accordionContainerCard": `Ca5H0ckaaDDcYAN6rTBo`,
	"accordionContainerHeader": `mn5iTJmdOuGc7UkO9LQq`,
	"inviteMailHeading": `f4Pbj6manJ6dbnZKBhrQ`,
	"cardBody": `zk38O23pcgn4XEE6BWMQ`,
	"accordionTextarea": `zbaDwSe5LvErOpSJlt9s`,
	"textareaCharacterLimit": `NpCaZFGhyLeBHw8oCKl1`
};
export default ___CSS_LOADER_EXPORT___;
