// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.DaOAv0cKlusuTTdXz_eN {
  color: #000000;
  font-size: 22px;
  margin-bottom: 8px;
}

.u6NddjlbHypJGNo0ymjy {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: -10px;
}

.tUqK5vZ3dRQRvszWCADS {
  padding: 20px 30px 40px 30px !important;
}
@media screen and (max-width: 601px) {
  .tUqK5vZ3dRQRvszWCADS {
    padding-bottom: 65px !important;
  }
}

label {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #262626 !important;
  margin-bottom: 6px !important;
}
label span {
  color: #f5222d;
}

input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
input::placeholder {
  color: #cccccc !important;
}

.JRsJs9brd19utSOYppRC {
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .JRsJs9brd19utSOYppRC {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .JRsJs9brd19utSOYppRC button {
    height: 40px;
    margin: 0 !important;
    width: 100%;
  }
  .JRsJs9brd19utSOYppRC .bIzyQmJxL6XOLZ854OHW,
  .JRsJs9brd19utSOYppRC .neZqTmjUqvOJZRvYOwmx {
    padding: 5px 35px;
  }
}

.dCnGMX1VlHOyrL2o6DZ_ {
  margin-bottom: 32px;
}

.uNIDt9YFJF9MGGF17XmV {
  width: 100%;
}

.uNIDt9YFJF9MGGF17XmV input {
  box-sizing: border-box;
  width: 100%;
}

.Wj77TtHzxdPiefB5t6w9 {
  margin-bottom: 32px;
}
@media screen and (max-width: 601px) {
  .Wj77TtHzxdPiefB5t6w9 {
    margin-bottom: 3px;
  }
}
.Wj77TtHzxdPiefB5t6w9 .KAUl4HIpVmZjrKfLZkgB {
  border: 1px solid #ebedf2;
  border-radius: 3px;
  height: 35px;
  font-weight: 100;
  padding-left: 5px;
}
.Wj77TtHzxdPiefB5t6w9 .Qpy8aYKLAgpfgY79aw3S {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.NFdvSHuZAP9Q8rNNaasO {
  font-size: 14px;
  color: #dc3545;
  margin-top: 0.25rem;
}

.IigEU13hYDq1l3ujwsL7 {
  margin-top: -10px;
}

@media screen and (max-width: 601px) {
  .IigEU13hYDq1l3ujwsL7 {
    margin-top: 20px;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"educationModalTitle": `DaOAv0cKlusuTTdXz_eN`,
	"educationModalSubTitle": `u6NddjlbHypJGNo0ymjy`,
	"addEditEducationModalBody": `tUqK5vZ3dRQRvszWCADS`,
	"educationSubmitBtn": `JRsJs9brd19utSOYppRC`,
	"activeBtn": `bIzyQmJxL6XOLZ854OHW`,
	"disableBtn": `neZqTmjUqvOJZRvYOwmx`,
	"formGroup": `dCnGMX1VlHOyrL2o6DZ_`,
	"datePickerWrapper": `uNIDt9YFJF9MGGF17XmV`,
	"formGroupDate": `Wj77TtHzxdPiefB5t6w9`,
	"datePickerStyle": `KAUl4HIpVmZjrKfLZkgB`,
	"datePickerHeaderStyle": `Qpy8aYKLAgpfgY79aw3S`,
	"errorMsg": `NFdvSHuZAP9Q8rNNaasO`,
	"checkBoxLabel": `IigEU13hYDq1l3ujwsL7`
};
export default ___CSS_LOADER_EXPORT___;
