export const ICONS = {
  EditCategory:
    'M8.545 4.765L9.235 5.455L2.44 12.25H1.75V11.56L8.545 4.765ZM11.245 0.25C11.0575 0.25 10.8625 0.325 10.72 0.4675L9.3475 1.84L12.16 4.6525L13.5325 3.28C13.602 3.21061 13.6572 3.1282 13.6948 3.03747C13.7325 2.94674 13.7518 2.84948 13.7518 2.75125C13.7518 2.65302 13.7325 2.55576 13.6948 2.46503C13.6572 2.3743 13.602 2.29189 13.5325 2.2225L11.7775 0.4675C11.6275 0.3175 11.44 0.25 11.245 0.25ZM8.545 2.6425L0.25 10.9375V13.75H3.0625L11.3575 5.455L8.545 2.6425Z',
  DeleteCategory:
    'M1.5 12.25C1.5 12.6478 1.65804 13.0294 1.93934 13.3107C2.22064 13.592 2.60218 13.75 3 13.75H9C9.39782 13.75 9.77936 13.592 10.0607 13.3107C10.342 13.0294 10.5 12.6478 10.5 12.25V3.25H1.5V12.25ZM3 4.75H9V12.25H3V4.75ZM8.625 1L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1H8.625Z',
  EditAdmin:
    'M1.66669 14.1666V16.6666H8.33335V15.0916H3.25002V14.1666C3.25002 13.6333 5.85835 12.4166 8.33335 12.4166C9.13335 12.4249 9.92502 12.5333 10.6917 12.7333L11.9584 11.4666C10.7917 11.0749 9.58335 10.8583 8.33335 10.8333C6.10835 10.8333 1.66669 11.9416 1.66669 14.1666ZM8.33335 3.33325C6.49169 3.33325 5.00002 4.82492 5.00002 6.66659C5.00002 8.50825 6.49169 9.99992 8.33335 9.99992C10.175 9.99992 11.6667 8.50825 11.6667 6.66659C11.6667 4.82492 10.175 3.33325 8.33335 3.33325ZM8.33335 8.33325C7.41669 8.33325 6.66669 7.59159 6.66669 6.66659C6.66669 5.74159 7.41669 4.99992 8.33335 4.99992C9.25002 4.99992 10 5.74992 10 6.66659C10 7.58325 9.25835 8.33325 8.33335 8.33325ZM18.0834 11.1249L17.25 11.9583L15.5417 10.2916L16.375 9.45825C16.4607 9.37427 16.5759 9.32723 16.6959 9.32723C16.8158 9.32723 16.931 9.37427 17.0167 9.45825L18.0834 10.5249C18.2584 10.6999 18.2584 10.9916 18.0834 11.1666V11.1249ZM10 15.7833L15.05 10.7333L16.7584 12.3999L11.7584 17.4583H10V15.7833Z',
  DeleteAdmin:
    'M5.00002 15.8333C5.00002 16.2754 5.17562 16.6993 5.48818 17.0118C5.80074 17.3244 6.22466 17.5 6.66669 17.5H13.3334C13.7754 17.5 14.1993 17.3244 14.5119 17.0118C14.8244 16.6993 15 16.2754 15 15.8333V5.83333H5.00002V15.8333ZM6.66669 7.5H13.3334V15.8333H6.66669V7.5ZM12.9167 3.33333L12.0834 2.5H7.91669L7.08335 3.33333H4.16669V5H15.8334V3.33333H12.9167Z',
  Flag: 'M10.1121 0.882142C10.0498 0.835286 9.97847 0.801707 9.90262 0.783476C9.82677 0.765245 9.74799 0.762749 9.67114 0.776142L8.57614 1.05014C7.45255 1.32478 6.27288 1.26146 5.18514 0.868142H5.17014C3.99402 0.432378 2.71987 0.332316 1.49014 0.579142L1.06414 0.670142V0.532142C1.06454 0.462167 1.05105 0.392809 1.02446 0.328084C0.997862 0.263359 0.95869 0.204554 0.90921 0.155074C0.85973 0.105594 0.800924 0.0664218 0.736199 0.0398273C0.671474 0.0132327 0.602116 -0.000256186 0.532141 0.000141783C0.461829 -0.00150042 0.391915 0.0111377 0.326627 0.0372919C0.26134 0.0634461 0.202036 0.102573 0.152304 0.152304C0.102573 0.202036 0.0634463 0.26134 0.0372922 0.326627C0.011138 0.391915 -0.00150043 0.46183 0.000141781 0.532142V13.1681C-0.000256188 13.2381 0.0132325 13.3075 0.039827 13.3722C0.0664216 13.4369 0.105594 13.4957 0.155074 13.5452C0.204554 13.5947 0.26336 13.6339 0.328085 13.6605C0.39281 13.6871 0.462167 13.7005 0.532141 13.7001C0.602454 13.7018 0.672369 13.6891 0.737656 13.663C0.802944 13.6368 0.862248 13.5977 0.911979 13.548C0.961711 13.4982 1.00084 13.4389 1.02699 13.3737C1.05315 13.3084 1.06578 13.2385 1.06414 13.1681V7.61814L1.67214 7.49614C2.7145 7.28341 3.79594 7.37292 4.78914 7.75414L4.83514 7.76914C5.5902 8.04661 6.38773 8.19076 7.19214 8.19514C7.75156 8.19922 8.30895 8.12757 8.84914 7.98214L9.94414 7.70814C10.0559 7.67923 10.1553 7.61489 10.2274 7.52475C10.2995 7.4346 10.3405 7.32352 10.3441 7.20814V1.30814C10.3239 1.14178 10.2409 0.989415 10.1121 0.882142Z',
  CircleTick:
    'M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z',
  NoData:
    'M64.5833 58.3333H61.2917L60.125 57.2083C64.2083 52.4583 66.6667 46.2917 66.6667 39.5833C66.6667 24.625 54.5417 12.5 39.5833 12.5C24.625 12.5 12.5 24.625 12.5 39.5833C12.5 54.5417 24.625 66.6667 39.5833 66.6667C46.2917 66.6667 52.4583 64.2083 57.2083 60.125L58.3333 61.2917V64.5833L79.1667 85.375L85.375 79.1667L64.5833 58.3333ZM39.5833 58.3333C29.2083 58.3333 20.8333 49.9583 20.8333 39.5833C20.8333 29.2083 29.2083 20.8333 39.5833 20.8333C49.9583 20.8333 58.3333 29.2083 58.3333 39.5833C58.3333 49.9583 49.9583 58.3333 39.5833 58.3333Z',
  Identity:
    'M16.5 1H1.5C0.671875 1 0 1.67188 0 2.5V13.5C0 14.3281 0.671875 15 1.5 15H16.5C17.3281 15 18 14.3281 18 13.5V2.5C18 1.67188 17.3281 1 16.5 1ZM16.5 13.5H9.475C9.50313 13.3594 9.5 13.6125 9.5 12.8C9.5 11.8063 8.55937 11 7.4 11C7.0625 11 6.81563 11.25 6 11.25C5.15938 11.25 4.95625 11 4.6 11C3.44062 11 2.5 11.8063 2.5 12.8C2.5 13.6125 2.49375 13.3594 2.525 13.5H1.5V4.5H16.5V13.5ZM11.25 11H14.75C14.8875 11 15 10.8875 15 10.75V10.25C15 10.1125 14.8875 10 14.75 10H11.25C11.1125 10 11 10.1125 11 10.25V10.75C11 10.8875 11.1125 11 11.25 11ZM11.25 9H14.75C14.8875 9 15 8.8875 15 8.75V8.25C15 8.1125 14.8875 8 14.75 8H11.25C11.1125 8 11 8.1125 11 8.25V8.75C11 8.8875 11.1125 9 11.25 9ZM11.25 7H14.75C14.8875 7 15 6.8875 15 6.75V6.25C15 6.1125 14.8875 6 14.75 6H11.25C11.1125 6 11 6.1125 11 6.25V6.75C11 6.8875 11.1125 7 11.25 7ZM6 10C7.10313 10 8 9.10312 8 8C8 6.89687 7.10313 6 6 6C4.89687 6 4 6.89687 4 8C4 9.10312 4.89687 10 6 10Z',
  Employment:
    'M6.78571 2.66667H12.2143V1.33333H6.78571V2.66667ZM19 9.33333V14.3333C19 14.7917 18.8339 15.184 18.5017 15.5104C18.1695 15.8368 17.7701 16 17.3036 16H1.69643C1.22991 16 0.830543 15.8368 0.498326 15.5104C0.166109 15.184 0 14.7917 0 14.3333V9.33333H7.125V11C7.125 11.1806 7.19215 11.3368 7.32645 11.4688C7.46075 11.6007 7.61979 11.6667 7.80357 11.6667H11.1964C11.3802 11.6667 11.5392 11.6007 11.6735 11.4688C11.8078 11.3368 11.875 11.1806 11.875 11V9.33333H19ZM10.8571 9.33333V10.6667H8.14286V9.33333H10.8571ZM19 4.33333V8.33333H0V4.33333C0 3.875 0.166109 3.48264 0.498326 3.15625C0.830543 2.82986 1.22991 2.66667 1.69643 2.66667H5.42857V1C5.42857 0.722222 5.52753 0.486111 5.72545 0.291667C5.92336 0.0972222 6.16369 0 6.44643 0H12.5536C12.8363 0 13.0766 0.0972222 13.2746 0.291667C13.4725 0.486111 13.5714 0.722222 13.5714 1V2.66667H17.3036C17.7701 2.66667 18.1695 2.82986 18.5017 3.15625C18.8339 3.48264 19 3.875 19 4.33333Z',
  Address:
    'M7.71429 5.33333C7.71429 4.59722 7.46317 3.96875 6.96094 3.44792C6.45871 2.92708 5.85268 2.66667 5.14286 2.66667C4.43304 2.66667 3.82701 2.92708 3.32478 3.44792C2.82254 3.96875 2.57143 4.59722 2.57143 5.33333C2.57143 6.06944 2.82254 6.69792 3.32478 7.21875C3.82701 7.73958 4.43304 8 5.14286 8C5.85268 8 6.45871 7.73958 6.96094 7.21875C7.46317 6.69792 7.71429 6.06944 7.71429 5.33333ZM10.2857 5.33333C10.2857 6.09028 10.1752 6.71181 9.95424 7.19792L6.29799 15.2604C6.19085 15.4896 6.03181 15.6701 5.82087 15.8021C5.60993 15.934 5.38393 16 5.14286 16C4.90179 16 4.67578 15.934 4.46484 15.8021C4.25391 15.6701 4.09821 15.4896 3.99777 15.2604L0.331473 7.19792C0.110491 6.71181 0 6.09028 0 5.33333C0 3.86111 0.502232 2.60417 1.5067 1.5625C2.51116 0.520833 3.72321 0 5.14286 0C6.5625 0 7.77455 0.520833 8.77902 1.5625C9.78348 2.60417 10.2857 3.86111 10.2857 5.33333Z',
  Education:
    'M19.2491 7.375L19.4444 10.6667C19.4734 11.1458 19.1768 11.5903 18.5547 12C17.9326 12.4097 17.0826 12.7344 16.0048 12.974C14.9269 13.2135 13.7587 13.3333 12.5 13.3333C11.2413 13.3333 10.0731 13.2135 8.99522 12.974C7.91739 12.7344 7.06742 12.4097 6.44531 12C5.82321 11.5903 5.52662 11.1458 5.55556 10.6667L5.75087 7.375L11.9792 9.26042C12.1383 9.30903 12.3119 9.33333 12.5 9.33333C12.6881 9.33333 12.8617 9.30903 13.0208 9.26042L19.2491 7.375ZM25 4C25 4.15972 24.9204 4.26736 24.7613 4.32292L12.6085 7.98958C12.5796 7.99653 12.5434 8 12.5 8C12.4566 8 12.4204 7.99653 12.3915 7.98958L5.31684 5.84375C5.00579 6.07986 4.74899 6.46701 4.54644 7.00521C4.34389 7.5434 4.22092 8.16319 4.17752 8.86458C4.63325 9.11458 4.86111 9.49306 4.86111 10C4.86111 10.4792 4.65133 10.8507 4.23177 11.1146L4.86111 15.625C4.87558 15.7222 4.84664 15.809 4.77431 15.8854C4.7092 15.9618 4.6224 16 4.51389 16H2.43056C2.32205 16 2.23524 15.9618 2.17014 15.8854C2.0978 15.809 2.06887 15.7222 2.08333 15.625L2.71267 11.1146C2.29311 10.8507 2.08333 10.4792 2.08333 10C2.08333 9.49306 2.31843 9.10764 2.78863 8.84375C2.8682 7.40625 3.22266 6.26042 3.852 5.40625L0.238715 4.32292C0.0795718 4.26736 0 4.15972 0 4C0 3.84028 0.0795718 3.73264 0.238715 3.67708L12.3915 0.0104167C12.4204 0.00347222 12.4566 0 12.5 0C12.5434 0 12.5796 0.00347222 12.6085 0.0104167L24.7613 3.67708C24.9204 3.73264 25 3.84028 25 4Z',
  World:
    'M6.5 6.85714C7.83724 6.85714 9.08702 6.72917 10.2493 6.47321C11.4117 6.21726 12.3286 5.83929 13 5.33929V6.85714C13 7.26786 12.7094 7.64881 12.1283 8C11.5471 8.35119 10.7572 8.62947 9.75846 8.83482C8.75977 9.04018 7.67361 9.14286 6.5 9.14286C5.32639 9.14286 4.24023 9.04018 3.24154 8.83482C2.24284 8.62947 1.45291 8.35119 0.871745 8C0.290582 7.64881 0 7.26786 0 6.85714V5.33929C0.671441 5.83929 1.58832 6.21726 2.75065 6.47321C3.91298 6.72917 5.16276 6.85714 6.5 6.85714ZM6.5 13.7143C7.83724 13.7143 9.08702 13.5863 10.2493 13.3304C11.4117 13.0744 12.3286 12.6964 13 12.1964V13.7143C13 14.125 12.7094 14.506 12.1283 14.8571C11.5471 15.2083 10.7572 15.4866 9.75846 15.692C8.75977 15.8973 7.67361 16 6.5 16C5.32639 16 4.24023 15.8973 3.24154 15.692C2.24284 15.4866 1.45291 15.2083 0.871745 14.8571C0.290582 14.506 0 14.125 0 13.7143V12.1964C0.671441 12.6964 1.58832 13.0744 2.75065 13.3304C3.91298 13.5863 5.16276 13.7143 6.5 13.7143ZM6.5 10.2857C7.83724 10.2857 9.08702 10.1577 10.2493 9.90179C11.4117 9.64583 12.3286 9.26786 13 8.76786V10.2857C13 10.6964 12.7094 11.0774 12.1283 11.4286C11.5471 11.7798 10.7572 12.058 9.75846 12.2634C8.75977 12.4688 7.67361 12.5714 6.5 12.5714C5.32639 12.5714 4.24023 12.4688 3.24154 12.2634C2.24284 12.058 1.45291 11.7798 0.871745 11.4286C0.290582 11.0774 0 10.6964 0 10.2857V8.76786C0.671441 9.26786 1.58832 9.64583 2.75065 9.90179C3.91298 10.1577 5.16276 10.2857 6.5 10.2857ZM6.5 0C7.67361 0 8.75977 0.102679 9.75846 0.308036C10.7572 0.513393 11.5471 0.791667 12.1283 1.14286C12.7094 1.49405 13 1.875 13 2.28571V3.42857C13 3.83929 12.7094 4.22024 12.1283 4.57143C11.5471 4.92262 10.7572 5.20089 9.75846 5.40625C8.75977 5.61161 7.67361 5.71429 6.5 5.71429C5.32639 5.71429 4.24023 5.61161 3.24154 5.40625C2.24284 5.20089 1.45291 4.92262 0.871745 4.57143C0.290582 4.22024 0 3.83929 0 3.42857V2.28571C0 1.875 0.290582 1.49405 0.871745 1.14286C1.45291 0.791667 2.24284 0.513393 3.24154 0.308036C4.24023 0.102679 5.32639 0 6.5 0Z',
  Drug: 'M11.25 0H0.75C0.3375 0 0 0.3375 0 0.75V2.25C0 2.6625 0.3375 3 0.75 3H11.25C11.6625 3 12 2.6625 12 2.25V0.75C12 0.3375 11.6625 0 11.25 0ZM1 15C1 15.55 1.45 16 2 16H10C10.55 16 11 15.55 11 15V4H1V15ZM3 9.25C3 9.1125 3.1125 9 3.25 9H5V7.25C5 7.1125 5.1125 7 5.25 7H6.75C6.8875 7 7 7.1125 7 7.25V9H8.75C8.8875 9 9 9.1125 9 9.25V10.75C9 10.8875 8.8875 11 8.75 11H7V12.75C7 12.8875 6.8875 13 6.75 13H5.25C5.1125 13 5 12.8875 5 12.75V11H3.25C3.1125 11 3 10.8875 3 10.75V9.25Z',
  Court_Record:
    'M15.7803 6.22934L15.0732 5.52224C14.7803 5.22934 14.3055 5.22934 14.0126 5.52224L13.8358 5.69903L10.3002 2.16349L10.477 1.98671C10.7699 1.69381 10.7699 1.21893 10.477 0.926057L9.76993 0.218932C9.47702 -0.0739743 9.00215 -0.0739743 8.70927 0.218932L4.82018 4.10803C4.52727 4.40093 4.52727 4.87581 4.82018 5.16868L5.52727 5.87578C5.82018 6.16868 6.29505 6.16868 6.58793 5.87578L6.76471 5.69899L8.00215 6.93643L5.46965 9.46893L5.29287 9.29215C4.90234 8.90162 4.26918 8.90162 3.87865 9.29215L0.292898 12.878C-0.0976328 13.2685 -0.0976328 13.9017 0.292898 14.2922L1.70712 15.7064C2.09765 16.0969 2.7308 16.0969 3.12134 15.7064L6.70712 12.1206C7.09765 11.7301 7.09765 11.0969 6.70712 10.7064L6.53034 10.5296L9.06284 7.99712L10.3003 9.23456L10.1235 9.41134C9.83059 9.70424 9.83059 10.1791 10.1235 10.472L10.8306 11.1791C11.1235 11.472 11.5984 11.472 11.8912 11.1791L15.7803 7.28999C16.0732 6.99712 16.0732 6.52224 15.7803 6.22934Z',
  CIBIL_Credit_Score:
    'M0 13.5C0 14.3281 0.671875 15 1.5 15H16.5C17.3281 15 18 14.3281 18 13.5V8H0V13.5ZM6 11.375C6 11.1687 6.16875 11 6.375 11H10.625C10.8313 11 11 11.1687 11 11.375V12.625C11 12.8313 10.8313 13 10.625 13H6.375C6.16875 13 6 12.8313 6 12.625V11.375ZM2 11.375C2 11.1687 2.16875 11 2.375 11H4.625C4.83125 11 5 11.1687 5 11.375V12.625C5 12.8313 4.83125 13 4.625 13H2.375C2.16875 13 2 12.8313 2 12.625V11.375ZM18 2.5V4H0V2.5C0 1.67188 0.671875 1 1.5 1H16.5C17.3281 1 18 1.67188 18 2.5Z',
  Reference:
    'M3 7C4.10313 7 5 6.10313 5 5C5 3.89687 4.10313 3 3 3C1.89688 3 1 3.89687 1 5C1 6.10313 1.89688 7 3 7ZM17 7C18.1031 7 19 6.10313 19 5C19 3.89687 18.1031 3 17 3C15.8969 3 15 3.89687 15 5C15 6.10313 15.8969 7 17 7ZM18 8H16C15.45 8 14.9531 8.22188 14.5906 8.58125C15.85 9.27188 16.7437 10.5188 16.9375 12H19C19.5531 12 20 11.5531 20 11V10C20 8.89687 19.1031 8 18 8ZM10 8C11.9344 8 13.5 6.43437 13.5 4.5C13.5 2.56562 11.9344 1 10 1C8.06563 1 6.5 2.56562 6.5 4.5C6.5 6.43437 8.06563 8 10 8ZM12.4 9H12.1406C11.4906 9.3125 10.7688 9.5 10 9.5C9.23125 9.5 8.5125 9.3125 7.85938 9H7.6C5.6125 9 4 10.6125 4 12.6V13.5C4 14.3281 4.67188 15 5.5 15H14.5C15.3281 15 16 14.3281 16 13.5V12.6C16 10.6125 14.3875 9 12.4 9ZM5.40938 8.58125C5.04688 8.22188 4.55 8 4 8H2C0.896875 8 0 8.89687 0 10V11C0 11.5531 0.446875 12 1 12H3.05938C3.25625 10.5188 4.15 9.27188 5.40938 8.58125Z',
  MenuDots:
    'M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z',
  EditCandidateCategory:
    'M3.33341 13.3335H12.6667C12.8436 13.3335 13.0131 13.4037 13.1382 13.5288C13.2632 13.6538 13.3334 13.8234 13.3334 14.0002C13.3334 14.177 13.2632 14.3465 13.1382 14.4716C13.0131 14.5966 12.8436 14.6668 12.6667 14.6668H3.33341C3.1566 14.6668 2.98703 14.5966 2.86201 14.4716C2.73699 14.3465 2.66675 14.177 2.66675 14.0002C2.66675 13.8234 2.73699 13.6538 2.86201 13.5288C2.98703 13.4037 3.1566 13.3335 3.33341 13.3335ZM2.66675 10.0002L9.33341 3.3335L11.3334 5.3335L4.66675 12.0002H2.66675V10.0002ZM10.0001 2.66683L11.3334 1.3335L13.3334 3.3335L11.9994 4.6675L10.0001 2.66683Z',
  GreenTick:
    'M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM10.001 16.413L6.288 12.708L7.7 11.292L9.999 13.587L15.293 8.293L16.707 9.707L10.001 16.413Z',
  BasicDetailsBGV:
    'M11.9987 1.33203H3.9987C2.52594 1.33203 1.33203 2.52594 1.33203 3.9987V11.9987C1.33203 13.2415 2.18224 14.2858 3.33277 14.5815C3.54563 14.6363 3.76876 14.6654 3.9987 14.6654H11.9987C12.2286 14.6654 12.4518 14.6363 12.6646 14.5815C13.8152 14.2858 14.6654 13.2415 14.6654 11.9987V3.9987C14.6654 2.52594 13.4715 1.33203 11.9987 1.33203ZM9.9987 5.9987C9.9987 4.89413 9.10327 3.9987 7.9987 3.9987C6.89413 3.9987 5.9987 4.89413 5.9987 5.9987C5.9987 7.10327 6.89413 7.9987 7.9987 7.9987C9.10327 7.9987 9.9987 7.10327 9.9987 5.9987ZM4.76708 11.3424C5.1326 10.187 6.44085 9.33203 7.9987 9.33203C9.55655 9.33203 10.8648 10.187 11.2303 11.3424C11.3414 11.6934 11.0336 11.9987 10.6654 11.9987H5.33203C4.96384 11.9987 4.65602 11.6934 4.76708 11.3424Z',
  ContactDetailsBGV:
    'M14 12.6667V11.5694C14 11.0242 13.6681 10.5339 13.1619 10.3314L11.8058 9.78897C11.1619 9.53143 10.4281 9.81041 10.118 10.4307L10 10.6667C10 10.6667 8.33333 10.3333 7 9C5.66667 7.66667 5.33333 6 5.33333 6L5.56934 5.88199C6.18959 5.57187 6.46857 4.83809 6.21103 4.19424L5.66859 2.83815C5.46611 2.33194 4.97583 2 4.43062 2H3.33333C2.59695 2 2 2.59695 2 3.33333C2 9.22437 6.77563 14 12.6667 14C13.403 14 14 13.403 14 12.6667Z',
  IdentityBGV:
    'M14.1667 2H1.83333C0.822 2 0 2.822 0 3.83333V12.1667C0 13.178 0.822 14 1.83333 14H14.1667C15.178 14 16 13.178 16 12.1667V3.83333C16 2.822 15.178 2 14.1667 2ZM5 4.66667C5.91867 4.66667 6.66667 5.41467 6.66667 6.33333C6.66667 7.252 5.91867 8 5 8C4.08133 8 3.33333 7.252 3.33333 6.33333C3.33333 5.41467 4.08133 4.66667 5 4.66667ZM8 10.8333C8 11.1093 7.776 11.3333 7.5 11.3333H2.5C2.224 11.3333 2 11.1093 2 10.8333V10.5C2 9.48867 2.822 8.66667 3.83333 8.66667H6.16667C7.178 8.66667 8 9.48867 8 10.5V10.8333ZM13.5 11.3333H9.83333C9.55733 11.3333 9.33333 11.1093 9.33333 10.8333C9.33333 10.5573 9.55733 10.3333 9.83333 10.3333H13.5C13.776 10.3333 14 10.5573 14 10.8333C14 11.1093 13.776 11.3333 13.5 11.3333ZM13.5 8.66667H9.83333C9.55733 8.66667 9.33333 8.44267 9.33333 8.16667C9.33333 7.89067 9.55733 7.66667 9.83333 7.66667H13.5C13.776 7.66667 14 7.89067 14 8.16667C14 8.44267 13.776 8.66667 13.5 8.66667ZM13.5 6H9.83333C9.55733 6 9.33333 5.776 9.33333 5.5C9.33333 5.224 9.55733 5 9.83333 5H13.5C13.776 5 14 5.224 14 5.5C14 5.776 13.776 6 13.5 6Z',
  EmploymentBGV:
    'M9.45208 8.50484V9.66484C9.45208 9.70205 9.44475 9.7389 9.43052 9.77327C9.41628 9.80765 9.39541 9.83888 9.3691 9.86519C9.34279 9.8915 9.31155 9.91237 9.27718 9.92661C9.2428 9.94085 9.20596 9.94818 9.16875 9.94818H6.83542C6.76027 9.94818 6.68821 9.91833 6.63507 9.86519C6.58193 9.81206 6.55208 9.73999 6.55208 9.66484V8.50484L0.71875 6.97151V11.9982C0.71875 12.5153 0.924196 13.0113 1.28989 13.377C1.47097 13.5581 1.68593 13.7017 1.92252 13.7997C2.1591 13.8977 2.41267 13.9482 2.66875 13.9482H13.3354C13.8526 13.9482 14.3486 13.7427 14.7143 13.377C15.08 13.0113 15.2854 12.5153 15.2854 11.9982V6.97151L9.45208 8.50484ZM13.3354 3.38151H9.95208V1.99818C9.95208 1.92303 9.92223 1.85097 9.8691 1.79783C9.81596 1.74469 9.7439 1.71484 9.66875 1.71484H6.33542C6.29821 1.71484 6.26137 1.72217 6.22699 1.73641C6.19261 1.75065 6.16138 1.77152 6.13507 1.79783C6.10876 1.82414 6.08789 1.85537 6.07365 1.88975C6.05941 1.92413 6.05208 1.96097 6.05208 1.99818V3.38151H2.66875C2.15158 3.38151 1.65559 3.58696 1.28989 3.95265C0.924196 4.31835 0.71875 4.81434 0.71875 5.33151V6.38818H0.742083L7.03875 8.05484H8.96542L15.2621 6.38818H15.2854V5.33151C15.2854 4.81434 15.08 4.31835 14.7143 3.95265C14.3486 3.58696 13.8526 3.38151 13.3354 3.38151ZM9.38542 3.38151H6.61875V2.28151H9.38542V3.38151ZM7.11875 8.61484V9.38151H8.88542V8.61484H7.11875Z',
  EducationBGV:
    'M15.9078 5.66004V12.8158C15.9078 12.9806 15.8424 13.1386 15.7259 13.255C15.6094 13.3715 15.4514 13.437 15.2866 13.437C15.1219 13.437 14.9639 13.3715 14.8474 13.255C14.7309 13.1386 14.6655 12.9806 14.6655 12.8158V7.69248L10.0689 9.88891C9.58423 10.175 9.03094 10.324 8.46816 10.32C7.87797 10.3209 7.29849 10.1623 6.79103 9.86095L2.20686 7.67881C1.84651 7.48504 1.54458 7.19837 1.3324 6.84855C1.12022 6.49872 1.00552 6.09849 1.00019 5.68938C0.99487 5.28027 1.09912 4.87719 1.30213 4.52197C1.50513 4.16674 1.7995 3.87231 2.15468 3.66922C2.17145 3.65928 2.18947 3.64997 2.20686 3.64127L6.83886 1.43118C7.33893 1.14399 7.90648 0.995205 8.48313 1.00012C9.05978 1.00503 9.62472 1.16347 10.1198 1.45913L14.7009 3.64127C15.0624 3.84127 15.3644 4.13341 15.5764 4.48799C15.7884 4.84257 15.9027 5.24696 15.9078 5.66004ZM8.46754 11.5611C7.67281 11.5628 6.89173 11.3545 6.20341 10.9573L3.48459 9.6597V11.3368C3.48496 11.9999 3.69725 12.6456 4.09049 13.1795C4.48372 13.7134 5.03731 14.1077 5.67045 14.3047C6.57507 14.5645 7.51278 14.6907 8.45387 14.6793C9.39509 14.6898 10.3328 14.5628 11.2373 14.3023C11.8704 14.1052 12.424 13.7109 12.8173 13.177C13.2105 12.6431 13.4228 11.9974 13.4232 11.3343V9.6628L10.6534 10.9859C9.98854 11.3678 9.2343 11.5667 8.46754 11.5623V11.5611Z',
  AddressBGV:
    'M12.7441 1.96473C11.4772 0.697753 9.79263 0 8.00093 0C6.20921 0 4.5247 0.697721 3.25766 1.96473C1.99072 3.23167 1.29297 4.91611 1.29297 6.70784C1.29297 8.49956 1.99072 10.1841 3.25766 11.451L7.66949 15.8627C7.76103 15.9543 7.88099 16 8.00093 16C8.1209 16 8.24087 15.9542 8.33237 15.8627L12.744 11.4511C14.011 10.1841 14.7087 8.4996 14.7088 6.70787C14.7087 4.91608 14.011 3.23164 12.7441 1.96473ZM10.1076 8.81441C9.54488 9.3771 8.79675 9.68701 8.00096 9.68701C7.20518 9.68701 6.45705 9.37713 5.89439 8.81444C5.33167 8.25175 5.0218 7.50362 5.0218 6.70784C5.0218 5.91209 5.33167 5.16396 5.89439 4.60124C6.45705 4.03855 7.20518 3.72867 8.00096 3.72867C8.79675 3.72867 9.54488 4.03855 10.1076 4.60124C10.6703 5.1639 10.9802 5.91202 10.9802 6.70781C10.9801 7.50359 10.6702 8.25175 10.1076 8.81441Z',
  ConsentBGV:
    'M8.41667 1.081V3.33333C8.41667 5.37372 10.0707 7.02778 12.1111 7.02778H14.3634C14.4169 7.25721 14.4444 7.49374 14.4444 7.73311V11.8889C14.4444 13.6071 13.0516 15 11.3333 15H5.11111C3.39289 15 2 13.6071 2 11.8889V4.11111C2 2.39289 3.39289 1 5.11111 1H7.71134C7.95071 1 8.18723 1.02759 8.41667 1.081ZM9.58333 1.62621V3.33333C9.58333 4.72939 10.7151 5.86111 12.1111 5.86111H13.8182C13.7315 5.74596 13.6363 5.63634 13.5332 5.53322L9.91122 1.91122C9.8081 1.8081 9.69848 1.71296 9.58333 1.62621ZM4.52778 8C4.52778 7.67783 4.78894 7.41667 5.11111 7.41667H8.22222C8.54439 7.41667 8.80556 7.67783 8.80556 8C8.80556 8.32217 8.54439 8.58333 8.22222 8.58333H5.11111C4.78894 8.58333 4.52778 8.32217 4.52778 8ZM5.11111 10.5278C4.78894 10.5278 4.52778 10.7889 4.52778 11.1111C4.52778 11.4333 4.78894 11.6944 5.11111 11.6944H11.3333C11.6555 11.6944 11.9167 11.4333 11.9167 11.1111C11.9167 10.7889 11.6555 10.5278 11.3333 10.5278H5.11111Z',
  TickBGV:
    'M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM10.0234 4.71406L6.73281 9.27656C6.68682 9.34076 6.62619 9.39306 6.55595 9.42914C6.48571 9.46523 6.40787 9.48405 6.32891 9.48405C6.24994 9.48405 6.17211 9.46523 6.10186 9.42914C6.03162 9.39306 5.97099 9.34076 5.925 9.27656L3.97656 6.57656C3.91719 6.49375 3.97656 6.37813 4.07812 6.37813H4.81094C4.97031 6.37813 5.12187 6.45469 5.21562 6.58594L6.32812 8.12969L8.78438 4.72344C8.87813 4.59375 9.02812 4.51562 9.18906 4.51562H9.92188C10.0234 4.51562 10.0828 4.63125 10.0234 4.71406Z',
  EditLarge:
    'M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C20.8027 6.94749 20.8763 6.8376 20.9264 6.71662C20.9766 6.59565 21.0024 6.46597 21.0024 6.335C21.0024 6.20403 20.9766 6.07435 20.9264 5.95338C20.8763 5.8324 20.8027 5.72251 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z',
  EmailPreferencesLarge:
    'M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z',
  DeleteLarge:
    'M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z'
};
