import { toast } from 'react-toastify';

import {
  getCandidates as getCandidatesAPI,
  editCandidate as editCandidateAPI,
  getStatusCount as getStatusCountAPI,
  getInsufficiencies as getInsufficienciesAPI,
  searchCandidates as searchCandidatesAPI,
  getCategories as getCategoriesAPI,
  addCategory as addCategoryAPI,
  editCategory as editCategoryAPI,
  deleteCategory as deleteCategoryAPI,
  getCompanyServices as getCompanyServicesAPI,
  getAdministrators as getAdministratorsAPI,
  addAdministrators as addAdministratorsAPI,
  editAdministrators as editAdministratorsAPI,
  deleteAdministrators as deleteAdministratorsAPI,
  getCandidateFormConfig as getCandidateFormConfigAPI,
  fetchCandidateDetails as fetchCandidateDetailsAPI,
  deleteCandidate as deleteCandidateAPI,
  editCandidateCategory as editCandidateCategoryAPI,
  editCandidateTag as editCandidateTagAPI,
  getTags as getTagsAPI,
  addTag as addTagAPI,
  editTag as editTagAPI,
  deleteTag as deleteTagAPI,
  getNewInsufficiencies as getNewInsufficienciesAPI,
  getEmailPreferences as getEmailPreferencesAPI,
  getAddCandidateConfig as getAddCandidateConfigAPI,
  getViewBuyPackages as getViewBuyPackagesAPI,
  getPurchaseHistory as getPurchaseHistoryAPI,
  getCreditTransactionHistory as getCreditTransactionHistoryAPI,
  getInsuffCredits as getInsuffCreditsAPI,
  getApprovalList as getApprovalListAPI,
  getPaymentDetails as getPaymentDetailsAPI,
  uploadCandidateCSV as uploadCandidateCSVAPI,
  getAddressGeocode as getAddressGeocodeAPI,
  getUsageLogs as getUsageLogsAPI,
  getSecuritySettings as getSecuritySettingsAPI,
  postSecuritySettings as postSecuritySettingsAPI,
  getWalletDetail as getWalletDetailAPI,
  getPurchaseOrders as getPurchaseOrderAPI,
  getRequestPaymentUsers as getRequestPaymentUsersAPI,
  getAdditionalChargeSettings as getAdditionalChargeSettingsAPI,
  updateAdditionalChargeSettings as updateAdditionalChargeSettingsAPI,
  getIntegrations as getIntegrationsAPI,
  getIntegrationFieldSubtypeMapping as getIntegrationFieldSubtypeMappingAPI,
  getGreenHouseCustomFields as getGreenHouseCustomFieldsAPI,
  getGreenHouseCustomFieldsJobs,
  expirePasswordAPI,
  getAlumniTableData as getAlumniTableDataAPI,
  getAlumniRequestTrends as getAlumniRequestTrendsAPI,
  getAlumniRecordTrends as getAlumniRecordTrendsAPI,
  getAlumniRequestOverview as getAlumniRequestOverviewAPI,
  getAlumniRecordOverview as getAlumniRecordOverviewAPI,
  getHRMSSyncData as getHRMSSyncDataAPI,
  getBrandSettings as getBrandSettingsAPI,
  editBrandSettings as editBrandSettingsAPI,
  getAlumniSettings as getAlumniSettingsAPI,
  editAlumniSettings as editAlumniSettingsAPI,
  uploadExEmpCSV as uploadExEmpCSVAPI,
  referHRContacts as referHRContactsAPI,
  getDAVLogs as getDAVLogsAPI,
  getReferralsAPI,
  putCheckOnHoldOrCloseAPI
} from '../api/company';

import store from '../store';
import { errToastMessage, toCamelCase, toCamelCaseTemp } from '../utils/utilities';
import { getErrorMessage } from '../utils/utilities';
import { updateProfile } from './authentication';

export const INVALIDATE_CANDIDATES = 'INVALIDATE_CANDIDATES';
export const REQUEST_CANDIDATES = 'REQUEST_CANDIDATES';
export const SET_CANDIDATES = 'SET_CANDIDATES';

export const SET_CANDIDATE_INFO = 'SET_CANDIDATE_INFO';
export const UPDATE_CANDIDATE_INFO = 'UPDATE_CANDIDATE_INFO';

export const UPDATE_STATUS_COUNT = 'UPDATE_STATUS_COUNT';

export const INVALIDATE_CATEGORIES = 'INVALIDATE_CATEGORIES';
export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';

export const INVALIDATE_INSUFFICIENCIES = 'INVALIDATE_INSUFFICIENCIES';
export const REQUEST_INSUFFICIENCIES = 'REQUEST_INSUFFICIENCIES';
export const SET_INSUFFICIENCIES = 'SET_INSUFFICIENCIES';

export const INVALIDATE_ADMINISTRATORS = 'INVALIDATE_ADMINISTRATORS';
export const REQUEST_ADMINISTRATORS = 'REQUEST_ADMINISTRATORS';
export const SET_ADMINISTRATORS = 'SET_ADMINISTRATORS';
export const UPDATE_ADMINISTRATORS = 'UPDATE_ADMINISTRATORS';

export const INVALIDATE_SEARCH = 'INVALIDATE_SEARCH';
export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';

export const UPDATE_COMPANY_SERVICES = 'UPDATE_COMPANY_SERVICES';

export const UPDATE_FORM_CONFIG = 'UPDATE_FORM_CONFIG';

export const INVALIDATE_TAGS = 'INVALIDATE_TAGS';
export const REQUEST_TAGS = 'REQUEST_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const UPDATE_TAGS = 'UPDATE_TAGS';

export const INVALIDATE_NEW_INSUFFICIENCIES = 'INVALIDATE_NEW_INSUFFICIENCIES';
export const SET_NEW_INSUFFICIENCIES = 'SET_NEW_INSUFFICIENCIES';

export const INVALIDATE_EMAIL_PREFERENCES = 'INVALIDATE_EMAIL_PREFERENCES';
export const SET_EMAIL_PREFERENCES = 'SET_EMAIL_PREFERENCES';
export const REQUEST_EMAIL_PREFERENCES = 'REQUEST_EMAIL_PREFERENCES';

export const SET_PURCHASE_HISTORY = 'SET_PURCHASE_HISTORY';
export const INVALIDATE_PURCHASE_HISTORY = 'INVALIDATE_PURCHASE_HISTORY';
export const REQUEST_PURCHASE_HISTORY = 'REQUEST_PURCHASE_HISTORY';
export const INVALIDATE_ADD_CANDIDATE_CONFIG = 'INVALIDATE_ADD_CANDIDATE_CONFIG';
export const REQUEST_ADD_CANDIDATE_CONFIG = 'REQUEST_ADD_CANDIDATE_CONFIG';
export const SET_ADD_CANDIDATE_CONFIG = 'SET_ADD_CANDIDATE_CONFIG';

export const INVALIDATE_VIEW_BUY_PACKAGES = 'INVALIDATE_VIEW_BUY_PACKAGES';
export const REQUEST_VIEW_BUY_PACKAGES = 'REQUEST_VIEW_BUY_PACKAGES';
export const SET_VIEW_BUY_PACKAGES = 'SET_VIEW_BUY_PACKAGES';

export const INVALIDATE_CREDIT_TRANSACTION_HISTORY = 'INVALIDATE_CREDIT_TRANSACTION_HISTORY';
export const REQUEST_CREDIT_TRANSACTION_HISTORY = 'REQUEST_CREDIT_TRANSACTION_HISTORY';
export const SET_CREDIT_TRANSACTION_HISTORY = 'SET_CREDIT_TRANSACTION_HISTORY';

export const INVALIDATE_INSUFF_CREDITS = 'INVALIDATE_INSUFF_CREDITS';
export const REQUEST_INSUFF_CREDITS = 'REQUEST_INSUFF_CREDITS';
export const SET_INSUFF_CREDITS = 'SET_INSUFF_CREDITS';

export const REQUEST_APPROVAL_LIST = 'REQUEST_APPROVAL_LIST';
export const SET_APPROVAL_LIST = 'SET_APPROVAL_LIST';
export const REQUEST_PAYMENT_DETAILS = 'REQUEST_PAYMENT_DETAILS';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_BULK_UPLOAD_DATA = 'SET_BULK_UPLOAD_DATA';
export const INVALIDATE_BULK_UPLOAD_DATA = 'INVALIDATE_BULK_UPLOAD_DATA';
export const SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA = 'SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA';
export const GET_ADDRESS_COMPONENT = 'GET_ADDRESS_COMPONENT';
export const SET_ADDRESS_COMPONENT = 'SET_ADDRESS_COMPONENT';
export const INVALIDATE_ADDRESS_COMPONENT = 'INVALIDATE_ADDRESS_COMPONENT';
// company usage lpogs
export const REQUEST_USAGE_LOGS = 'REQUEST_USAGE_LOGS';
export const SET_USAGE_LOGS = 'SET_USAGE_LOGS';
export const INVALIDATE_USAGE_LOGS = 'INVALIDATE_USAGE_LOGS';
// Payment credit transaction
export const INVALIDATE_CREDIT_TRANSACTION_DETAILS = 'INVALIDATE_CREDIT_TRANSACTION_DETAILS';
export const REQUEST_CREDIT_TRANSACTION_DETAILS = 'REQUEST_CREDIT_TRANSACTION_DETAILS';
export const SET_CREDIT_TRANSACTION_DETAILS = 'SET_CREDIT_TRANSACTION_DETAILS';

//security settings
export const REQUEST_SECURITY_SETTINGS_CONFIGURATION = 'REQUEST_SECURITY_SETTINGS_CONFIGURATION';
export const SET_SECURITY_SETTINGS_CONFIGURATION = 'SET_SECURITY_SETTINGS_CONFIGURATION';
export const INVALIDATE_SECURITY_SETTINGS_CONFIGURATION =
  'INVALIDATE_SECURITY_SETTINGS_CONFIGURATION';

//Integration Data
export const SET_INTEGRATION_DATA = 'SET_INTEGRATION_DATA';
export const INVALIDATE_INTEGRATION_DATA = 'INVALIDATE_INTEGRATION_DATA';
export const SET_FREQUENT_INTEGRATION_DATA = 'SET_FREQUENT_INTEGRATION_DATA';
export const REQUEST_FREQUENT_INTEGRATION_DATA = 'REQUEST_FREQUENT_INTEGRATION_DATA';
export const INVALIDATE_FREQUENT_INTEGRATION_DATA = 'INVALIDATE_FREQUENT_INTEGRATION_DATA';
export const REQUEST_INTEGRATION_DATA = 'REQUEST_INTEGRATION_DATA';
export const SET_GREENHOUSE_FIELD = 'SET_GREENHOUSE_FIELD';
export const SET_GREENHOUSE_CUSTOM_FIELD_JOBS = 'SET_GREENHOUSE_CUSTOM_FIELD_JOBS';
export const SET_INTEGRATION_FIELD_SUBTYPE_MAPPING = 'SET_INTEGRATION_FIELD_SUBTYPE_MAPPING';
export const REQUEST_INTEGRATION_FIELD_SUBTYPE_MAPPING =
  'REQUEST_INTEGRATION_FIELD_SUBTYPE_MAPPING';
export const INVALIDATE_INTEGRATION_FIELD_SUBTYPE_MAPPING =
  'INVALIDATE_INTEGRATION_FIELD_SUBTYPE_MAPPING';

//Wallet Detail
export const REQUEST_WALLET_DETAIL = 'REQUEST_WALLET_DETAIL';
export const SET_WALLET_DETAIL = 'SET_WALLET_DETAIL';
export const INVALIDATE_WALLET_DETAIL = 'INVALIDATE_WALLET_DETAIL';

// Tier select
export const SET_SELECTED_TIERS = 'SET_SELECTED_TIERS';
export const INVALIDATE_SELECTED_TIERS = 'INVALIDATE_SELECTED_TIERS';
export const GET_SELECTED_TIERS = 'GET_SELECTED_TIERS';

// purchase order
export const REQUEST_PURCHASE_ORDER = 'REQUEST_PURCHASE_ORDER';
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER';
export const INVALIDATE_PURCHASE_ORDER = 'INVALIDATE_PURCHASE_ORDER';

// payment request users
export const REQUEST_PAYMENT_USERS = 'REQUEST_PAYMENT_USERS';
export const SET_PAYMENT_USERS = 'SET_PAYMENT_USERS';
export const INVALIDATE_PAYMENT_USERS = 'INVALIDATE_PAYMENT_USERS';

// Zoho connection
export const INVALIDATE_ZOHO_CONNECTION = 'INVALIDATE_ZOHO_CONNECTION';
export const SET_ZOHO_CONNECTION = 'SET_ZOHO_CONNECTION';
export const REQUEST_ZOHO_CONNECTION = 'REQUEST_ZOHO_CONNECTION';

// Additional Charges
export const REQUEST_ADDITIONAL_CHARGE_SETTINGS = 'REQUEST_ADDITIONAL_CHARGE_SETTINGS';
export const SET_ADDITIONAL_CHARGE_SETTINGS = 'SET_ADDITIONAL_CHARGE_SETTINGS';
export const INVALIDATE_ADDITIONAL_CHARGE_SETTINGS = 'INVALIDATE_ADDITIONAL_CHARGE_SETTINGS';

// Password Expiry
export const REQ_EXPIRE_PASSWORD = 'REQ_EXPIRE_PASSWORD';
export const SET_EXPIRE_PASSWORD_RES = 'SET_EXPIRE_PASSWORD_RES';

// Alumni Table Data
export const REQUEST_ALUMNI_TABLE_DATA = 'REQUEST_ALUMNI_TABLE_DATA';
export const SET_ALUMNI_TABLE_DATA = 'SET_ALUMNI_TABLE_DATA';

// Alumni Request trend
export const REQUEST_ALUMNI_REQUEST_TREND = 'REQUEST_ALUMNI_REQUEST_TREND';
export const SET_ALUMNI_REQUEST_TREND = 'SET_ALUMNI_REQUEST_TREND';

// Alumni Records trend
export const REQUEST_ALUMNI_RECORDS_TREND = 'REQUEST_ALUMNI_RECORDS_TREND';
export const SET_ALUMNI_RECORDS_TREND = 'SET_ALUMNI_RECORDS_TREND';

//getHRMSSyncData
export const REQUEST_HRMS_SYNC_DATA = 'REQUEST_HRMS_SYNC_DATA';
export const SET_HRMS_SYNC_DATA = 'SET_HRMS_SYNC_DATA';

//getBrandSettings
export const REQUEST_BRAND_SETTINGS = 'REQUEST_BRAND_SETTINGS';
export const SET_BRAND_SETTINGS = 'SET_BRAND_SETTINGS';

export const SET_GREENHOUSE_FIELDS = 'SET_GREENHOUSE_FIELDS';

export const SET_ALUMNI_UPLOAD_DATA = 'SET_ALUMNI_UPLOAD_DATA';
export const INVALIDATE_ALUMNI_UPLOAD_DATA = 'INVALIDATE_ALUMNI_UPLOAD_DATA';

// DAV LOGS

export const REQUEST_DAV_LOGS = 'REQUEST_DAV_LOGS';
export const SET_DAV_LOGS = 'SET_DAV_LOGS';
export const INVALIDATE_DAV_LOGS = 'INVALIDATE_DAV_LOGS';

export const requestBrandSettings = (data) => {
  return {
    type: REQUEST_BRAND_SETTINGS,
    data
  };
};

export const setBrandSettings = (data) => {
  return {
    type: SET_BRAND_SETTINGS,
    data
  };
};

//getAlumniSettings
export const REQUEST_ALUMNI_SETTINGS = 'REQUEST_ALUMNI_SETTINGS';
export const SET_ALUMNI_SETTINGS = 'SET_ALUMNI_SETTINGS';

export const requestAlumniSettings = (data) => {
  return {
    type: REQUEST_ALUMNI_SETTINGS,
    data
  };
};

export const setAlumniSettings = (data) => {
  return {
    type: SET_ALUMNI_SETTINGS,
    data
  };
};

export const requestAlumniRequestTrend = (data) => {
  return {
    type: REQUEST_ALUMNI_REQUEST_TREND,
    data
  };
};

export const setAlumniRequestTrend = (data) => {
  return {
    type: SET_ALUMNI_REQUEST_TREND,
    data
  };
};

export const requestAlumniRecordsTrend = (data) => {
  return {
    type: REQUEST_ALUMNI_RECORDS_TREND,
    data
  };
};

export const setAlumniRecordsTrend = (data) => {
  return {
    type: SET_ALUMNI_RECORDS_TREND,
    data
  };
};

// Alumni Request Overview
export const REQUEST_ALUMNI_REQUEST_OVERVIEW = 'REQUEST_ALUMNI_REQUEST_OVERVIEW';
export const SET_ALUMNI_REQUEST_OVERVIEW = 'SET_ALUMNI_REQUEST_OVERVIEW';

// Alumni Records Overview
export const REQUEST_ALUMNI_RECORD_OVERVIEW = 'REQUEST_ALUMNI_RECORD_OVERVIEW';
export const SET_ALUMNI_RECORD_OVERVIEW = 'SET_ALUMNI_RECORD_OVERVIEW';

export const requestAlumniRequestOverview = (data) => {
  return {
    type: REQUEST_ALUMNI_REQUEST_OVERVIEW,
    data
  };
};

export const setAlumniRequestOverview = (data) => {
  return {
    type: SET_ALUMNI_REQUEST_OVERVIEW,
    data
  };
};

export const requestAlumniRecordOverview = (data) => {
  return {
    type: REQUEST_ALUMNI_RECORD_OVERVIEW,
    data
  };
};

export const setAlumniRecordOverview = (data) => {
  return {
    type: SET_ALUMNI_RECORD_OVERVIEW,
    data
  };
};

//getHRMSSyncData
export const requestHRMSSyncData = (data) => {
  return {
    type: REQUEST_HRMS_SYNC_DATA,
    data
  };
};

export const setHRMSSyncData = (data) => {
  return {
    type: SET_HRMS_SYNC_DATA,
    data
  };
};

export const reqAlumniTableData = (data) => {
  return {
    type: REQUEST_ALUMNI_TABLE_DATA,
    data
  };
};

export const setAlumniTableData = (data) => {
  return {
    type: SET_ALUMNI_TABLE_DATA,
    data
  };
};
//Referral Data
export const SET_REFERRAL_DATA = 'SET_REFERRAL_DATA';

export const reqExpirePassword = (data) => {
  return {
    type: REQ_EXPIRE_PASSWORD,
    data
  };
};

export const setExpirePasswordRes = (data) => {
  return {
    type: SET_EXPIRE_PASSWORD_RES,
    data
  };
};

export const setAdditionalChargeSettings = (data) => {
  return {
    type: SET_ADDITIONAL_CHARGE_SETTINGS,
    data
  };
};

export const requestAdditionalChargeSettings = (data) => {
  return {
    type: REQUEST_ADDITIONAL_CHARGE_SETTINGS,
    data
  };
};

export const invalidateAdditionalChargeSettings = (data) => {
  return {
    type: INVALIDATE_ADDITIONAL_CHARGE_SETTINGS,
    data
  };
};

export const setSecuritySettingsConfiguration = (data) => {
  return {
    type: SET_SECURITY_SETTINGS_CONFIGURATION,
    data
  };
};

export const invalidateSecuritySettingsConfiguration = (data) => {
  return {
    type: INVALIDATE_SECURITY_SETTINGS_CONFIGURATION,
    data
  };
};

export const requestSecuritySettingsConfiguration = (data) => {
  return {
    type: REQUEST_SECURITY_SETTINGS_CONFIGURATION,
    data
  };
};

export const requestIntegrationData = (data) => {
  return {
    type: REQUEST_INTEGRATION_DATA,
    data
  };
};
export const setIntegrationData = (data) => {
  return {
    type: SET_INTEGRATION_DATA,
    data
  };
};

export const invalidateIntegrationData = (data) => {
  return {
    type: INVALIDATE_INTEGRATION_DATA,
    data
  };
};

export const requestFrequentIntegrations = (data) => {
  return {
    type: REQUEST_FREQUENT_INTEGRATION_DATA,
    data
  };
};

export const setFrequentIntegrations = (data) => {
  return {
    type: SET_FREQUENT_INTEGRATION_DATA,
    data
  };
};

export const invalidateFrequentIntegrations = (data) => {
  return {
    type: INVALIDATE_FREQUENT_INTEGRATION_DATA,
    data
  };
};

export const setIntegrationFieldSubtypeMapping = (data) => {
  return {
    type: SET_INTEGRATION_FIELD_SUBTYPE_MAPPING,
    data
  };
};
export const requestIntegrationFieldSubtypeMapping = (data) => {
  return {
    type: REQUEST_INTEGRATION_FIELD_SUBTYPE_MAPPING,
    data
  };
};

export const invalidateIntegrationFieldSubtypeMapping = () => {
  return {
    type: INVALIDATE_INTEGRATION_FIELD_SUBTYPE_MAPPING
  };
};
export const setGreenHouseCustomFields = (data) => {
  return {
    type: SET_GREENHOUSE_FIELDS,
    data
  };
};
export const setGreenHouseCustomFieldJobs = (data) => {
  return {
    type: SET_GREENHOUSE_CUSTOM_FIELD_JOBS,
    data
  };
};

export const setReferralData = (data) => {
  return {
    type: SET_REFERRAL_DATA,
    data
  };
};

export const getAdditionalChargeSettings = (params) => (dispatch) => {
  // Query params added
  dispatch(requestAdditionalChargeSettings());
  return getAdditionalChargeSettingsAPI(params)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setAdditionalChargeSettings(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error;
    });
};

export const updateAdditionalChargeSettings = (data) => (dispatch) => {
  return updateAdditionalChargeSettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      toast.success('Settings updated successfully');
      dispatch(setAdditionalChargeSettings(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error?.response?.data;
    });
};

export const editBrandSettings = (data) => (dispatch) => {
  return editBrandSettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      toast.success('Details Updated Successfully');
      dispatch(setBrandSettings(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error?.response?.data;
    });
};

export const editAlumniSettings = (data) => (dispatch) => {
  return editAlumniSettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      toast.success('Details Updated Successfully');
      dispatch(setAlumniSettings(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error?.response?.data;
    });
};

export const getSecuritySettings = (companyId) => (dispatch) => {
  dispatch(requestSecuritySettingsConfiguration());
  return getSecuritySettingsAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setSecuritySettingsConfiguration(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error;
    });
};

export const postSecuritySettings = (data) => (dispatch) => {
  return postSecuritySettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      return resp.message;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const invalidateCandidates = () => {
  return {
    type: INVALIDATE_CANDIDATES
  };
};

export const requestCandidates = () => {
  return {
    type: REQUEST_CANDIDATES
  };
};

export const setCandidates = (index, data) => {
  return {
    type: SET_CANDIDATES,
    index,
    data
  };
};

export const setCandidateInfo = (data) => {
  return {
    type: SET_CANDIDATE_INFO,
    data
  };
};

export const updateCandidateInfo = (data) => {
  return {
    type: UPDATE_CANDIDATE_INFO,
    data
  };
};

export const updateStatusCount = (data) => {
  return {
    type: UPDATE_STATUS_COUNT,
    data
  };
};

export const setCategories = (data) => {
  return {
    type: SET_CATEGORIES,
    data
  };
};

export const updateCategories = (data) => {
  return {
    type: UPDATE_CATEGORIES,
    data
  };
};

export const invalidateInsufficiencies = () => {
  return {
    type: INVALIDATE_INSUFFICIENCIES
  };
};

export const requestInsufficiencies = () => {
  return {
    type: REQUEST_INSUFFICIENCIES
  };
};

export const setInsufficiencies = (index, data) => {
  return {
    type: SET_INSUFFICIENCIES,
    index,
    data
  };
};

export const setAdministrators = (data) => {
  return {
    type: SET_ADMINISTRATORS,
    data
  };
};

export const updateAdministrators = (data) => {
  return {
    type: UPDATE_ADMINISTRATORS,
    data
  };
};

export const invalidateSearch = () => {
  return {
    type: INVALIDATE_SEARCH
  };
};

export const requestSearch = () => {
  return {
    type: REQUEST_SEARCH
  };
};

export const setSearchResult = (data) => {
  return {
    type: SET_SEARCH_RESULT,
    data
  };
};

export const updateCompanyServices = (data) => {
  return {
    type: UPDATE_COMPANY_SERVICES,
    data
  };
};

export const updateFormConfig = (data) => {
  return {
    type: UPDATE_FORM_CONFIG,
    data
  };
};

export const setTags = (data) => {
  return {
    type: SET_TAGS,
    data
  };
};

export const updateTags = (data) => {
  return {
    type: UPDATE_TAGS,
    data
  };
};

export const setNewInsufficiencies = (data) => {
  return {
    type: SET_NEW_INSUFFICIENCIES,
    data
  };
};

export const invalidateNewInsufficiencies = () => {
  return {
    type: INVALIDATE_NEW_INSUFFICIENCIES
  };
};

export const requestEmailPreferences = () => {
  return {
    type: REQUEST_EMAIL_PREFERENCES
  };
};

export const setEmailPreferences = (data) => {
  return {
    type: SET_EMAIL_PREFERENCES,
    data
  };
};

export const invalidateEmailPreferences = () => {
  return {
    type: INVALIDATE_EMAIL_PREFERENCES
  };
};

export const setPurchaseHistory = (data) => {
  return {
    type: SET_PURCHASE_HISTORY,
    data
  };
};

export const invalidatePurchaseHistory = () => {
  return {
    type: INVALIDATE_PURCHASE_HISTORY
  };
};

export const requestPurchaseHistory = () => {
  return {
    type: REQUEST_PURCHASE_HISTORY
  };
};

export const invalidateAddCandidateConfig = () => {
  return {
    type: INVALIDATE_ADD_CANDIDATE_CONFIG
  };
};

export const requestAddCandidateConfig = () => {
  return {
    type: REQUEST_ADD_CANDIDATE_CONFIG
  };
};

export const setAddCandidateConfig = (data) => {
  return {
    type: SET_ADD_CANDIDATE_CONFIG,
    data
  };
};

export const invalidateViewBuyPackages = () => {
  return {
    type: INVALIDATE_VIEW_BUY_PACKAGES
  };
};

export const requestViewBuyPackages = () => {
  return {
    type: REQUEST_VIEW_BUY_PACKAGES
  };
};

export const setViewBuyPackages = (data) => {
  return {
    type: SET_VIEW_BUY_PACKAGES,
    data
  };
};

export const invalidateCreditTransactionHistory = () => {
  return {
    type: INVALIDATE_CREDIT_TRANSACTION_HISTORY
  };
};

export const requestCreditTransactionHistory = () => {
  return {
    type: REQUEST_CREDIT_TRANSACTION_HISTORY
  };
};

export const setCreditTransactionHistory = (index, data) => {
  return {
    type: SET_CREDIT_TRANSACTION_HISTORY,
    index,
    data
  };
};

export const invalidateInsuffCredits = () => {
  return {
    type: INVALIDATE_INSUFF_CREDITS
  };
};

export const requestInsuffCredits = () => {
  return {
    type: REQUEST_INSUFF_CREDITS
  };
};

export const setInsuffCredits = (index, data) => {
  return {
    type: SET_INSUFF_CREDITS,
    index,
    data
  };
};
export const requestCandidateApprovalList = () => {
  return {
    type: REQUEST_APPROVAL_LIST
  };
};

export const setCandidateApprovalList = (data) => {
  return {
    type: SET_APPROVAL_LIST,
    data
  };
};
export const getGeocodeAddress = () => {
  return {
    type: GET_ADDRESS_COMPONENT
  };
};
export const setGeocodeAddress = (data) => {
  return {
    type: SET_ADDRESS_COMPONENT,
    data
  };
};
export const invalidateGeocodeAddress = () => {
  return {
    type: INVALIDATE_ADDRESS_COMPONENT
  };
};

export const requestUsageLogs = () => {
  return {
    type: REQUEST_USAGE_LOGS
  };
};

export const setUsageLogs = (data) => {
  return {
    type: SET_USAGE_LOGS,
    data
  };
};
export const invalidateUsageLogs = () => {
  return {
    type: INVALIDATE_USAGE_LOGS
  };
};

// Payment credit transaction
export const requestPaymentCreditTransaction = () => {
  return {
    type: REQUEST_CREDIT_TRANSACTION_DETAILS
  };
};

export const setPaymentCreditTransaction = (data) => {
  return {
    type: SET_CREDIT_TRANSACTION_DETAILS,
    data
  };
};
export const invalidatePaymentCreditTransaction = () => {
  return {
    type: INVALIDATE_CREDIT_TRANSACTION_DETAILS
  };
};
export const requestWalletDetail = () => {
  return {
    type: REQUEST_WALLET_DETAIL
  };
};

export const setWalletDetail = (data) => {
  return {
    type: SET_WALLET_DETAIL,
    data
  };
};
export const invalidateWalletDetail = () => {
  return {
    type: INVALIDATE_WALLET_DETAIL
  };
};

export const invalidateSelectedTiers = () => {
  return {
    type: INVALIDATE_SELECTED_TIERS
  };
};
export const setSelectedTiers = (data) => {
  return {
    type: SET_SELECTED_TIERS,
    data
  };
};

export const requestPurchaseOrder = () => {
  return {
    type: REQUEST_PURCHASE_ORDER
  };
};

export const getPurchaseOrder = () => {
  return {
    type: GET_PURCHASE_ORDER
  };
};
export const setPurchaseOrder = (data) => {
  return {
    type: SET_PURCHASE_ORDER,
    data
  };
};
export const invalidatePurchaseOrder = () => {
  return {
    type: INVALIDATE_PURCHASE_ORDER
  };
};

export const requestRequestPaymentUsers = () => {
  return {
    type: REQUEST_PAYMENT_USERS
  };
};

export const invalidateRequestPaymentUsers = () => {
  return {
    type: INVALIDATE_PAYMENT_USERS
  };
};

export const setRequestPaymentUsers = (data) => {
  return {
    type: SET_PAYMENT_USERS,
    data
  };
};

// zoho connection action creators
export const requestZohoConnection = () => {
  return {
    type: REQUEST_ZOHO_CONNECTION
  };
};

export const invalidateZohoZonnection = () => {
  return {
    type: INVALIDATE_ZOHO_CONNECTION
  };
};

export const setZohoConnection = (data) => {
  return {
    type: SET_ZOHO_CONNECTION,
    data
  };
};

export const setUploadExEmpData = (data) => {
  return {
    type: SET_ALUMNI_UPLOAD_DATA,
    data
  };
};

export const requestDavLog = () => {
  return {
    type: REQUEST_DAV_LOGS
  };
};

export const invalidateDavLog = () => {
  return {
    type: INVALIDATE_DAV_LOGS
  };
};
export const setDavLog = (data) => {
  return {
    type: SET_DAV_LOGS,
    data
  };
};

// API CALL through action
export const getCandidates =
  (
    limit,
    offset,
    status = null,
    search = null,
    tag = null,
    userCategory = null,
    days = null,
    dateRange = {
      from: null,
      to: null
    },
    showAllCandidate
  ) =>
  (dispatch) => {
    dispatch(requestCandidates());
    return getCandidatesAPI(
      limit,
      offset,
      status,
      search,
      tag,
      userCategory,
      days,
      dateRange,
      showAllCandidate
    )
      .then((response) => {
        const employees = toCamelCase(response.data.data);
        dispatch(setCandidates(offset, employees));
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

export const editCandidate = (data) => {
  return editCandidateAPI(data);
};

export const deleteCandidate = (id) => {
  return deleteCandidateAPI(id);
};

export const fetchCandidateDetails = (id) => (dispatch) => {
  return fetchCandidateDetailsAPI(id).then((response) => {
    const candidateInfo = toCamelCase(response.data.data);
    dispatch(setCandidateInfo(candidateInfo));
    dispatch(updateCandidateInfo(candidateInfo));
  });
};

export const getStatusCount = () => (dispatch) => {
  return getStatusCountAPI()
    .then((response) => {
      dispatch(updateStatusCount(response.data));
      return response.data;
    })
    .catch((error) => {
      errToastMessage(error);
    });
};

export const getCategories = (limit, offset) => (dispatch) => {
  dispatch({ type: REQUEST_CATEGORIES });
  return getCategoriesAPI(limit, offset)
    .then((response) => {
      const categories = toCamelCase(response.data.data);
      dispatch(setCategories(categories));
      dispatch(updateCategories(categories));
    })
    .catch((error) => console.log(error));
};

export const addCategory = (data) => () => {
  return addCategoryAPI(data);
};

export const editCategory = (data) => () => {
  return editCategoryAPI(data);
};

export const deleteCategory = (id) => () => {
  return deleteCategoryAPI(id);
};

export const getAdministrators = (limit, offset) => (dispatch) => {
  dispatch({ type: REQUEST_ADMINISTRATORS });
  return getAdministratorsAPI(limit, offset)
    .then((response) => {
      const administrators = toCamelCase(response.data.data);
      dispatch(setAdministrators(administrators));
      dispatch(updateAdministrators(administrators));
    })
    .catch((error) => console.log(error));
};

export const invalidateAdminstrators = () => {
  return {
    type: INVALIDATE_ADMINISTRATORS
  };
};
export const requestPaymentDetails = () => {
  return {
    type: REQUEST_PAYMENT_DETAILS
  };
};

export const setPaymentDetails = (data) => {
  return {
    type: SET_PAYMENT_DETAILS,
    data
  };
};

export const addAdministrators = (data) => () => {
  return addAdministratorsAPI(data);
};

export const editAdministrators = (data) => (dispatch) => {
  return editAdministratorsAPI(data).then((response) => {
    if (store.getState().profile.profile.email === data.email) {
      let categoryList = [];
      let categoryListCopy = data.user_category_ids || [];
      categoryListCopy.map((category) => {
        let cat = store
          .getState()
          .categories.userCategories.filter((value) => value.id === category)[0];
        if (cat) {
          categoryList.push(cat);
        }
      });
      response.data.data.userCategoryMappingResponse.map((result) => {
        if (result?.deleted_at === null) {
          let c = store
            .getState()
            .categories.userCategories.filter((value) => value.id === result.user_category_id)[0];
          result['user_category'] = {
            category_name: c.categoryName,
            company_id: c.companyId,
            created_at: c.createdAt,
            deleted_at: c.deletedAt,
            id: c.id,
            updated_at: c.updatedAt,
            user_id: c.userId,
            uuid: c.uuid
          };
          categoryList.push(result);
        }
      });
      store.getState().profile.profile.name = response.data.data.user.name;
      store.getState().profile.profile.mobile = response.data.data.user.mobile;
      store.getState().profile.profile.user_category_mappings = categoryList;
      dispatch(updateProfile(store.getState().profile.profile));
    }
  });
};

export const deleteAdministrators = (id) => () => {
  return deleteAdministratorsAPI(id);
};

export const makeGlobalSearch = (query) => (dispatch) => {
  dispatch(requestSearch());
  return searchCandidatesAPI(query)
    .then((response) => {
      return dispatch(setSearchResult(toCamelCase(response.data).data));
    })
    .catch((error) => {
      errToastMessage(error);
    });
};

export const getCompanyServices = () => (dispatch) => {
  return getCompanyServicesAPI()
    .then((response) => {
      let services = toCamelCase(response.data).data;
      dispatch(updateCompanyServices(services));
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const getInsufficiencies = (limit, offset, status) => (dispatch) => {
  dispatch(requestInsufficiencies());
  return getInsufficienciesAPI(limit, offset, status)
    .then((response) => {
      let insufficienciesList = toCamelCase(response.data.data);
      dispatch(setInsufficiencies(offset, insufficienciesList));
    })
    .catch((error) => console.log(error));
};

export const getCandidateFormConfig = () => (dispatch) => {
  return getCandidateFormConfigAPI()
    .then((response) => {
      let config = toCamelCase(response.data).data;
      dispatch(updateFormConfig(config));
    })
    .catch((error) => console.log(error));
};

export const editCandidateCategory = (data) => (dispatch) => {
  return editCandidateCategoryAPI(data)
    .then((response) => {
      if (response.data.data.response.created.length) {
        let result = response.data.data.response.created[0];
        let category = store
          .getState()
          .categories.userCategories.filter(
            (userCategory) => userCategory.id === result.user_category_id
          );
        let candidateCategory = {
          candidateId: result.candidate_id,
          createdAt: result.created_at,
          deletedAt: null,
          id: result.id,
          updatedAt: result.updated_at,
          userCategory: category[0],
          userCategoryId: result.user_category_id,
          uuid: result.uuid
        };
        store.getState().candidateInfo.candidateUserCategoryMapping = candidateCategory;
      } else {
        if (!data.userCategoryId.length)
          store.getState().candidateInfo.candidateUserCategoryMapping = [];
      }
    })
    .catch((error) => {
      const data = error?.response?.data;
      const regex = /is\/are deleted/;
      const isDeletedErr = regex.test(data?.message);
      if (data?.message && !isDeletedErr) {
        errToastMessage(error);
      }
      throw isDeletedErr ? data : '';
    });
};

export const editCandidateTag = (data) => (dispatch) => {
  return editCandidateTagAPI(data)
    .then((response) => {
      let result = response.data.data.response.created;
      let tagList = [];
      data.categoryId.map((tag) => {
        let t = store
          .getState()
          .candidateInfo.candidateCompanyCategoryMappings.filter(
            (value) => value.companyCategoryId === tag
          )[0];
        if (t) {
          tagList.push(t);
        }
      });
      result.map((resultTag) => {
        let candidateTag = {
          candidateId: resultTag.candidate_id,
          companyCategory: store
            .getState()
            .tags.categories.filter((tag) => tag.id === resultTag.company_category_id)[0],
          companyCategoryId: resultTag.company_category_id,
          createdAt: resultTag.created_at,
          deletedAt: null,
          id: resultTag.id,
          updatedAt: resultTag.updated_at,
          uuid: resultTag.uuid
        };
        tagList.push(candidateTag);
      });
      store.getState().candidateInfo.candidateCompanyCategoryMappings = tagList;
    })
    .catch((error) => {
      const data = error?.response?.data;
      const regex = /is\/are deleted/;
      const isDeletedErr = regex.test(data?.message);
      if (data?.message && !isDeletedErr) {
        errToastMessage(error);
      }
      throw isDeletedErr ? data : '';
    });
};

export const getTags = (limit, offset) => (dispatch) => {
  dispatch({ type: REQUEST_TAGS });
  return getTagsAPI(limit, offset)
    .then((response) => {
      const tags = toCamelCase(response.data.data);
      dispatch(setTags(tags));
      dispatch(updateTags(tags));
    })
    .catch((error) => console.log(error));
};

export const addTag = (data) => () => {
  return addTagAPI(data);
};

export const editTag = (data) => () => {
  return editTagAPI(data);
};

export const deleteTag = (id) => () => {
  return deleteTagAPI(id);
};

export const getNewInsufficiencies = (data) => (dispatch) => {
  return getNewInsufficienciesAPI(data)
    .then((response) => {
      const insuffData = toCamelCase(response.data.data);
      dispatch(setNewInsufficiencies(insuffData));
    })
    .catch((error) => {
      errToastMessage(error);
    });
};

export const getEmailPreferences = (adminId, companyId) => (dispatch) => {
  dispatch(requestEmailPreferences());
  return getEmailPreferencesAPI(adminId, companyId)
    .then((response) => {
      const emailData = toCamelCase(response.data);
      dispatch(setEmailPreferences(emailData));
    })
    .catch((error) => console.log(error));
};

// *********** PREPAID PACKAGES**********
//Purchase history
export const getPurchaseHistory =
  (limit, offset, transactionId = '') =>
  (dispatch) => {
    dispatch(requestPurchaseHistory());
    return getPurchaseHistoryAPI(limit, offset, transactionId)
      .then((response) => {
        const data = toCamelCase(response.data.data);
        dispatch(setPurchaseHistory(data));
      })
      .catch((error) => {
        console.log(error);
        errToastMessage(error);
      });
  }; //end of : Purchase History

/**
 * Get Add candidate config
 * @param   {packageId}
 * @return  {promise}
 */
export const getAddCandidateConfig =
  (companyId = null) =>
  (dispatch) => {
    dispatch(requestAddCandidateConfig());
    return getAddCandidateConfigAPI(companyId)
      .then((response) => {
        const data = toCamelCaseTemp(response.data);
        dispatch(setAddCandidateConfig(data));
        return data;
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };
// end of func : getAddCandidateConfig

/**
 * Get Packages List
 * @param   {data object}
 * @return  {promise}
 */
export const getViewBuyPackages = (data) => (dispatch) => {
  dispatch(requestViewBuyPackages());

  return getViewBuyPackagesAPI(data)
    .then((response) => {
      const data = toCamelCaseTemp(response.data);
      dispatch(setViewBuyPackages(data.data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
// end of func : getViewBuyPackages

/**
 * Get Credit Transaction History
 * @param   {data object}
 * @return  {promise}
 */
export const getCreditTransactionHistory = (data) => (dispatch) => {
  dispatch(requestCreditTransactionHistory());

  return getCreditTransactionHistoryAPI(data)
    .then((response) => {
      const resp = toCamelCaseTemp(response.data.data);
      dispatch(setCreditTransactionHistory(data.offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
// end of func : getCreditTransactionHistory

/**
 * Get Insuff Credit
 * @param   {data object}
 * @return  {promise}
 */
export const getInsuffCredits = (data) => (dispatch) => {
  dispatch(requestInsuffCredits());

  return getInsuffCreditsAPI(data)
    .then((response) => {
      const resp = toCamelCaseTemp(response.data.data);
      dispatch(setInsuffCredits(data.offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getApprovalList =
  (
    limit,
    offset,
    from_date = null,
    to_date = null,
    status = null,
    charge_type = null,
    candidate = null
  ) =>
  (dispatch) => {
    dispatch(requestCandidateApprovalList());

    return getApprovalListAPI(limit, offset, from_date, to_date, status, charge_type, candidate)
      .then((response) => {
        const resp = toCamelCaseTemp(response.data.data);
        dispatch(setCandidateApprovalList(resp));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };
// end of func : getInsuffCredits

/**
 *  funtion to get Payment details for prepaid/postpaid company to show different banners
 */
export const getCompanyPaymentDetails = () => (dispatch) => {
  dispatch(requestPaymentDetails());

  return getPaymentDetailsAPI()
    .then((response) => {
      const resp = toCamelCaseTemp(response.data.data);
      dispatch(setPaymentDetails(resp));
      dispatch(getWalletDetail());
    })
    .catch((error) => {
      getErrorMessage(error);
    });
};

export const getPaymentAndWalletDetails = () => async (dispatch) => {
  dispatch(requestPaymentDetails());
  dispatch(requestWalletDetail());

  try {
    const [paymentResponse, walletResponse] = await Promise.all([
      getPaymentDetailsAPI(),
      getWalletDetailAPI()
    ]);

    const paymentResp = toCamelCaseTemp(paymentResponse.data.data);
    dispatch(setPaymentDetails(paymentResp));

    const walletDetails = toCamelCase(walletResponse.data);
    dispatch(
      setWalletDetail({
        ...walletDetails,
        customRecommendationOptions: walletResponse.data.custom_recommendation_options
      })
    );
  } catch (error) {
    getErrorMessage(error);
  }
};

export const uploadCandidateCSV = (data) => (dispatch) => {
  return uploadCandidateCSVAPI(data);
};

/**
 *  funtion to set bulkupload data
 */
export const setBulkUploadData = (values) => (dispatch) => {
  const { type, data } = values;
  if (type === 'empty') {
    return dispatch({
      type: INVALIDATE_BULK_UPLOAD_DATA,
      data
    });
  } else if (type === 'rows') {
    return dispatch({
      type: SET_BULK_UPLOAD_DATA,
      data
    });
  } else if (type === 'dropDownValues') {
    return dispatch({
      type: SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA,
      data
    });
  }
};

export const setIntegration = (companyId) => (dispatch) => {
  dispatch(requestIntegrationData());
  dispatch(requestFrequentIntegrations());
  return getIntegrationsAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setIntegrationData(resp.data));
      dispatch(setFrequentIntegrations(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error;
    });
};
export const getIntegrationFieldSubtypeMapping = (data) => (dispatch) => {
  dispatch(requestIntegrationFieldSubtypeMapping());
  return getIntegrationFieldSubtypeMappingAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setIntegrationFieldSubtypeMapping({ data: response.data }));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error;
    });
};
export const getGreenHouseCustomFields = (companyId) => (dispatch) => {
  return getGreenHouseCustomFieldsAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setGreenHouseCustomFields(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error;
    });
};
export const greenHouseCustomFieldJob = (companyId) => (dispatch) => {
  return getGreenHouseCustomFieldsJobs(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setGreenHouseCustomFieldJobs(resp));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error;
    });
};

export const geocodeAddress = (data) => (dispatch) => {
  dispatch(getGeocodeAddress());
  return getAddressGeocodeAPI(data)
    .then((res) => {
      dispatch(setGeocodeAddress(res.data));
    })
    .catch((err) => {
      dispatch(invalidateGeocodeAddress());
    });
};
export const fetchUsageLogs = (companyId, limit, offset) => (dispatch) => {
  dispatch(requestUsageLogs());

  return getUsageLogsAPI(companyId, limit, offset)
    .then((response) => {
      const usageLogsData = toCamelCase(response.data);
      dispatch(setUsageLogs(usageLogsData));
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidateUsageLogs());
    });
};

export const getWalletDetail = () => (dispatch) => {
  dispatch(requestWalletDetail());

  return getWalletDetailAPI()
    .then((response) => {
      const walletDetails = toCamelCase(response.data);
      dispatch(
        setWalletDetail({
          ...walletDetails,
          customRecommendationOptions: response.data.custom_recommendation_options
        })
      );
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidateWalletDetail());
    });
};
export const setActiveTiers = (data) => (dispatch) => {
  dispatch(setSelectedTiers(data));
};
export const getPurchaseOrders = (limit, offset) => (dispatch) => {
  dispatch(requestPurchaseOrder());
  return getPurchaseOrderAPI(limit, offset)
    .then((response) => {
      const purchaseOrders = toCamelCase(response.data.data);
      dispatch(setPurchaseOrder(purchaseOrders));
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidatePurchaseOrder());
    });
};
export const invalidatePurchaseOrders = () => (dispatch) => {
  dispatch(invalidatePurchaseOrder());
};

export const getRequestPaymentUsers = () => (dispatch) => {
  dispatch(requestRequestPaymentUsers());
  dispatch(requestZohoConnection);
  return getRequestPaymentUsersAPI()
    .then((res) => {
      const reqPaymentUsers = toCamelCase(res.data.data);
      dispatch(setRequestPaymentUsers(reqPaymentUsers.userDetails));
      dispatch(setZohoConnection(reqPaymentUsers.company));
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidateRequestPaymentUsers());
      dispatch(invalidateZohoZonnection());
    });
};

export const expirePassword = (data) => (dispatch) => {
  dispatch(reqExpirePassword());
  return expirePasswordAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setExpirePasswordRes(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setExpirePasswordRes({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniTablesData = (data, tableType) => (dispatch) => {
  dispatch(reqAlumniTableData());
  return getAlumniTableDataAPI(data, tableType)
    .then((response) => {
      const resp = toCamelCase(response.data.data);
      dispatch(setAlumniTableData(resp));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniTableData({}));
      errToastMessage(error);
      throw error;
    });
};
export const referHRContacts = (data) => {
  return referHRContactsAPI(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniRequestTrends = () => (dispatch) => {
  dispatch(requestAlumniRequestTrend());
  return getAlumniRequestTrendsAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRequestTrend(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRequestTrend({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniRecordsTrends = () => (dispatch) => {
  dispatch(requestAlumniRecordsTrend());
  return getAlumniRecordTrendsAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRecordsTrend(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRecordsTrend({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniRequestOverview = (data) => (dispatch) => {
  dispatch(requestAlumniRequestOverview());
  return getAlumniRequestOverviewAPI(data)
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRequestOverview(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRequestOverview({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniRecordOverview = () => (dispatch) => {
  dispatch(requestAlumniRecordOverview());
  return getAlumniRecordOverviewAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRecordOverview(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRecordOverview({}));
      errToastMessage(error);
      throw error;
    });
};

export const getHRMSSyncData = () => (dispatch) => {
  dispatch(requestHRMSSyncData());
  return getHRMSSyncDataAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setHRMSSyncData(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setHRMSSyncData({}));
      errToastMessage(error);
      throw error;
    });
};

export const getBrandSettings = () => (dispatch) => {
  dispatch(requestBrandSettings());
  return getBrandSettingsAPI()
    .then((response) => {
      const resp = toCamelCaseTemp(response);
      dispatch(setBrandSettings(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setBrandSettings({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniSettings = () => (dispatch) => {
  dispatch(requestAlumniSettings());
  return getAlumniSettingsAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniSettings(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniSettings({}));
      errToastMessage(error);
      throw error;
    });
};

export const uploadExEmpCSV = (data) => (dispatch) => {
  dispatch(setUploadExEmpData(data));
};

export const uploadExEmpCSVData = (data) => (dispatch) => {
  return uploadExEmpCSVAPI(data);
};
export const getReferrals = (data) => (dispatch) => {
  return getReferralsAPI(data)
    .then((res) => {
      const response = toCamelCase(res.data);
      dispatch(setReferralData(response));
      return response;
    })
    .catch((error) => {
      errToastMessage(error);
      throw error;
    });
};

export const getDavLogs = (id, limit, offset) => (dispatch) => {
  dispatch(requestDavLog());
  return getDAVLogsAPI(id, limit, offset)
    .then((response) => {
      const davLogs = toCamelCase(response.data);
      dispatch(setDavLog(davLogs));
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidateDavLogs());
    });
};
export const invalidateDavLogs = () => (dispatch) => {
  dispatch(invalidateDavLog());
};
// Hold Check
export const putCheckOnHold = (data) => (dispatch) => {
  return putCheckOnHoldOrCloseAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      return resp.message;
    })
    .catch((error) => {
      errToastMessage(error);
      window.location.reload();
      throw error.response.data;
    });
};

// Close Check
export const closeCheck = (data) => (dispatch) => {
  return putCheckOnHoldOrCloseAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      return resp.message;
    })
    .catch((error) => {
      errToastMessage(error);
      window.location.reload();
      throw error.response.data;
    });
};
