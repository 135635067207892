// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CVVTZQSy3rJbCx3DkIwT {
  display: inline-flex;
  align-items: center;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  top: -44px;
}

.nAt6XzRcuDgrotfgoiFt {
  border: "1px solid #38d39f";
  color: "#38d39f";
  background-color: "#e6f4ea";
}

.wJPnG7ZsviIzjCgFOacr {
  border: "1px solid #b19cd9";
  color: "#b19cd9";
  background-color: "#f4eaff";
}

._fFxy16ATsUDTfU81Afa {
  border: "1px solid #c0c0c0";
  color: "#c0c0c0";
  background-color: "#f4f4f4";
}

.uqNThafgnvw0bmQdi5s2 {
  height: 0 !important;
}

.aRdOfjcHVd8WHAGoZLq1 {
  margin-left: 5px;
  cursor: pointer;
}

.aRdOfjcHVd8WHAGoZLq1:hover {
  color: red;
}

.R4cYYAtG85RrzbPsk2Rq {
  display: none;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected-option": `CVVTZQSy3rJbCx3DkIwT`,
	"connected": `nAt6XzRcuDgrotfgoiFt`,
	"not-connected-available": `wJPnG7ZsviIzjCgFOacr`,
	"not-connected-unavailable": `_fFxy16ATsUDTfU81Afa`,
	"selected-options-wrapper": `uqNThafgnvw0bmQdi5s2`,
	"remove-option": `aRdOfjcHVd8WHAGoZLq1`,
	"no-show": `R4cYYAtG85RrzbPsk2Rq`
};
export default ___CSS_LOADER_EXPORT___;
