import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { mergeWith, isEmpty, has } from 'lodash';

import validationSchema from './BasicDetails.validation';
import logger from '../../../../../core-components/Logger';
import { errToastMessage, getErrorMessage } from '../../../../../utils/utilities';
import { capitalizeName } from '../../../../CompanyAdmin/frontend-common/utils/Utilities';
import { saveBgvCache as saveBgvCacheAPI, invalidateHrRefer } from '../../../../../actions/bgv';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data))
  };
};

const mapStateToProps = (state) => {
  return {
    cached_data: !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data },
    initial_candidate_details: !isEmpty(state.initialCandidateDetails.data)
      ? { ...state.initialCandidateDetails.data, loading: state.initialCandidateDetails.loading }
      : null,
    isLoadingBgvCache: state?.bgvCachedData?.loading,
    bgv_config: !isEmpty(state.bgvPackageConfig.data)
      ? { ...state.bgvPackageConfig.data, loading: state.bgvPackageConfig.loading }
      : null
  };
};

const formattedSaveObject = (props, values) => {
  let finalCacheObj = {};
  let saveObject = {};

  values.full_name = values.full_name.trim();
  values.uan_number = values.uan_number || null;

  if (!props.cached_data) {
    saveObject['cache_data'] = {
      basic_details: {
        ...values
      }
    };
  } else {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props.cached_data.cache_data,
        basic_details: {
          ...values
        }
      }
    };
  }
  finalCacheObj['saveObject'] = {
    ...saveObject
  };
  return finalCacheObj;
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const storedValues = {
        full_name: '',
        father_name: '',
        dob: '',
        employee_id: '',
        uan_number: '',
        employee_id_exists: false,
        gender: '',
        is_sex_optional: props.bgv_config.candidate_bgv_form_config?.is_gender_optional,
        is_uan_mandatory: false,
        uan_not_available: false
      };

      if (
        props?.cached_data &&
        !isEmpty(props.cached_data) &&
        props.cached_data &&
        !isEmpty(props.cached_data) &&
        props.cached_data.cache_data &&
        props.cached_data.cache_data.hasOwnProperty('basic_details') &&
        !isEmpty(props.cached_data.cache_data['basic_details'])
      ) {
        propsValue =
          props.cached_data &&
          !isEmpty(props.cached_data) &&
          props.cached_data.cache_data &&
          props.cached_data.cache_data.hasOwnProperty('basic_details') &&
          !isEmpty(props.cached_data.cache_data['basic_details']) &&
          props.cached_data.cache_data['basic_details'];
        propsValue['is_sex_optional'] =
          props.bgv_config.candidate_bgv_form_config?.is_gender_optional;
        if (propsValue.full_name) {
          propsValue.full_name = capitalizeName(propsValue.full_name);
        }
      } else {
        propsValue =
          (!isEmpty(props.initial_candidate_details) && {
            full_name: capitalizeName(
              props.initial_candidate_details['basic_details']['full_name']
            ),
            father_name: capitalizeName(
              props.initial_candidate_details['basic_details']['father_name']
            ),
            employee_id: props.initial_candidate_details['basic_details']['employee_id'],
            employee_id_exists: props.initial_candidate_details['basic_details']['employee_id']
              ? true
              : false,
            gender: props.initial_candidate_details['basic_details']['gender'] || '',
            uan_number: has(props.initial_candidate_details.basic_details, 'uan_number')
              ? props.initial_candidate_details['basic_details']['uan_number'] || ''
              : '',
            is_sex_optional: props.bgv_config.candidate_bgv_form_config.is_gender_optional
          }) ||
          {};
      }

      propsValue['is_uan_mandatory'] =
        props?.bgv_config?.candidate_bgv_form_config?.is_uan_mandatory;

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props, setFieldError }) => {
      if (!values.full_name) {
        setFieldError('full_name', 'Please enter a valid name');
        setSubmitting(false);
      }
      const finalCacheObj = formattedSaveObject(props, values);
      props
        .setBgvCache(finalCacheObj)
        .then(() => {
          setSubmitting(false);
          const loggerObj = {
            message: 'Basic details step',
            data: JSON.stringify(finalCacheObj),
            category: `candidate-id-${props.candidateId}`,
            subCategory: 'Basic details',
            type: 'info'
          };
          logger.push(loggerObj);
          props.nextStep(true);
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
          const loggerObj = {
            message: getErrorMessage(error),
            data: JSON.stringify(finalCacheObj),
            category: `candidate-id-${props.candidateId}`,
            subCategory: 'Basic details',
            type: 'error'
          };
          logger.push(loggerObj);
          errToastMessage(getErrorMessage(error));
        });
    },
    displayName: 'Basic Details'
  })
);
