import React, { useState, useEffect, useRef, useMemo, createContext } from 'react';

const DropzoneContext = createContext(undefined);

export function DropzoneProvider({ children }) {
  const [isDragging, setDragging] = useState(false);

  const isFileFromOS = useRef(true);
  const cachedTarget = useRef(null);

  const hasDraggedFileFromBrowserOutsideOfWindow = useRef(false);

  useEffect(() => {
    const handleDragEnter = (e) => {
      cachedTarget.current = e.target;
    };

    const handleDragStart = () => {
      isFileFromOS.current = false;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;
    };

    const handleDragOver = (e) => {
      e.preventDefault();

      if (hasDraggedFileFromBrowserOutsideOfWindow.current && document.hasFocus()) {
      } else if (isFileFromOS.current) {
        hasDraggedFileFromBrowserOutsideOfWindow.current = false;
        if (!isDragging) setDragging(true);
      }
    };

    const handleDragLeave = (e) => {
      if (e.target === cachedTarget.current) {
        if (isFileFromOS.current && !hasDraggedFileFromBrowserOutsideOfWindow.current) {
          if (isDragging) setDragging(false);
        } else {
          hasDraggedFileFromBrowserOutsideOfWindow.current = true;
          isFileFromOS.current = true;
        }
      }
    };

    const handleDrop = (e) => {
      e.preventDefault();
      isFileFromOS.current = true;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;

      if (isDragging) setDragging(false);
    };

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragstart', handleDragStart);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragstart', handleDragStart);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [isDragging]);

  const value = useMemo(() => {
    return { isDragging };
  }, [isDragging]);

  return <DropzoneContext.Provider value={value}>{children}</DropzoneContext.Provider>;
}

export default function useDropzoneCustomHook() {
  const context = React.useContext(DropzoneContext);

  if (!context) {
    throw new Error(`useDropzone must be used within a DropzoneProvider`);
  }

  return context.isDragging;
}
