import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export { mapDispatchToProps, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps);
