// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { has, isEmpty } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

// Import  styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styles from './CompanyAdmin.module.scss';

// Import local components & utils
import { errToastMessage, getBanners, jwtParser, toCamelCaseTemp } from '../../utils/utilities';
import { getAdministrators, getHRMSDetails, getRecommendedChecks } from '../../api/company';
import localStorage from '../../api/localStorage';

import RecentlyAdded from './Dashboard/RecentlyAdded';
import Loader from '../../core-components/Loader';
import WelcomeModal from './AlumniWelcomeModal/WelcomeModal';
import UpsellBanner from './Banners/UpsellBanner/UpsellBanner';
import AlumniVerification from './Banners/AlumniVerification/AlumniVerification';
import MarketingBanner from '../MarketingBanner/MarketingBanner';
import RazorpayBanner from './Banners/Razorpay/RazorpayBanner';
import { updateCompanyPaymentType, updateProfile } from '../../actions/authentication';
import UpsellServices from './UpsellServices/UpsellServices';

export default (props) => {
  // Props
  const { paymentDetails } = props;
  const dispatch = useDispatch();
  // State variables
  const [recentCandidates, setRecentCandidates] = useState([]);
  const [loading, setLoading] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [upsellRecommendationData, setUpsellRecommendationData] = useState({});
  const [carouselBanners, setCarouselBanners] = useState(['refer']);

  // Other constants
  const CarouselBanners = {
    upsell: (
      <UpsellBanner
        getRecommendationsChecksApi={() => getRecommendationsChecksApi('DASHBOARD')}
        upsellRecommendationData={upsellRecommendationData}
      />
    ),
    // As per Improvement 20836
    // refer: <ReferAndEarnBanner history={props.history} />,

    // Commenting out the Slack Connect Banner under IMP-21002
    // connect: (
    //   <SlackConnectBanner
    //     history={props.history}
    //     getBanner={() =>
    //       setCarouselBanners(getBanners({ paymentDetails, upsellRecommendationData }))
    //     }
    //   />
    // ),
    alumniVerification: <AlumniVerification />,
    marketing: <MarketingBanner />,
    razorpay: <RazorpayBanner />
  };
  const swiperRefLocal = useRef();

  const getUserPaymentDetails = (userData, paymentData) => {
    const { userDetails, companyDetails } = paymentData;
    const response = {};
    const token = localStorage.getToken();
    const parsedJwtDetail = jwtParser(token);

    // Extract keys from param1 (userData)
    response.id = userDetails?.id;
    response.name = userDetails?.name;
    response.email = userDetails?.email;
    response.mobile = userDetails?.mobile;
    response.access_level = userDetails?.accessLevel;
    response.user_email_reject_type = userDetails?.emailRejectType || null;
    response.candidates_added = userData?.candidates_added || 0;
    response.updated_at = userData?.updated_at || null;
    response.user_category_mappings = userData?.user_category_mappings || [];
    response.is_admin_deletion_allowed = userData?.is_admin_deletion_allowed || false;

    // Extract keys from param2 (paymentData)
    response.is_credit_limit_exceeded = paymentData?.isCreditLimitExceeded || false;
    response.any_unpaid_invoices = paymentData?.anyUnpaidInvoices || false;
    response.packages = paymentData?.packages || { count: 0, rows: [] };
    response.allow_adding_candidates = paymentData?.allowAddingCandidates || false;
    response.tax = paymentData?.tax || 0;
    response.totalPendingChargesCount = paymentData?.totalPendingChargesCount || 0;
    response.alumni_verification_enabled = paymentData?.alumniVerificationEnabled || false;
    response.alumni_verification_url = paymentData?.alumniVerificationUrl || null;
    response.isReferralEnabled = paymentData?.isReferralEnabled || false;
    response.disable_ca_feature_reminder = paymentData?.disableCaFeatureReminder || null;
    response.emailBounceData = paymentData?.emailBounceData || { count: 0 };
    response.userEmailBounceData = paymentData?.userEmailBounceData || { count: 0 };
    response.isConnected = paymentData?.isConnected || false;
    response.mobile = paymentData?.mobile || '';
    response.designation = paymentData?.designation || '';

    // Extract nested company details

    if (paymentData.companyDetails && paymentData.companyDetails.company) {
      const company = paymentData.companyDetails.company;
      response.company_user_mapping = {
        company_id: companyDetails?.companyId,
        access_level: userDetails?.accessLevel,
        company: {
          user_id: userDetails?.id,
          company_id: companyDetails?.companyId,
          id: companyDetails?.id,
          access_level: userDetails?.accessLevel,
          id: company?.id || null,
          uuid: company?.uuid || '',
          name: company?.name || '',
          friendly_name: company?.friendlyName || '',
          show_brand_name_in_report: company?.showBrandNameInReport || false,
          company_url: company?.companyUrl || '',
          gstin: company?.gstin || '',
          city: company?.city || '',
          white_labeled: company?.whiteLabeled || false,
          package_quantity: company?.packageQuantity || null,
          package_type: company?.packageType || null,
          linked_in_url: company?.linkedInUrl || '',
          tds: company?.tds || null,
          show_bgv_period_in_report: company?.showBgvPeriodInReport || false,
          name_config_in_report: company?.nameConfigInReport || '',
          upsell_laptop_banner_enabled: company?.upsellLaptopBannerEnabled || false,
          payments_company_model_config: {
            model_type: parsedJwtDetail?.data?.modelType
          }
        }
      };
    } else {
      response.company = {};
    }

    return response;
  };

  // UseEffects
  useEffect(() => {
    window.scrollTo(0, 0);
    props?.getCandidates('10', '0').then(() => {
      setRecentCandidates(props?.candidates?.all[0]?.candidates);
      setLoading(props?.candidates?.loading);
    });

    getRecommendationsChecksApi('DASHBOARD');
  }, []);

  useEffect(() => {
    if (paymentDetails?.hasOwnProperty('alumniVerificationEnabled')) {
      getHRMSDetails()
        .then((res) => {
          exEmpRemainderModalHandler(res?.data?.data?.integrationDetails ? true : false);
        })
        .catch((err) => {
          exEmpRemainderModalHandler(false);
          console.error(err);
        });
    }

    if (paymentDetails && !paymentDetails.loading) {
      let userData = null;
      const token = localStorage.getToken();
      const parsedJwtDetail = jwtParser(token);
      let profileData = {};
      if (parsedJwtDetail?.accessLevel === '5') {
        getAdministrators(10, 0).then((res) => {
          userData = res?.data?.data?.admins?.[0];

          if (userData) {
            profileData = getUserPaymentDetails(userData, paymentDetails);
          }
        });
      } else {
        profileData = getUserPaymentDetails(null, paymentDetails);
      }

      dispatch(updateProfile(profileData));
      dispatch(updateCompanyPaymentType(parsedJwtDetail.data.modelType));
    }
    setCarouselBanners(getBanners({ paymentDetails, upsellRecommendationData }));
  }, [paymentDetails]);

  useEffect(() => {
    setCarouselBanners(getBanners({ paymentDetails, upsellRecommendationData }));
  }, [upsellRecommendationData]);

  // Helper Functions
  const exEmpRemainderModalHandler = (isConnectedWithHRMS) => {
    const hideXEmpRemainderTill = Cookies.get(
      'hideXEmpRemainderTill' + props?.profile?.profile?.id
    );
    const isXEmpModalDisplayedToday = Cookies.get(
      'isXEmpModalDisplayedToday' + props?.profile?.profile?.id
    );
    const isExEmpEnabled = props?.paymentDetails?.alumniVerificationEnabled;

    const isSetShowModal =
      hideXEmpRemainderTill || isExEmpEnabled || isConnectedWithHRMS || isXEmpModalDisplayedToday
        ? false
        : true;
    setShowModal(isSetShowModal);
  };

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  const getRecommendationsChecksApi = (currentRoute) => {
    const params = {
      page: currentRoute
    };

    getRecommendedChecks(params)
      .then((res) => {
        setUpsellRecommendationData(toCamelCaseTemp(res?.data?.data));
      })
      .catch((err) => {
        errToastMessage(err);
        console.error(err);
      });
  };

  // Child components
  const Carousel = () => {
    return (
      <div
        className={styles.cContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {carouselBanners.length ? (
          <div className='swiper-custom'>
            <Swiper
              loop={true}
              ref={swiperRefLocal}
              slidesPerView={1}
              speed={700}
              autoplay={{ delay: 6000 }}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination]}
              className='mySwiper'
            >
              {carouselBanners.map((item, i) => {
                return (
                  <SwiperSlide key={'CAR_' + i}>
                    <div>{CarouselBanners[item]}</div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {showModal && !loading && !props?.paymentDetails?.disableCaFeatureReminder && (
        <WelcomeModal
          userId={props?.profile?.profile?.id}
          {...props}
          onHide={() => {
            const midnight = new Date();
            midnight.setHours(23, 59, 59, 999);
            Cookies.set(
              'isXEmpModalDisplayedToday' + props?.profile?.profile?.id,
              midnight.toDateString(),
              {
                expires: midnight,
                path: '/'
              }
            );
            setShowModal(false);
          }}
        />
      )}
      {props?.profile && !isEmpty(props?.profile) && has(props?.profile, 'profile') ? (
        <>
          {Carousel()}
          <UpsellServices />
          <RecentlyAdded data={recentCandidates} loading={loading} {...props} />
        </>
      ) : (
        <Card className={styles.loadingviewCandidateCard}>
          <Loader className={styles.viewCandidateLoader} />
        </Card>
      )}
    </div>
  );
};
