import { connect } from 'react-redux';
import { putCheckOnHold } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    adcSettings: state.adcSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putCheckOnHoldAPI: (data) => dispatch(putCheckOnHold(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
