// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BVaY4aL2foj8Tj2uvOMI {
  background: linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%);
  min-height: 340px;
  min-width: 1200px;
  padding-bottom: 17px;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l {
  max-width: 1370px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 24px 0 18px;
  position: relative;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 {
  display: flex;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .LSlBzfKymhYrITofWLGn {
  margin-right: 32px;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH {
  display: flex;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH button {
  background: #2162ab;
  margin-top: 6px;
  height: 36px;
  padding: 6px 19px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 8px;
}
@media only screen and (max-width: 1300px) {
  .BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH button {
    font-size: 13px !important;
  }
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH .Y4adcrbJ7eRB56X7cDC0 {
  outline: 3px solid #1775cb;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH .SbrDSvyz66yKDBeKauxV {
  display: flex;
  background: #2162ab;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH .D7lnMFXOGNuhBXRhGVqZ {
  display: flex;
  background: #ffc107;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH .t7i9Wnqvhes28dgTH3Ne:hover {
  background: #daa403;
  color: #ffffff;
  border: none;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .Y4NKRpsnKSX0hbNvmAzH .x_wcDRAEUU0Yzkez92O3:hover {
  background: #347ed1;
  color: #ffffff;
  border: none;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .LfbNb6IfeVolhGN_fUh4 {
  display: flex;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .LfbNb6IfeVolhGN_fUh4 button {
  background: #d3455b;
  margin-top: 6px;
  height: 36px;
  padding: 6px 19px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 8px;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .AYn1VONGRjYvtXbr4Al0 .LfbNb6IfeVolhGN_fUh4 .mnhlnWAsnffkXJV52y7_:hover {
  background: #cc1934;
  color: #ffffff;
  border: none;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .PRmaRgl6tdPaEcwSpB6m {
  display: flex;
  justify-content: space-between;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .PRmaRgl6tdPaEcwSpB6m .sQltu8VwYPjhi9HtsuhN {
  margin-right: 8px;
  margin-top: 5px;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .PRmaRgl6tdPaEcwSpB6m .sQltu8VwYPjhi9HtsuhN input {
  width: 268px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  height: 32px;
  padding: 4px 16px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .PRmaRgl6tdPaEcwSpB6m .YwehsybXec3t9Y7ELGFg {
  margin-top: 7px;
  margin-right: 16px;
  cursor: pointer;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .PRmaRgl6tdPaEcwSpB6m .gQQ4VTn3HQBmXC4VNUW1 {
  background: #0b8497;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  padding-top: 2px;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .PRmaRgl6tdPaEcwSpB6m .XkcnuM0wO0ITG99_bJHf button {
  background: #228889;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  height: 48px;
  width: 48px;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .tdHvh4Jv31vsQDT2H5XN {
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 1;
}
.BVaY4aL2foj8Tj2uvOMI .MUj9xDhwJbt5Ee0z645l .f1zUPDWmwLntoK4qvQwp {
  margin-bottom: 4px;
  position: absolute;
  margin-top: 180px;
}
.BVaY4aL2foj8Tj2uvOMI .FdPgp8flEk3NE23d7NK3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  cursor: pointer;
  font-weight: 400;
  padding-bottom: 24px;
}
.BVaY4aL2foj8Tj2uvOMI .FdPgp8flEk3NE23d7NK3 .VBlwOGvub9_QUeY5iKfa {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
}
.BVaY4aL2foj8Tj2uvOMI .FdPgp8flEk3NE23d7NK3 .VBlwOGvub9_QUeY5iKfa .B6_W9pzDqmenY0CvIN_2 {
  font-weight: 600 !important;
  color: #333333;
}
.BVaY4aL2foj8Tj2uvOMI .FdPgp8flEk3NE23d7NK3 .VBlwOGvub9_QUeY5iKfa .mW6zrZ5K_di0LgrJK2o_ {
  font-weight: 400;
  color: #333333;
}
.BVaY4aL2foj8Tj2uvOMI .FdPgp8flEk3NE23d7NK3 .ZjqPbbDwHMoK7MMq87ld {
  font-weight: 600 !important;
  display: block;
  color: #333333;
  text-align: center;
  max-width: fit-content;
  padding: 0px 3px;
  cursor: pointer;
}
.BVaY4aL2foj8Tj2uvOMI .FdPgp8flEk3NE23d7NK3 .ZjqPbbDwHMoK7MMq87ld .rF2ShgzmkmuBDB4IPDkM {
  color: #2755fe;
  font-weight: 600;
}

.gUyjOY6t1WVMCf8st3bc {
  cursor: pointer;
}

.HwpQpnlyGiVLiN90xCpZ {
  max-width: 1200px;
  margin: auto;
}

.XSsmWN69UlVwGsiM482x {
  font-weight: 400;
}

.NajVlJ8igczJWutv0h3c {
  color: #ffffff;
}

.vUAlSc1EjvX33wY3yYE1 {
  color: #2755fe;
  font-weight: 600;
}

.JTLiTma2aNCqHcHh7ntD {
  min-height: 410px !important;
}

.J7y2yAl50RvXEMNbSgy9 {
  min-height: 450px !important;
}

.hmhDXJarSSysuYlspYeS {
  min-height: 520px !important;
}

.LmIQ5PnNImW45VmrK_ey {
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 0;
  height: 40px;
}
.LmIQ5PnNImW45VmrK_ey .JNEp4izLXdwkG0l4iRvS {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
}
.LmIQ5PnNImW45VmrK_ey .JNEp4izLXdwkG0l4iRvS .VacsacK5bv0y8G4PfBbQ {
  font-size: 14px;
  font-weight: 400;
}
.LmIQ5PnNImW45VmrK_ey .JNEp4izLXdwkG0l4iRvS .VacsacK5bv0y8G4PfBbQ .zAhTLutD6rqX0qssX5jk {
  font-weight: 600 !important;
  text-decoration: underline;
  color: #2755fe;
}
.LmIQ5PnNImW45VmrK_ey .JNEp4izLXdwkG0l4iRvS .VacsacK5bv0y8G4PfBbQ .fKBYAvERz0O2ghBztj8t {
  font-weight: 600 !important;
  text-decoration: underline;
  color: #2755fe;
}
.LmIQ5PnNImW45VmrK_ey .JNEp4izLXdwkG0l4iRvS .cr1hs4S6RzsqNdAnCpFj {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-top: -1px;
  height: 14px;
  width: 14px;
}

.neFy6wwG2orHyBKAQBXH {
  background-color: #fffbe6 !important;
  color: #333333 !important;
  border-color: #fffbe6 !important;
}

.J1lmF_ROPTCAln4337Cl {
  border-color: #ffe3e3 !important;
  background-color: #ffe3e3 !important;
  color: #333333 !important;
}

.X72AROOKuQpes3OOzSXe {
  color: #333333 !important;
  background-color: #fffbe6 !important;
  font-size: 14px;
  font-weight: 400 !important;
  margin-bottom: 4px;
}
.X72AROOKuQpes3OOzSXe .WuwWR3Jq9m3_GG7mYv5Q {
  color: #2755fe !important;
  font-weight: 600 !important;
  text-decoration: underline;
}

.MDOY3LwtRielN4QwDKqd {
  font-weight: 400 !important;
}

.cfIOrqRPLzfEeyNFulaA {
  max-width: 18rem;
  font-family: Poppins, sans-serif;
  margin-right: 10px;
  font-size: 14px;
  line-height: 21px;
  text-align: end;
}
.cfIOrqRPLzfEeyNFulaA .uapZ4H5LUfaNYKRFU4vP {
  color: #ffffff;
  margin-bottom: 0;
}
@media only screen and (max-width: 1300px) {
  .cfIOrqRPLzfEeyNFulaA .uapZ4H5LUfaNYKRFU4vP {
    font-size: 12px !important;
    max-width: 200px;
  }
}
.cfIOrqRPLzfEeyNFulaA .q7U2zcG0RH8O7BuRuA_w {
  color: #68aab2;
  margin-bottom: 0;
}

.fuQRF99WaDDIKuJ0DWhG {
  height: 20px;
}

.ImEAmMWvbdZPfIH3Lmu4 {
  display: flex;
  align-items: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerParent": `BVaY4aL2foj8Tj2uvOMI`,
	"headerMain": `MUj9xDhwJbt5Ee0z645l`,
	"header1": `AYn1VONGRjYvtXbr4Al0`,
	"headerLogo": `LSlBzfKymhYrITofWLGn`,
	"headerButtons": `Y4NKRpsnKSX0hbNvmAzH`,
	"buttonWithBorder": `Y4adcrbJ7eRB56X7cDC0`,
	"approveChargesNormal": `SbrDSvyz66yKDBeKauxV`,
	"approveChargesWarning": `D7lnMFXOGNuhBXRhGVqZ`,
	"approveChargesWarningHover": `t7i9Wnqvhes28dgTH3Ne`,
	"hover": `x_wcDRAEUU0Yzkez92O3`,
	"headerDebounceButton": `LfbNb6IfeVolhGN_fUh4`,
	"candidateButtonHover": `mnhlnWAsnffkXJV52y7_`,
	"header2": `PRmaRgl6tdPaEcwSpB6m`,
	"searchField": `sQltu8VwYPjhi9HtsuhN`,
	"searchIcon": `YwehsybXec3t9Y7ELGFg`,
	"searchIconActive": `gQQ4VTn3HQBmXC4VNUW1`,
	"profileBtn": `XkcnuM0wO0ITG99_bJHf`,
	"banner": `tdHvh4Jv31vsQDT2H5XN`,
	"adminBouncedBanner": `f1zUPDWmwLntoK4qvQwp`,
	"actionsContainer": `FdPgp8flEk3NE23d7NK3`,
	"warningText": `VBlwOGvub9_QUeY5iKfa`,
	"warningTextFirst": `B6_W9pzDqmenY0CvIN_2`,
	"warningSecondFirst": `mW6zrZ5K_di0LgrJK2o_`,
	"invoices": `ZjqPbbDwHMoK7MMq87ld`,
	"mailTag": `rF2ShgzmkmuBDB4IPDkM`,
	"cursorPointer": `gUyjOY6t1WVMCf8st3bc`,
	"headerDashboard": `HwpQpnlyGiVLiN90xCpZ`,
	"fontWeight600": `XSsmWN69UlVwGsiM482x`,
	"myTeamTag": `NajVlJ8igczJWutv0h3c`,
	"linkColor": `vUAlSc1EjvX33wY3yYE1`,
	"twoBanners": `JTLiTma2aNCqHcHh7ntD`,
	"threeBanners": `J7y2yAl50RvXEMNbSgy9`,
	"fourBanners": `hmhDXJarSSysuYlspYeS`,
	"bannerCardContainer": `LmIQ5PnNImW45VmrK_ey`,
	"bannerDivContainer": `JNEp4izLXdwkG0l4iRvS`,
	"bannerText": `VacsacK5bv0y8G4PfBbQ`,
	"bannerTextYellow": `zAhTLutD6rqX0qssX5jk`,
	"bannerTextRed": `fKBYAvERz0O2ghBztj8t`,
	"warningRedIcon": `cr1hs4S6RzsqNdAnCpFj`,
	"lowBalance": `neFy6wwG2orHyBKAQBXH`,
	"criticalLow": `J1lmF_ROPTCAln4337Cl`,
	"noPackageBanner": `X72AROOKuQpes3OOzSXe`,
	"ctaColor": `WuwWR3Jq9m3_GG7mYv5Q`,
	"ptext": `MDOY3LwtRielN4QwDKqd`,
	"profileInfo": `cfIOrqRPLzfEeyNFulaA`,
	"companyName": `uapZ4H5LUfaNYKRFU4vP`,
	"loginName": `q7U2zcG0RH8O7BuRuA_w`,
	"heightAdjustment": `fuQRF99WaDDIKuJ0DWhG`,
	"headerBtn": `ImEAmMWvbdZPfIH3Lmu4`
};
export default ___CSS_LOADER_EXPORT___;
