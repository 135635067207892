import { Box } from '@material-ui/core';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LuArrowLeftRight } from 'react-icons/lu';
import { PiPlugsFill } from 'react-icons/pi';

import AppConstants from '../../../../../core-components/AppConstants';
import { integrationsCardData } from '../../../frontend-common/Integrations/integrationItems';
import SpringVerifyIcon from '../../../frontend-common/images/springverify.svg';

import styles from './AtsHrmsConnectModal.module.scss';

const ConnectModal = ({ show, onHide, list }) => {
  const options = [...list];
  const iconName = list?.length
    ? Object.values(integrationsCardData).find((item) => item.title === list[0])?.icon
    : null;
  const IntergrationIconName = iconName
    ? require(`../../../frontend-common/images/${iconName}`)
    : null;

  const concatenateArray = (arr) => {
    if (arr?.length > 1) {
      const lastElement = arr.pop();
      return `${arr.join(', ')} and ${lastElement}`;
    } else {
      return arr[0];
    }
  };

  const handleConnectClick = () => {
    if (list?.length > 1) {
      window.open(`${AppConstants.baseURL}integrations`, '_blank');
    } else if (list?.length === 1) {
      const integrationName = list[0]?.toLowerCase();
      window.open(`${AppConstants.baseURL}integrations/${integrationName}`, '_blank');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <div className='d-flex align-items-center justify-content-center'>
        {list.length === 1 && IntergrationIconName && (
          <div>
            <img
              width={34}
              height={34}
              src={SpringVerifyIcon}
              alt='Icon'
              className={styles.iconWrapper}
            />
            <LuArrowLeftRight size={34} className={styles.iconWrapper} />
            <img
              width={34}
              height={34}
              src={IntergrationIconName}
              alt='Icon'
              className={styles.iconWrapper}
            />
          </div>
        )}
        {list.length > 1 && (
          <div>
            <PiPlugsFill size={34} />
          </div>
        )}
      </div>
      <Modal.Body>
        <p className='text-center'>
          Connect {concatenateArray(options)} to automate and speed up the process of adding
          candidates for background verification.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Box display='flex' width='100%' justifyContent='center'>
          <Button size='sm' variant='primary' onClick={handleConnectClick}>
            Connect
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectModal;
