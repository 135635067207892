import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Button from '../../../../core-components/Button';
import Image from '../../../../core-components/Image';

import styles from './AdminModals.module.scss';

export default (props) => {
  const { handleSubmit, isSubmitting, addAnotherCandidateHandler } = props;
  return (
    <Modal
      size='md'
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className={styles.modalBody}>
          <Image
            name={
              props?.content?.includes('Admin') ? 'GreenTickModal.svg' : 'greenWhiteTickModal.svg'
            }
          />
          {props?.content !== 'CandidateAdded' ? (
            <div className={styles.content}>{props?.content}</div>
          ) : (
            <div>
              <div className={styles.content}>
                {'Candidate details has been added successfully'}
              </div>
              <div className={styles.subContent}>{'You can proceed with more details'}</div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props?.content === 'CandidateAdded' ? (
          <div className={styles.buttonFooter}>
            <Form onSubmit={handleSubmit}>
              <Button type='submit' disabled={isSubmitting} className={styles.submitButton}>
                {'Proceed'}
              </Button>
              <Button
                className={styles.cancelButton}
                onClick={() => {
                  props?.onHide();
                }}
              >
                {'Later'}
              </Button>
            </Form>
          </div>
        ) : props?.content === 'BGV Link has been sent to the candidate' ? (
          <div className={styles.buttonFooter}>
            <Button className={styles.submitButton} onClick={addAnotherCandidateHandler}>
              {'Add another candidate'}
            </Button>
            <Button
              className={styles.cancelButton}
              onClick={() => {
                props?.onHide();
              }}
            >
              {'Later'}
            </Button>
          </div>
        ) : (
          <div className={styles.buttonFooter}>
            <Button className={`${styles.okayButton}`} onClick={() => props?.onHide()}>
              {'Okay'}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
