import React, { useState } from 'react';

import FlightServiceModal from './Modals/FlightServiceModal';

import AppConstants from '../../../core-components/AppConstants';

import styles from './UpsellServices.module.scss';

import laptopCard from '../../../images/laptopCard.svg';
import flightTravelCard from '../../../images/flightTravelCard.svg';
import moreServices from '../../../images/moreServices.svg';
import newTag2 from '../../../images/newTag2.svg';
import eorServicesCard from '../../../images/eorServicesCard.svg';

const UpsellServices = (props) => {
  const [isFlightTravelModalOpen, setIsFlightTravelModalOpen] = useState(false);

  const handleFlightModal = () => {
    setIsFlightTravelModalOpen(!isFlightTravelModalOpen);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span>HR services</span> <img src={newTag2} className={styles.newTag} />
        </div>
        <div className={styles.cardContainer}>
          <img
            src={laptopCard}
            alt='Upsell Laptop'
            onClick={() => window.open(`${AppConstants.baseURL}buy-rent-laptop`, '_blank')}
          />
          <img src={flightTravelCard} alt='Upsell Flight Services' onClick={handleFlightModal} />
          <img
            src={eorServicesCard}
            alt='Eor services'
            onClick={() => window.open('https://get.deel.com/springverify', '_blank')}
          />
          <img src={moreServices} alt='More Upsell services' />
        </div>
      </div>
      {isFlightTravelModalOpen && (
        <FlightServiceModal show={isFlightTravelModalOpen} handleClose={handleFlightModal} />
      )}
    </>
  );
};
export default UpsellServices;
