import * as Yup from 'yup';

export default Yup.object().shape({
  reason: Yup.string().required('Close reason required.'),
  comment: Yup.mixed().when('reason', {
    is: 'OTHER',
    then: Yup.string()
      .min(10, 'Reason must be at least 10 characters')
      .required('Reason is required')
  })
});
