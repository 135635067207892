// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qwDiWIIf68UpIeNntFEt {
  font-family: Poppins, sans-serif;
  color: #333333;
}
.qwDiWIIf68UpIeNntFEt .cxskJPFROZyiPafEd_Fp {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
}
.qwDiWIIf68UpIeNntFEt .cxskJPFROZyiPafEd_Fp img {
  margin-right: 16px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS {
  margin-left: 40px;
  margin-top: 32px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .frE7NzVR8DOGkxlrqpSQ {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 3px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .frE7NzVR8DOGkxlrqpSQ .CYAUXteWpA2FpJgmnFKS {
  display: inline;
  margin-left: 16px;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .HI_ssNgajupWLLphcUXd {
  max-width: 820px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .HI_ssNgajupWLLphcUXd svg {
  margin-right: 8px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv {
  display: flex;
  margin-bottom: 8px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .xLCJ8oEKNkVtg4DGf9bx {
  width: 154px;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .FTqh9jpZY3CUDoj0ysdD {
  width: 448px;
  font-size: 14px;
  line-height: 21px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc {
  width: 674px;
  display: flex;
  flex-wrap: wrap;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn {
  margin-right: 12px;
  display: inline-block;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a,
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a:hover {
  text-decoration: none;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a img,
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a:hover img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS hr {
  margin: 0;
  margin-top: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}
.qwDiWIIf68UpIeNntFEt .KXr8xgBecM8TFiGha5T4 {
  max-width: 200px;
  max-height: 200px;
}
.qwDiWIIf68UpIeNntFEt .TLjy8bAql3x_cOPupbHv {
  overflow-y: auto;
  overflow-x: hidden;
}
.qwDiWIIf68UpIeNntFEt canvas {
  width: 200px !important;
  height: 200px !important;
  margin-bottom: 10px;
}
.qwDiWIIf68UpIeNntFEt .xE8OlkPebqhguck5XW_y {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.yKEDJHw6KFAPR550frWJ {
  display: flex;
  align-content: center;
  align-items: center;
  margin-right: 32px;
}
.yKEDJHw6KFAPR550frWJ ._ecXjKzf4jvleGyiba3k {
  position: relative;
}
.yKEDJHw6KFAPR550frWJ ._ecXjKzf4jvleGyiba3k button {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yKEDJHw6KFAPR550frWJ ._ecXjKzf4jvleGyiba3k button:focus, .yKEDJHw6KFAPR550frWJ ._ecXjKzf4jvleGyiba3k button:active {
  background-color: rgb(241, 245, 255) !important;
  box-shadow: none !important;
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH {
  top: 52px !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  padding: 10px 0;
  width: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH .bjO4RAISuX5UcuhkweL_ {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
  margin-bottom: 8px;
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH .bjO4RAISuX5UcuhkweL_:hover {
  background: #ebefff;
  color: #2755fe;
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH .wAH2PB3svxPdFBMbW2DG {
  font-weight: 500;
  color: #333;
  padding: 6px 16px;
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH .e2LwXYw5lIcxsRbDyOfH {
  font-weight: 500;
  color: lightgray;
  padding: 6px 16px;
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH .FliL7JRsvsyMugPVhDTQ {
  cursor: not-allowed;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
  margin-bottom: 8px;
  color: inherit;
  opacity: 0.5;
}
.yKEDJHw6KFAPR550frWJ .fMVCJGDVtMPrQekxOjkH .FliL7JRsvsyMugPVhDTQ:hover {
  background: "transparent";
}

.avY8sGm0Os2tJ03JPLVv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.B0_HeZTH83a22xM5LIdv::after {
  display: none;
}

.mDTl8QGttEMjcVwPJqxz {
  overflow-wrap: break-word;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationDetailsContainer": `qwDiWIIf68UpIeNntFEt`,
	"verificationDetailsHeading": `cxskJPFROZyiPafEd_Fp`,
	"verificationDetailsContent": `U7t6YLBIba5Od0BfbtQS`,
	"verificationCard": `rHGWVI3yReLxZBF7Gh4z`,
	"cardHeader": `frE7NzVR8DOGkxlrqpSQ`,
	"duration": `CYAUXteWpA2FpJgmnFKS`,
	"cardStatus": `HI_ssNgajupWLLphcUXd`,
	"cardContentWrapper": `YMvFIQpO1bXab0w61flT`,
	"cardContent": `KIOKW_YQRX3SCpAkjCWv`,
	"label": `xLCJ8oEKNkVtg4DGf9bx`,
	"value": `FTqh9jpZY3CUDoj0ysdD`,
	"documents": `qNt1HTNSQr6j0bhhMSCc`,
	"docValue": `UFv9vbjOgsdAs7eqK3Cn`,
	"viewPdfWrapper": `KXr8xgBecM8TFiGha5T4`,
	"docs": `TLjy8bAql3x_cOPupbHv`,
	"titleContainer": `xE8OlkPebqhguck5XW_y`,
	"dropdownMenuWrapper": `yKEDJHw6KFAPR550frWJ`,
	"mainDropdown": `_ecXjKzf4jvleGyiba3k`,
	"dropdownMenu": `fMVCJGDVtMPrQekxOjkH`,
	"dropdownParticularCard": `bjO4RAISuX5UcuhkweL_`,
	"dropdownItem": `wAH2PB3svxPdFBMbW2DG`,
	"dropdownItemDisabled": `e2LwXYw5lIcxsRbDyOfH`,
	"disabledDropdownParticularCard": `FliL7JRsvsyMugPVhDTQ`,
	"cardHeaderContainer": `avY8sGm0Os2tJ03JPLVv`,
	"toggleButton": `B0_HeZTH83a22xM5LIdv`,
	"reasonSpan": `mDTl8QGttEMjcVwPJqxz`
};
export default ___CSS_LOADER_EXPORT___;
